import React, { useEffect, useState } from 'react';
import {useRecoilState,} from 'recoil';
import {LivekitDevicesRequested} from "../atoms"

function AudioProcessor(props) {
  const [livekitDevicesRequested, setLivekitDevicesRequested] = useRecoilState(LivekitDevicesRequested)

    // useEffect(() => {
    //     // Everything around if statement
    //     console.log("ASASAS useEffect of AudioProcessor")
  
    //     navigator.mediaDevices.getUserMedia({audio: props.audioConstraints, video: false}).then(props.onUserMedia).catch(props.onUserMediaError);
    //   }, []) 
    // useEffect(() => {
    //     // Everything around if statement
    //     console.log("ASASAS useEffect of AudioProcessor props.audioConstraints change")
  
    //     navigator.mediaDevices.getUserMedia({audio: props.audioConstraints, video: false}).then(props.onUserMedia).catch(props.onUserMediaError);
    //   }, [props.audioConstraints])
    
    useEffect(() => {
      if (props.room && props.room != null) {
        if (props.room.localParticipant && !livekitDevicesRequested && props.connectedToRoom) {
          // Turns microphone track on
          props.room.localParticipant.setMicrophoneEnabled(true)
          setLivekitDevicesRequested(true)
        }        
      }    
    }, [props.audioConstraints, props.room, livekitDevicesRequested, props.connectedToRoom])    
    return <audio class="MyMicAudio"></audio>           
}



export default AudioProcessor
import { Button, Grid, Slider, Stack, Switch, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import {FormattedMessage} from "react-intl";
import { SuccessMessage, MaxGameNameLength, MaxRoomPasswordLength, DefaultLang, MaxParticipantsPremium, MaxParticipantsStandart } from '../../constants';
import GemesService from '../../services/games/GamesService';
import {useNavigate} from "react-router-dom"
import {useRecoilState} from "recoil";
import {UserPersonalAtom, UserInfoAtom} from "../atoms/UserAtoms";
import {BirthDayModal, ShowTooYoung} from "../atoms/Common";
import {underAgeValidate}from "../../utils/DateUtils"
import {checkForMate} from "../../utils/TextFilters"
import {lightLogout} from "../../utils/common"
import {ShareRoomLinkModal} from "../../livekit_prod/atoms"



const CreateGame = (props) => {
	const [roomName, setRoomName] = useState("");
	const [password, setPassword] = useState("");
	const navigate = useNavigate();
	const [roomNameError, setRoomNameError] = useState(null);
	const [gamePasswordError, setGamePasswordError] = useState(null);

    const [userPersonal, setUserPersonal] = useRecoilState(UserPersonalAtom);
	const [birthDayModal, setBirthDayModal] = useRecoilState(BirthDayModal)
	const [showTooYoung, setShowTooYoung] = useRecoilState(ShowTooYoung)

	const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);
    const [showShareRoomLink, setShowShareRoomLink] = useRecoilState(ShareRoomLinkModal);


	const changeRoomName = (event) => {
		if (event.target.value.length > MaxGameNameLength) {
			setRoomNameError(<span><FormattedMessage id="cantbelonger"/> {MaxGameNameLength}</span>)
			return
		} else {
			setRoomNameError(null)
		}
		if (checkForMate(DefaultLang, event.target.value)) {
			setRoomNameError(<span><FormattedMessage id="cant_use_mate"/></span>)		
		} else {
			setRoomNameError(null)
		}
		setRoomName(event.target.value);
	}
	const changePassword = (event) => {
		if (event.target.value.length > MaxRoomPasswordLength) {
			setGamePasswordError(<span><FormattedMessage id="cantbelonger"/> {MaxRoomPasswordLength}</span>)
			return
		} else {
			setGamePasswordError(null)
		}
		setPassword(event.target.value);
	}
    
	const createRoom = () => {
		if (roomName.length > MaxGameNameLength) {
			setRoomNameError(<span><FormattedMessage id="cantbelonger"/> {MaxGameNameLength}</span>)
			return
		} else {
			setRoomNameError(null)
		}
		if (password.length > MaxRoomPasswordLength) {
			setGamePasswordError(<span><FormattedMessage id="cantbelonger"/> {MaxRoomPasswordLength}</span>)
			return
		} else {
			setGamePasswordError(null)
		}
		GemesService.createRoom(roomName, props.gameName, password).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				setShowShareRoomLink(true)
				navigate("/room/"+response.data.RoomKey)
			}
		}).catch((err)=>{
			if (err.response.data.ErrorCode == 50) {
				if (userPersonal && !userPersonal.UserPersonal.Birthday.Valid) {
					setBirthDayModal({Open: true})
					return
				} else {
					lightLogout()
					setShowTooYoung({show: true})
					return
				}									
			}
		})
	}    
    return (
        <Grid
        container
        rowSpacing={2}
        spacing={{ xs: 1, sm: 2 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        direction="column"
        padding={1}
    >
        <Grid item xs={4} sm={8} md={12}>
            <Typography variant='h4'>
                <FormattedMessage id="creating_room" />
            </Typography>
            <Typography variant='body1'>
                <FormattedMessage id="first_game_will_be" /> <FormattedMessage id={props.gameName+"_name"}/>
            </Typography>			
        </Grid>

            <Grid
                item 
                container
                rowSpacing={0}
                spacing={{ xs: 0, sm: 0 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
            >
                    <Typography variant='body1'>
                        <FormattedMessage id="max_number_of_players_is" />: {MaxNumbersOfPlayers[props.gameName]}
                    </Typography>
                    <Typography variant='body1'>
						<FormattedMessage id="max_number_of_users_is" />: {userInfo.UserInfo.AccountType != "standart" ? MaxParticipantsPremium : MaxParticipantsStandart}
                    </Typography>
                    <Typography variant='body1'>
						{(userInfo.UserInfo.AccountType != "standart" ? MaxParticipantsPremium > MaxNumbersOfPlayers[props.gameName] : MaxParticipantsStandart > MaxNumbersOfPlayers[props.gameName]) && <FormattedMessage id="spectators_are_possible" />}
                    </Typography>										
            </Grid>

            {false && <Grid item xs={4} sm={8} md={12}>
				<TextField 
					fullWidth 
					value={roomName}
					onChange={changeRoomName}
					id="name" 
					label={<FormattedMessage id="room_name" />}
					variant="outlined" 
					size='small'
					helperText={roomNameError}
					error= {roomNameError != null}					
				/>
			</Grid>} 			                   

			<Grid item xs={4} sm={8} md={12}>
				<Typography variant='body1'>
                        <FormattedMessage id="entry_conditions" />
                </Typography>
				{false && <TextField 
					fullWidth 
					id="password" 
					label={<FormattedMessage id="password" />} 
					type="password" 
					variant="outlined"
					size='small'
					value={password}
					onChange={changePassword}
					helperText={gamePasswordError}
					error= {gamePasswordError != null}					
				/>}
			</Grid>        
            <Grid item xs={4} sm={8} md={12}>
				<Button variant="contained" size="large" onClick={createRoom} disabled={roomNameError != null}>
					<FormattedMessage id="create" />
				</Button>
			</Grid>
		</Grid>        
    )
}

export default CreateGame;

const MaxNumbersOfPlayers = {
	"hackword": 8,
	"iamalive": 8,
	"generalize": 10,
}
import React, { useState, useEffect, useRef } from 'react';
import SoundLevelChanger from './SoundLevelChanger'
import UserInfo from './UserInfo.js'
import BanMate from './BanMate.js'
import {PositionsToMute} from "../../atoms";
import {useRecoilState,} from 'recoil';
import MicOffIcon from '@mui/icons-material/MicOff';
import {MyInfo, ShowVideoAudioSettings, UseAudio, PositionsToHideVideo, UseVideo, RoomInfo} from '../../atoms'
import Webcam from "react-webcam";
import VideocamOff from '@mui/icons-material/VideocamOff';
import { Chip,} from '@mui/material';
import {FormattedMessage} from "react-intl";
import MyBoxMenu from "./MyBoxMenu"

function MyMediaBox(props) {
	const [borders, setBorders] = useState({Name: "none"});

    const [roomInfo, setRoomInfo] = useRecoilState(RoomInfo);  
    useEffect(() => {
		for (let nickname in roomInfo.roomMates) {
			if (roomInfo.roomMates[nickname].Position == props.myPosition && roomInfo.roomMates[nickname].Borders) {
				let borders = roomInfo.roomMates[nickname].Borders
				setBorders(borders)
			}
		}
	}, [roomInfo])

    const [myInfo, setMyInfo] = useRecoilState(MyInfo);
    const [showVideoAudioSettings, setShowVideoAudioSettings] = useRecoilState(ShowVideoAudioSettings);
    const [positionsToHideVideo, setPositionsToHideVideo]  = useRecoilState(PositionsToHideVideo);

    const [useAudio, setUseAudio] = useRecoilState(UseAudio);
    const [useVideo, setUseVideo] = useRecoilState(UseVideo);

    const [webcam, setWebcam] = useState(props.webcam)

    useEffect(()=> {
        if (positionsToHideVideo.has(props.myPosition)) {
            let webcamUpdate = props.webcam
            if (React.isValidElement(webcamUpdate)) {
                webcamUpdate = React.cloneElement(webcamUpdate, { videoConstraints: false }); 
            }
            setWebcam(webcamUpdate)  
        } 
    }, [props.webcam, positionsToHideVideo])

    let identityPositionStyle = {
        position: "absolute",
        bottom: borders.Name != "none" ? "7%" : "4%",
        left: borders.Name != "none" ? "3%" : "1%",
        color: "white",
        padding: "0.2rem",
        zIndex: 201,
        cursor: "pointer",        
    }
    let colorNoPositionValue = {
      marginTop: "15px",
      width: "15px",
      height: "15px",
      fontSize: "5px",
      backgroundColor: props.color,
      borderRadius: "10px",
      display: "inline-block",
      zIndex: 201,
   }
   let mobile = false
    if (window.matchMedia("(max-width: 899px)").matches && !props.modal) {
      identityPositionStyle.display = 'none'
      mobile = true
    }
    let positionSquareStyle = {
        padding: "5px 10px",
        backgroundColor: "black",
        opacity: "0.7",
        borderRadius: "6px",
        zIndex: 201,
     }
      let audioStyle = {
        position: "absolute",
        left: borders.Name != "none" ? "3%" : "1%",
        bottom: borders.Name != "none" ? "24%" : "21%",
        color: "red",
        zIndex: 201,
        width: mobile ? "20%" : "8%", 
    }
    const goToCard = ()=>{
        window.open('/user/'+myInfo.name,'_blank')
    }    
    return (
        <div style={{height: "100%", position: "relative"}}>
            <div style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", height: "100%", zIndex: 1}}>{webcam}</div>
            {!useVideo && <VideocamOff style={{position: "absolute", width: "100px", left: "50%", top: "50%", zIndex: 199, transform: "translate(-50%, -50%)", color: "white"}}/>}
            {!useAudio ? <MicOffIcon style={audioStyle} /> : null }
            <div style={identityPositionStyle} onClick={goToCard}>{!props.roulette ? props.show_position ? <span style={positionSquareStyle}>{props.position}</span> : props.color ? <span style={colorNoPositionValue}></span> : null : null} <span style={positionSquareStyle}>{myInfo.name}</span></div>
            <BanMate userid={props.myId} myself={true} modal={props.modal}/>
            {props.isAdmin && !mobile && <Chip sx={{color: "white"}} label={<FormattedMessage id="admin" />}  size="small" key={1} className="admin-label-style"/>}
            {!mobile && !props.isAdmin && <MyBoxMenu isAdmin={props.isAdmin} userid={props.userid} identity={props.identity} myself={false} modal={props.modal}/>}
        </div>
    )
}

export default MyMediaBox;

import React from "react";
import { Avatar, Stack, Typography } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import { FormattedMessage } from 'react-intl';

import './community-item.scss';

const CommunityItem = ({
	onClick,
	icon,
	title,
	type,
	countUser
}) => {
	return (
		<Stack 
			className="community-item"
			direction="row" 
			alignItems="center" 
			spacing={2} 
			onClick={onClick}
			paddingX={2}
			paddingY={1}
		>
			<Avatar src={icon} />

			<Stack direction="column">
				<Typography variant="h6">
				    <FormattedMessage id={type}/> {title}
				</Typography>

				<Stack direction="row" alignItems="center" spacing={1}>
					<Typography variant="body2">
						{countUser}
					</Typography>

					<PersonIcon />					
				</Stack>
			</Stack>			
		</Stack>
	);
};

export default CommunityItem;
const BaseModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',

  bgcolor: 'background.default',
	color: 'text.primary',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  boxSizing: 'border-box',
  overflow: "auto",
}

 const ModalStyle = {
  ...BaseModalStyle,
  
  maxWidth: 500,
  maxHeight: '80%',

  '@media (min-width: 600px)': {
    width: '100%',
    maxWidth: 500,
  },


};

export const ModalStyleLG = {
  ...BaseModalStyle,
  
  maxWidth: 480,
  maxHeight: '80%',
  
  '@media (min-width: 600px)': {
    width: '100%',
    maxWidth: 500,
  },

  '@media (min-width: 992px)': {
    maxWidth: 680,
  },

};

export const ModalStyleXL = {
  ...BaseModalStyle,
  
  maxWidth: 800,
  maxHeight: '80%',
  
  '@media (max-width: 600px)': {
    width: '100%',
    maxWidth: 500,
  },

  '@media (max-width: 992px)': {
    maxWidth: 680,
  },

  '@media (max-width: 1200px)': {
    maxWidth: 900,
  },

};

export default ModalStyle;
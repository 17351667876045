import { Container, Divider, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, {useEffect, useState} from 'react';
import Navbar from '../Navbar/Navbar';
import {FormattedMessage} from "react-intl";
import AccountBannedInfo from './AccountBannedInfo/AccountBannedInfo';
import FlatChat from '../common/FlatChat/FlatChat';
import {useRecoilState,} from 'recoil';
import {UserInfoAtom,UserAvatarSuffix,} from "../atoms/UserAtoms"
import {domainNameAPI, protocolAPI, apiPort} from "../../environment"
import {ThemeAtom,} from "../atoms/Common"
import {useNavigate} from "react-router-dom"


let date = new Date();

const AccountBanned = () => {
	const [userID, setUserID] = useState(0)

	const [innerTheme, _] = useRecoilState(ThemeAtom);

	const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);
	const [userAvatarSuffix, setUserAvatarSuffix] = useRecoilState(UserAvatarSuffix);

	const navigate = useNavigate();
	useEffect(() => {
		if (userInfo.UserInfo != null && userInfo.UserInfo.AccountStatus != "banned") {
			navigate("/games")
		}
		if (userInfo.UserInfo != null) {
			setUserID(parseInt(userInfo.UserInfo.UserID))
		}
	}, [userInfo])

	let apiUrl = protocolAPI+domainNameAPI+apiPort
	let avaSrc = "/themes/"+innerTheme.theme+"/imgs/icons/user.png";
	if (userInfo.UserInfo && userInfo.UserInfo != null && userInfo.UserInfo.IconUrl !== "") {
	  avaSrc=apiUrl+"/mkstatic/shared/avatars/"+userInfo.UserInfo.IconUrl+"?dop"+userAvatarSuffix
	}
	return (
		<Box
			sx={{
				minHeight: '100vh',
				bgcolor: 'background.default',
				color: 'text.primary',
			}}
		>
			<Container maxWidth="lg">
				<Stack paddingY={4} spacing={3}>
					<Box>
						<Typography variant='body1'>
							<FormattedMessage id="your_account_has_been_blocked" />: &nbsp;
							<b>
								<FormattedMessage id="inappropriate_behavior" />.
							</b>
						</Typography>

						<Typography variant='body1'>
							<FormattedMessage id="you_can_protest" />
						</Typography>
					</Box>
					
					<AccountBannedInfo
						avatar={avaSrc}
						nickname={userInfo.UserInfo != null && userInfo.UserInfo.Nickname}
						email={userInfo.UserInfo != null && userInfo.UserInfo.Email}
					/>

					<Divider />

					{userID != 0 && <FlatChat occasionID={userID} occasionType="banned" />}
				</Stack>
			</Container>
		</Box>
	)
};

export default AccountBanned;
import React, { useState, useEffect } from 'react';
import UserWindow from '../../../../livekit_prod/CallBoxes/Layouts/UserWindow/UserWindow';
import { Button, Grid, Modal, Stack, Switch, TextField, Typography, Box } from '@mui/material';
import {GeneralizeGameState, GeneralizeTeamsState, MyTeamAndRole, EndedUpdate} from "./atoms/GameState"
import GeneralizeGameController from "./GeneralizeGameController"
import {useRecoilState} from "recoil";
import TeamsPicking from "./stages/TeamsPicking"
import GameMap from "./stages/GameMap"
import TeamState from "./stages/TeamState"
import GeneralizeGameBox from "./GeneralizeGameBox/GeneralizeGameBox"
import {PositionsToHideVideo, LivekitComponentUpdateCounter, MyWebcamUpdateCounter} from "../../../../livekit_prod/atoms"
import ModalStyle from '../../../../utils/ModalStyle/ModalStyle';
import {BoxesSpacingHorizontal, BoxesSpacingVertical, BoxesSpacingGeneralizeHorizontal} from "../../../../constants"
import ModalClose from "../../../../utils/ModalStyle/ModalClose"


export const GetMyTeamID = function(myPos, teamsState) {
    for (let teamID in teamsState.Teams) {
        for (let playerPos of teamsState.Teams[teamID].Players) {
            if (myPos == playerPos) {
                return teamID
            }
        }
    }
    return 0
}

export const GetIsMaster = function(myPos, teamsState) {
    for (let teamID in teamsState.Teams) {
        if (teamsState.Teams[teamID].Master == myPos) {
            return true
        }
    }
    return false
}

const GeneralizeLayout = (props) => {
    const [generalizeGameState, setGeneralizeGameState] = useRecoilState(GeneralizeGameState);
    const [generalizeTeamsState, setGeneralizeTeamsState]  = useRecoilState(GeneralizeTeamsState);
    const [positionsToHideVideo, setPositionsToHideVideo]  = useRecoilState(PositionsToHideVideo);
    const [myTeamAndRole, setMyTeamAndRole]  = useRecoilState(MyTeamAndRole);    
    const [endedUpdated, setEndedUpdate] = useRecoilState(EndedUpdate)
    const [myWebcamUpdateCounter, setMyWebcamUpdateCounter] = useRecoilState(MyWebcamUpdateCounter)

    const [teamRed, setTeamRed] = useState(new Map())
    const [teamBlue, setTeamBlue] = useState(new Map())
    const [redTeamPositions, setRedTeamPositions] = useState(new Map())
    const [blueTeamPositions, setBlueTeamPositions] = useState(new Map())

    const [choosedBox, setChoosedBox] = useState(<span>No choosed</span>)
    const [openBoxModal, setOpenBoxModal] = useState(false)
	const [choosedBoxPosition, setChoosedBoxPosition] = useState(0)
    const handleCloseBoxModal = ()=>{
        setOpenBoxModal(false)
    }
    const mobileShowBoxByClick = (e, box, position)=>{
        if (window.matchMedia("(max-width: 899px)").matches && box != undefined) {
            let boxUp = box
            if (React.isValidElement(boxUp)) {
                boxUp = React.cloneElement(boxUp, { modal: true }); 
            }
            setChoosedBox(boxUp)
			setChoosedBoxPosition(position)
            setOpenBoxModal(true)
        }
    }

	useEffect(()=> {   
        let redTeam = new Map()
        let blueTeam = new Map()
        let redTeamPositions = new Map()
        let blueTeamPositions = new Map()        
        let newSet = new Set()
        let myTeamID = GetMyTeamID(props.myposition, generalizeTeamsState)
        let isMaster = GetIsMaster(props.myposition, generalizeTeamsState)
        if (myTeamAndRole.TeamID > 0 && props.myposition != 0 && (myTeamAndRole.TeamID != myTeamID || isMaster != myTeamAndRole.Master)) {
            setMyTeamAndRole({Master: isMaster, TeamID: myTeamID})
            //setLivekitComponentUpdateCounter(livekitComponentUpdateCounter + 1)
            //setMyWebcamUpdateCounter(myWebcamUpdateCounter+1)
            console.log("ASASAS setLivekitComponentUpdateCounter increment 11111")  
        } else if (myTeamAndRole.TeamID == 0) {
            setMyTeamAndRole({Master: isMaster, TeamID: myTeamID})
        }

        if (generalizeTeamsState.Teams && generalizeTeamsState.Teams[1]) {
            let i = 1;
            newSet.add(generalizeTeamsState.Teams[1].Master)
            redTeam.set(i, <GeneralizeGameBox livekitBox={props.roommates.get(generalizeTeamsState.Teams[1].Master)} position={generalizeTeamsState.Teams[1].Master} color={PositionToColor(generalizeTeamsState.Teams[1].Master)} ismaster={true}></GeneralizeGameBox>)
            redTeamPositions.set(i, generalizeTeamsState.Teams[1].Master)
            for (let player of generalizeTeamsState.Teams[1].Players) {
                if (player != generalizeTeamsState.Teams[1].Master) {
                    i++;
                    if (i < 6) {
                        redTeam.set(i, <GeneralizeGameBox livekitBox={props.roommates.get(player)} position={player} color={PositionToColor(player)} ismaster={false}></GeneralizeGameBox>)
                        redTeamPositions.set(i, player)
                    } else {
                        blueTeam.set(6 - i + 5, <GeneralizeGameBox livekitBox={props.roommates.get(player)} position={player} color={PositionToColor(player)} ismaster={false}></GeneralizeGameBox>)
                        blueTeamPositions.set(6 - i + 5, player)
                    }
                }
            }
        }
        if (generalizeTeamsState.Teams && generalizeTeamsState.Teams[2]) {
            let i = 1;
            newSet.add(generalizeTeamsState.Teams[2].Master)
            blueTeam.set(i, <GeneralizeGameBox livekitBox={props.roommates.get(generalizeTeamsState.Teams[2].Master)} position={generalizeTeamsState.Teams[2].Master} color={PositionToColor(generalizeTeamsState.Teams[2].Master)} ismaster={true}></GeneralizeGameBox>)
            blueTeamPositions.set(i, generalizeTeamsState.Teams[2].Master)
            for (let player of generalizeTeamsState.Teams[2].Players) {
                if (player != generalizeTeamsState.Teams[2].Master) {
                    i++;
                    if (i < 6) {
                        blueTeam.set(i, <GeneralizeGameBox livekitBox={props.roommates.get(player)} position={player} color={PositionToColor(player)} ismaster={false}></GeneralizeGameBox>)
                        blueTeamPositions.set(i, player)
                    } else {
                        redTeam.set(6 - i + 5, <GeneralizeGameBox livekitBox={props.roommates.get(player)} position={player} color={PositionToColor(player)} ismaster={false}></GeneralizeGameBox>)
                        redTeamPositions.set(6 - i + 5, player)
                    }
                }
            }
        }  
        if (generalizeGameState.Phase != "picking" && generalizeGameState.Phase != "ended") {
            setPositionsToHideVideo(newSet)      
        } else {
            setPositionsToHideVideo(new Set())      
        }
        if (generalizeGameState.Phase == "ended" && isMaster && !endedUpdated) {
            setEndedUpdate(true)
            //setLivekitComponentUpdateCounter(livekitComponentUpdateCounter + 1)
            //setMyWebcamUpdateCounter(myWebcamUpdateCounter+1)
        } else if (generalizeGameState.Phase == "picking") {
            setEndedUpdate(false)
        }
        setTeamRed(redTeam)
        setTeamBlue(blueTeam)
        setRedTeamPositions(redTeamPositions)
        setBlueTeamPositions(blueTeamPositions)
        console.log("ASASAS redTeamPositions", redTeamPositions)
    }, [generalizeTeamsState,  generalizeGameState, props.roommates, endedUpdated]) 
    

    return (
    <Stack
        direction="column" 
        justifyContent="center"
        sx={{padding: "15px"}}
    >
        <GeneralizeGameController myrole={props.myrole} roomSeed={props.roomSeed} myid={props.myid} generalizeID={props.generalizeID} roomID={props.roomID} myname={props.myname} myposition={props.myposition} apiUrl={props.apiUrl}/>

        <Box sx={{height: "25%"}} key="1">
            <Grid container key="redteam" columns={{ xs: 5, sm: 10, md: 15 }} spacing={BoxesSpacingGeneralizeHorizontal} direction="row" style={{width: "100%"}}>
                    <Grid item xs={1} sm={2} md={3} onClick={(e)=>{mobileShowBoxByClick(e, teamRed.get(1), redTeamPositions.get(1))}}>                    
                        <UserWindow key="red1" position={redTeamPositions.get(1)} matebox={teamRed.get(1)} />
                    </Grid>
                    <Grid item xs={1} sm={2} md={3} onClick={(e)=>mobileShowBoxByClick(e, teamRed.get(2), redTeamPositions.get(2))}>
                        <UserWindow key="red2" position={redTeamPositions.get(2)} matebox={teamRed.get(2)} />
                    </Grid>
                    <Grid item xs={1} sm={2} md={3} onClick={(e)=>mobileShowBoxByClick(e, teamRed.get(3), redTeamPositions.get(3))}>
                        <UserWindow key="red3" position={redTeamPositions.get(3)} matebox={teamRed.get(3)} />
                    </Grid>
                    <Grid item xs={1} sm={2} md={3} onClick={(e)=>mobileShowBoxByClick(e, teamRed.get(4), redTeamPositions.get(4))}>
                        <UserWindow key="red4" position={redTeamPositions.get(4)} matebox={teamRed.get(4)} />
                    </Grid>
                    <Grid item xs={1} sm={2} md={3} onClick={(e)=>mobileShowBoxByClick(e, teamRed.get(5), redTeamPositions.get(5))}>
                        <UserWindow key="red5" position={redTeamPositions.get(5)} matebox={teamRed.get(5)}/>
                    </Grid>                                                                
            </Grid>
        </Box>
        <Box sx={{height: "25%"}} key="2">
            <Grid container key="blueteam" columns={{ xs: 5, sm: 10, md: 15 }} spacing={BoxesSpacingGeneralizeHorizontal} pt={BoxesSpacingHorizontal} direction="row" sx={{heigth: "25%", marginTop: "7px"}} className="mobile-show-flex" style={{width: "100%"}}>
                    <Grid item xs={1} sm={2} md={3} onClick={(e)=>{mobileShowBoxByClick(e, teamBlue.get(5), blueTeamPositions.get(5))}}>                    
                        <UserWindow key="blue1" position={blueTeamPositions.get(5)} matebox={teamBlue.get(5)}/>
                    </Grid>
                    <Grid item xs={1} sm={2} md={3} onClick={(e)=>{mobileShowBoxByClick(e, teamBlue.get(4), blueTeamPositions.get(4))}}>
                        <UserWindow key="blue2" position={blueTeamPositions.get(4)} matebox={teamBlue.get(4)}/>
                    </Grid>
                    <Grid item xs={1} sm={2} md={3} onClick={(e)=>{mobileShowBoxByClick(e, teamBlue.get(3), blueTeamPositions.get(3))}}>
                        <UserWindow key="blue3" position={blueTeamPositions.get(3)} matebox={teamBlue.get(3)}/>
                    </Grid>
                    <Grid item xs={1} sm={2} md={3} onClick={(e)=>{mobileShowBoxByClick(e, teamBlue.get(2), blueTeamPositions.get(2))}}>
                        <UserWindow key="blue4" position={blueTeamPositions.get(2)} matebox={teamBlue.get(2)}/>
                    </Grid>
                    <Grid item xs={1} sm={2} md={3} onClick={(e)=>{mobileShowBoxByClick(e, teamBlue.get(1), blueTeamPositions.get(1))}}>
                        <UserWindow key="blue5" position={blueTeamPositions.get(1)} matebox={teamBlue.get(1)}/>
                    </Grid>                                                                
            </Grid>
        </Box>
        <Box sm={{height: 500}}>
            <Grid container columns={{ xs: 7, sm: 7, md: 7 }} direction="row" sx={{heigth: 1000}}>            
                <Grid item xs={1} sm={1} md={1} className="mobile-hidden"> 
                    {generalizeGameState.Phase != "picking" && <TeamState myposition={props.myposition} teamID={1}></TeamState>}
                </Grid>
                <Grid item xs={7} sm={7} md={5}>
                    {generalizeGameState.Phase == "picking" && <TeamsPicking myposition={props.myposition}></TeamsPicking>}
                    {generalizeGameState.Phase != "picking" && <GameMap myposition={props.myposition}></GameMap>}
                </Grid>
                <Grid item xs={1} sm={1} md={1} className="mobile-hidden">
                    {generalizeGameState.Phase != "picking" && <TeamState myposition={props.myposition} teamID={2}></TeamState>}
                </Grid>
            </Grid>
        </Box>
        <Box sx={{height: 500}} className="mobile-show-flex">
            <Grid container columns={{ xs: 8, sm: 8, md: 8 }} direction="row" sx={{heigth: 1000}}> 
                <Grid item xs={4} sm={4} md={4}> 
                    {generalizeGameState.Phase != "picking" && <TeamState myposition={props.myposition} teamID={1}></TeamState>}
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    {generalizeGameState.Phase != "picking" && <TeamState myposition={props.myposition} teamID={2}></TeamState>}
                </Grid>                          
            </Grid>
        </Box>
        <Box sx={{height: "25%"}} key="3">
            <Grid container key="blueteam" columns={{ xs: 5, sm: 10, md: 15 }} spacing={BoxesSpacingGeneralizeHorizontal} direction="row" sx={{heigth: "25%"}} className="mobile-hidden" style={{width: "100%"}}>
                    <Grid item xs={1} sm={2} md={3}>                    
                        <UserWindow key="blue1" position={blueTeamPositions.get(5)} matebox={teamBlue.get(5)}/>
                    </Grid>
                    <Grid item xs={1} sm={2} md={3}>
                        <UserWindow key="blue2" position={blueTeamPositions.get(4)} matebox={teamBlue.get(4)}/>
                    </Grid>
                    <Grid item xs={1} sm={2} md={3}>
                        <UserWindow key="blue3" position={blueTeamPositions.get(3)} matebox={teamBlue.get(3)}/>
                    </Grid>
                    <Grid item xs={1} sm={2} md={3}>
                        <UserWindow key="blue4" position={blueTeamPositions.get(2)} matebox={teamBlue.get(2)}/>
                    </Grid>
                    <Grid item xs={1} sm={2} md={3}>
                        <UserWindow key="blue5" position={blueTeamPositions.get(1)} matebox={teamBlue.get(1)}/>
                    </Grid>                                                                
            </Grid>
        </Box>
        <Modal
            open={openBoxModal}
            onClose={handleCloseBoxModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{ ...ModalStyle, width: 300, padding: "0"}}>
                <ModalClose onClose={handleCloseBoxModal}/>
				<div style={{width: "256px", marginTop: "40px",}}>
                	<UserWindow position={choosedBoxPosition} matebox={choosedBox} />
				</div>
            </Box>
        </Modal>
    </Stack>)
}

export default GeneralizeLayout;

export const PositionToColor = function (pos) {
    if (pos == 1) {
        return "#e82727"
    }
    if (pos == 2) {
        return "#fc990d"
    }
    if (pos == 3) {
        return "#f7fa05"
    }
    if (pos == 4) {
        return "#2df50f"
    }
    if (pos == 5) {
        return "#08e2ff"
    }
    if (pos == 6) {
        return "#0511fc"
    }
    if (pos == 7) {
        return "#c909eb"
    }
    if (pos == 8) {
        return "#b0873f"
    }
    if (pos == 9) {
        return "#17965b"
    }
    if (pos == 10) {
        return "#6d70bf"
    }
    return "#e82b1a"                     
}
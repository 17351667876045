import { Button, Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import {FormattedMessage} from "react-intl";
import RoomService from "../../../../../services/rooms/RoomService"
import { SuccessMessage } from '../../../../../constants';
import React, { useState, useEffect }  from 'react';
import {useNavigate} from "react-router-dom"
import {useRecoilState} from "recoil";
import {GeneralizeGameState, GeneralizeTeamsState, GameFinishedState, FinishChangeExpPointsInfo,} from "../atoms/GameState"
import {RoomInfo} from "../../../../../livekit_prod/atoms"
import { makeString } from '../../../../../utils/common';
import {domainNameAPI, protocolAPI, apiPort} from "../../../../../environment"
import {ThemeAtom} from "../../../../atoms/Common"
import {RoomSeed} from "../../../../../livekit_prod/atoms"
import useSound from 'use-sound';
import {SoundTheme, UseSoundAtom, SoundLevelAtom} from "../../../../atoms/Common"
import ExpChangeBar from "../../../../common/ExpChangeBar/ExpChangeBar"

const GetMyTeamID = function(myPos, teamsState) {
    for (let teamID in teamsState.Teams) {
        for (let playerPos of teamsState.Teams[teamID].Players) {
            if (myPos == playerPos) {
                return teamID
            }
        }
    }
    return 0
}

const GetIsMaster = function(myPos, teamsState) {
    for (let teamID in teamsState.Teams) {
        if (teamsState.Teams[teamID].Master == myPos) {
            return true
        }
    }
    return false
}


function Victory(props) {
    const [generalizeGameState, setGeneralizeGameState]  = useRecoilState(GeneralizeGameState);
    const [generalizeTeamsState, setGeneralizeTeamsState]  = useRecoilState(GeneralizeTeamsState);
    const [roomInfo, setRoomInfo]  = useRecoilState(RoomInfo);
    const [theme, _] = useRecoilState(ThemeAtom);
    const [roomSeed, setRoomSeed]  = useRecoilState(RoomSeed);
    const [gameFinishedState, setGameFinishedState] = useRecoilState(GameFinishedState);
    const [soundTheme, setSoundTheme]  = useRecoilState(SoundTheme);
	const [useSoundAtom, setUseSoundAtom]  = useRecoilState(UseSoundAtom);
    const [soundLevelAtom, setSoundLevelAtom] = useRecoilState(SoundLevelAtom);
    const [finishChangeExpPointsInfo, setFinishChangeExpPointsInfo]  = useRecoilState(FinishChangeExpPointsInfo);

    const [myTeamID, setMyTeamID] = useState(0)
    const [isMaster, setIsMaster] = useState(false)
    const [team, setTeam] = useState([])
    const [finishText, setFinishText] = useState(<span></span>)
    const [finishIcon, setFinishIcon] = useState("")

    const [playWon] = useSound(
		'/sounds/themes/'+soundTheme.theme+'/generalize/win.mp3',
		{ volume: soundLevelAtom }
	  );
	const [playLost] = useSound(
		'/sounds/themes/'+soundTheme.theme+'/generalize/loose.mp3',
		{ volume: soundLevelAtom }
	);

    let apiUrl = protocolAPI+domainNameAPI+apiPort
    useEffect(()=> {
        if (!generalizeTeamsState.Teams || !generalizeTeamsState.Teams[1]) {
           return 
        }
        let myTeamID = GetMyTeamID(props.myposition, generalizeTeamsState)
        setMyTeamID(myTeamID)
        setIsMaster(GetIsMaster(props.myposition, generalizeTeamsState))

        let team = generalizeTeamsState.Teams[myTeamID]
        if (!team) {
            return
        }
        let myTeamPlayers = []
        for (let player of team.Players) {
            for (let identity in roomInfo.roomMates) {
                if (roomInfo.roomMates[identity].Position == player) {
                    let iconUrl = ""
                    if (roomInfo.roomMates[identity].Icon != "") {
                        iconUrl = apiUrl+"/mkstatic/shared/avatars/"+roomInfo.roomMates[identity].Icon+"?dop"+makeString(5)
                    }
                    myTeamPlayers.push({
                        name: identity,
                        icon: iconUrl,
                    })
                }
            }
        }

        setTeam(myTeamPlayers)
    }, [generalizeTeamsState, props.myposition]);

    useEffect(()=> {
        let finishIcon = "/themes/"+theme.theme+"/imgs/games/"
        
        if (useSoundAtom) {
            if (gameFinishedState.FinishGameState == myTeamID) {
                playWon()
            } else if (gameFinishedState.FinishGameState > 0) {
                playLost()
            }
        }

        if (gameFinishedState.FinishGameState < 0) {
            if (gameFinishedState.FinishGameState === -1 * myTeamID ) {
                setFinishText(<FormattedMessage id="your_team_assasinated"></FormattedMessage>)
                setFinishIcon(finishIcon + "win4.svg")
            } else {
                setFinishText(<FormattedMessage id="another_team_assasinated"></FormattedMessage>)
                setFinishIcon(finishIcon + "win.svg")
            }
        } else {
            if (gameFinishedState.FinishGameState == myTeamID ) {
                setFinishText(<FormattedMessage id="your_team_guessed_all"></FormattedMessage>)
                setFinishIcon(finishIcon + "win.svg")
            } else {
                setFinishText(<FormattedMessage id="another_team_guessed_all"></FormattedMessage>)
                setFinishIcon(finishIcon + "win4.svg")
            }
        }
    }, [gameFinishedState, myTeamID, playWon, playLost])

    const navigate = useNavigate();

	const leaveGame = ()=>{
		RoomService.leaveRoom().then((response)=>{
			if (response.data.Success == SuccessMessage) {
                setRoomSeed("")
				navigate("/games")
			}
		})
        props.onClose()
	}


    return (
        <Grid
        container
        rowSpacing={2}
        spacing={{ xs: 1, sm: 2 }}
        columns={12}
        direction="column"
        padding={1}
    >
        <Grid 
            item
            container
            alignItems="center"
            spacing={2}
        >
            <Grid item>
                <Typography variant='h4'>
                    {finishText}
                </Typography>
            </Grid>	

            <Grid item>
                <img src={finishIcon} alt="icon" style={{width: '56px', height: '56px'}} />
            </Grid>				
        </Grid> 

        <Grid 
            item
        >
            <Stack spacing={1}>
                <Typography variant='h6'>
                    <FormattedMessage id="your_team" />:
                </Typography>

                {team.map((item, index) => 
                    <Stack direction="row" spacing={1} alignItems="center" key={index}>
                        {item.icon != "" ? <img src={item.icon} alt="icon" style={{width: '32px', height: '32px', borderRadius: '50%'}} /> : null}
                        <Typography variant='body1'>{item.name}</Typography>
                    </Stack>
                )}					
            </Stack>
        </Grid>
        <Grid 
            item
        >
            <ExpChangeBar expChangeInfo={finishChangeExpPointsInfo} featureType={"generalize"}></ExpChangeBar>
        </Grid>
        <Grid 
            item
        >
            <Stack direction="row" spacing={1} alignItems="center" paddingTop={2}>
                <Button 
                    variant="outlined" 
                    color="success"
                    size='large'
                    onClick={leaveGame}
                >
                        <FormattedMessage id="exit" />
                </Button>

                <Button 
                    variant="contained" 
                    color="success"
                    size='large'
                    onClick={props.keepPlaying}
                >
                        <FormattedMessage id="another_one" />
                </Button>
            </Stack>
        </Grid>
    </Grid>
    )
}

export default Victory

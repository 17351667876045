import { Box, Stack, Avatar, Typography, Divider, Grid, Button, Modal } from '@mui/material';
import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from "react-router-dom";
import UserService from '../../../services/UserService';
import {SuccessMessage, MiniChatWidth} from "../../../constants"
import {FormattedMessage} from 'react-intl'
import Navbar from "../../Navbar/Navbar"
import {domainNameAPI, protocolAPI, apiPort} from "../../../environment"
import {ThemeAtom,} from "../../atoms/Common"
import {useRecoilState,} from 'recoil';
import {makeString} from "../../../utils/common"
import ExpChangeBar from "../../common/ExpChangeBar/ExpChangeBar"
import CommunityItem from "../../communities/CommunityItem/CommunityItem"
import ChatIcon from '@mui/icons-material/Chat';
import {OpenedPersonalChats} from "../../atoms/UserAtoms"
import {Authorized,} from "../../atoms/AuthAtoms"
import {showChat} from "../../common/SmallChat/scutils"
import ModalStyle from '../../../utils/ModalStyle/ModalStyle';
import ReportUserModal from "./ReportUserModal"
import ModalClose from '../../../utils/ModalStyle/ModalClose';

const UserCard = (props)=>{
    const [innerTheme, _] = useRecoilState(ThemeAtom);
    const [openedPersonalChats, setOpenedPersonalChats] = useRecoilState(OpenedPersonalChats);
	const [authorized, setAuthorized] = useRecoilState(Authorized);

    const [avatar, setAvatar] = useState("");
    let [userPersonal, setUserPersonal] = useState({Surname: "", Name: "", About: "", Phone: "", Birthday: "", Country: "", Region: "", City: ""});    
    let [userExperienceLevels, setUserExperienceLevels] = useState([])
    let [userCommunities, setUserCommunities] = useState([])
    let [userClan, setUserClan] = useState(null)
    let [cardUserInfo, setCardUserInfo] = useState({})
	let { nickname } = useParams();
    const [showReportuserModal, setShowReportuserModal] = useState(false);

	const navigate = useNavigate();

    useEffect(() => {
        UserService.getUserInfoNickname(nickname).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                UserService.showUserInfo(response.data.UserInfo.UserID).then((response)=>{
                    if (response.data.Success == SuccessMessage) {
                        setUserPersonal(response.data.UserPersonal)
                    }
                })
                UserService.getExperienceLevels(response.data.UserInfo.UserID).then((response)=>{
                    if (response.data.Success == SuccessMessage) {
                        let expLevels = makeExpLevelsArray(response.data.ExperienceLevels)
                        setUserExperienceLevels(expLevels)
                    }
                })
                UserService.getUserCommunities(response.data.UserInfo.UserID).then((response)=>{
                    if (response.data.Success == SuccessMessage) {
                        if (response.data.Communities.Clan && response.data.Communities.Clan.ID > 0) {
                            setUserClan({
                                onClick: () => {navigate("/community/"+response.data.Communities.Clan.ID)},
                                icon: apiUrl+"/mkstatic/shared_communities/icons/"+response.data.Communities.Clan.Icon+"?dop"+makeString(5),
                                communityType: response.data.Communities.Clan.CommunityType,
                                title: response.data.Communities.Clan.Name,
                                countUser: response.data.Communities.Clan.CountOfUsers,
                            })
                        }                        
                        let comms = []
                        for (let c of response.data.Communities.Member) {
                            comms.push({
                                onClick: () => {navigate("/community/"+c.ID)},
                                icon: apiUrl+"/mkstatic/shared_communities/icons/"+c.Icon+"?dop"+makeString(5),
                                communityType: c.CommunityType,
                                title: c.Name,
                                countUser: c.CountOfUsers,
                            })
                        }
                        setUserCommunities(comms)
                    }
                })
                let apiUrl = protocolAPI+domainNameAPI+apiPort
                let avaSrc = "/themes/"+innerTheme.theme+"/imgs/icons/user.png";
                if (response.data.UserInfo && response.data.UserInfo != null && response.data.UserInfo.IconUrl !== "") {
                  avaSrc=apiUrl+"/mkstatic/shared/avatars/"+response.data.UserInfo.IconUrl+"?dop"+makeString(5)
                }
                setAvatar(avaSrc)
                setCardUserInfo({UserID: response.data.UserInfo.UserID, IconUrl: response.data.UserInfo.IconUrl})
            }
        })
    }, [])

    let fromString = ""
    if (userPersonal.Country !== "" || userPersonal.Region !== "" || userPersonal.City !== "") {
        fromString = <span><FormattedMessage id="from"/> {userPersonal.City} {userPersonal.Region} {userPersonal.Country}</span>
    }
    let telString = ""
    if (userPersonal.Phone != "") {
        telString = <span><FormattedMessage id="tel"/> {userPersonal.Phone}</span>
    }
    let dohaveAnything = false;
    if (userPersonal.Name !== "" || userPersonal.Surname !== "" || userPersonal.About !== "" || userPersonal.Country !== "" || userPersonal.Region !== "" || userPersonal.City !== "" || userPersonal.Phone != "") {
        dohaveAnything = true
    }

    const handleOpenNewChat = ()=>{
        if (cardUserInfo.UserID) {
            showChat(cardUserInfo.UserID, nickname, cardUserInfo.IconUrl, true, openedPersonalChats, setOpenedPersonalChats)
        }
    }
    const handleOpenReportUser = ()=>{
        if (cardUserInfo.UserID) {
            setShowReportuserModal(true)
        }
    }
    const handleRemortUserModalClose = ()=>{
        setShowReportuserModal(false)
    }
    let mobile = false
    if (window.matchMedia("(max-width: 899px)").matches) {
        mobile = true
    }
    return (
        <Box
            sx={{
                minHeight: '100vh',
                bgcolor: 'background.default',
                color: 'text.primary',
            }}
        >
            <Stack direction="column" spacing={3} py={3} px={5}>
                <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12, lg: 16}} alignItems="flex-start" justifyContent="flex-start">
                    <Grid item xs={12} sm={12} md={4} lg={4} alignItems="flex-start" justifyContent="flex-start">
                        <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
                            <Avatar
                                    key={"akey"}
                                    alt={nickname}
                                    src={avatar}
                                    sx={{ width: 64, height: 64 }}
                                />
                            <Typography variant="h6">{nickname}</Typography>
                        </Stack>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={6} lg={4} columns={{ xs: 12, sm: 12, md: 12, lg: 12}} justifyContent="flex-start" spacing={2}>
                        {authorized.Authorized && cardUserInfo.UserID && <Stack spacing={3} mt={3} direction={{ xs: 'column', sm: 'column', md: 'row', lg: "row" }}>
                            <Button variant="contained" startIcon={<ChatIcon />} onClick={handleOpenNewChat}>
                                    <FormattedMessage id="personal_messages" />
                            </Button>
                            <Button variant="contained" color="error" startIcon={<ChatIcon />} onClick={handleOpenReportUser}>
                                <FormattedMessage id="report_user" />
                            </Button>
                        </Stack>}                     
                    </Grid>           
                </Grid>
                <Typography variant="h6" align="left" >
                    {userPersonal.Surname} {userPersonal.Name}<br/>
                    <span style={{width: "100%", whiteSpace: "initial", wordWrap: "break-word"}}>{userPersonal.About}</span><br/>
                    {fromString}<br/>
                    {telString}<br/>
                    {dohaveAnything ? <span></span> : <FormattedMessage id="havenoanything"/>}
                </Typography>
                <Divider/>
                <Typography variant="h4" align="left" >
                    <FormattedMessage id="Experience"/>:
                </Typography>
                {userExperienceLevels && userExperienceLevels.map((item, index) => (
                    <>
                        <Typography variant="h5" align="left" >
                            <FormattedMessage id={item.ExperienceType}/>:
                        </Typography>
                        <ExpChangeBar  key={index} expChangeInfo={item} featureType={item.ExperienceType}></ExpChangeBar>
                    </>
                ))}
                <Divider/>
                {false && <Grid
						container
						spacing={4}
						columns={{ xs: 4, sm: 12, md: 12, lg: 12}}
					>
						{userClan != null ? <><Grid item xs={4} sm={12} md={12} lg={2}>
							<Typography variant='h4'>
								<FormattedMessage id="clan" />
							</Typography>
						</Grid> 
						<Grid item xs={4} sm={12} md={10} lg={10}>
							<CommunityItem
								onClick={userClan.onClick}
								icon={userClan.icon}
								type={userClan.communityType}
								title={userClan.title}
								countUser={userClan.countUser}
							/>
						</Grid></> : 
                            <Typography variant='h4'>
                                <FormattedMessage id="has_no_user_clan" />
                            </Typography>
                        }

						<Grid item xs={4} sm={12} md={12} lg={12}>
							<Typography variant='h4'>
								<FormattedMessage id="user_communities" />
							</Typography>
						</Grid> 

						{userCommunities.length > 0 ? userCommunities.map((item, index) => (
							<Grid item xs={4} sm={12} md={12} lg={6} key={index}>
									<CommunityItem
                                        key={index}
										onClick={item.onClick}
										icon={item.icon}
										type={item.communityType}
										title={item.title}
										countUser={item.countUser}
									/>
							</Grid>
						)) : <Typography variant='h4'>
                                <FormattedMessage id="has_no_user_communities" />
                            </Typography>}
					</Grid>}                
            </Stack>

            <Modal
                open={showReportuserModal}
                onClose={handleRemortUserModalClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...ModalStyle, width: 400, }}>
                    <ModalClose onClose={handleRemortUserModalClose}/>
                    <ReportUserModal profileUserID={cardUserInfo.UserID} onClose={handleRemortUserModalClose}/>
                </Box>
            </Modal>                        
        </Box>
    )
}

export default UserCard;

function makeExpLevelsArray(returnedArray) {
    var expLevels = []
    let i = 0
    for (;i < 5;) {
        for (let level of returnedArray) {
            switch (i) {
                case 0:
                    if (level.ExperienceType == "all") {
                        expLevels.push(level)
                        break
                    }
                    break
                case 1:
                    if (level.ExperienceType == "talk") {
                        expLevels.push(level)
                        break
                    }
                    break      
                case 2:
                    if (level.ExperienceType == "hackword") {
                        expLevels.push(level)
                        break
                    }
                    break   
                case 3:
                    if (level.ExperienceType == "iamalive") {
                        expLevels.push(level)
                        break
                    }
                    break 
                case 4:
                    if (level.ExperienceType == "generalize") {
                        expLevels.push(level)
                        break
                    }
                    break                                                                                                                                                   
            }
        }
        i++;
    }
    return expLevels
}
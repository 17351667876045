import { Stack } from '@mui/system';
import React from 'react';
import FeaturesItem from './FeaturesItem/FeaturesItem';
// import IconGame from '../../assets/images/icon-game.svg';
// import IconSoc from '../../assets/images/icon-soc.svg';
// import IconRead from '../../assets/images/icon-read.svg';
import { FormattedMessage } from 'react-intl';

const Features = ({userInfo}) => {
	return (
		<Stack spacing={{sm: 1, xl: 5}} maxWidth={240}>
			<FeaturesItem
				userInfo={userInfo}
				icon="/home/icon-game.svg"
				description={<FormattedMessage id="feature_games" />}
				url="/games"
			/>

			<FeaturesItem
				userInfo={userInfo}
				icon="/home/icon-soc.svg"
				description={<FormattedMessage id="feature_soc" />}
				url="/rooms"
			/>

			<FeaturesItem
				userInfo={userInfo}
				icon="/home/communities.png"
				description={<FormattedMessage id="feature_com" />}
				url="/communities"
			/>
		</Stack>
	)
};

export default Features;
import React, { useState, useRef } from 'react';
import {
    useRecoilState,
  } from 'recoil';
  import {Theme} from '../../atoms'
import {FormattedMessage} from 'react-intl'
import {sendMsg} from "../../WSController"
import {ComplainsStatuses} from "../../atoms";
import { Tooltip, Typography } from '@mui/material';

function BanMate(props) {
    const [theme, _] = useRecoilState(Theme);
    let [viewText, setViewText] = useState(false);
    const [complainsStatuses, setComplainsStatuses] = useRecoilState(ComplainsStatuses);

    const banImgSrc = "/themes/"+theme.name+"/imgs/box/ban.png";
    let iconStyle = {
        position: "absolute",
        right: "1.3rem",
        top: "1rem",
        zIndex: 101,
        display: "none",
    }
    let mouseOverHandler = (e)=>{
        setViewText(true)
    }
    let mouseLeaveHandler = (e)=>{
        setViewText(false)
    }
    let onClick = (e)=>{
        sendMsg("Complain", {UserID: props.userid})
    }
    let complainTextStyle = {
        position: "absolute",
        right: "2.8rem",
        top: "1rem",
        zIndex: 100,
        backgroundColor: "black",
        color: "white",
        overflow: "hidden",
        padding: viewText ? "2px 5px 5px 5px" : "0px",
        opacity: "0.7",
        width: viewText ? "108px" : "0px",
        transition: "width 0.5s, padding 0.5s",
        borderRadius: "6px 0px 0px 6px",
    }
    
    let complainSquareStyle = {
        position: "absolute",
        right: "4rem",
        top: "1.4rem",
        padding: "4px 10px",
        backgroundColor: "black",
        color: "white",
        opacity: "0.7",
        borderRadius: "6px",
        fontSize: "0.9rem",
        border: "1px solid #af0000"
     }
    // if (props.myself) {
    //     complainSquareStyle.right = "1.3rem"
    // }
    let complains = 0;
    let nickList = ""
    if (complainsStatuses.complainsStatuses[props.userid]) {
        complains = complainsStatuses.complainsStatuses[props.userid].length
        for (let nick of complainsStatuses.complainsStatuses[props.userid]) {
            if (nickList == "") {
                nickList = nick
            } else {
                nickList += ", "+nick
            }
        }
    }
    if (window.matchMedia("(max-width: 899px)").matches && !props.modal) {
        iconStyle.display = 'none'
    }
    return (
        <div>
          {props.myself ? null : <div style={iconStyle}><img style={{width: "1.7rem", height: "1.7rem",}} src={banImgSrc} onClick={onClick} onMouseEnter={mouseOverHandler} onMouseLeave={mouseLeaveHandler}/></div>}
          {props.myself ? null : <div style={complainTextStyle}><FormattedMessage id="complain"/></div>}
          {complains > 0 ?              
            <Tooltip
                style={complainSquareStyle} 
                title={
                    <>
                        {complains == 1 && <Typography variant='h6'><FormattedMessage id="complained_one" /> {nickList}</Typography>}
                        {complains > 1 && <Typography variant='h6'><FormattedMessage id="complained_more" />: {nickList}</Typography>}
                    </>
                } 
            >
                <span>{complains}</span>
            </Tooltip>
            : null}
        </div>
    )
}

export default BanMate;
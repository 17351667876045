import {
    atom,
} from 'recoil';

export const ListLobbiesInterval = atom({
    key: 'ListLobbiesInterval',
    default: {
        interval: 0,
    },
})

export const CurrentGameName = atom({
    key: 'CurrentGameName',
    default: {
        gameName: "hackword",
    },
})

export const ListGameLobbies = atom({
    key: 'ListGameLobbies',
    default: {
        lobbies: [],
    },
})
import logo from './logo.svg';
import './App.css';
import React, {useMemo, useState} from 'react';
import AuthCheck from "./main/AuthCheck"
import Index from "./main/Index"
import LivekitComponent from "./livekit_prod/LivekitComponent"
import {domainNameAPI, protocolAPI, apiPort} from "./environment"
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom"
import {
  RecoilRoot
} from 'recoil';
import { CircleType } from './livekit_prod/CallBoxes/PositionStyles';
import CheckMail from "./main/CheckMail"
import UserAgrementRU from "./main/license_pages/UserAgreementRU"
import PrivacyPolicyRU from "./main/license_pages/PrivacyPolicyRU"
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import { I18nPropvider, LOCALES } from './i18n';
import ColorModeContext from './utils/ColorModeContext';
import Rooms from "./main/rooms/Rooms"
import UserPage from './main/user/UserPages/UserPage';
import Home from './main/Home/Home';
import Games from "./main/games/Games";
import Subscription from "./main/subscriptions/Subscriptions";
import Communities from './main/communities/Communities';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ruRU } from '@mui/x-date-pickers/locales';
import ru from 'dayjs/locale/ru';
import Community from './main/communities/Community';
import Event from "./main/communities/Event";
import {influxDBLoadPage} from "./services/influxdb/navigation"
import SiteController from './main/SiteController';
import GetVKCodeToRegisterLogin from "./main/user/Auth/GetVKCodeToRegisterLogin";
import AccountBanned from "./main/user/AccountBanned";
import Ratings from "./main/ratings/Ratings"
import {useRecoilState,} from 'recoil';
import {ColorThemeAtom} from "./main/atoms/Common"
import UserCard from "./main/user/UserCard/UserCard"
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/ru';
import moment from 'moment';
import RecoverPassword from "./main/user/Auth/RecoverPassword"
import KnowledgeBase from "./main/knowledge_base/KnowledgeBase"
import Layout from './main/Navbar/Layout';
import Chats from "./main/user/UserPages/Chats/Chats"
import Borders from "./main/user/UserPages/Borders/Borders"
import Profile from "./main/user/UserPages/Profile/Profile"


function App() {
  moment.updateLocale("ru", {
    week: {
      dow: 1
    }
  });
  const [locale, setLocale] = useState(LOCALES.RUSSIAN); 
  
  let startTheme = 'light'
  let deviceSettings = localStorage.getItem('DeviceSettings')
  if (deviceSettings !== null) {
    deviceSettings = JSON.parse(deviceSettings)
    startTheme = deviceSettings.theme
  }

  const [mode, setMode] = useState(startTheme);
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          let newMode = 'light'
          if (prevMode === 'light') {
            newMode = 'dark'
          }
          let deviceSettings = localStorage.getItem('DeviceSettings')
          if (deviceSettings !== null) {
            deviceSettings = JSON.parse(deviceSettings)
            deviceSettings.theme = newMode
            localStorage.setItem('DeviceSettings', JSON.stringify(deviceSettings))
          } else {
            deviceSettings = {theme: newMode}
            localStorage.setItem('DeviceSettings', JSON.stringify(deviceSettings))
          }
          return newMode
        });
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode],
  );

  influxDBLoadPage()
//  adapterLocale="ru" localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
  return (
    <RecoilRoot>
    <ColorModeContext.Provider value={colorMode}>
    <ThemeProvider theme={theme}>
    <I18nPropvider locale={locale} defaultLocale={LOCALES.RUSSIAN}>
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
    <div className="App">
      <AuthCheck/>
      <SiteController apiUrl={"wss://"+domainNameAPI+apiPort+"/api/controller/ws"}></SiteController>
      <BrowserRouter>
          <Routes>
            <Route element={<Layout />}>
            <Route path='/' element={<Home></Home>}/>
            <Route path='/Index' element={<Index></Index>}/>          
            <Route path='/profile' element={<Profile></Profile>}/>
            <Route path='/messages' element={<Chats></Chats>}/>
            <Route path='/walls' element={<Borders></Borders>}/>
            <Route path='/games' element={<Games/>}/>
            <Route path='/rooms' element={<Rooms></Rooms>}/>
            <Route path='/room/:room_key' element={<LivekitComponent key={"room"} updateCounter={0} positionsType={CircleType} livekitURL={"wss://"+domainNameAPI+":8880"} apiURL={"wss://"+domainNameAPI+apiPort+"/api/livekit/ws"} max_participants={10} roulette={false}/>} />
            <Route path='/roulette' element={<LivekitComponent key={"roulette"} updateCounter={0} positionsType={CircleType} livekitURL={"wss://"+domainNameAPI+":8880"} apiURL={"wss://"+domainNameAPI+apiPort+"/api/livekit/ws"} max_participants={10} roulette={true}/>}/>
            <Route path='/check_email/:token' element={<CheckMail></CheckMail>}/>
            <Route path='/recover_password/:token' element={<RecoverPassword></RecoverPassword>}/>           
            <Route path='/user_agreement' element={<UserAgrementRU></UserAgrementRU>}/>
            <Route path='/privacy_policy' element={<PrivacyPolicyRU></PrivacyPolicyRU>}/>
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/subscription/success/:order_id" element={<Subscription type="success"/>} />
            <Route path="/subscription/failure" element={<Subscription type="failure"/>} />
            <Route path="/communities" element={<Communities />} />
            <Route path="/community/:community_id" element={<Community/>} />
            <Route path="/event/:community_id/:event_id" element={<Event />} />
            <Route path="/getVKCodeToRegisterLogin" element={<GetVKCodeToRegisterLogin />} />  
            <Route path="/banned" element={<AccountBanned />} />
            <Route path='/ratings' element={<Ratings></Ratings>}/>
            <Route path='/user/:nickname' element={<UserCard></UserCard>}/>
            <Route path='/knowledge_base' element={<KnowledgeBase></KnowledgeBase>}/>
            <Route path='/knowledge_base/:current_page' element={<KnowledgeBase></KnowledgeBase>}/>
            </Route>           
        </Routes>
      </BrowserRouter>
    </div>
    </LocalizationProvider>
    </I18nPropvider>
    </ThemeProvider>
    </ColorModeContext.Provider>
    </RecoilRoot>
  );
}

export default App;

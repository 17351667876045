import {
    useRecoilState,
} from 'recoil';
import {IamaliveWSOpened, IamaliveWS, IamaliveWSRestartTimeout} from "./atoms/Connection"
import {IamaliveGameState, WordChangeState, ShowCantStartGame} from "./atoms/GameAtoms"
import TokenService from "../../../../services/TokenService"
import React, {useState, useEffect} from 'react';
import Api from "../../../../services/Api";
import {FormattedMessage} from "react-intl";
import { Snackbar, Alert, Modal, Box } from '@mui/material';
import {SoundTheme, UseSoundAtom, SoundLevelAtom} from "../../../atoms/Common"
import useSound from 'use-sound';
import {RoomInfo, CanBecameCommonParticipant} from "../../../../livekit_prod/atoms"

let ws = null
let iamaliveID = 0
let roomSeed = ""

export const sendMsgIamalive = (ws, type, obj)=>{
    if (ws == null) {
        console.log("webRTCClient is null");
        return
    }
    try {
        if (ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({'Type': type, 'Payload': obj}));
        }
    } catch (error) {
        console.error(error);
    }
}

function connect(atomValues, atomSets, melodies, props, reconnect) {
    let {wsOpened, iamaliveWS, iamaliveWSRestartTimeout, iamaliveGameState, useSoundAtom, roomInfo, playedSound} = atomValues
    let {setWSOpened, setIamaliveWS, setIamaliveGameState, setIamaliveWSRestartTimeout, setWordChangeState, setShowCantStartGame, setCanBecameCommonParticipant, setPlayedSound} = atomSets
    let {playIntro, playHaveNewWord, playWin, playStart} = melodies

    console.log("ASASAS iamalive connect", ws, iamaliveWS.ws, iamaliveID, props.iamaliveID, roomSeed, props.roomSeed)

    if (reconnect) {
        ws = new WebSocket(props.apiUrl)
        setIamaliveWS({ws: ws})
    } else if (iamaliveWS.ws == null) {
        return
    } else if (iamaliveWS.ws.readyState === WebSocket.OPEN && (iamaliveID != roomInfo.entityID || roomSeed !== props.roomSeed)) {
        iamaliveID = roomInfo.entityID
        roomSeed = props.roomSeed
        restartGameContext({setIamaliveGameState, setWordChangeState})
        iamaliveWS.ws.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), 'IamaliveID': roomInfo.entityID, 'Nickname': props.myname, 'Position': props.myposition, "RoomID": props.roomID, "Role": props.myrole }}));
    }

    iamaliveWS.ws.onopen = () => {
        let newState = Object.assign({}, wsOpened);
        newState.opened = true;
        setWSOpened(newState);
        console.log('Iamalive WebSocket Client Connected');
        if (iamaliveID != roomInfo.entityID) {
            restartGameContext({setIamaliveGameState, setWordChangeState})
            iamaliveID = roomInfo.entityID
        }
        iamaliveWS.ws.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), 'IamaliveID': roomInfo.entityID, 'Nickname': props.myname, 'Position': props.myposition, "RoomID": props.roomID, "Role": props.myrole }}));
    };

    iamaliveWS.ws.onclose = () => {
        if (iamaliveWSRestartTimeout.timeout != 0) {
            clearInterval(iamaliveWSRestartTimeout.timeout)
        }
        let newState = Object.assign({}, wsOpened);
        newState.opened = false;
        setWSOpened(newState);
        let rTimeOut = setTimeout(function() {
            connect(atomValues, atomSets, melodies, props, true);
        }, 2000);
        setIamaliveWSRestartTimeout({timeout: rTimeOut})
    };

    iamaliveWS.ws.onmessage = (message) => {
        let msg = JSON.parse(message.data);
        switch (msg.Type) {
            case "InitGame":
                processInitGame(msg, setIamaliveGameState)
                break;
            case "GameState":
                console.log("ASASAS IAMALIVE GAMESTATE", msg)
                processGameState(msg, setIamaliveGameState, iamaliveGameState, useSoundAtom, playIntro, playHaveNewWord, playWin, playStart, playedSound, setPlayedSound) 
                break;
            case "WordChange":
                processWordChange(msg, setWordChangeState)
                break
            case "CanParticipate":
                setCanBecameCommonParticipant({can: true})
                break;
            case "CannotParticipate":
                setCanBecameCommonParticipant({can: false})
                break;                                   
            case "ErrorCode":
                if (msg.Payload.Code == 800) {
                    Api.post("/auth/refreshToken", {
                        RefreshToken: TokenService.getLocalRefreshToken(),
                    }).then(response=> {
                        TokenService.updateLocalAccessTokens(response.data.Tokens.AuthToken, response.data.Tokens.RefreshToken);
                        iamaliveWS.ws.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), 'IamaliveID': roomInfo.entityID, 'Nickname': props.myname, 'Position': props.myposition, "RoomID": props.roomID, "Role": props.myrole}}));
                    });
                } else if (msg.Payload.Code == 100) {
                    setShowCantStartGame(true)
                } else if (msg.Payload.Code == 101) {
                    setShowCantStartGame(true)
                }                 
                break         
        }
    }
}

function IamaliveGameController(props) {
    const [wsOpened, setWSOpened] = useRecoilState(IamaliveWSOpened); 
    const [iamaliveWSRestartTimeout, setIamaliveWSRestartTimeout]  = useRecoilState(IamaliveWSRestartTimeout);
    const [iamaliveWS, setIamaliveWS]  = useRecoilState(IamaliveWS);
    const [iamaliveGameState, setIamaliveGameState]  = useRecoilState(IamaliveGameState);
    const [wordChangeState, setWordChangeState]  = useRecoilState(WordChangeState);

    const [soundTheme, setSoundTheme]  = useRecoilState(SoundTheme);
	const [useSoundAtom, setUseSoundAtom]  = useRecoilState(UseSoundAtom);
    const [soundLevelAtom, setSoundLevelAtom] = useRecoilState(SoundLevelAtom);

    const [roomInfo, setRoomInfo]  = useRecoilState(RoomInfo);

    const [showCantStartGame, setShowCantStartGame] = useRecoilState(ShowCantStartGame);

    const [canBecameCommonParticipant, setCanBecameCommonParticipant] = useRecoilState(CanBecameCommonParticipant)

	const [playIntro] = useSound(
		'/sounds/themes/'+soundTheme.theme+'/iamalive/intro.mp3',
		{ volume: soundLevelAtom }
	);
	const [playHaveNewWord] = useSound(
		'/sounds/themes/'+soundTheme.theme+'/iamalive/have_new_word.mp3',
		{ volume: soundLevelAtom }
	);
	const [playWin] = useSound(
		'/sounds/themes/'+soundTheme.theme+'/iamalive/win.mp3',
		{ volume: soundLevelAtom }
	);
	const [playStart] = useSound(
		'/sounds/themes/'+soundTheme.theme+'/iamalive/start.mp3',
		{ volume: soundLevelAtom }
	);            
    const [playedSound, setPlayedSound] = useState(0);

    useEffect(()=> {
        if (iamaliveWS.ws == null) {
            ws = new WebSocket(props.apiUrl)
            setIamaliveWS({ws: ws}) // , TokenService.getLocalAccessToken()
        }
    }, []);

    useEffect(()=> {
        if (props.myid != 0) {
            connect({wsOpened, iamaliveWS, iamaliveWSRestartTimeout, iamaliveGameState, useSoundAtom, roomInfo, playedSound}, 
                {setWSOpened, setIamaliveWS, setIamaliveGameState, setIamaliveWSRestartTimeout, setWordChangeState, setShowCantStartGame, setCanBecameCommonParticipant, setPlayedSound}, 
                {playIntro, playHaveNewWord, playWin, playStart}, props, false)
        }
    }, [iamaliveWS, iamaliveGameState, useSoundAtom, soundLevelAtom, roomInfo, props.myid, props.iamaliveID, props.roomSeed, playIntro, playHaveNewWord, playWin, playStart]);

    return (
        <span>
            <Snackbar open={showCantStartGame} 
				onClose={()=>{setShowCantStartGame(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                sx={{marginBottom: "100px"}}  
				  key={"ImaliveCantStartGameWithOnePlayerK"}>
  				<Alert  severity="error" sx={{ width: '100%', zIndex: 99999 }} key={"ImaliveCantStartGameWithOnePlayer"}>
				  <FormattedMessage id="CantStartGameWithOnePlayer" />
  				</Alert>
			</Snackbar>	
        </span>
    )
}

export default IamaliveGameController

const processInitGame = (msg, setIamaliveGameState)=>{
    setIamaliveGameState({
        phase: msg.Payload.Phase,
        isAdmin: msg.Payload.IsAdmin,
    })
}

const processGameState = (msg, setIamaliveGameState, iamaliveGameState, useSoundAtom, playIntro, playHaveNewWord, playWin, playStart, playedSound, setPlayedSound)=>{
    if (useSoundAtom) {
        if (iamaliveGameState.phase == "picking" && msg.Payload.Phase == "choose_word" && playedSound != 1) {
            playIntro()
            setPlayedSound(1)
        } else if (iamaliveGameState.phase == "playing" && msg.Payload.Phase == "choose_word" && playedSound != 2) {
            playWin()
            setPlayedSound(2)
        } else if (iamaliveGameState.phase == "choose_word" && msg.Payload.Phase == "playing" && playedSound != 3) {
            playStart()
            setPlayedSound(3)
        } else if (iamaliveGameState.positionToAddWord != msg.Payload.PositionToAddWord && msg.Payload.Phase == "choose_word" && playedSound != 4) {
            playHaveNewWord()
            setPlayedSound(4)
        } 
    }
    setIamaliveGameState({
        phase: msg.Payload.Phase,
        isAdmin: msg.Payload.IsAdmin,
        adminPosition: msg.Payload.AdminPosition,
        positionToChooseWord: msg.Payload.PositionToChooseWord,
        positionToAddWord: msg.Payload.PositionToAddWord,
        players: msg.Payload.Players,
        allowNewPlayers: msg.Payload.AllowNewPlayers,
    })
}

const processWordChange = (msg, setWordChangeState)=>{
    setWordChangeState({
        word: msg.Payload.Word
    })
}

const restartGameContext = ({setIamaliveGameState, setWordChangeState})=>{
    setIamaliveGameState({phase: "picking", isAdmin: false, players: {}, allowNewPlayers: true,})
    setWordChangeState({word: ""})
}
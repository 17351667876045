
import { Box } from '@mui/system';
import RatingEntry from "./RatingEntry"
import { Stack, Pagination, useTheme} from "@mui/material";
import RatingsService from "../../../services/Ratings"
import React, { useState, useEffect } from 'react';
import { SuccessMessage } from '../../../constants';
import {Authorized,} from "../../atoms/AuthAtoms"
import {useRecoilState,} from 'recoil';

const NumberOfRatingsOnPage = 10


const RatingPage = (props) => {
    const theme = useTheme();

    const [ratingEntries, setRatingEntries] = useState([]);
    const [maxExperience, setMaxExperience] = useState(1);

    const [pagesCount, setPagesCount] = useState(1);
	const [page, setPage] = useState(1);

    const [meUser, setMeUser] = useState(null);

    const [authorized, setAuthorized] = useRecoilState(Authorized);

    useEffect(() => {
        const setEntries = (entries, count)=>{
            if (entries.length > 0) {
                setMaxExperience(entries[0].full_experience)
            }
            setRatingEntries(entries)
            let pagesCount = (count - count % NumberOfRatingsOnPage) / NumberOfRatingsOnPage
            if (count % NumberOfRatingsOnPage > 0) {
                pagesCount = pagesCount + 1
            }
            setPagesCount(pagesCount) 
        }
        if (authorized.Authorized) {
            RatingsService.getRatingUser(props.ratingType, page, NumberOfRatingsOnPage).then((response)=>{
                if (response.data.Success == SuccessMessage) {
                    setEntries(response.data.RatingEntriesUser.entries, response.data.RatingEntriesUser.count)
                    setMeUser(response.data.RatingEntriesUser.user)
                }
            })
        } else {
            RatingsService.getRatingList(props.ratingType, page, NumberOfRatingsOnPage).then((response)=>{
                if (response.data.Success == SuccessMessage) {
                    setEntries(response.data.RatingEntries.entries, response.data.RatingEntries.count)
                }
            })
        }
    }, [page, authorized])
    
	const pagesChange = (e, v)=>{
		setPage(v)
	}
    return (
        <Box
            sx={{
                minHeight: '100vh',
                bgcolor: 'background.default',
                color: 'text.primary',
                width: "100%",
            }}
        >
            <Stack direction="column" spacing={2} alignItems="left" style={{width: "100%"}}>
                {ratingEntries.map((item, index) => (
                    <RatingEntry
                            key={"retingentry"+index}
                            place={item.place}
                            nickname={item.nickname}
                            userID={item.user_id}
                            level={item.level}
                            experience={item.experience}
                            myplace={meUser != null ? meUser.place : 0}
                            divider={true}
                            percents={Math.round(item.full_experience / maxExperience * 100)}
                    />
				))}
            </Stack>
            <Stack spacing={4} marginY={4}>
            <Box style={{ display: "flex", justifyContent: 'center'}}>
                {pagesCount > 1 ? <Pagination count={pagesCount} shape="rounded" page={page} onChange={pagesChange}/> : null}
            </Box>
            </Stack>
            {meUser != null ? <div style={{padding: "25px 20px 10px 20px", position: "fixed", bottom: "0px", left: "0px", width: "100%", backgroundColor: theme.palette.mode == "light" ? "#D0EBFC" : "#3f2f9c"}}>
                    <RatingEntry
                        key={"retingentryme"}
                        place={meUser.place}
                        nickname={meUser.nickname}
                        userID={meUser.user_id}
                        level={meUser.level}
                        experience={meUser.experience}
                        myplace={meUser != null ? meUser.place : 0}
                        percents={Math.round(meUser.full_experience / maxExperience * 100)}
                    />
            </div> : null}
        </Box>
    )
}

export default RatingPage;

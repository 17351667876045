import { Button, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import {useRecoilState,} from 'recoil';
import {FormattedMessage} from "react-intl";
import {IamaliveGameState} from "./atoms/GameAtoms"
import {IamaliveWS} from "./atoms/Connection"
import React, {useEffect} from 'react';
import AdminPanel from './AdminPanel';
import GameNotes from './GameNotes';
import GamePanel from './GamePanel';
import IamaliveGameController from "./IamaliveGameController"

const InIamaliveGame = (props) => {
    const [value, setValue] = React.useState(0);
    
    const [iamaliveGameState, setIamaliveGameState]  = useRecoilState(IamaliveGameState); 

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    
    let lastIamaliveID = localStorage.getItem('IamaliveID')
    if (lastIamaliveID == null) {
        localStorage.setItem('IamaliveID', props.iamaliveID);
    } else if (props.iamaliveID != lastIamaliveID) {
        localStorage.setItem('IamaliveNotes', "");
        localStorage.setItem('IamaliveID', props.iamaliveID);
    }

    return (
        <Box
        sx={{
          minHeight: '100vh',
          bgcolor: 'background.default',
          color: 'text.primary',
        }}
      >
            <IamaliveGameController myrole={props.myrole} roomSeed={props.roomSeed} myid={props.myid} iamaliveID={props.iamaliveID} roomID={props.roomID} myname={props.myname} myposition={props.myposition} apiUrl={props.apiUrl}></IamaliveGameController>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label={<FormattedMessage id={iamaliveGameState.isAdmin ? "admin_panel" : "game_panel"} />} {...a11yProps(0)} />
                <Tab label={<FormattedMessage id="your_notes" />} {...a11yProps(1)} />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
            { iamaliveGameState.isAdmin ? <AdminPanel iamaliveID={props.iamaliveID} myposition={props.myposition} scope={props.scope}/> : <GamePanel  myrole={props.myrole} iamaliveID={props.iamaliveID}/>}
            </TabPanel>

            <TabPanel value={value} index={1}>
              <GameNotes iamaliveID={props.iamaliveID}/>
            </TabPanel>
      </Box>
    )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default InIamaliveGame










export const Communities = {
    "communities":`
### В сообществе единомышленников и живется и дышится легче    

У нас есть 2 типа сообществ:
- Клуб - любой пользователь может вступить в любое количество клубов
- Клан - пользователь может вступить только в один Клан

В будущем возможно появится рейтинг Кланов и будут проводиться соревнования.

Создатель сообщества является его админом. Он может принимать заявки на вступление и исключать действующих членов. А также редактировать профиль сообщества.

Важной возможностью сообществ являются события. Их может создать опять же админ. В результате, в заданное время будет создана комната, в которую могут присоединиться члены сообщества.

У сообщества есть свой чат. У каждого события тоже есть свой чат.
    `
}

import { Chip, ListItem, Paper, Stack, Typography, Button, Box } from '@mui/material';
import React, {useState} from 'react';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import './room.scss';
import { red } from '@mui/material/colors';
import {FormattedMessage} from "react-intl";
import { positions } from '@mui/system';


const Room = ({
	isPremium,
	title,
	roomSize,
	users,
	ageLimit,
	privet,
	tags,
	onClick,
	onClickSpectator,
	listTalksType, 
}) => {
	let [showButtons, setShowButtons] = useState(false);

	const mouseOverHandler = () => {
		setShowButtons(true)
	}
	const mouseLeaveHandler = () => {
		setShowButtons(false)
	}
	return (
		<div className='room' onMouseEnter={mouseOverHandler} onMouseLeave={mouseLeaveHandler}>
			<Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" sx={{position: "relative",}} >
				<Stack direction="row" spacing={3} alignItems="center">
					<Stack direction="row" alignItems="center" spacing={0.5}>
						<Typography variant='body2'>{users}/{roomSize}</Typography>
						<PersonIcon fontSize='small' />
					</Stack>

					<Typography variant='body'>{title}</Typography>

					{ageLimit &&
						<Typography variant='body' color={red[500]}>{ageLimit}</Typography>
					}
				</Stack>

				{showButtons ? 
					<Stack direction="row" sx={{ zIndex: 'modal', position: "absolute", right: "50px", top: "-5px"}} spacing={3} alignItems="right">
						{isPremium ? 
							<Button variant="outlined" color="success" onClick={onClickSpectator}
							sx={{bgcolor: 'background.default',"&:hover": {bgcolor: 'background.default',},}}>							
								<FormattedMessage id="SpectatorMode" />
							</Button>
						: null}
						{listTalksType == "is_recruiting" ? 
							<Button variant="contained" color="success" onClick={onClick}>
								<FormattedMessage id="JoinRoom" />
							</Button>
						: null}						
					</Stack>
				: null}

				{privet ?
					<LockIcon fontSize='small' />
					:
					<PlayCircleOutlineIcon fontSize='small' />
				}
			</Stack>

			{tags && 
				<ul className="room__tags">
					{tags.map((item, index) => (
						<Chip label={item} size="small" key={index} />
					))}
				</ul>
			}

		</div>
	);
};

export default Room;
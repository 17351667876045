import { Grid, Box, Modal, Stack } from '@mui/material';
import React, { useState, useEffect } from 'react';
import UserWindow from './UserWindow/UserWindow';
import ModalStyle from '../../../utils/ModalStyle/ModalStyle';
import {BoxesSpacingHorizontal, BoxesSpacingVertical} from "../../../constants"
import ModalClose from "../../../utils/ModalStyle/ModalClose"



const PShapedGrid = ({ roommates, children }) => {
	const [choosedBox, setChoosedBox] = useState(<span>No choosed</span>)
    const [openBoxModal, setOpenBoxModal] = useState(false)
	const [choosedBoxPosition, setChoosedBoxPosition] = useState(0)
    const handleCloseBoxModal = ()=>{
        setOpenBoxModal(false)
    }
	const mobileShowBoxByClick = (e, box, position)=>{
        if (window.matchMedia("(max-width: 899px)").matches && box != undefined) {
            let boxUp = box
            if (React.isValidElement(boxUp)) {
                boxUp = React.cloneElement(boxUp, { modal: true }); 
            }
            setChoosedBox(boxUp)
			setChoosedBoxPosition(position)
            setOpenBoxModal(true)
        }
    }
	let maxWidth = "1600px"
	return (
		<Box
		sx={{
		  minHeight: '100vh',
		  bgcolor: 'background.default',
		  color: 'text.primary',
		}}>
			<Grid container paddingX={2} pt={2} alignItems="center" justifyContent="center">
				<Grid key="pshapedgcpc1" container item xs={12} columnSpacing={BoxesSpacingHorizontal} style={{width: "100%", maxWidth: maxWidth}}>
					<Grid key="pshapedgall1" item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(1), 1)}}>
						<UserWindow key="pshapeduwall1" position={1} matebox={roommates.get(1)}/>
					</Grid>

					<Grid key="pshapedgall2" item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(2), 2)}}>
						<UserWindow key="pshapeduwall2" position={2} matebox={roommates.get(2)}/>
					</Grid>

					<Grid key="pshapedgall3" item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(3), 3)}}>
						<UserWindow key="pshapeduwall3" position={3} matebox={roommates.get(3)}/>
					</Grid>

					<Grid key="pshapedgall4" item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(4), 4)}}>
						<UserWindow key="pshapeduwall4" position={4} matebox={roommates.get(4)}/>
					</Grid>
				</Grid>

				<Grid container key="pshapedgcpc2" item xs={12} spacing={BoxesSpacingHorizontal} pt={BoxesSpacingVertical} style={{width: "100%", maxWidth: maxWidth}} alignItems="flex-start" className="mobile-hidden">
					<Grid container item xs={3} spacing={8} className="mobile-hidden">
						<Grid key="pshapedgpc8" item xs={12}>
							<UserWindow key="pshapeduwpc8" position={7} matebox={roommates.get(7)}/>
						</Grid>

						<Grid key="pshapedgpc7" item xs={12}>
							<UserWindow key="pshapeduwpc7" position={8} matebox={roommates.get(8)}/>
						</Grid>
					</Grid>

					<Grid item xs={6} md={6} spacing={2} className="mobile-hidden">
						{children}
					</Grid>

					<Grid container item xs={3} spacing={8} className="mobile-hidden">
						<Grid key="pshapedgpc5" item xs={12}>
							<UserWindow key="pshapeduwpc5" position={5} matebox={roommates.get(5)}/>
						</Grid>

						<Grid key="pshapedgpc6" item xs={12}>
							<UserWindow key="pshapeduwpc6" position={6} matebox={roommates.get(6)}/>
						</Grid>
					</Grid>
				</Grid>


				<Grid key="pshapedgcm2" container  item xs={12} spacing={BoxesSpacingHorizontal} pt={BoxesSpacingHorizontal} style={{width: "100%"}} className="mobile-show-flex">
					<Grid key="pshapedgm5" item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(5), 5)}}>
						<UserWindow key="pshapeduwm5" position={5} matebox={roommates.get(5)}/>
					</Grid>

					<Grid key="pshapedgm6" item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(6), 6)}}>
						<UserWindow key="pshapeduwm6" position={6} matebox={roommates.get(6)}/>
					</Grid>

					<Grid key="pshapedgm7" item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(7), 7)}}>
						<UserWindow key="pshapeduwm7" position={7} matebox={roommates.get(7)}/>
					</Grid>

					<Grid key="pshapedgm8" item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(8), 8)}}>
						<UserWindow key="pshapeduwm8" position={8} matebox={roommates.get(8)}/>
					</Grid>
				</Grid>
				<Grid item xs={12} spacing={2} className="mobile-show-block">
					{children}
				</Grid>
			</Grid>
        <Modal
            open={openBoxModal}
            onClose={handleCloseBoxModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{ ...ModalStyle, width: 300, padding: "0"}}>
				<ModalClose onClose={handleCloseBoxModal}/>
				<div style={{width: "256px", marginTop: "40px",}}>
                	<UserWindow position={choosedBoxPosition} matebox={choosedBox} />
				</div>
            </Box>
        </Modal>			
		</Box>
	);
};

export default PShapedGrid;
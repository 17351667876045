import {useNavigate} from "react-router-dom"
import {useParams} from "react-router-dom";
import AuthService from "../../../services/AuthService";
import React, {useState} from 'react';
import {FormattedMessage} from "react-intl";
import { Button, Stack, TextField, Typography } from '@mui/material';
import { SuccessMessage, MaxPasswordLength, EndSaltLength,} from '../../../constants';
import {
    useRecoilState,
} from 'recoil';
import {Authorized, AuthTokens} from '../../atoms/AuthAtoms'
import {makeString} from "../../../utils/common"






function RecoverPassword() {
	const [password, setPassword] = useState("");
	const [password2, setPassword2] = useState("");
	const [password2Error, SetPassword2Error] = useState(null);
	const [passwordError, SetPasswordError] = useState(null);

    const [authorized, setAuthorized] = useRecoilState(Authorized);
    const [authTokens, setAuthTokens] = useRecoilState(AuthTokens);

    let { token } = useParams();

    const navigate = useNavigate();

    const changePassword = (event) => {
		if (event.target.value.length > MaxPasswordLength) {
			SetPasswordError(<span><FormattedMessage id="cantbelonger"/> {MaxPasswordLength}</span>)
		} else {
			SetPasswordError(null)
		}
		setPassword(event.target.value);
	}
	const changePassword2 = (event) => {
		if (event.target.value.length > MaxPasswordLength) {
			SetPassword2Error(<span><FormattedMessage id="cantbelonger"/> {MaxPasswordLength}</span>)
		} else {
			SetPassword2Error(null)
		}
		setPassword2(event.target.value);
	}
    const recover = ()=>{
		if (password.length > MaxPasswordLength) {
			SetPasswordError(<span><FormattedMessage id="cantbelonger"/> {MaxPasswordLength}</span>)
			return
		}
		if (password2.length > MaxPasswordLength) {
			SetPassword2Error(<span><FormattedMessage id="cantbelonger"/> {MaxPasswordLength}</span>)
			return
		}
        if (password == password2) {
            AuthService.setNewPassword(token, password).then((response)=>{
                if (response.data.Success == SuccessMessage) {
                    localStorage.setItem('AuthToken', response.data.Tokens.AuthToken+makeString(EndSaltLength));
                    localStorage.setItem('RefreshToken', response.data.Tokens.RefreshToken+makeString(EndSaltLength));
                    localStorage.setItem('DeviceKey', response.data.Tokens.DeviceKey);
                    setAuthTokens({AuthToken: response.data.Tokens.AuthToken, RefreshToken: response.data.Tokens.RefreshToken})
                    setAuthorized({Authorized: true})
                    navigate("/")
                }
            })
        } else {
			SetPassword2Error(<FormattedMessage id="notMatchingPasswords" />)
		}       
    }
    return (
		<Stack spacing={2} style={{position: "absolute", width: "500px", left: "50%", top: "50%", transform: "translate(-50%, -50%)"}} >
            <Typography variant='h5'>
                <FormattedMessage id="set_new_password" />
            </Typography>
			<TextField 
				id="password"
				value={password}
				onChange={changePassword}
				label={<FormattedMessage id="password" />} 
				type="password"
				variant="outlined"
				helperText={passwordError}
				error= {passwordError != null} 
			/>	

			<TextField 
				id="password2" 
				value={password2}
				onChange={changePassword2}
				label={<FormattedMessage id="repeat_password" />}
				type="password"
				variant="outlined"
				helperText={password2Error}
				error= {password2Error != null}
			/>

            <Button variant="contained" size="large" onClick={recover}>
				<FormattedMessage id="install" />
			</Button>	
        </Stack>
    )
}

export default RecoverPassword; 
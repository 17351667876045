import { Button, Container, Modal, Stack, Tab, Tabs, Typography, Snackbar, Alert } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import { FormattedMessage } from 'react-intl';

import AvatarImg from '../../assets/images/avatar.png';
import AvatarImg2 from '../../assets/images/avatar2.png';
import CommunityHeader from './CommunityHeader/CommunityHeader';
import EventItem from './EventItem/EventItem';
import { useNavigate } from 'react-router-dom';
import UserItem from './UserItem/UserItem';
import RequestToJoinItem from './/RequestToJoinItem/RequestToJoinItem';
import FlatChat from '../common/FlatChat/FlatChat';
import ModalStyle from '../../utils/ModalStyle/ModalStyle';
import ModalClose from '../../utils/ModalStyle/ModalClose';
import CreateEventForm from './CreateEventForm/CreateEventForm';
import {useParams} from "react-router-dom";
import CommunitiesService from '../../services/CommunitiesService';
import {SuccessMessage} from "../../constants";
import { ConstructionOutlined } from '@mui/icons-material';
import {OpenCreateEvent, OpenEditCommunity} from "./atoms/Common";
import {CommunityStatus, UpdateUsersList} from "./atoms/Community";
import {useRecoilState} from "recoil";
import {ThemeAtom} from "../atoms/Common";
import {UserInfoAtom} from "../atoms/UserAtoms"
import {ruTypeTime} from "../../utils/DateUtils"
import {domainNameAPI, protocolAPI, apiPort} from "../../environment"
import { makeString } from '../../utils/common';
import {Authorized} from "../atoms/AuthAtoms"
import OwnershipTransferModal from "./OwnershipTransferModal/OwnershipTransferModal"

const mockDataCommunity = {
	icon: AvatarImg,
	title: 'Клуб Любителей пива',
	description: 'А это описание клуба о том, как здорово быть его членом и о веселых пьянках в режиме онлайн',
	countUser: 10,
	eventsList: [],
	peopleList: [],
	applications: []
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`community-tabpanel-${index}`}
      aria-labelledby={`community-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `community-tab-${index}`,
    'aria-controls': `community-tabpanel-${index}`,
  };
}

const Community = () => {
	const [value, setValue] = useState(0);
	const [communityDetails, setCommunityDetails] = useState({icon: "", type: "community", name: "Название", description: "Описание", countOfUsers: 1, });
	const [events, setEvents] = useState([]);
	const [users, setUsers] = useState([]);
	const [requests, setRequests] = useState([]);
	const [creatorID, setCreatorID] = useState(0);
	const [openTransferOwnership, setOpenTransferOwnership] = useState(false)
	const [toLeaveForOwner, setToLeaveForOwner] = useState(false);

	const [openCreateEvent, setOpenCreateEvent] = useRecoilState(OpenCreateEvent);
	const [themeAtom, setThemeAtom] = useRecoilState(ThemeAtom);
	const [authorized, setAuthorized] = useRecoilState(Authorized);
	const [communityStatus, setCommunityStatus] = useRecoilState(CommunityStatus);
	const [updateUsersList, setUpdateUsersList] = useRecoilState(UpdateUsersList)
	const [openEditCommunity, setOpenEditCommunity] = useRecoilState(OpenEditCommunity);
	const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);


	let { community_id } = useParams();

	const navigate = useNavigate();

	const handleChange = (event, newValue) => {
		if (communityStatus == "member" && newValue == 2) {
			setValue(newValue+1);
		} else {
			setValue(newValue);
		}
	};

	const handleOpenCreateEvent = () => {
		setOpenCreateEvent(true);
	};

	const handleCloseCreateEvent = () => {
		setOpenCreateEvent(false);
	};
	const handleOpenTransferOwnership = () => {
		setToLeaveForOwner(true)
		setOpenTransferOwnership(true);
	};
	const handleCloseTransferOwnership = ()=>{
		setOpenTransferOwnership(false)
	};


	let apiUrl = protocolAPI+domainNameAPI+apiPort
	useEffect(() => {
		if (openTransferOwnership) {
			return
		}
		CommunitiesService.communityDetails(parseInt(community_id)).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				setCommunityDetails({
					id: parseInt(community_id), 
					pureIcon: response.data.Community.Icon,
					icon: apiUrl+"/mkstatic/shared_communities/icons/"+response.data.Community.Icon+"?dop"+makeString(5),
					type: response.data.Community.CommunityType,
					name: response.data.Community.Name,
					description: response.data.Community.Description,
					countOfUsers: response.data.Community.CountOfUsers,
				})
				setCreatorID(response.data.Community.CreatorID)
				if (authorized.Authorized) {
					CommunitiesService.userStatus(parseInt(community_id)).then((r)=>{
						if (r.data.Success == SuccessMessage) {
							setCommunityStatus(r.data.Status.Status)
						}
					})
				}
			}
		})
		CommunitiesService.listEvents(parseInt(community_id)).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				setEvents(response.data.Events.Events)
			}
		})
	}, [openCreateEvent, openEditCommunity, authorized, openTransferOwnership])
	useEffect(() => {
		if (value == 1) {
			CommunitiesService.listUsers(parseInt(community_id)).then((response)=>{
				if (response.data.Success == SuccessMessage) {
					setUsers(response.data.Users.Users)
				}
			})
		}
		if (value == 2) {
			CommunitiesService.listRequest(parseInt(community_id)).then((response)=>{
				if (response.data.Success == SuccessMessage) {
					setRequests(response.data.Requests.Requests)
				}
			})
		}		
	}, [value, updateUsersList])

	return (
		<Box
			sx={{
				minHeight: '100vh',
				bgcolor: 'background.default',
				color: 'text.primary',
			}}
		>
			<Container maxWidth="lg">
				<Stack spacing={4} paddingY={3}>	
					<CommunityHeader
						id={communityDetails.id}
						icon={communityDetails.icon}
						pureIcon={communityDetails.pureIcon}
						name={communityDetails.name}
						type={communityDetails.type}
						description={communityDetails.description}
						countOfUsers={communityDetails.countOfUsers}
					/>					
					
					<Box sx={{ width: '100%' }}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons allowScrollButtonsMobile>
								<Tab label="События" {...a11yProps(0)} />
								<Tab label="Люди" {...a11yProps(1)} />
								{communityStatus == "admin" || communityStatus == "owner" ? <Tab label="Заявки" {...a11yProps(2)} /> : null}
								{communityStatus == "admin" || communityStatus == "owner" || communityStatus == "member" ? <Tab label="Чат" {...a11yProps(3)} /> : null}
							</Tabs>
						</Box>

						<TabPanel value={value} index={0}>
							<Stack 
								spacing={3} 
							>
								{communityStatus == "admin" || communityStatus == "owner" ?
									<Button variant="contained" size="large" onClick={handleOpenCreateEvent}>
										Создать событие
									</Button> : null}

								{events.map((item, index) => (
									<EventItem
										onClick={() => navigate('/event/'+community_id+"/"+item.ID)}
										date={ruTypeTime(item.Datetime)}
										icon={"/themes/"+themeAtom.theme+"/imgs/features/"+item.EventType+".png"}
										name={item.Name}
										roomSize={item.MaxUsers}
										users={item.JoinedUsers}
									/>
								))}							
							</Stack>
						</TabPanel>

						<TabPanel value={value} index={1}>
							<Stack 
								spacing={2} 
							>
								{users.map((item, index) => (
									<UserItem
										key={"user"+index}
										name={item.Nickname}
										avatar={apiUrl+"/mkstatic/shared/avatars/"+item.Avatar+"?dopasdasda"}
										role={item.Role}
										userID={item.ID}
										communityID={parseInt(community_id)}
										myRole={communityStatus}
										openTransferOwnership={handleOpenTransferOwnership}
										me={userInfo != null && userInfo.UserInfo != null && userInfo.UserInfo.UserID == item.ID}										
									/>
								))}
							</Stack>							
						</TabPanel>

						{communityStatus == "admin" || communityStatus == "owner" ?
							<TabPanel value={value} index={2}>
								<Stack 
									spacing={2} 
								>

									{requests.map((item, index) => (
										<RequestToJoinItem
											key={index}
											name={item.Nickname}
											avatar={apiUrl+"/mkstatic/shared/avatars/"+item.Avatar+"?dopasdasda"}
											role={item.Role}
											userID={item.ID}
											communityID={parseInt(community_id)}
											myRole={communityStatus}																				
										/>
									))}
								</Stack>							
							</TabPanel> : null}

						{communityStatus == "admin" || communityStatus == "member" || communityStatus == "owner" ?
							<TabPanel value={value} index={3}>
								<FlatChat key={"community"+community_id} occasionID={parseInt(community_id)} occasionType="community"/>
							</TabPanel>: null}
					</Box>
				</Stack>
			</Container>			

			<Modal
				open={openCreateEvent}
				onClose={handleCloseCreateEvent}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box sx={{ ...ModalStyle }}>
					<ModalClose onClose={handleCloseCreateEvent}/>
					<CreateEventForm communityID={community_id}/>
        		</Box>
			</Modal>
			<Modal
				open={openTransferOwnership}
				onClose={handleCloseTransferOwnership}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box sx={{ ...ModalStyle }}>
					<ModalClose onClose={handleCloseTransferOwnership}/>
					<OwnershipTransferModal communityID={community_id} members={users} onClose={handleCloseTransferOwnership} name={communityDetails.name} type={communityDetails.type}/>
        		</Box>
			</Modal>
			<Snackbar open={toLeaveForOwner} 
				onClose={()=>{setToLeaveForOwner(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
				  key={"toLeaveForOwner"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"toLeaveForOwner"}>
				  <FormattedMessage id="to_leave_for_owner" />
  				</Alert>
			</Snackbar>						
		</Box>
	)
};

export default Community;

import {
    useRecoilState,
} from 'recoil';
import {RoomToken, WSOpened, RoomInfo, MyInfo, ComplainsStatuses, MatesInfoVersions, ShareRoomLinkModal} from './atoms'
import React, { useState, useEffect, useRef } from 'react';
import {useParams} from "react-router-dom";
import TokenService from "../services/TokenService";
import Api from "../services/Api";
import {redirect, useNavigate} from "react-router-dom"
import AlreadyInTheRoom from "./Modals/AlreadyInTheRoom"
import ModalStyle from '../utils/ModalStyle/ModalStyle';
import { Box, Modal, Button, Grid,} from '@mui/material';
import ModalClose from "../utils/ModalStyle/ModalClose"
import {Authorized} from "../main/atoms/AuthAtoms"
import Auth from '../main/user/Auth/Auth';
import {FormattedMessage} from "react-intl";
import SetBirthDay from "../main/Navbar/SetBirthDay"
import {NickNameModal} from "../main/atoms/Common"
import ShareLinkMessage from "./Modals/ShareLinkMessage"

let webRTCClient = null;

export const sendMsg = (type, obj)=>{
    if (webRTCClient == null) {
        console.log("webRTCClient is null");
        return
    }
    if (webRTCClient.readyState !== WebSocket.OPEN) {
        return
    }
    console.log("My msg: ", obj);
    webRTCClient.send(JSON.stringify({'Type': type, 'Payload': obj}));
}

function connect(atomValues, atomSets, props, apiUrl, navigate, reconnect) {
    let {wsOpened, roomInfo, matesInfoVersions} = atomValues
    let {setRoomToken, setMyInfo, setRoomInfo, setWSOpened, setComplainsStatuses, setMatesInfoVersions, setShowAlreadyInAnotherRoom, setAnotherRoomKey, 
        setShowYouBlocked, setShowBirthDayModal, setShowRoomIsClosed, setShowYouWillSpectate, setShowHaveNoPlacesInRoom, setOpenAuth} = atomSets

    if (reconnect) {
        webRTCClient = new WebSocket(apiUrl)
    }
    if (webRTCClient == null) {
        return
    }
    //console.log("Remade Listeners");
    webRTCClient.onopen = () => {
        let newState = Object.assign({}, wsOpened);
        newState.opened = true;
        setWSOpened(newState);
        console.log('WebSocket Client Connected');
        webRTCClient.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), "RoomKey": props.roomKey}}));
    };

    webRTCClient.onclose = () => {
        // setTimeout(function() {
        //     connect(atomValues, atomSets, props, apiUrl, navigate, true);
        // }, 2000);
    };

    webRTCClient.onmessage = (message) => {
        let msg = JSON.parse(message.data);
        switch (msg.Type) {
            case "InitRoom":
                InitRoomHandler(msg, setRoomToken, setMyInfo, setShowYouWillSpectate);
                break;
            case "MyRoomInfo":
                MyRoomInfoHandler(msg, setRoomInfo, roomInfo, matesInfoVersions, setMatesInfoVersions)
                break;
            case "ComplainsStatus":
                setComplainsStatuses({complainsStatuses: msg.Payload.Complains})
                break;  
            case "Blocked":
                window.location.href = "/"
                break;
            case "InAnotherRoom":
                InAnotherRoom(msg, setShowAlreadyInAnotherRoom, setAnotherRoomKey)
                break;    
            case "ErrorCode":
                console.log("ErrorCode", msg.Payload.Code);
                if (msg.Payload.Code == 800) {
                    Api.post("/auth/refreshToken", {
                        RefreshToken: TokenService.getLocalRefreshToken(),
                    }).then(response=> {
                        TokenService.updateLocalAccessTokens(response.data.Tokens.AuthToken, response.data.Tokens.RefreshToken);
                        webRTCClient.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), "RoomKey": props.roomKey}}));
                    }).catch((err) => {
                        webRTCClient.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), "RoomKey": props.roomKey}}));
                    });
                } else if (msg.Payload.Code == 404 && !props.roulette && window.location.pathname == "/room") {
                    navigate('/games')
                } else if (msg.Payload.Code == 703) {
                    setShowYouBlocked(true)
                } else if (msg.Payload.Code == 701) {
                    setShowBirthDayModal(true)
                } else if (msg.Payload.Code == 702) {
                    setShowRoomIsClosed(true)
                } else if (msg.Payload.Code == 704) {
                    setShowHaveNoPlacesInRoom(true)
                } else if (msg.Payload.Code == 301) {
                    console.log("ASASAS OPENAUTH")
                    setOpenAuth(true)
                }
                break;
        }
    };
}

// props.apiURL
function WSController(props) {
    const apiUrl = props.apiURL

    const [showAlreadyInAnotherRoom, setShowAlreadyInAnotherRoom] = useState(false);
    const [anotherRoomKey, setAnotherRoomKey] = useState("");

    const [showYouBlocked, setShowYouBlocked] = useState(false);
    const [openAuth, setOpenAuth] = useState(false);
    const [showBirthDayModal, setShowBirthDayModal] = useState(false);
    const [showRoomIsClosed, setShowRoomIsClosed]  = useState(false);
    const [showYouWillSpectate, setShowYouWillSpectate] = useState(false);
    const [showHaveNoPlacesInRoom, setShowHaveNoPlacesInRoom] = useState(false);
    const [nicknameWasNotSet, setNicknameWasNotSet] = useState(false);

    // Got string from path
    let { sessionid } = useParams(); 
    const [roomToken, setRoomToken] = useRecoilState(RoomToken); 
    const [wsOpened, setWSOpened] = useRecoilState(WSOpened); 
    const [roomInfo, setRoomInfo] = useRecoilState(RoomInfo);  
    const [myInfo, setMyInfo] = useRecoilState(MyInfo);
    const [complainsStatuses, setComplainsStatuses] = useRecoilState(ComplainsStatuses);
    const [matesInfoVersions, setMatesInfoVersions] = useRecoilState(MatesInfoVersions);
    const [authorized, setAuthorized] = useRecoilState(Authorized);
    const [nickNameModal, setNickNameModal] = useRecoilState(NickNameModal)
    const [showShareRoomLink, setShowShareRoomLink] = useRecoilState(ShareRoomLinkModal);


    const navigate = useNavigate();

    useEffect(()=> {
        if (nickNameModal.Open) {
            setNicknameWasNotSet(true)
        }
        if (nicknameWasNotSet && !nickNameModal.Open) {
            if (webRTCClient != null) {
                webRTCClient.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), "RoomKey": props.roomKey}}));
                setNicknameWasNotSet(false)
            }
        }
    }, [nickNameModal, nicknameWasNotSet]);

    useEffect(()=> {
        webRTCClient = new WebSocket(apiUrl) // , TokenService.getLocalAccessToken()
    }, []);

    useEffect(()=> {
        connect({wsOpened, roomInfo, matesInfoVersions}, {setRoomToken, setMyInfo, setRoomInfo, setWSOpened, setComplainsStatuses, setMatesInfoVersions, 
            setShowAlreadyInAnotherRoom, setAnotherRoomKey, setShowYouBlocked, setShowBirthDayModal, setShowRoomIsClosed, setShowYouWillSpectate,
             setShowHaveNoPlacesInRoom, setOpenAuth}, props, apiUrl, navigate, false)
    }, []);
    let gameControllerStyle = {
        Position: "fixed",
        Width: 0,
        Height: 0,
    }

    const handleAlreadyInAnotherRoomClose = ()=>{
        setShowAlreadyInAnotherRoom(false)
    }
    const restartRoom = ()=>{
        if (webRTCClient != null) {
            navigate("/room/"+anotherRoomKey)
            webRTCClient.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), "RoomKey": anotherRoomKey}}));
        } else {
            window.location.reload()
        }
    }
    const reloadRoom = ()=>{
        if (webRTCClient != null) {
            navigate("/room/"+props.roomKey)
            webRTCClient.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), "RoomKey": props.roomKey}}));
        } else {
            window.location.reload()
        }
    }
    const requestRoom = ()=>{
        if (webRTCClient != null) {
            webRTCClient.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), "RoomKey": props.roomKey}}));
        } else {
            window.location.reload()
        }
    }
    return (
        <span style={gameControllerStyle}>


            <Modal
              open={showShareRoomLink}
              onClose={()=>{setShowShareRoomLink(false)}}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...ModalStyle }}>
                  <ModalClose onClose={()=>{setShowShareRoomLink(false)}}/>
                  <ShareLinkMessage  onClose={()=>{setShowShareRoomLink(false)}} link={window.location.href}/>
                </Box>
            </Modal>

            <Modal
              open={showAlreadyInAnotherRoom}
              onClose={handleAlreadyInAnotherRoomClose}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...ModalStyle }}>
                  <ModalClose onClose={handleAlreadyInAnotherRoomClose}/>
                  <AlreadyInTheRoom roomKey={anotherRoomKey} roomKeyNew={props.roomKey} onClose={handleAlreadyInAnotherRoomClose} onRestart={restartRoom} onReload={reloadRoom}/>
                </Box>
            </Modal>

            <Modal
                open={openAuth}
                onClose={()=>{setOpenAuth(false)}}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...ModalStyle, width: 370 }}>				
                    <Auth startIndex={1}/>
                </Box>
            </Modal>

            <Modal
              open={showYouBlocked}
              onClose={()=>{setShowYouBlocked(false)}}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...ModalStyle }}>
                  <ModalClose onClose={()=>{setShowYouBlocked(false)}}/>
                  <Grid container rowSpacing={2} spacing={{ xs: 1, sm: 2 }} columns={{ xs: 4, sm: 8, md: 12 }} direction="column" padding={1} alignItems="center">
				    <FormattedMessage id="you_are_blocked_in_room" /><br/><br/>
                    <Button variant="contained" size="large" onClick={()=>{navigate("/games")}}>
                        <FormattedMessage id="understood" />
                    </Button>
                  </Grid>                   
                </Box>
            </Modal>

            <Modal
                open={showBirthDayModal}
                onClose={()=>{setShowBirthDayModal(false)}}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...ModalStyle, width: 400 }}>
                    <SetBirthDay onClose={()=>{setShowBirthDayModal(false)}} onActionAfter={requestRoom}/>
                </Box>
            </Modal>

            <Modal
                open={showRoomIsClosed}
                onClose={()=>{setShowRoomIsClosed(false)}}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...ModalStyle, width: 400 }} >
                    <ModalClose onClose={()=>{setShowRoomIsClosed(false)}}/>
                    <Grid container rowSpacing={2} spacing={{ xs: 1, sm: 2 }} columns={{ xs: 4, sm: 8, md: 12 }} direction="column" padding={1} alignItems="center">
                        <FormattedMessage id="room_is_already_closed" /><br/><br/>
                        <Button variant="contained" size="large" onClick={()=>{navigate("/")}}>
                            <FormattedMessage id="understood" />
                        </Button>
                    </Grid>                   
                </Box>
            </Modal>

            <Modal
                open={showYouWillSpectate}
                onClose={()=>{setShowYouWillSpectate(false)}}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...ModalStyle, width: 400 }}>
                    <ModalClose onClose={()=>{setShowYouWillSpectate(false)}}/>
                    <Grid container rowSpacing={2} spacing={{ xs: 1, sm: 2 }} columns={{ xs: 4, sm: 8, md: 12 }} direction="column" padding={1} alignItems="center">
                        <FormattedMessage id="cant_place_you_will_spectate" /><br/><br/>
                        <Button variant="contained" size="large" onClick={()=>{setShowYouWillSpectate(false)}}>
                            <FormattedMessage id="understood" />
                        </Button>
                    </Grid>                                      
                </Box>
            </Modal> 

            <Modal
                open={showHaveNoPlacesInRoom}
                onClose={()=>{setShowHaveNoPlacesInRoom(false)}}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...ModalStyle, width: 400 }}>
                    <ModalClose onClose={()=>{setShowHaveNoPlacesInRoom(false)}}/>
                    <Grid container rowSpacing={2} spacing={{ xs: 1, sm: 2 }} columns={{ xs: 4, sm: 8, md: 12 }} direction="column" padding={1} alignItems="center">
                        <FormattedMessage id="have_no_place_in_room" /><br/><br/>
                        <Button variant="contained" size="large" onClick={()=>{navigate("/")}}>
                            <FormattedMessage id="understood" />
                        </Button>
                    </Grid>                                                         
                </Box>
            </Modal>                               
            
        </span>
    );
}

export default WSController;

function InitRoomHandler(msg, setRoomToken, setMyInfo, setShowYouWillSpectate) {
    setRoomToken({name: msg.Payload.RoomName, token: msg.Payload.Token});
    setMyInfo({name: msg.Payload.MyName, role: msg.Payload.MyRole});
    if (msg.Payload.MyRole == "spectator") {
        setShowYouWillSpectate(true)
    }
}

function MyRoomInfoHandler(msg, setRoomInfo, roomInfo, matesInfoVersions, setMatesInfoVersions) {
    if (roomInfo.roomMates == null) {
        let versions = {}
        for (let identity in msg.Payload.RoomMates) {
            versions[msg.Payload.RoomMates[identity].Position] = 1
        }
        setRoomInfo({roomMates: msg.Payload.RoomMates, type: msg.Payload.RoomType, layout: msg.Payload.Layout, entityID: msg.Payload.EntityID, roomID: msg.Payload.RoomID, scope: msg.Payload.Scope})
        setMatesInfoVersions({versions: versions})
        return
    }
    let versions = Object.assign({}, matesInfoVersions.versions)
    for (let currIdentity in msg.Payload.RoomMates) {
        let foundMate = false
        for (let identity in roomInfo.roomMates) {
            if (identity == currIdentity) {
                foundMate = true
                if ((msg.Payload.RoomMates[currIdentity].Position != roomInfo.roomMates[identity].Position) 
                || (msg.Payload.RoomMates[currIdentity].Position != roomInfo.roomMates[identity].Position)) {
                    versions[msg.Payload.RoomMates[currIdentity].Position] += 1
                }
            }
        }
        if (!foundMate) {
            if (versions[msg.Payload.RoomMates[currIdentity].Position] != undefined) {
                versions[msg.Payload.RoomMates[currIdentity].Position] += 1
            } else {
                versions[msg.Payload.RoomMates[currIdentity].Position] = 1
            }
        }
    }
    for (let identity in roomInfo.roomMates) {
        let foundMate = false
        for (let currIdentity in msg.Payload.RoomMates) {
            if (identity == currIdentity) {
                foundMate = true
            }
        }
        if (!foundMate) {
            versions[roomInfo.roomMates[identity].Position] += 1
        }
    }
    setMatesInfoVersions({versions: versions})
    setRoomInfo({roomMates: msg.Payload.RoomMates, type: msg.Payload.RoomType, layout: msg.Payload.Layout, entityID: msg.Payload.EntityID, roomID: msg.Payload.RoomID, scope: msg.Payload.Scope})
}

function InAnotherRoom(msg, setShowAlreadyInAnotherRoom, setAnotherRoomKey) {
    console.log("ASASAS InAnotherRoom")
    setAnotherRoomKey(msg.Payload.RoomKey)
    setShowAlreadyInAnotherRoom(true)
}
import {
    useRecoilState,
} from 'recoil';
import {SiteWSOpened, SiteWS, SiteWSRestartTimeout} from "./atoms/SiteAtoms"
import {TalkRoomsList} from "./atoms/Rooms"
import {ListGameLobbies} from "./atoms/GamesAtoms"
import TokenService from "../services/TokenService"
import Api from "../services/Api";
import React, {useState, useEffect} from 'react';
import {CurrentlyInRoom, CurrentRoom} from "./atoms/Rooms"
import {Authorized} from "./atoms/AuthAtoms"
import {AllExperience, AddPointsNumber} from "./atoms/Ratings"

let ws = null

export const sendMsgSite = (ws, type, obj)=>{
    if (ws == null) {
        console.log("webRTCClient is null");
        return
    }
    try {
        if (ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({'Type': type, 'Payload': obj}));
        }
    } catch (error) {
        console.error(error);
    }
}

function connect(atomValues, atomSets, props, reconnect) {
    let {wsOpened, siteWSRestartTimeout, allExperience} = atomValues
    let {setWSOpened, setSiteWS, setSiteWSRestartTimeout, setTalkRoomsList, setListGameLobbies, setCurrentlyInRoom, setCurrentRoom, setAllExperience,
        setAddPointsNumber} = atomSets

    if (reconnect) {
        ws = new WebSocket(props.apiUrl)
        setSiteWS({ws: ws})
    }
    if (ws == null) {
        return
    }

    ws.onopen = () => {
        let newState = Object.assign({}, wsOpened);
        newState.opened = true;
        setWSOpened(newState);
        console.log('Site WebSocket Client Connected');

        ws.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(),}}));
    };

    ws.onclose = () => {
        if (siteWSRestartTimeout.timeout != 0) {
            clearInterval(siteWSRestartTimeout.timeout)
        }
        let newState = Object.assign({}, wsOpened);
        newState.opened = false;
        setWSOpened(newState);
        let rTimeOut = setTimeout(function() {
            connect(atomValues, atomSets, props, true);
        }, 2000);
        setSiteWSRestartTimeout({timeout: rTimeOut})
    };

    ws.onmessage = (message) => {
        let msg = JSON.parse(message.data);
        switch (msg.Type) {
            case "TalkRoomsRecruiting":
                processTalkRoomsRecruiting(msg, setTalkRoomsList)
                break;
            case "TalkRoomsAlready":
                processTalkRoomsAlready(msg, setTalkRoomsList)
                break;
            case "HackwordLobbiesRecruiting":
                processGameLobbies(msg, setListGameLobbies)
                break;
            case "HackwordLobbiesAlready":
                processGameLobbies(msg, setListGameLobbies)
                break;
            case "IamaliveLobbiesRecruiting":
                processGameLobbies(msg, setListGameLobbies)
                break;
            case "IamaliveLobbiesAlready":
                processGameLobbies(msg, setListGameLobbies)
                break;
            case "GeneralizeLobbiesRecruiting":
                processGameLobbies(msg, setListGameLobbies)
                break;
            case "GeneralizeLobbiesAlready":
                processGameLobbies(msg, setListGameLobbies)
                break;                
            case "CurrentRoom":
                processCurrentRoom(msg, setCurrentlyInRoom, setCurrentRoom)
                break;                                                     
            case "AllExperienceState":
                processAllExperienceState(msg, allExperience, setAllExperience)
                break;
            case "AddExperience":
                processAddExperience(msg, setAddPointsNumber)
                break                                                        
            case "ErrorCode":
                if (msg.Payload.Code == 800) {
                    Api.post("/auth/refreshToken", {
                        RefreshToken: TokenService.getLocalRefreshToken(),
                    }).then(response=> {
                        TokenService.updateLocalAccessTokens(response.data.Tokens.AuthToken, response.data.Tokens.RefreshToken);
                        ws.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(),}}));
                    }).catch((err) => {
                        TokenService.anotherRefreshErrorCheck()
                        ws.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(),}}));
                    });
                }               
                break         
        }
    }
}

function SiteController(props) {
    const [wsOpened, setWSOpened] = useRecoilState(SiteWSOpened); 
    const [siteWSRestartTimeout, setSiteWSRestartTimeout]  = useRecoilState(SiteWSRestartTimeout);
    const [siteWS, setSiteWS]  = useRecoilState(SiteWS);

    const [talkRoomsList, setTalkRoomsList] = useRecoilState(TalkRoomsList);
    const [listGameLobbies, setListGameLobbies] = useRecoilState(ListGameLobbies);
    const [currentlyInRoom, setCurrentlyInRoom] = useRecoilState(CurrentlyInRoom)
    const [currentRoom, setCurrentRoom] = useRecoilState(CurrentRoom)
    const [authorized, setAuthorized] = useRecoilState(Authorized);

    const [allExperience, setAllExperience] = useRecoilState(AllExperience)
    const [addPointsNumber, setAddPointsNumber] = useRecoilState(AddPointsNumber)

    useEffect(()=> {
        // Empty SessionID means unauthorized user. Not empty - means authorized
        sendMsgSite(siteWS.ws, "InitRequest", {'SessionID': TokenService.getLocalAccessToken(),})
    }, [authorized]);

    useEffect(()=> {
        ws = new WebSocket(props.apiUrl)
        setSiteWS({ws: ws}) // , TokenService.getLocalAccessToken()
    }, []);

    useEffect(()=> {
        connect({wsOpened, siteWSRestartTimeout, allExperience,}, {setWSOpened, setSiteWS, setSiteWSRestartTimeout, setTalkRoomsList, setListGameLobbies, 
            setCurrentlyInRoom, setCurrentRoom, setAllExperience, setAddPointsNumber}, props, false)
    }, [allExperience,]);

    return (
        <span>
        </span>
    )
}

export default SiteController

const processTalkRoomsRecruiting = function(msg, setTalkRoomsList) {
    setTalkRoomsList({rooms: msg.Payload.Rooms})
}

const processTalkRoomsAlready = function(msg, setTalkRoomsList) {
    setTalkRoomsList({rooms: msg.Payload.Rooms})
}

const processGameLobbies = function(msg, setListGameLobbies) {
    setListGameLobbies({lobbies: msg.Payload.Lobbies})
}

const processCurrentRoom = function(msg, setCurrentlyInRoom, setCurrentRoom) {
    setCurrentRoom(msg.Payload)
    setCurrentlyInRoom(msg.Payload.HaveCurrent)
}

const processAllExperienceState = function(msg, allExperience, setAllExperience) {
    setAllExperience(msg.Payload)
}

const processAddExperience = function(msg, setAddPointsNumber) {
    setAddPointsNumber(msg.Payload)
}
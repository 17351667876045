





export const Rules = {
    "rules":`
### Правила портала

Да, правила могут ограничивать вас в действиях, но они необходимы, чтобы пребывание на сайте было комфортно для каждого. При оценке нарушений администрация всегда в первую очередь будет стараться руководствоваться здравым смыслом.

1. Запрещено нарушать законы Российской Федерации.

Запрещается распространение информации, которая направлена на пропаганду войны, разжигание национальной, расовой или религиозной ненависти и вражды, а также иной информации, за распространение которой предусмотрена уголовная или административная ответственность.

2. Вы должны быть не младше 18 лет.

3. Очень важно - на портале нельзя обсуждать темы касающиеся политики и вероисповедания.

4. Также важно - это не быть таксичным и если кто-то является токсичным - пожаловаться на него. Так мы заботимся об общей атмосфере на портале.

5. Запрещено флудить в чате.

6. Запрещено рекламировать сторонние ресурсы и товары.

7. Запрещено представать перед камерой в голом виде, домогаться до людей и навязчиво транслировать сексуальный подтекст.

8. И уважайте друг друга.

За нарушение правил Администрация оставляет за собой право банить пользователей.

Если вы находитесь в комнате, вы можете пожаловаться на другого пользователя

![Жалоба](/knowledge_base/screen_blame.png)

Чтобы не забыли ссылки на документы:

[Пользовательское соглашение](https://mnogokomnat.ru/user_agreement)

[Политика конфиденциальности](https://mnogokomnat.ru/privacy_policy)
`
}
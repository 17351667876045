
import AuthService from "../services/AuthService";
import {makeString} from "../utils/common"
import {Authorized, AuthTokens} from './atoms/AuthAtoms'
import {useNavigate} from "react-router-dom"
import {useParams} from "react-router-dom";
import {
    useRecoilState,
} from 'recoil';
import {SuccessMessage, EndSaltLength,} from "../constants";



function CheckMail() {
    let { token } = useParams();
    
    const [authorized, setAuthorized] = useRecoilState(Authorized);
    const [authTokens, setAuthTokens] = useRecoilState(AuthTokens);

    const navigate = useNavigate();

    AuthService.loginCheckEmail(token).then(response=>{
        console.log("response", response)

        if (response.data.Success == SuccessMessage) {
            localStorage.setItem('AuthToken', response.data.Tokens.AuthToken+makeString(EndSaltLength));
            localStorage.setItem('RefreshToken', response.data.Tokens.RefreshToken+makeString(EndSaltLength));
            localStorage.setItem('DeviceKey', response.data.Tokens.DeviceKey);
            setAuthTokens({AuthToken: response.data.Tokens.AuthToken, RefreshToken: response.data.Tokens.RefreshToken})
            setAuthorized({Authorized: true})
            navigate("/")
        }
    })
    return (
        <div></div>
    )
}

export default CheckMail; 
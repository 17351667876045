import {
    atom,
} from 'recoil';

export const Authorized = atom({
    key: 'Authorized',
    default: {
        Authorized: false,
    },
})

export const AuthTokens = atom({
    key: 'AuthTokens',
    default: {
        AuthToken: "",
        RefreshToken: "",
    },
})

export const OpenAuth = atom({
    key: 'OpenAuth',
    default: {Open: false
    }
})

export const RefreshTokenErrorsInARow = atom({
    key: 'RefreshTokenErrorsInARow',
    default: 0
})
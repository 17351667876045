import { Box, } from '@mui/system';
import { Modal, } from '@mui/material';
import {useRecoilState,} from 'recoil';
import {IamaliveGameState, } from "./atoms/GameAtoms"
import {FormattedMessage} from "react-intl";
import { Button, } from '@mui/material';
import { sendMsgIamalive } from './IamaliveGameController';
import {IamaliveWS} from "./atoms/Connection"
import {RoomInfo} from "../../../../livekit_prod/atoms"
import {UserInfoAtom} from "../../../atoms/UserAtoms"
import {influxDBButtonPush} from "../../../../services/influxdb/iamalive"
import SetPreparedWords from "./SetPreparedWords/SetPrepraredWords"
import React, {useState} from 'react';
import ModalStyle from '../../../../utils/ModalStyle/ModalStyle';
import ModalClose from '../../../../utils/ModalStyle/ModalClose';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {ShowChangeRoomType, SendAdmin} from "../../../../livekit_prod/atoms"

const AdminPanel = (props) => {
    const [iamaliveGameState, setIamaliveGameState]  = useRecoilState(IamaliveGameState); 
    const [iamaliveWS, setIamaliveWS]  = useRecoilState(IamaliveWS);
    const [roomInfo, setRoomInfo]  = useRecoilState(RoomInfo);
    const [userInfo, setUserInfo]  = useRecoilState(UserInfoAtom);
    const [sendAdmin, setSendAdmin]  = useRecoilState(SendAdmin);

    const [showSetPreparedWord, setShowSetPreparedWord] = useState(false);
    const [showChangeRoomType, setShowChangeRoomType] = useRecoilState(ShowChangeRoomType)

    let buttonMargin = {
      marginTop: "15px",
    }

    const stopPickingPlayers = ()=>{
        influxDBButtonPush(userInfo, props.iamaliveID, "stop_picking_players")
        sendMsgIamalive(iamaliveWS.ws, "StopNewPlayers", {})
    }
    const startPickingPlayers = ()=>{
        influxDBButtonPush(userInfo, props.iamaliveID, "start_picking_players")
        sendMsgIamalive(iamaliveWS.ws, "StartNewPlayers", {})
    }
    const startGame = ()=>{
        influxDBButtonPush(userInfo, props.iamaliveID, "start_game")
        sendMsgIamalive(iamaliveWS.ws, "StartGame", {})
    }
    let nickOfForWhoome = ""
    let nickOfWho = ""
    for (let identity in roomInfo.roomMates) {
      if (roomInfo.roomMates[identity].Position == iamaliveGameState.positionToAddWord) {
        nickOfForWhoome = identity
      }
      if (roomInfo.roomMates[identity].Position == iamaliveGameState.positionToChooseWord) {
        nickOfWho = identity
      }
    }
    const changeWord = ()=>{
      influxDBButtonPush(userInfo, props.iamaliveID, "change_word")
      sendMsgIamalive(iamaliveWS.ws, "ChangeWord", {Position: props.myposition})
    }
    const clickSendAdmin = ()=>{
      setSendAdmin(true)
    }
    const keepAdmin = ()=>{
      setSendAdmin(false)
    }
    const setPreparedWords = ()=>{
      setShowSetPreparedWord(true)
    }
    const handleCloseCreateRoom = ()=>{
      setShowSetPreparedWord(false)
    }
    return (
        <Box
        sx={{
          minHeight: '100vh',
          bgcolor: 'background.default',
          color: 'text.primary',
        }}
      >
            {iamaliveGameState.phase == "picking" ?
            <div>
                <div><FormattedMessage id="YouAreAnAdmin" /></div>
                <Button variant="contained" color="success" style={{marginTop: "10px"}} onClick={startGame}>
                  <FormattedMessage id="StartGame" />
                </Button>
            </div>
            : null}
            {iamaliveGameState.phase == "choose_word" ?
            <div>
                <div><FormattedMessage id="choosing_word" /></div><br/>
                <div><FormattedMessage id="for_whom_we_choose" />: {nickOfForWhoome} (<FormattedMessage id="not_hear_anyone" />)</div><br/>
                <div><FormattedMessage id="who_choose" />: {nickOfWho}</div><br/>
                <Button variant="contained" color="success" style={{marginTop: "10px"}} onClick={setPreparedWords}>
                  <FormattedMessage id="set_prepared_words" />
                </Button>
            </div>
            : null}
            {iamaliveGameState.phase == "playing" ?
            <div>
                <div><FormattedMessage id="playing_now" /></div><br/>
                <div><FormattedMessage id="to_change_word_do_this" /></div><br/>
                <Button variant="contained" color="success" onClick={changeWord}>
                  <FormattedMessage id="change_own_word" />
                </Button><br/>
                <Button variant="contained" color="success" style={{marginTop: "10px"}} onClick={setPreparedWords}>
                  <FormattedMessage id="set_prepared_words" />
                </Button><br/>                
            </div>
            : null} 
            {iamaliveGameState.phase != "picking" && iamaliveGameState.allowNewPlayers && props.scope && props.scope == "lobby" ? 
                <div><br/><br/>
                  <div><FormattedMessage id="RecruitingPlayers" /></div><br/>
                  <Button variant="contained" color="success" onClick={stopPickingPlayers}>
                    <FormattedMessage id="just_stop_picking_players" />
                  </Button><br/>                
                </div>
               : null}
            {iamaliveGameState.phase != "picking" && !iamaliveGameState.allowNewPlayers && props.scope && props.scope == "lobby" ?  
                <div><br/>
                  <div><FormattedMessage id="players_is_not_picking" /></div><br/>
                  <Button variant="outlined" color="success" onClick={startPickingPlayers}>
                    <FormattedMessage id="StartPickingPlayers" />
                  </Button><br/>                
                </div>              
               : null}
            {sendAdmin ? 
              <Box component="span" style={{ display: 'block', width: '100%', textAlign: 'center'}}>
                <Button 
                  variant="contained" 
                  color="success"
                  onClick={keepAdmin}
                  style={buttonMargin}
                >
                  <FormattedMessage id="keep_admin" />                
                </Button>	
              </Box>                
            :                                 
              <Box component="span" style={{ display: 'block', width: '100%', textAlign: 'center'}}>
                <Button 
                  variant="outlined" 
                  color="success"
                  onClick={clickSendAdmin}
                  style={buttonMargin}
                >
                  <FormattedMessage id="send_admin" />                
                </Button>	
              </Box>}
              
              <br/>
              <Button variant="outlined" onClick={()=>{setShowChangeRoomType(true)}} size="small" endIcon={<ChevronRightIcon />}>
                <FormattedMessage id="change_game" />
              </Button> 

              <Modal
                open={showSetPreparedWord}
                onClose={handleCloseCreateRoom}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
              >
                <Box sx={{ ...ModalStyle, width: 370 }}>
                    <ModalClose onClose={handleCloseCreateRoom}/>
                    <SetPreparedWords iamaliveID={props.iamaliveID} onClose={handleCloseCreateRoom}/>
                </Box>
            </Modal>                                       
      </Box>
    )
}

export default AdminPanel
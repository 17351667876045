import React, {useState} from 'react';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import HelpIcon from '@mui/icons-material/Help';
import {
	useRecoilState
  } from 'recoil';
import { Tooltip, Button } from '@mui/material';
import { ThemeAtom, } from '../atoms/Common';
import {FormattedMessage} from "react-intl";

const CustomWidthTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
  ))({
	[`& .${tooltipClasses.tooltip}`]: {
	  maxWidth: 500,
	},
  });




function RulesButton(props) {
    const [theme, _] = useRecoilState(ThemeAtom);

    const [changeButtonVariant, setChangeButtonVariant] = useState(false);

    const mouseOverHandler = ()=>{
        setChangeButtonVariant(true)
    }
    const mouseLeaveHandler = ()=>{
        setChangeButtonVariant(false)
    }

    return (
        <CustomWidthTooltip  onMouseEnter={mouseOverHandler} onMouseLeave={mouseLeaveHandler}
            title={
                <div onMouseEnter={mouseOverHandler} onMouseLeave={mouseLeaveHandler}>
                    {<img src={"/themes/"+theme.theme+"/imgs/brief-rules.png"} alt="" style={{ display: "block", maxWidth: "220px", margin: "10px auto" }} />}
                    <h1><FormattedMessage id={props.gameName+"_rules"}/></h1>
                </div>
            } 
        >
            <Button variant={changeButtonVariant ? "contained" : "outlined"} startIcon={<HelpIcon />} style={{padding: "6px 16px"}}>
                <FormattedMessage id="rules" />
            </Button>
        </CustomWidthTooltip>
    )
}

export default RulesButton
import Api from "../Api"



// type CreateTalk struct {
// 	Name         string
// 	EighteenPlus bool
// 	WayType      string
// 	Tags         []string
// 	Modules      []string
// 	Theme        string
// }


const createTalk = async function(name, maxNumberOfParticipants, eighteenPlus, wayType, tags, theme) {
    return await Api.post("/rooms/talk/create", {Name: name, MaxNumberOfParticipants: maxNumberOfParticipants, EighteenPlus: eighteenPlus, WayType: wayType, Tags:tags, Theme: theme})
}

const joinTalk = async function(talkID) {
  return await Api.post("/rooms/talk/join", {TalkID: talkID})
}

const listTalks = async function(typeToRequest) {
  return await Api.post("/talks/list", {TypeToRequest: typeToRequest})
}


const TalkService = {
    createTalk,
    joinTalk,
    listTalks,
  };
  
export default TalkService;
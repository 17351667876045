







export const Talks = {
    "talks":`
### Иногда нам хочется просто поговорить.

Может какая-то тема или новость вас взволновала и вы хотите её обсудить.

Может вы просто хотите познакомиться с новыми людьми.

А может вы ищите тех, кто тоже смотрел новый фильм. Или хотите обсудить какую-то книгу. Чье-то творчество.

Просто пишите о том, что хотите обсудить в тегах и названии комнаты. И зовите собеседников.

Или присоединяйтесь к существующим комнатам.
`
}
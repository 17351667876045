import {EndSaltLength} from "../constants";
import {makeString} from "../utils/common"



const getLocalRefreshToken = () => {
    let refreshToken = localStorage.getItem('RefreshToken');
    if (refreshToken === null) {
      return null
    }
    refreshToken = refreshToken.slice(0, -EndSaltLength);
    return refreshToken;
  };
  
const getLocalAccessToken = () => {
    let authToken = localStorage.getItem('AuthToken');
    if (authToken === null) {
        return null
    }
    authToken = authToken.slice(0, -EndSaltLength);
    return authToken;
};

const getDeviceKey = () => {
  let deviceKey = localStorage.getItem('AuthToken');
  if (deviceKey === null) {
      return ""
  }
  return deviceKey
}
  
  const updateLocalAccessTokens = (authToken, refreshToken) => {
    localStorage.setItem('AuthToken', authToken+makeString(EndSaltLength));
    localStorage.setItem('RefreshToken', refreshToken+makeString(EndSaltLength));
    localStorage.setItem('RefreshTokenErrorsInARow', "0")
  };

  const anotherRefreshErrorCheck = () =>{
    let errorsInARow = localStorage.getItem('RefreshTokenErrorsInARow');
    if (errorsInARow === null) {
        localStorage.setItem('RefreshTokenErrorsInARow', "1")
        return false
    }
    if (parseInt(errorsInARow) > 2) {
      localStorage.removeItem("TetatetSettings");
      localStorage.removeItem("AuthToken");
      localStorage.removeItem("RefreshToken");
      localStorage.removeItem("DeviceKey");
      window.location.href = "/";
      return true
    }
    localStorage.setItem('RefreshTokenErrorsInARow', parseInt(errorsInARow)+1)
    return false
  }
  
  const TokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessTokens,
    getDeviceKey,
    anotherRefreshErrorCheck,
  };
  
  export default TokenService;
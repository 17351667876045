import { Button, Container, Modal, Stack, Snackbar, Alert, Slide } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState, useEffect, useMemo,} from 'react';
import Navbar from '../Navbar/Navbar';
import {FormattedMessage} from "react-intl";
import Room from './Room/Room';
import ModalStyle from '../../utils/ModalStyle/ModalStyle';
import ModalClose from '../../utils/ModalStyle/ModalClose';
import CreateRoom from './CreateRoom/CreateRoom';
import CasualInterlocutors from './CasualInterlocutors/CasualInterlocutors';
import RoomBar from './RoomBar/RoomBar';
import TalkService from '../../services/rooms/TalkService'
import RoomService from '../../services/rooms/RoomService'
import {SuccessMessage} from "../../constants";
import {useNavigate, useLocation} from "react-router-dom"
import { CurrentlyInRoom, ListRoomsInterval } from '../atoms/Rooms';
import { BirthDayModal, ShowTooYoung } from '../atoms/Common';
import {Authorized, OpenAuth} from "../atoms/AuthAtoms"
import {UserInfoAtom,} from "../atoms/UserAtoms"
import {
	useRecoilState,
  } from 'recoil';
import RoomsFilter from "../common/RoomsFilter/RoomsFilter";
import {UserPersonalAtom} from "../atoms/UserAtoms";
import {influxDBButtonPush} from "../../services/influxdb/rooms"
import {TalkRoomsList} from "../atoms/Rooms"
import {sendMsgSite} from "../SiteController"
import {SiteWS, SiteWSOpened} from "../atoms/SiteAtoms"
import {lightLogout} from "../../utils/common"


const Rooms = () => {
	const [isOpenCreateRoom, setIsOpenCreateRoom] = useState(false);
	const [isOpenCasualInterlocutors, setIsOpenCasualInterlocutors] = useState(false);
	const [talkRooms, setTalkRooms] = useState([]);
	const [listTalksType, setListTalksType] = useState("is_recruiting");
	const [showAlreadyInTheRoom, setShowAlreadyInTheRoom] = useState(false);
	const [showBlockedInTheRoom, setShowBlockedInTheRoom] = useState(false);
	const navigate = useNavigate();

	const [currentlyInRoom, setCurrentlyInRoom] = useRecoilState(CurrentlyInRoom)
	const [listRoomsInterval, setListRoomsInterval] = useRecoilState(ListRoomsInterval)
	const [open, setOpen] = useRecoilState(OpenAuth);
	const [authorized, setAuthorized] = useRecoilState(Authorized);
	const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);
	const [showTooYoung, setShowTooYoung] = useRecoilState(ShowTooYoung)
	const [userPersonal, setUserPersonal] = useRecoilState(UserPersonalAtom);
	const [birthDayModal, setBirthDayModal] = useRecoilState(BirthDayModal)
    const [talkRoomsList, setTalkRoomsList] = useRecoilState(TalkRoomsList);
    const [siteWS, setSiteWS]  = useRecoilState(SiteWS);
	const [wsOpened, setWSOpened] = useRecoilState(SiteWSOpened); 


	const handleOpenCreateRoom = () => {
		influxDBButtonPush(userInfo, "rooms", "create_room")
	    if (!authorized.Authorized) {
			setOpen({Open: true});
			return
		}
		if (!currentlyInRoom) {
			sendMsgSite(siteWS.ws, "Action", {})
    		setIsOpenCreateRoom(true);
		} else {
			setShowAlreadyInTheRoom(true)
		}
  	};
  	const handleCloseCreateRoom = () => {
   	 	setIsOpenCreateRoom(false);
 	};

	const handleOpenCasualInterlocutors = () => {
		influxDBButtonPush(userInfo, "rooms", "open_casual_interlocutors")
		if (!authorized.Authorized) {
			setOpen({Open: true});
			return
		}
		if (!currentlyInRoom) {
			sendMsgSite(siteWS.ws, "Action", {})
    		setIsOpenCasualInterlocutors(true);
		} else {
			setShowAlreadyInTheRoom(true)
		}
 	};
  	const handleCloseCasualInterlocutors = () => {
    	setIsOpenCasualInterlocutors(false);
  	};
	useEffect(() => {
		sendMsgSite(siteWS.ws, "GetTalkRooms", {RecruitingType: listTalksType})
	}, [siteWS, wsOpened])

	useEffect(() => {
		if (listRoomsInterval.interval != 0) {
			clearInterval(listRoomsInterval.interval)
		}
		let rooms = []
		let i = 0;
		for (let room of talkRoomsList.rooms) {
			i++;
			const joinTheRoom = ()=>{
				if (!authorized.Authorized) {
					setOpen({Open: true});
					return
				}
				influxDBButtonPush(userInfo, "rooms", "join_room")
				if (!showAlreadyInTheRoom) {
					sendMsgSite(siteWS.ws, "Action", {})
					TalkService.joinTalk(room.ID).then(()=>{
						navigate("/room")
					}).catch((err)=>{
						if (err.response.data.ErrorCode == 50) {
							if (userPersonal && !userPersonal.UserPersonal.Birthday.Valid) {
								setBirthDayModal({Open: true})
								return
							} else {
								lightLogout()
								setShowTooYoung({show: true})
								return
							}									
						}
						if (!showAlreadyInTheRoom && err.response.data.ErrorCode == 2) {
							setShowAlreadyInTheRoom(true)
						}
						if (err.response.data.ErrorCode == 6) {
							// blocked
							setShowBlockedInTheRoom(true)
						}						
					})
				} else {
					setShowAlreadyInTheRoom(true)
				}
			}
			const specMode = ()=>{
				if (!showAlreadyInTheRoom) {
					influxDBButtonPush(userInfo, "rooms", "join_spec_mode")
					sendMsgSite(siteWS.ws, "Action", {})
					RoomService.spectate("talkroom", room.ID).then((response)=>{
						if (response.data.Success == SuccessMessage) {
							navigate("/room")
						}
					}).catch((err)=>{
						if (!showAlreadyInTheRoom && err.response.data.ErrorCode == 2) {
							setShowAlreadyInTheRoom(true)
						}
						if (err.response.data.ErrorCode == 50) {
							if (userPersonal && !userPersonal.UserPersonal.Birthday.Valid) {
								setBirthDayModal({Open: true})
								return
							} else {
								lightLogout()
								setShowTooYoung({show: true})
								return
							}									
						}						
					});
				}
			}
			rooms.push(<Room
				key={i}
				id={room.ID}
				isPremium={userInfo.UserInfo && userInfo.UserInfo.AccountType != "standart"}
				roomSize={room.MaxNumberOfParticipants}
				users={room.ActiveNumberOfParticipants}
				title={room.Name}
				ageLimit={room.EighteenPlus ? "18+": null}
				tags={room.Tags}
				onClick={joinTheRoom}
				onClickSpectator={specMode}
				listTalksType={listTalksType}
			/>)
		}
		setTalkRooms(rooms)
		let updateTalks = setInterval(() => {
			sendMsgSite(siteWS.ws, "GetTalkRooms", {RecruitingType: listTalksType})
		}, 3000)
		setListRoomsInterval({interval: updateTalks})
	}, [userInfo, listTalksType, userPersonal, talkRoomsList, siteWS]);

	const isRecruitingOn = ()=>{
		influxDBButtonPush(userInfo, "rooms", "list_is_recruiting")
		if (listTalksType == "already_recruited") {
			sendMsgSite(siteWS.ws, "GetTalkRooms", {RecruitingType: "is_recruiting"})
		}
		setListTalksType("is_recruiting");
	}
	const alreadyRecruitedOn = ()=>{
		influxDBButtonPush(userInfo, "rooms", "list_already_recruited")
		if (listTalksType == "is_recruiting") {
			sendMsgSite(siteWS.ws, "GetTalkRooms", {RecruitingType: "already_recruited"})
		}
		setListTalksType("already_recruited");
	}

	return (
		<Box
			sx={{
				minHeight: '100vh',
				bgcolor: 'background.default',
				color: 'text.primary',
			}}
		>

			<Container maxWidth="lg">
				<Stack spacing={4} paddingY={3}>
					<Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
						<Button variant="contained" color="success" onClick={handleOpenCreateRoom}>
							<FormattedMessage id="create_room" />
						</Button>

						{false && <Button variant="contained" onClick={handleOpenCasualInterlocutors}>
							<FormattedMessage id="casual_interlocutors" />
						</Button>}
					</Stack>

					{false && <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
						<RoomsFilter msgid="is_recruiting" onClick={isRecruitingOn} choosed={listTalksType == "is_recruiting"}/>
						{userInfo.UserInfo && userInfo.UserInfo.AccountType != "standart" ?
							<RoomsFilter msgid="already_recruited" onClick={alreadyRecruitedOn} choosed={listTalksType == "already_recruited"}/> : null}
					</Stack>}
					
					{false && <Stack spacing={2}>
						{talkRooms}
					</Stack>}
				</Stack>
			</Container>


		<Modal
			open={isOpenCreateRoom}
			onClose={handleCloseCreateRoom}
			aria-labelledby="parent-modal-title"
			aria-describedby="parent-modal-description"
		>
        <Box sx={{ ...ModalStyle, width: 370, }}>
			<ModalClose onClose={handleCloseCreateRoom}/>
          	<CreateRoom />
        </Box>
      </Modal>

		<Modal
			open={isOpenCasualInterlocutors}
			onClose={handleCloseCasualInterlocutors}
			aria-labelledby="parent-modal-title"
			aria-describedby="parent-modal-description"
		>
        <Box sx={{ ...ModalStyle, width: 370, }}>
			<ModalClose onClose={handleCloseCasualInterlocutors}/>
          	<CasualInterlocutors />
        </Box>
      </Modal>

	  		<Snackbar open={showAlreadyInTheRoom} 
				onClose={()=>{setShowAlreadyInTheRoom(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
				  key={"showAlreadyInTheRoom"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"showAlreadyInTheRoomAlert"}>
				  <FormattedMessage id="YouAlreadyInTheRoom" />
  				</Alert>
			</Snackbar>	

			<Snackbar open={showBlockedInTheRoom} 
				onClose={()=>{setShowBlockedInTheRoom(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
				  key={"showBlockedInTheRoom"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"showBlockedInTheRoomAlert"}>
				  <FormattedMessage id="you_are_blocked_in_room" />
  				</Alert>
			</Snackbar>			
		</Box>
	)
};

export default Rooms;
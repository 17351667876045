






export const Walls = {
    "walls":`
### Выгляди интересно

Чтобы посмотреть и назначить стенки перейдите в профиль и выбере таб СТЕНКИ

![Жалоба](/knowledge_base/screen_walls.png)

Чтобы установить стенки, нужно удовлетворять условиям по уровню, а иногда нужно обладать подпиской
`
}
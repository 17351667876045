import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import {FormattedMessage} from "react-intl";
import {useRecoilState,} from 'recoil';
import {SelectedPage} from "./atoms"


const data = [
  {
    id: 'about',
    name: <FormattedMessage id="about_project" />,
  },
  {
    id: 'games',
    name: <FormattedMessage id="games" />,
    children: [
      {
        id: 'games_hackword',
        name: <FormattedMessage id="hackword" />,
      },
      {
        id: 'games_iamalive',
        name: <FormattedMessage id="iamalive" />,
      },
      {
        id: 'games_generalize',
        name: <FormattedMessage id="generalize" />,
      },      
    ],
  },
  {
    id: 'talks',
    name: <FormattedMessage id="talks" />,
  },
  {
    id: 'communities',
    name: <FormattedMessage id="communities" />,
  },
  {
    id: 'ratings',
    name: <FormattedMessage id="ratings" />,
  },
  {
    id: 'walls',
    name: <FormattedMessage id="borders" />,
  },   
  {
    id: 'subscription',
    name: <FormattedMessage id="subscription" />,
  },
  {
    id: 'rules',
    name: <FormattedMessage id="rules" />,
  },   
];


const ContentTree = (props) => {
    const [selectedPage, setSelectedPage] = useRecoilState(SelectedPage)

    useEffect(()=>{
      if (props.currentPage) {
        setSelectedPage(props.currentPage)
      }
    }, [])

    const renderTree = (nodes) => (
        <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}
            style={{align: "left", textAlign: "start"}}         
        >
          {Array.isArray(nodes.children)
            ? nodes.children.map((node) => renderTree(node))
            : null}
        </TreeItem>
      );

      const handleSelect = (event, nodeId)=>{
        setSelectedPage(nodeId)
      }
    return (
        <Box sx={{ minHeight: 180, flexGrow: 1, maxWidth: 300 }}>
            <TreeView
                aria-label="rich object"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                defaultExpanded={["games"]}
                defaultSelected={props.currentPage}
                onNodeSelect={handleSelect}
            >
                {data.map((node)=>renderTree(node))}
            </TreeView>
        </Box>
    )
}

export default ContentTree;
import { Button, Container, Grid, Modal, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import Navbar from '../Navbar/Navbar';
import {FormattedMessage} from "react-intl";
import Auth from '../user/Auth/Auth';
import ModalStyle from '../../utils/ModalStyle/ModalStyle';
import Features from './Features/Features';
import {useRecoilState,} from 'recoil';
import {UserInfoAtom,} from "../atoms/UserAtoms"
import {influxDBButtonPush} from "../../services/influxdb/navigation"
import {useNavigate} from "react-router-dom"

const Home = () => {
	const navigate = useNavigate();

	const [open, setOpen] = React.useState(false);

	const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);
  
	const handleOpen = () => {
    	setOpen(true);
  	};

 	const handleClose = () => {
	    setOpen(false);
  	};

  	const handlePlay = () => {
		influxDBButtonPush(userInfo, "home", "play")
		navigate("/games")
  	}
  	const handleMeet = () => {
		influxDBButtonPush(userInfo, "home", "meet")
		navigate("/rooms")
  	}

	return (
		<Box className="home-page">
			<Navbar home openModal={handleOpen} />

			<Container maxWidth="xl" className="home-page__body">
				<Grid container xs={12} paddingY={3} paddingX={{lg: 2, xl: 0}} marginTop={{xs: 6, md: 6, xl: 8}} alignItems={{md: 'center', lg: 'flex-end'}}>
					<Grid item xs={12} md={7} marginBottom={{md: 4, lg: 7}}>
						<Stack spacing={3}>
							<Typography variant='h1'>
								<FormattedMessage id="home_title" />
							</Typography>

							<Typography variant='body1' style={{maxWidth: '680px'}}>
								<FormattedMessage id="home_description" />
							</Typography>

							<Stack direction="row" spacing={4} paddingTop={1}>
								<Button variant="contained" size="large" onClick={handlePlay}>
									<FormattedMessage id="play" />
								</Button>

								<Button variant="outlined" color="inherit" size="large" onClick={handleMeet}>
									<FormattedMessage id="talking" />
								</Button>
							</Stack>
						</Stack>
					</Grid>

					<Grid 
						item 
						container 
						xs={12} 
						md={5}  
						justifyContent={{xs: 'center', md: 'flex-end'}}
						marginTop={{xs: 3, md: 0}}
					>
						<Grid item>
							<Features userInfo={userInfo}/>
						</Grid>
					</Grid>
				</Grid>
			</Container>

			<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...ModalStyle, width: 400, }}>
          <Auth />
        </Box>
      </Modal>
		</Box>
	)
};

export default Home;

import '../../games/GameItem/game-item.scss';
import {
	useRecoilState,
  } from 'recoil';
import {  Grid,  } from '@mui/material';
import {Theme, RoomInfo, ShowChangeRoomType} from "../../../livekit_prod/atoms"
import {sendMsg} from "../../../livekit_prod/WSController"
import TokenService from "../../../services/TokenService";
import {useParams} from "react-router-dom";

const roomTypes = [
    {
        name: "hackword",
        img: "hackword.jpg",
    },
    {
        name: "iamalive",
        img: "iamalive.jpg",
    },
    {
        name: "generalize",
        img: "generalize.jpg",
    },        
]

const ChooseTypeModal = (props)=>{
    const [themeName, __] = useRecoilState(Theme);
    const [roomInfo, setRoomInfo] = useRecoilState(RoomInfo);
    const [showChangeRoomType, setShowChangeRoomType] = useRecoilState(ShowChangeRoomType)

    let { room_key } = useParams();


    let gamesPresentationalsFolder = "/themes/" + themeName.name + "/imgs/games/presentationals/"

    let banners = []
    for (let rType of roomTypes) {
        if (rType.name != roomInfo.type) {
            banners.push(<TypeBanner roomKey={room_key} onClose={()=>{setShowChangeRoomType(false)}} name={rType.name} img={gamesPresentationalsFolder+rType.img}/>)
        }
    }
    return (
        <Grid
            container
            spacing={2}
            columns={{ xs: 4, sm: 8, md: 12 }}
        >
            {banners.map((item, index) => (
                	<Grid item xs={4} sm={4} md={4} key={index}>
                        {item}
                    </Grid>
                ))}
        </Grid>
    )
}

export default ChooseTypeModal;


const TypeBanner = (props)=>{
    let style = {
		backgroundImage: `url(${props.img})`,
		minHeight: "150px",
	}
    const chooseType = ()=>{
        sendMsg("ChangeType", {'NewType': props.name, 'SessionID': TokenService.getLocalAccessToken(), "RoomKey": props.roomKey})
        props.onClose()
    }
    return (
        <div 
            className="game-item" 
            onClick={chooseType}
            style={style}
        >
        </div>
    )
}
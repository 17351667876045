import {
    atom,
} from 'recoil';

export const GeneralizeGameState = atom({
    key: 'GeneralizeGameState',
    default: {
        IsAdmin: false,
        Phase: "picking",
        FinishGameState: 0,
        MadeWordAndNumber: false,
        AllowNewPlayers: true,
    },
})

export const GeneralizeTeamsState = atom({
    key: 'GeneralizeTeamsState',
    default: {
        Teams: [],
    },
})

export const MasterGameMap = atom({
    key: 'GeneralizeMasterGameMap',
    default: {
        Cells: []
    }
})

export const VisibleGameMap = atom({
    key: 'GeneralizeVisibleGameMap',
    default: {
        Cells: []
    }
})

export const VotesState = atom({
    key: 'GeneralizeVotesState',
    default: {
        Votes: []
    }
})

export const ProcessTime = atom({
    key: 'GeneralizeProcessTime',
    default: 0
})

export const MyTeamAndRole = atom({
    key: 'GeneralizeMyTeamAndRole',
    default: {
        TeamID: 0,
        Master: false,
    }
})

// need it to show master cameras during picking/ended
export const EndedUpdate = atom({
    key: 'GeneralizeEndedUpdate',
    default: false
})

export const GameFinishedState = atom({
    key: 'GeneralizeGameFinishedState',
    default: {
        FinishGameState: 0,
    },
})

export const FinishChangeExpPointsInfo = atom({
    key: 'GeneralizeFinishChangeExpPointsInfo',
    default: {
        Level: 0,       
        CurrentExp: 0,
        NearestEdge: 300,
        PointsToAdd: 0,
        NextEdge: 500,
    }
})
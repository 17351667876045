import {
	useRecoilState,
  } from 'recoil';
import { Button, Container, Grid, Modal, Stack, Typography, Snackbar, Alert, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React, {useEffect, useState} from 'react';
import Navbar from '../Navbar/Navbar';
import Auth from '../user/Auth/Auth';
import {FormattedMessage} from "react-intl";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import GameItem from './GameItem/GameItem';
import ActiveGameItem from "./hackword/ActiveHackwordGame/ActiveHackwordGame";
import ModalStyle from '../../utils/ModalStyle/ModalStyle';
import ModalClose from '../../utils/ModalStyle/ModalClose';
import CreateGame from "./CreateGame";
import { ListLobbiesInterval, CurrentGameName, ListGameLobbies } from '../atoms/GamesAtoms';
import {SuccessMessage} from "../../constants";
import RoomService from '../../services/rooms/RoomService'
import {useNavigate} from "react-router-dom"
import {Authorized, OpenAuth} from "../atoms/AuthAtoms"
import {UserInfoAtom,} from "../atoms/UserAtoms"
import {Theme} from "../../livekit_prod/atoms";
import RoomsFilter from "../common/RoomsFilter/RoomsFilter";
import GamesService from "../../services/games/GamesService";
import {UserPersonalAtom} from "../atoms/UserAtoms";
import { BirthDayModal, ShowTooYoung, SetGamePasswordModal, } from '../atoms/Common';
import SetGamePassword from "./SetGamePassword";
import { CurrentlyInRoom, } from '../atoms/Rooms';
import {influxDBButtonPush} from "../../services/influxdb/games"
import {SiteWS, SiteWSOpened} from "../atoms/SiteAtoms"
import {ChatOnline} from "../common/FlatChat/atoms/Chat";
import {sendMsgSite} from "../SiteController"
import {HackwordWS} from "./hackword/InHackwordGame/atoms/Connection"
import {IamaliveWS} from "./iamalive/InIamaliveGame/atoms/Connection"
import {GeneralizeWS} from "./generalize/InGeneralizeGame/atoms/Connection"
import RulesButton from "./RulesButton"
import {lightLogout} from "../../utils/common"

const Games = () => {
	const [open, setOpen] = React.useState(false);
	const [openNewGame, setOpenNewGame] = React.useState(false);
	const [showAlreadyInTheLobby, setShowAlreadyInTheLobby] = useState(false);
	const [showBlockedInTheRoom, setShowBlockedInTheRoom] = useState(false);
	const [hackwordLobbies, setHackwordLobbies] = useState([]);
	const [listLobbiesType, setListLobbiesType] = useState("is_recruiting");

	const navigate = useNavigate();

	const [listLobbiesInterval, setListLobbiesInterval] = useRecoilState(ListLobbiesInterval)
	const [openAuth, setOpenAuth] = useRecoilState(OpenAuth);
	const [authorized, setAuthorized] = useRecoilState(Authorized);
	const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);
	const [themeName, __] = useRecoilState(Theme);
	const [showTooYoung, setShowTooYoung] = useRecoilState(ShowTooYoung)
	const [userPersonal, setUserPersonal] = useRecoilState(UserPersonalAtom);
	const [birthDayModal, setBirthDayModal] = useRecoilState(BirthDayModal)
    const [setGamePasswordModal, setSetGamePasswordModal] = useRecoilState(SetGamePasswordModal)
	const [currentGameName, setCurrentGameName] = useRecoilState(CurrentGameName);
	const [currentlyInRoom, setCurrentlyInRoom] = useRecoilState(CurrentlyInRoom)
    const [listGameLobbies, setListGameLobbies] = useRecoilState(ListGameLobbies);
    const [siteWS, setSiteWS]  = useRecoilState(SiteWS);
	const [wsOpened, setWSOpened] = useRecoilState(SiteWSOpened); 
    const [hackwordWS, setHackwordWS]  = useRecoilState(HackwordWS);
    const [iamaliveWS, setIamaliveWS]  = useRecoilState(IamaliveWS);
	const [generalizeWS, setGeneralizeWS]  = useRecoilState(GeneralizeWS);
    const [chatOnline, setChatOnline] = useRecoilState(ChatOnline);

	const handleOpen = () => {
		if (!authorized.Authorized) {
			setOpenAuth({Open: true});
			return
		}
    	setOpen(true);
  	};

  	const handleClose = () => {
   	 	setOpen(false);
  	};

	const handleOpenNewGame = () => {
		influxDBButtonPush(userInfo, "games", currentGameName.gameName, "create_game")
		if (!authorized.Authorized) {
			setOpenAuth({Open: true});
			return
		}
		if (currentlyInRoom) {
			setShowAlreadyInTheLobby(true)
			return
		}
		sendMsgSite(siteWS.ws, "Action", {})
    	setOpenNewGame(true);
  	};

  	const handleCloseNewGame = () => {
    	setOpenNewGame(false);
  	};

	const justPlay = ()=>{
		influxDBButtonPush(userInfo, "games", currentGameName.gameName, "just_play")
		if (!authorized.Authorized) {
			setOpenAuth({Open: true});
			return
		}
		if (currentlyInRoom) {
			setShowAlreadyInTheLobby(true)
			return
		}
		sendMsgSite(siteWS.ws, "Action", {})
		GamesService.playLobby(currentGameName.gameName).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				navigate("/room")
			}
		}).catch((err)=>{
			if (err.response.data.ErrorCode == 50) {
				if (userPersonal && !userPersonal.UserPersonal.Birthday.Valid) {
					setBirthDayModal({Open: true})
					return
				} else {
					lightLogout()
					setShowTooYoung({show: true})
					return
				}									
			}
		})
	}

  useEffect(() => {
	sendMsgSite(siteWS.ws, "GetGameLobbies", {GameName: currentGameName.gameName, RecruitingType: listLobbiesType})
  },[siteWS, currentGameName, wsOpened])

  useEffect(() => {
		// will need to have this logic separately and outside for all diff games
		if (listLobbiesInterval.interval != 0) {
			clearInterval(listLobbiesInterval.interval)
		}
		const reconsileConnections = ()=>{
			// if (hackwordWS.ws != null) {
			// 	hackwordWS.ws.close()
			// 	setHackwordWS({ws: null})
			// }
			// if (iamaliveWS.ws != null) {
			// 	iamaliveWS.ws.close()
			// 	setIamaliveWS({ws: null})
			// }
			// if (generalizeWS.ws != null) {
			// 	generalizeWS.ws.close()
			// 	setGeneralizeWS({ws: null})
			// }
		}
		let lobbies = []
		let i = 0;
		for (let game of listGameLobbies.lobbies) {
			i++;
			const joinTheLobby = ()=>{
				influxDBButtonPush(userInfo, "games", currentGameName.gameName, "join_lobby")
				if (!authorized.Authorized) {
					setOpenAuth({Open: true});
					return
				}
				if (!showAlreadyInTheLobby) {
					sendMsgSite(siteWS.ws, "Action", {})
					if (game.Closed) {
						setSetGamePasswordModal({Open: true, EntityID: game.ID, GameName: currentGameName.gameName,})
					} else {
						GamesService.joinLobby(currentGameName.gameName, game.ID, "").then(()=>{
							// reconsileConnections()
							navigate("/room")
						}).catch((err)=>{
							if (err.response.data.ErrorCode == 50) {
								if (userPersonal && !userPersonal.UserPersonal.Birthday.Valid) {
									setBirthDayModal({Open: true})
									return
								} else {
									lightLogout()
									setShowTooYoung({show: true})
									return
								}									
							}
							if (!showAlreadyInTheLobby && err.response.data.ErrorCode == 2) {
								setShowAlreadyInTheLobby(true)
							}
							if (err.response.data.ErrorCode == 6) {
								// blocked
								setShowBlockedInTheRoom(true)
							}
						})
					}
				} else {
					setShowAlreadyInTheLobby(true)
				}
			}
			const specMode = ()=>{
				influxDBButtonPush(userInfo, "games", currentGameName.gameName, "join_as_spectator")
				if (!showAlreadyInTheLobby) {
					sendMsgSite(siteWS.ws, "Action", {})
					RoomService.spectate(currentGameName.gameName, game.ID).then((response)=>{
						if (response.data.Success == SuccessMessage) {
							// reconsileConnections()
							navigate("/room")
						}
					}).catch((err)=>{
						if (!showAlreadyInTheLobby && err.response.data.ErrorCode == 2) {
							setShowAlreadyInTheLobby(true)
						}
						if (err.response.data.ErrorCode == 50) {
							if (userPersonal && !userPersonal.UserPersonal.Birthday.Valid) {
								setBirthDayModal({Open: true})
								return
							} else {
								lightLogout()
								setShowTooYoung({show: true})
								return
							}									
						}						
					});
				}
			}
			lobbies.push(<ActiveGameItem 
				key={i}
				isPremium={userInfo.UserInfo && userInfo.UserInfo.AccountType != "standart"}
				title={game.Name}
				closed={game.Closed}
				ageLimit={game.EighteenPlus ? "18+": null}
				roomSize={game.MaxNumOfPLayers}
				users={game.CurrrentNumOfPlayers}
				onClick={joinTheLobby}
				onClickSpectator={specMode}
				lobbyType={listLobbiesType}
			/>)
		}
		setHackwordLobbies(lobbies)

		let updateLobbies = setInterval(() => {
			sendMsgSite(siteWS.ws, "GetGameLobbies", {GameName: currentGameName.gameName, RecruitingType: listLobbiesType})
		}, 3000)
		setListLobbiesInterval({interval: updateLobbies})
  	}, [userInfo, currentGameName, listLobbiesType, userPersonal, showAlreadyInTheLobby, listGameLobbies])

	const isRecruitingOn = ()=>{
		influxDBButtonPush(userInfo, "games", currentGameName.gameName, "is_recruiting")
		if (listLobbiesType == "already_recruited") {
			sendMsgSite(siteWS.ws, "GetGameLobbies", {GameName: currentGameName.gameName, RecruitingType: "is_recruiting"})
		}
		setListLobbiesType("is_recruiting");
	}
	const alreadyRecruitedOn = ()=>{
		influxDBButtonPush(userInfo, "games", currentGameName.gameName, "already_recruited")
		if (listLobbiesType == "is_recruiting") {
			sendMsgSite(siteWS.ws, "GetGameLobbies", {GameName: currentGameName.gameName, RecruitingType: "already_recruited"})
		}
		setListLobbiesType("already_recruited");
	}
	const handleSetGamePasswordClose = ()=>{
		setSetGamePasswordModal({Open: false, EntityID: 0, GameName: "",})
	}

	let gamesPresentationalsFolder = "/themes/" + themeName.name + "/imgs/games/presentationals/"
	return (
		<Box
			sx={{
				minHeight: '100vh',
				bgcolor: 'background.default',
				color: 'text.primary',
			}}
		>
			<Container maxWidth="lg">
				<Stack spacing={4} 	paddingY={3}>
					<Grid
							container
							spacing={4}
							columns={{ xs: 4, sm: 12, md: 12}}
							justifyContent="space-between"
							alignItems="center"
						>
							<Grid item xs={4} sm={8}>
								<Stack spacing={2}>
									<Typography variant='h4'>
										<FormattedMessage id="сhoose_game_and_create_room" />
									</Typography>

									<Typography variant='body1'>
										<FormattedMessage id="can_change_game_in_room" />
									</Typography>
								</Stack>							
							</Grid>
						</Grid>
				
					<Grid
						container
						spacing={2}
						columns={{ xs: 4, sm: 8, md: 12 }}
					>
						<Grid item xs={4} sm={4} md={3}>
							<GameItem
								gameName={"hackword"}
								img={gamesPresentationalsFolder+"hackword.jpg"}
								description={<FormattedMessage id="hackword_rules" />}
								userInfo={userInfo}
							/>
						</Grid>						

						<Grid item xs={4} sm={4} md={3}>
							<GameItem
								gameName={"iamalive"}
								img={gamesPresentationalsFolder+"iamalive.jpg"}
								description={<FormattedMessage id="iamalive_rules" />}
								userInfo={userInfo}
							/>
						</Grid>

						<Grid item xs={4} sm={4} md={3}>
							<GameItem
								gameName={"generalize"}
								img={gamesPresentationalsFolder+"generalize.jpg"}
								description={<FormattedMessage id="generalize_rules" />}
								userInfo={userInfo}
							/>
						</Grid>						
					</Grid>

					{false && <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
						<Typography variant='h4'>
							<FormattedMessage id={"lobby_"+currentGameName.gameName}/>
						</Typography>

						<Stack direction="row" spacing={2}>
							<Button variant="contained" startIcon={<AddCircleOutlineIcon />} onClick={handleOpenNewGame}>
								<FormattedMessage id="create_your_own" />
							</Button>

							<Button variant="contained" startIcon={<PlayCircleOutlineIcon />} onClick={justPlay}>
								<FormattedMessage id="play" />
							</Button>


							<span className="mobile-hidden">
								<RulesButton gameName={currentGameName.gameName} />
							</span>
						</Stack>						
					</Stack>}

					{false && <Grid container spacing={2}>
						<Grid item sm={7} xs={12}>
							<Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
								<RoomsFilter msgid="is_recruiting" onClick={isRecruitingOn} choosed={listLobbiesType == "is_recruiting"}/>
								{userInfo.UserInfo && userInfo.UserInfo.AccountType != "standart" ?
									<RoomsFilter msgid="already_recruited" onClick={alreadyRecruitedOn} choosed={listLobbiesType == "already_recruited"}/> : null}
							</Stack>

							<Stack spacing={2} style={{marginTop:"15px"}}>
								{hackwordLobbies}
							</Stack>
						</Grid>					
					</Grid>}

				</Stack>
			</Container>

		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="parent-modal-title"
			aria-describedby="parent-modal-description"
		>
			<Box sx={{ ...ModalStyle, width: 370 }}>				
			<Auth />
			</Box>
		</Modal>

		<Modal
			open={openNewGame}
			onClose={handleCloseNewGame}
			aria-labelledby="parent-modal-title"
			aria-describedby="parent-modal-description"
		>
			<Box sx={{ ...ModalStyle, width: 370, }}>
				<ModalClose onClose={handleCloseNewGame}/>
				<CreateGame gameName={currentGameName.gameName}/>
			</Box>
		</Modal>

			<Modal
				open={setGamePasswordModal.Open}
				onClose={handleSetGamePasswordClose}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box sx={{ ...ModalStyle, width: 370 }}>
					<ModalClose onClose={handleSetGamePasswordClose}/>
					<SetGamePassword/>
				</Box>
			</Modal>

			<Snackbar open={showAlreadyInTheLobby} 
				onClose={()=>{setShowAlreadyInTheLobby(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
				  key={"showAlreadyInTheLobby"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"showAlreadyInTheLobbyAlert"}>
				  <FormattedMessage id="YouAlreadyInTheRoom" />
  				</Alert>
			</Snackbar>

			<Snackbar open={showBlockedInTheRoom} 
				onClose={()=>{setShowBlockedInTheRoom(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
				  key={"showBlockedInTheRoom"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"showBlockedInTheRoomAlert"}>
				  <FormattedMessage id="you_are_blocked_in_room" />
  				</Alert>
			</Snackbar>			
		</Box>
	)
};

export default Games;








export const Subscription = {
    "subscription":`
### Портал Много Комнат в какой-то степени принадлежит своим пользователям

Как люди хотят сохранить уют в своей комнате, где они живут, также возникает желание сохранять уют и порядок в виртуальной комнате, где они проводят время.

И сделать эту виртуальную комнату более функциональной, более удобной, чтобы она приносила больше радости.

Покупая подписку вы помогает себе, так как полученные деньги идут на развитие нашего общего портала.

Об остальных преимуществах подписки смотрите тут: [другие преимущества подписки](https://mnogokomnat.ru/subscription)
    `
}
import {PositionsToMute,} from "../../atoms";
import {useRecoilState,} from 'recoil';
import React, { useState, useEffect, useRef } from 'react';






function OthersAudioMediaBox(props) {
    const [positionsToMute, setPositionsToMute]  = useRecoilState(PositionsToMute);

    let audioClass = "OtherMic"+props.position
    useEffect(() => {
        if (props.audioTrack) {
            const audioElementsT = document.getElementsByClassName(audioClass)
            for (let audioElement of audioElementsT) {
                audioElement.onplay = ()=>{
                    if (positionsToMute.has(props.position)) {
                        audioElement.pause()
                    } else {
                        audioElement.play()
                    }
                };
                props.audioTrack.attach(audioElement)
                if (audioElement && positionsToMute.has(props.position)) {
                    audioElement.pause()
                } else if (audioElement) {
                    try {
                        audioElement.play()
                    } catch (e) {
                        
                    }
                }            
            }
        }
    }, [props.audioTrack, positionsToMute,]);

    return (
        <audio class={audioClass}  preload="true" autoPlay/>
    )

}


export default OthersAudioMediaBox;


import moment from 'moment'
import {FormattedMessage} from "react-intl";


export const removeTimezoneOffsetMomentJS = function (date) {
    if (!date) {
      return date
    }
    date = date.utc().toDate()
	var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() - userTimezoneOffset);

    return moment.utc(date)
  }

export const convertLocalToUTCDateMomentJS = function (date) {
    if (!date) {
      return date
    }
    date = date.utc().toDate()


    return moment.utc(date)
  }

export const underAgeValidate = function (birthday){
	//set date based on birthday at 01:00:00 hours GMT+0100 (CET)
	var myBirthday = new Date(birthday);

	var currentDate = new Date().toJSON().slice(0,10)+' 00:00:00';

	// calculate age comparing current date and birthday
	var myAge = ~~((Date.now(currentDate) - myBirthday) / (31557600000));

	if (myAge < 18) {
     	return false;
    } else {
	    return true;
	} 
}

export const ruTypeTime = function (datetime){
	var date = new Date(datetime);

	return twoDigits(date.getDate())+"."+twoDigits(date.getMonth()+1)+"."+twoDigits(date.getFullYear())+" "+twoDigits(date.getHours())+":"+twoDigits(date.getMinutes());
}

export const ruOnlyTime = function (datetime){
	var date = new Date(datetime);
	return twoDigits(date.getHours())+":"+twoDigits(date.getMinutes());
}

export const daysOrTime = function (datetime){
	let date = new Date(datetime);
	let currentDate = new Date().toJSON().slice(0,10)+' 00:00:00';
	
	let daysTill = ~~((date - Date.now(currentDate)) / (86400000));
	
	if (daysTill === 0) {
	  let hoursTill = ~~((date - Date.now(currentDate)) / (3600000));
	  if (hoursTill === 0) {
		let minutesTill = ~~((date - Date.now(currentDate)) / (60000));
		 return <span>{minutesTill+1} <FormattedMessage id="minutes"/></span>
	  }
	  return <span>{hoursTill+1} <FormattedMessage id="hours"/></span>
	}
	return <span>{daysTill+1} <FormattedMessage id="days"/></span>
}

export const daysTill = function (datetime){
	let date = new Date(datetime);
	let currentDate = new Date().toJSON().slice(0,10)+' 00:00:00';
	
	let daysTill = ~~((date - Date.now(currentDate)) / (86400000));
	
	return daysTill
}

const twoDigits = function(d) {
	if (d < 10) {
		return "0"+d;
	} else {
		return d;
	}
}
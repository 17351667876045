import { Container, Divider, Grid, List, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ReactMarkdown from 'react-markdown';
import {useRecoilState,} from 'recoil';
import {SelectedPage} from "./atoms"
import React, { useState, useEffect } from 'react';
import remarkGfm from 'remark-gfm'
import {Games} from "./content/ru/Games"
import {About} from "./content/ru/About"
import {Talks} from "./content/ru/Talks"
import {Communities} from "./content/ru/Communities"
import {Ratings} from "./content/ru/Ratings"
import {Subscription} from "./content/ru/Subscription"
import {Rules} from "./content/ru/Rules"
import {Walls} from "./content/ru/Walls"




const ContentPage = (props)=>{
    const [selectedPage, setSelectedPage] = useRecoilState(SelectedPage)

    const [allPages, setAllPages] = useState({})
    const [currentPage, setCurrentPage] = useState("")

    useEffect(()=>{
        let allContentPages = {...About, ...Games, ...Talks, ...Communities, ...Ratings, ...Subscription, ...Walls, ...Rules}
        setAllPages(allContentPages)
    },[])

    useEffect(()=>{
        if (allPages[selectedPage]) {
            setCurrentPage(allPages[selectedPage])
        }
    }, [selectedPage, allPages])

    return (
        <Container maxWidth="md">
            <Stack spacing={2} paddingY={4}>
                    <ReactMarkdown 	className="markdown"
                    remarkPlugins={[remarkGfm]}
						children={currentPage}
					/>
            </Stack>
		</Container>            
    )
}

export default ContentPage

import CloseIcon from '@mui/icons-material/Close';






const ModalClose = (props)=>{
    let mobile = false
    if (window.matchMedia("(max-width: 899px)").matches) {
        mobile = true
    }
    return (
        <div style={{position: "absolute", top: "7px", right: "7px"}} onClick={props.onClose}>{mobile && <CloseIcon />}</div>
    )
}

export default ModalClose
import { Box } from '@mui/system';
import {useRecoilState,} from 'recoil';
import {FormattedMessage} from "react-intl";
import { Button, Stack, Modal, Avatar, Typography, Chip} from '@mui/material';
import BlockedChats from "./BlockedChats"
import React, { useState, useEffect } from 'react';
import ModalStyle, { ModalStyleXL } from '../../../../utils/ModalStyle/ModalStyle';
import ModalClose from '../../../../utils/ModalStyle/ModalClose';
import UserService from "../../../../services/UserService"
import {SuccessMessage} from "../../../../constants";
import {domainNameAPI, protocolAPI, apiPort} from "../../../../environment"
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import {stringAvatar} from '../../../../utils/common';
import {ruOnlyTime} from "../../../../utils/DateUtils";
import {showChat} from "../../../common/SmallChat/scutils"
import {OpenedPersonalChats, UserInfoAtom} from "../../../atoms/UserAtoms"




const Chats = (props)=>{
    const [showBlockedUsers, setShowBlockedUsers] = useState(false)
    const [userChatsList, setUserChatsList] = useState([])
    const [listLoaded, setListLoaded] = useState(false)

    const [openedPersonalChats, setOpenedPersonalChats] = useRecoilState(OpenedPersonalChats);
    const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);

	const navigate = useNavigate();

    const openBlocked = ()=>{
        setShowBlockedUsers(true)
    }
    const handleShowBlockedUsersClose = ()=>{
        setShowBlockedUsers(false)
    }
    const goToCard = (name)=>{
		navigate('/user/'+name)
    }

    useEffect(()=>{
        UserService.getUserChats().then((response)=>{
            if (response.data.Success == SuccessMessage) {
                setListLoaded(true)                
                setUserChatsList(response.data.UserChats.UserChats)
            }
        })
    }, [])

    let apiUrl = protocolAPI+domainNameAPI+apiPort
    const handleOpen = (item)=>{
        showChat(item.UserID, item.Nickname, item.Icon, true, openedPersonalChats, setOpenedPersonalChats)
    }
    return (
        <Box
            sx={{
                minHeight: '100vh',
                bgcolor: 'background.default',
                color: 'text.primary',
                p: 4,
            }}
        >
            {!listLoaded && <div style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)"}}><CircularProgress /></div>}
            <Stack direction="row" alignItems="flex-start" justifyContent="flex-start" spacing={2} style={{marginBottom: "15px"}}>
            	<Button variant="contained" onClick={openBlocked}>
					<FormattedMessage id="blocked" />
				</Button>
            </Stack>


            {userChatsList.map((item, index) => (
                <Stack 
                    className="user-item"
                    direction="row" 
                    alignItems="center"
                    justifyContent="space-between"			
                    padding={1}
                    spacing={2}
                    onClick={()=>handleOpen(item)}
                >
                    <Stack 
                        spacing={2} 
                        direction="row" 
                        alignItems="center"
                        maxWidth="65%"
                    >
                        {item.Icon ? (
                            <Avatar alt={item.Nickname} src={apiUrl+"/mkstatic/shared/avatars/"+item.Icon+"?dopasdasda"} onClick={()=>goToCard(item.Nickname)}
                            />
                        ) 
                        : 
                        (
                            <Avatar {...stringAvatar(item.Nickname)} onClick={()=>goToCard(item.Nickname)}/>
                        )}				

                        <Stack direction="column" alignItems="start" style={{width: "100%"}}>
                            <Typography variant="body2" noWrap onClick={()=>goToCard(item.Nickname)}>
                                <b style={{color: "black",}}>{item.Nickname}</b>
                            </Typography>
                            <span style={{position: "relative", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%", overflow: "hidden"}}>{ruOnlyTime(item.Datetime)}&nbsp;{userInfo.UserInfo && userInfo.UserInfo.UserID == item.UserIDTexted ? userInfo.UserInfo.Nickname : item.Nickname}:&nbsp; {item.Message}</span>
                        </Stack>
                    </Stack>

                    {false && <Stack 
                        spacing={0} 
                        direction="row" 
                        alignItems="center"
                    >                        
                        <Chip label={<FormattedMessage id="open" />} color="success" variant="outlined" onClick={()=>handleOpen(item)}></Chip> 				
                    </Stack>}
                </Stack>
				))}



            <Modal
                open={showBlockedUsers}
                onClose={handleShowBlockedUsersClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...ModalStyleXL, width: 400, }}>
                    <ModalClose onClose={handleShowBlockedUsersClose}/>
                    <BlockedChats></BlockedChats>
                </Box>
            </Modal>            
        </Box>
    )
}

export default Chats




import { Grid, Typography } from '@mui/material';
import {FormattedMessage} from "react-intl";


const InfoNote = (props) => {
    return (
        <span>
                <Grid
                    container
                    rowSpacing={2}
                    spacing={{ xs: 1, sm: 2 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                    direction="column"
                    padding={1}
                >
                    <Grid item xs={4} sm={8} md={12}>
                        <Typography variant='h4'>
                            <FormattedMessage id="BriefNote" />
                        </Typography>
                    </Grid> 
                    <Grid
                        item 
                        container
                        rowSpacing={1}
                        spacing={{ xs: 1, sm: 2 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                        <Grid item xs={4} sm={8} md={12}>
                            <Typography variant='body1'>
                                <FormattedMessage id={props.noteID} />
                            </Typography>
                        </Grid>
                    </Grid>	
                </Grid>
        </span>
    )
}

export default InfoNote
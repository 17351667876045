import {
    atom,
} from 'recoil';

export const GeneralizeWSOpened = atom({
    key: 'GeneralizeWSOpened',
    default: {
        open: false,
    },
})

export const GeneralizeWS = atom({
    key: 'GeneralizeWS',
    default: {
        ws: null,
    },
})

export const GeneralizeWSRestartTimeout = atom({
    key: 'GeneralizeWSRestartTimeout',
    default: {
        timeout: 0,
    },
})

import { Button, Divider, Grid, Stack, Typography, Tooltip } from '@mui/material';
import React from 'react';
import './all-words.scss';
import {useRecoilState,} from 'recoil';
import {ChangePointsLogs} from "../atoms/GameConfiguration"
import PointsChangeLog from "./PointsChangeLog"
import {FormattedMessage} from "react-intl";
import HelpIcon from '@mui/icons-material/Help';

const AllWords = ({
	count,
	children
}) => {
	const [changePointsLogs, setChangePointsLogs]  = useRecoilState(ChangePointsLogs);

	let logs = []
	let i = 0;
	for (let logRaw of changePointsLogs) {
		logs.push(<PointsChangeLog key={i} nickname={logRaw.Nickname} word={logRaw.Word} guessed={logRaw.Guessed}/>)
		i++;
	}
	return (
		<Grid container spacing={2} className="all-words">
			<Grid 
				item xs={6} 
				container 
				direction="row" 
				justifyContent="flex-start"
			>
                  <Tooltip 
                      title={
                        <>
                            <Typography variant='body1'>
                                <FormattedMessage id="hackword_round_words_note" />
                            </Typography>
                        </>
                      } 
                    >
                    <HelpIcon/>
                  </Tooltip>			
			</Grid>		
			<Grid 
				item xs={6} 
				container 
				direction="row" 
				justifyContent="flex-end"
			>
				<Typography variant="h4" component="h2" className="all-words__count">
					{count > 0 ? `+${count}` : 0}
				</Typography>				
			</Grid>

			<Grid 
				item xs={12}
			>
				<Divider />
			</Grid>


			<Grid 
				item xs={12} sm={5}
				className='all-words__body mobile-hidden'
			>
				<Stack alignItems="flex-start">
					{logs}
				</Stack>
			</Grid>	
			<Grid 
				item xs={12} sm={7}
				className='all-words__body'
			>
				<Stack alignItems="flex-end">
					{children}
				</Stack>
			</Grid>
			<Grid 
				item xs={12}
				className='mobile-show-flex'
			>
				<Divider />
			</Grid>
			<Grid 
				item xs={12}
				className='all-words__body mobile-show-flex'
			>
				<Stack alignItems="flex-start">
					{logs}
				</Stack>
			</Grid>				
		
		</Grid>
	);
};

export default AllWords;
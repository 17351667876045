import {useRecoilState,} from 'recoil';
import {ChatWSOpened, ChatWS, ChatWSRestartTimeout} from "./atoms/Connection"
import React, {useState, useEffect} from 'react';
import {domainNameAPI, apiPort} from "../../../environment"
import {keepChatMessages} from "../../../constants"
import TokenService from "../../../services/TokenService"
import Api from "../../../services/Api";
import {ChatMessagesMap, ChatOnline, NewMessagesCount, NotWatchedPersonalChats} from "./atoms/Chat"
import {Authorized} from "../../atoms/AuthAtoms"
import {RequestGamesChatOnlineInterval} from "../../atoms/Common"
import {FormattedMessage} from "react-intl";


let ws = null

let apiUrl = "wss://"+domainNameAPI+apiPort+"/api/chats/ws"

export const sendMsgChat = (ws, type, obj)=>{
    if (ws == null) {
        console.log("webRTCClient is null");
        return
    }
    try {
        if (ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({'Type': type, 'Payload': obj}));
        }
    } catch (error) {
        console.error(error);
    }
}

function connect(atomValues, atomSets, props, reconnect) {
    let {wsOpened, chatWSRestartTimeout, chatMessagesMap, newMessagesCount, chatWS, notWatched,} = atomValues
    let {setWSOpened, setChatWS, setChatWSRestartTimeout, setChatMessagesMap, setNewMessagesCount, setChatOnline, setInitSent, setNotWatched} = atomSets

    if (reconnect) {
        ws = new WebSocket(apiUrl)
        setChatWS({ws: ws})
    }
    if (ws == null) {
        return
    }


    ws.onopen = () => {
        let newState = Object.assign({}, wsOpened);
        newState.opened = true;
        setWSOpened(newState);

        ws.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), 'OccasionID': props.occasionID, 'OccasionType': props.occasionType, }}));
        setInitSent(true)

        console.log('Chat WebSocket Client Connected');
        console.log("ASASAS send ONOPEN INIT "+props.occasionType)        
    };

    ws.onclose = () => {
        if (chatWSRestartTimeout.timeout != 0) {
            clearInterval(chatWSRestartTimeout.timeout)
        }
        let newState = Object.assign({}, wsOpened);
        newState.opened = false;
        setWSOpened(newState);
        let rTimeOut = setTimeout(function() {
            connect(atomValues, atomSets, props, true);
        }, 2000);
        setChatWSRestartTimeout({timeout: rTimeOut})
    };

    ws.onmessage = (message) => {
        let msg = JSON.parse(message.data);
        switch (msg.Type) {
            case "AllMessagesToShow":
                processAllMessagesToShow(msg, chatMessagesMap, setChatMessagesMap)
                break;
            case "NewMessage":
                processNewMessage(msg, chatMessagesMap, setChatMessagesMap, newMessagesCount, setNewMessagesCount, notWatched, setNotWatched)
                break;
            case "CurrentOnline":
                processCurrentOnline(msg, setChatOnline)
                break;
            case "NotWatched":
                processNotWatched(msg, setNotWatched)
                break; 
            case "IBlocked":
                processIBlocked(msg, chatMessagesMap, setChatMessagesMap)
                break;
            case "ErrorCode":
                if (msg.Payload.Code == 800) {
                    Api.post("/auth/refreshToken", {
                        RefreshToken: TokenService.getLocalRefreshToken(),
                    }).then(response=> {
                        setInitSent(true)
                        TokenService.updateLocalAccessTokens(response.data.Tokens.AuthToken, response.data.Tokens.RefreshToken);
                        ws.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), 'OccasionID': props.occasionID, 'OccasionType': props.occasionType,}}));
                    });
                }
                break                
        }
    }
}

function ChatController(props) {
    const [wsOpened, setWSOpened] = useRecoilState(ChatWSOpened); 
    const [chatWSRestartTimeout, setChatWSRestartTimeout]  = useRecoilState(ChatWSRestartTimeout);
    const [chatWS, setChatWS]  = useRecoilState(ChatWS);
    const [chatMessagesMap, setChatMessagesMap]  = useRecoilState(ChatMessagesMap);
    const [authorized, setAuthorized] = useRecoilState(Authorized);
	const [requestGamesChatOnlineInterval, setRequestGamesChatOnlineInterval]  = useRecoilState(RequestGamesChatOnlineInterval);
    const [chatOnline, setChatOnline] = useRecoilState(ChatOnline);
    const [newMessagesCount, setNewMessagesCount] = useRecoilState(NewMessagesCount);
    const [notWatched, setNotWatched] = useRecoilState(NotWatchedPersonalChats);

    const [initSent, setInitSent] = useState(false)

    useEffect(() => {
        if (requestGamesChatOnlineInterval.interval != 0) {
            clearInterval(requestGamesChatOnlineInterval.interval)
        }
        if (props.occasionID == 1 && props.occasionType == "page") {
            // let updateLobbies = setInterval(() => {
            //     if (chatWS.ws != null && chatWS.ws.readyState != 1) {
            //         clearInterval(updateLobbies);
            //         return;
            //     }
            //     sendMsgChat(chatWS.ws, "RequestOnline", {OccasionID: props.occasionID, OccasionType: props.occasionType})
            // }, 5000)
            // setRequestGamesChatOnlineInterval({interval: updateLobbies})
        }
      }, [chatWS])

    useEffect(()=> {
        console.log("ASASAS CHECK", props.occasionType, wsOpened.opened, chatWS.ws && chatWS.ws.readyState === WebSocket.OPEN, initSent)
        if (wsOpened.opened && chatWS.ws && chatWS.ws.readyState === WebSocket.OPEN && !initSent) {
            chatWS.ws.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), 'OccasionID': props.occasionID, 'OccasionType': props.occasionType, }}));
            setInitSent(true)
            console.log("ASASAS send OPENED INIT "+props.occasionType)
        }
    }, [chatWS.ws, wsOpened, initSent])

    useEffect(()=> {
        if (chatWS.ws == null) {
            ws = new WebSocket(apiUrl)
            setChatWS({ws: ws}) // , TokenService.getLocalAccessToken()
        }
    }, []);

    useEffect(()=> {
        // Empty SessionID means unauthorized user. Not empty - means authorized
        sendMsgChat(chatWS.ws, "InitRequest", {'SessionID': TokenService.getLocalAccessToken(), 'OccasionID': props.occasionID, 'OccasionType': props.occasionType,})
    }, [authorized]);

    useEffect(()=> {
        connect({wsOpened, chatWSRestartTimeout, chatMessagesMap, newMessagesCount, chatWS, notWatched,}, {setWSOpened, setChatWS, setChatWSRestartTimeout, setChatMessagesMap, setNewMessagesCount, setChatOnline, setInitSent, setNotWatched}, props, false)
    }, [newMessagesCount, chatMessagesMap, setChatMessagesMap, chatWS,]);
    return (
        <span>
        </span>
    )
}

export default ChatController;

const processAllMessagesToShow = (msg, chatMessagesMap, setChatMessagesMap)=>{
    let currChatMessages = new Map(chatMessagesMap)
    msg.Payload.Messages.sort((a, b)=>{if (a.Datetime > b.Datetime) {return 1} else if (a.Datetime < b.Datetime) {return -1} else {return 0}})
    msg.Payload.Messages.forEach((element, index) => {
        msg.Payload.Messages[index].Message = preprocessMessage(element.Message);
      });
    currChatMessages.set(msg.Payload.OccasionType+msg.Payload.OccasionID, msg.Payload.Messages)
    setChatMessagesMap(currChatMessages)
}

const processNewMessage = (msg, chatMessagesMap, setChatMessagesMap, newMessagesCount, setNewMessagesCount, notWatched, setNotWatched)=>{
    let currChatMessages = new Map(chatMessagesMap)
    let messages = currChatMessages.get(msg.Payload.OccasionType+msg.Payload.OccasionID)
    if (!messages) {
        messages = []
    }
    msg.Payload.Message.Message = preprocessMessage(msg.Payload.Message.Message)
    if (messages.length > keepChatMessages) {
        currChatMessages.set(msg.Payload.OccasionType+msg.Payload.OccasionID,[...messages.slice(1, messages.length), msg.Payload.Message])
    } else {
        currChatMessages.set(msg.Payload.OccasionType+msg.Payload.OccasionID, [...messages, msg.Payload.Message])
    }
    setChatMessagesMap(currChatMessages)
    setNewMessagesCount(newMessagesCount + 1)
    if (msg.Payload.OccasionType == "personal") {
        let newNotWatched = [...notWatched]
        let hasIt = false
        for (let uid of newNotWatched) {
            if (parseInt(uid) == parseInt(msg.Payload.OccasionID)) {
                hasIt = true
                break;
            }
        }
        if (!hasIt) {
            newNotWatched.push({UserID: parseInt(msg.Payload.OccasionID), Nickname: msg.Payload.Message.Nickname, Icon: msg.Payload.Message.Icon})
            setNotWatched(newNotWatched)
        }
    }
}

const processCurrentOnline = (msg, setChatOnline) =>{
    setChatOnline({online: msg.Payload.Online})
}

const processNotWatched = (msg, setNotWatched)=>{
    setNotWatched(msg.Payload.PersonalChats)
}

const processIBlocked = (msg, chatMessagesMap, setChatMessagesMap, )=>{
    let currChatMessages = new Map(chatMessagesMap)
    let messages = currChatMessages.get(msg.Payload.OccasionType+msg.Payload.OccasionID)
    if (!messages) {
        messages = []
    }
    let systemMessage = {
        isSystem: true, 
        Nickname: "System",
        Message: <FormattedMessage id="you_was_blocked" />,
        Datetime: "now",
    }
    if (messages.length > keepChatMessages) {
        currChatMessages.set(msg.Payload.OccasionType+msg.Payload.OccasionID,[...messages.slice(1, messages.length), systemMessage])
    } else {
        currChatMessages.set(msg.Payload.OccasionType+msg.Payload.OccasionID, [...messages, systemMessage])
    }
    setChatMessagesMap(currChatMessages)
}

class A extends React.Component {
    renderText() {
      let parts = this.props.text.split(/((?:https?:\/\/)?[\w-]+(?:\.[\w-]+)+\.?(?::\d+)?(?:\/\S*)?)/)
      for (let i = 1; i < parts.length; i += 2) {
        if (parts[i] && !parts[i].startsWith("https")) {
            parts[i] = "https://"+parts[i]
        }
        parts[i] = <a key={'link' + i} href={parts[i]}>{parts[i]}</a>
      }
      return parts
    }
    render() {
      let text = this.renderText()
      return (
        <span>{text}</span>
      )
    }
  }

const preprocessMessage = (message)=>{
    return <A text={message}></A>
}
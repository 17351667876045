import ReactMarkdown from 'react-markdown'


const markdown = `
# Политика конфиденциальности

Настоящая Политика конфиденциальности персональной информации действует в отношении всей информации, которую ИП Гаркавенко Сергей Владимирович (ОГРНИП: 318366800120949, ИНН: 366230073258), может получить о Пользователя во время использования им сайта, расположенного на доменах https://mnogokomnat.ru и https://многокомнат.рф. Реализацию данной обязанности регулирует Политика конфиденциальности персональной информации и законодательство Российской Федерации.

Настоящая Политика конфиденциальности персональных данных (далее – Политика
конфиденциальности) разработана на основании Федерального закона №152 «О персональных данных» от 27.07.2006 г.

## Общие положения

1.1 В политике конфиденциальности используются следующие определения:

1.1.1 **Персональные данные** — информация, которую пользователь предоставляет о себе самостоятельно при заполнении форм обратной связи, а также данные, которые автоматически передаются в процессе использования Сайта с помощью установленного на устройстве пользователя программного обеспечения, информация из cookies, информация о браузере пользователя (или иной программе, с помощью которой осуществляется доступ к Сервисам), время доступа, адрес запрашиваемой страницы.

1.1.2 **Сайт** — совокупность информации, содержащейся в информационной системе, доступ к которой обеспечивается посредством информационно-телекоммуникационной сети «Интернет», расположенная на доменных именах https://mnogokomnat.ru/ и https://многокомнат.рф/.

1.1.3 **Оператор** — Сайт осуществляющий обработку персональных данных, а также определяющий цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.

1.1.4 **Администрация сайта** — сотрудники, уполномоченные на управление Сайтом и действующие от имени ИП Гаркавенско Сергея Владимировича, которые организуют и (или) осуществляют обработку персональных данных.

1.1.5 **Cookies** — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в
HTTP-запросе при попытке открыть страницу соответствующего сайта.

1.1.6 **Пользователь** — лицо, имеющее доступ к Сайту посредством сети Интернет и использующее сайт, включая все его страницы и материалы, расположенные на них.

1.1.7 **Правила сайта** - правила пользования сайтом описанные по адресу https://mnogokomnat.ru/knowledge_base/rules

1.1.8 **Распространение персональных данных** — действия, направленные на раскрытие персональных данных неопределенному кругу лиц.

1.1.9 **Блокирование персональных данных** — временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).

1.1.10 **Уничтожение персональных данных** — действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных.

1.1.11 В настоящем Соглашении могут быть использованы иные термины и определения, не указанные в п.1.1. Соглашения. В этом случае толкование такого термина производится в соответствии с текстом Соглашения.

1.2 Политика конфиденциальности является официальным документом ИП Гаркавенко Сергея Владимировича, расположенном на доменных именах https://mnogokomnat.ru/ и https://многокомнат.рф/, определяет порядок обработки и защиты персональных данных.

1.3 Отношения, связанные с обработкой и защитой персональных данных о пользователях, регулируются Политикой конфиденциальности и действующим законодательством Российской Федерации.

1.4 Администрация вправе вносить изменения в Политику конфиденциальности. Новая редакция Политики конфиденциальности вступает в силу с момента ее размещения на сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности.

1.5 Уведомление пользователя об изменении политики конфиденциальности происходит путем размещения новой редакции Политики конфиденциальности на Сайте.

1.6 Пользователь обязуется регулярно, не реже одного раза в четырнадцать дней, знакомиться с содержанием настоящей политики в целях своевременного ознакомления с его изменениями и/или дополнениями.

1.7 Принимая условия настоящей Политики конфиденциальности, вы подтверждаете своё согласие на обработку персональных данных. Моментом подтверждения согласия считается регистрация на Сайте или авторизованный вход на Сайт с помощью стороннего сервиса, что тоже приравнивается к регистрации и создает Учетную запись пользователя.

1.8 Настоящее согласие действует со дня выражения согласия до дня отзыва.

1.9 Администрация не контролирует и не несет ответственности за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте.

1.10 Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем сайта.

1.11 Пользователь вправе изменять свои личные данные, а также требовать удаления личных данных у Сайта, путем обращения по электронной почте на адрес mail@mnogokomnat.ru.

1.12 В случае изменения персональных данных пользователь обязан обновить и(или) дополнить предоставленную информацию.

1.13 Администрация сайта принимает необходимые меры для защиты персональных данных, которые содержатся в пункте 9 настоящей Политики конфиденциальности.

1.14 Вопросы, не урегулированные Политикой конфиденциальности, подлежат разрешению в соответствии с законодательством РФ.

## Цели сбора персональных данных

2.1 Персональные данные Пользователя Администрация может использовать в целях:

2.1.1 Идентификации Пользователя, воспользовавшегося Сервисами Сайта

2.1.2 Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, обработки запросов и заявок от Пользователя.

2.1.3 Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием Сайта и Приложения.

2.1.4 Проведения статистических и иных исследований на основе обезличенных данных.

2.1.5 Предоставления пользователю услуг Cайта, в том числе исполнения пользовательского соглашения Сайта.

2.1.6 Предоставления пользователю доступа на сайт и в приложение.

2.1.7 Исполнения пользовательского соглашения сайта

2.1.8 Исполнения Правил Сайта

2.1.9 Предоставления Пользователю с его согласия новостной рассылки и иных сведений.

## Правовые основания обработки персональных данных

3.1 Обработка персональных данных субъектов осуществляется в соответсвии с п. 2 ч. 2 ст. 6 Федерального закона от 27.07.2006 г. № 152-ФЗ «О персональных данных»; ст. 24 Конституции Российской Федерации; главой 28 Гражданского кодекса Российской Федерации; пользовательским соглашением сайта; ст.3,ст.16 Федерального закона № 149-ФЗ от 27.07.06 г. «Об информации, информационных технологиях и о защите информации»; ст.9 Федерального закона от 6 апреля 2011 г. № 63-ФЗ «Об электронной подписи».

## Объем и категории обрабатываемых персональных данных, категории субъектов персональных данных

4.1 Осуществляется обработка следующих категорий персональных данных, которые не подлежат распространению или распространяются с позволения пользователя: адрес электронной почты (e-mail), идентификатор мобильного устройства, страна, регион, город, ФИО, профессия, дата рождения, телефон, идентификаторы учетной записи пользователя на других интренет ресурсах, используемые для авторизации.

4.2 Категории персональных данных, которые предоставляет пользователь дополнительно на добровольной основе и которые становятся общедоступными: фотография, видеоизображение, голосовая информация. Выраженная воля на предоставление доступа неограниченного круга лиц к своим общедоступным персональным данным предполагает, что субъект одновременно предоставляет согласие каждому неограниченному кругу лиц обрабатывать общедоступные указанные данные в любых не противоречащих закону целях.

## Файлы cookie

5.1 Веб-сайты используют файлы cookie и аналогичные технологии сбора данных для отслеживания пользователей. Эти технологии помогают нам обеспечивать беспроблемный доступ на Веб-сайты и использование услуг сайта, а также используются в аналитических целях. Такая информация не персонифицирована, каждому пользователю присваивается анонимный идентификационный код.

5.2 Считается, что вы дали согласие на использование файлов cookie, если в соответствующий момент времени их приём разрешён настройками/конфигурацией вашего браузера.

5.3 Пользователь имеет право в любой момент отключить прием файлов cookies посредством выполнения соответствующих настроек веб-браузера. Отключение cookies может повлечь некорректную работу некоторых элементов Cайта.

## Порядок и условия обработки персональных данных

6.1 Оператор осуществляет сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, блокирование, удаление и уничтожение персональных данных.

6.2 Персональные данные субъектов персональных данных могут быть получены, проходить дальнейшую обработку и передаваться на хранение, как на бумажных носителях, так и в электронном виде.

6.3 Запрещено раскрывать третьим лицам и распространять персональные данные без согласия субъекта персональных данных, если иное не предусмотрено Федеральным законом.

6.4 В случае достижения цели обработки персональных данных оператор прекращает обработку персональных данных и уничтожает персональные данные в срок, не превышающий тридцати дней с даты достижения цели обработки персональных данных.

6.5 Оператор может осуществлять обработку персональных данных как с использованием средств автоматизации, так и без их использования.

## Сторонние сервисы, социалные сети и сайты

7.1 Сайт может быть связан со сторонними сервисами, социальными сетями или сайтами через публичные API этих ресурсов. Политика конфиденциальности сторонних ресурсов не регулируется настоящей Политикой конфиденциальности. Мы рекомендуем вам отдельно ознакомиться с политиками конфиденциальности этих сторонних веб-сайтов и служб.

## Актуализация, исправление, удаление и уничтожение персональных данных, ответы на запросы субъектов на доступ к персональным данным.

8.1 Администрация сайта не осуществляет проверку достоверности предоставляемых пользователями сайта персональных данных, полагая, что они действуют добросовестно и поддерживают информацию о своих персональных данных в актуальном состоянии.

8.2 В случае подтверждения факта неточности персональных данных, персональные данные подлежат актуализации оператором. До актуализации персональные данные подлежат блокированию. В случае подтверждения факта неправомерности обработки персональных данных, обработка персональных данных прекращается. До подтверждения факта неправомерности персональные данные подлежат блокированию. Если обеспечить правомерность обработки персональных данных невозможно, оператор в срок, не превышающий десяти рабочих дней с даты выявления неправомерной обработки персональных данных, обязан уничтожить такие персональные данные.

8.3 При достижении целей обработки персональных данных, а также в случае отзыва субъектом персональных данных согласия на их обработку, персональные данные подлежат уничтожению.

8.4 Субъект персональных данных имеет право на получение информации, касающейся обработки его персональных данных по электронной почте mail@mnogokomnat.ru.

## Меры, применяемые для защиты персональных данных пользователей

9.1 Администрация принимает необходимые организационные и технические меры для защиты персональных данных пользователя от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных.

9.1.1 Осуществление внутреннего контроля соответствия обработки персональных данных настоящему Федеральному закону и принятым в соответствии с ним нормативным правовым актам.

9.1.2 Ознакомление сотрудников, непосредственно осуществляющих обработку персональных данных, с положениями законодательства Российской Федерации о персональных данных, в том числе с документами, определяющими политику ИП Гаркавенко Сергей Владимировича в отношении обработки персональных данных.

9.1.3 Комплексная защита серверов и баз данных

9.1.4 Протокол https, который обеспечивает конфиденциальность обмена данными между сайтом и пользовательским устройством криптографическим способом.

9.1.5 Иные организационные и технические меры защиты персональных данных, предусмотренные нормативными актами РФ и внутренними документами ИП Гаркавенко Сергея Владимировича.

*Дата последнего изменения: 08.01.2024*
`




function PrivacyPolicyRU() {
    return (
        <div style={{textAlign: "left", padding: "20px"}}>
            <ReactMarkdown children={markdown} />,
        </div>
    )
}

export default PrivacyPolicyRU;

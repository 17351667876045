import { Button, Stack, Typography } from '@mui/material';
import UserItem from "./UserItem/UserItem"
import { FormattedMessage } from 'react-intl';
import React, { useState, useEffect } from 'react';
import {domainNameAPI, protocolAPI, apiPort} from "../../../environment"
import {useRecoilState} from "recoil";
import {CommunityStatus,} from "../atoms/Community";
import {UserInfoAtom} from "../../atoms/UserAtoms"









const OwnershipTransferModal = (props)=>{
    let apiUrl = protocolAPI+domainNameAPI+apiPort

    const [communityStatus, setCommunityStatus] = useRecoilState(CommunityStatus);
	const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);

    return (
        <Stack
            direction="column"
            spacing={3}
        >
            <Typography variant='h4'>
                <FormattedMessage id="transfer_ownership" /> <FormattedMessage id={props.type}/> {props.name}
            </Typography>

            {props.members.map((item, index) => (
                       userInfo == null || userInfo.UserInfo == null || userInfo.UserInfo.UserID != item.ID && <UserItem
                            key={"user"+index}
                            name={item.Nickname}
                            avatar={apiUrl+"/mkstatic/shared/avatars/"+item.Avatar+"?dopasdasda"}
                            role={item.Role}
                            userID={item.ID}
                            communityID={parseInt(props.communityID)}
                            myRole={communityStatus}
                            me={userInfo != null && userInfo.UserInfo != null && userInfo.UserInfo.UserID == item.ID}
                            closeTransferOwnership={props.onClose}										
                        />
                    ))}	
    </Stack>
    )
}

export default OwnershipTransferModal
import {
    atom, useRecoilState,
} from 'recoil';


export const SiteWSOpened = atom({
    key: 'SiteWSOpened',
    default: {
        opened: false,
    },
})

export const SiteWS = atom({
    key: 'SiteWS',
    default: {
        ws: null,
    },
})

export const SiteWSRestartTimeout = atom({
    key: 'SiteWSRestartTimeout',
    default: {
        timeout: 0,
    },
})







export const About = {
    "about":`
### Много Комнат - это про общение

А человеку нужно общение! Это буквально высечено природой в функционировании его мозга. Это физическая необходимость.

Да, есть заменители и кому-то хватает мессенджеров и соц сетей, но настоящие яркие эмоции мы получаем от живого общения.

К тому же важен не только материальный капитал, но и капитал социальный. Это те люди, с которыми ты знаком. Они дарят чувство защищенности не меньше чем мешок денег. А то и больше.

Без общения человеку плохо. Неуютно. А в век высоких технологий найти себе качественное общение бывает сложно.

Много Комнат - это островок в интернете, где тебя ждут, чтобы просто с удовольствием провести время.
    `,
}
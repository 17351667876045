



export const fallbackCopyTextToClipboard = function(text, successf, errf) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      if (msg == successful) {
        successf()
      } else {
        errf()
      }
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
  
    document.body.removeChild(textArea);
}

export const copyTextToClipboard = function(text, successf, errf) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text, successf, errf);
      return;
    }
    navigator.clipboard.writeText(text).then(function() {
        successf()
    }, function(err) {
        errf()
    });
}
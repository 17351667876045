import {
    atom,
} from 'recoil';

export const HackwordGameSettings = atom({
    key: 'HackwordGameSettings',
    default: {
        wordsSet: "easy",
        roundSeconds: 60,
        winWordsNumber: 20,
        roundNumber: 1,
        phase: "picking",
        isAdmin: false,
        adminPosition: 0,
    },
})

export const TeamsState = atom({
    key: 'TeamsState',
    default: {
        teams: []
    }
})

export const HackwordSettingsOpened = atom({
    key: 'HackwordSettingsOpened',
    default: {
        opened: false
    }
})

export const TeamToPlay = atom({
    key: 'TeamToPlay',
    default: {
        Guess: [],
        Explains: 0,
        Ready: [],
        TeamNum: 0,
        PointsTeam: 0,
    }
})

export const RoundSecondsPassed = atom({
    key: 'RoundSecondsPassed',
    default: {
        RoundSecondsPassed: 0,
        Updated: true,        
    }
})

export const WordToHack = atom({
    key: 'WordToHack',
    default: {
        Word: "",
    }
})

export const WordsHistory = atom({
    key: 'WordsHistory',
    default: {
        Words: [],
    }
})

export const RoundPhase = atom({
    key: 'RoundPhase',
    default: {
        Phase: "stopped",
    }
})

export const DoWinCondition = atom({
    key: 'DoWinCondition',
    default: {
        DoWinCondition: false,
    }
})

export const GameFinished = atom({
    key: 'HackwordGameFinished',
    default: {
        Place: 1, 
        Finished: false
    }
})

export const FinishChangeExpPointsInfo = atom({
    key: 'HackwordFinishChangeExpPointsInfo',
    default: {
        Level: 0,       
        CurrentExp: 0,
        NearestEdge: 300,
        PointsToAdd: 0,
    }
})

export const ChangePointsLogs = atom({
    key: 'HackwordChangePointsLogs',
    default: [],
})
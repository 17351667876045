import { Typography, } from '@mui/material';
import {FormattedMessage} from "react-intl";
import React, { useState, useLayoutEffect, useEffect, useRef } from 'react';
import {ThemeAtom} from "../../atoms/Common";
import {useRecoilState} from "recoil";











function ExpChangeBar(props) {
    const [themeAtom, setThemeAtom] = useRecoilState(ThemeAtom);
    let gameIcon = "/themes/"+themeAtom.theme+"/imgs/features/"+props.featureType+".png"

    const lineRef = useRef();
    const [fullLineWidth, setFullLineWidth] = useState(0);
    const [lineWidth, setLineWidth] = useState(0);

    useLayoutEffect(() => {
        if (lineRef.current) {
            setFullLineWidth(lineRef.current.offsetWidth)
            setLineWidth(props.expChangeInfo.CurrentExp / props.expChangeInfo.NearestEdge * lineRef.current.offsetWidth)
        }
    }, []);


    const fullLineStyle = {
        width:  "calc(100% - 51px)",
        height: "40px",
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        border: "2px solid black",
        float: "left",
        marginLeft: "-10px",
        zIndex: 30,
        position: "relative",
    }
    // let lineWidth = props.expChangeInfo.CurrentExp / props.expChangeInfo.NearestEdge * lineLengthInPixels
    const experienceLevelLineStyle = {
        height: "100%",
       // width: lineWidth+"px",
        backgroundColor: "#7dcff5",
        borderRadius: "10px 0px 0px 10px",
    }
    const pointsToAddStyle = {
        position: "absolute",
        top: "-20px",
        right: "10px",
    }
    const experienceLevelNumbersStyle = {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        color: "#65307a",
    }
    const typeIconStyle = {
        width: "44px",
        float: "left",
        zIndex: 40,
        position: "relative",
    }   

    const addStartSize = 50
    const addBiggestSize = 90
    const steps = 70
    const millisFor1 = 200
    const millisFor2 = 100
    const millisFor3 = 200    
    const millisFor4 = 1800
    const smallestSize = 10    

    const [fontSize, setFontSize] = useState(addStartSize);
    const [pointsToAdd, setPointsToAdd] = useState(props.expChangeInfo.PointsToAdd ? props.expChangeInfo.PointsToAdd : -1);

    const [state, setState] = useState(-1);
    const [currEdge, setCurrEdge] = useState(props.expChangeInfo.NearestEdge);
    const [expAnimation, setExpAnimation] = useState(props.expChangeInfo.CurrentExp);
    const [plusExpAnimation, setPlusExpAnimation] = useState(0);
    useEffect(() => {
        let timeoutMe = 0
        switch (state) {
            case -1:
                if (pointsToAdd > 0) {
                    setExpAnimation(props.expChangeInfo.CurrentExp)
                    setPlusExpAnimation(pointsToAdd)
                    setState(0)
                } 
                break;        
            case 0:
                timeoutMe = setTimeout(() => {
                    let size = fontSize + (addBiggestSize - addStartSize) / steps
                    setFontSize(size)
                }, millisFor1 / steps)
                if (addBiggestSize <= fontSize) {
                    setState(1)
                }
                break;
            case 1:
                timeoutMe = setTimeout(() => {
                    let size = fontSize - (addBiggestSize - addStartSize) / steps
                    setFontSize(size)
                }, millisFor2 / steps)
                if (addStartSize >= fontSize) {
                    setState(2)
                }
                break;
            case 2:
                timeoutMe = setTimeout(() => {
                    let size = fontSize + (addBiggestSize - addStartSize) / steps
                    setFontSize(size)
                }, millisFor3 / steps)
                if (addBiggestSize <= fontSize) {
                    setState(3)
                }
                break;
            case 3:
                timeoutMe = setTimeout(() => {
                        let size = fontSize - (addBiggestSize - smallestSize) / steps
                        setFontSize(size)
                        if (expAnimation + pointsToAdd / steps < props.expChangeInfo.NearestEdge) {
                            setExpAnimation(expAnimation + pointsToAdd / steps)
                        } else {
                            setExpAnimation(pointsToAdd / steps)
                            setCurrEdge(props.expChangeInfo.NextEdge)
                        }
                       // setPlusExpAnimation(plusExpAnimation - pointsToAdd / steps)
                }, millisFor4 / steps)                
                if (smallestSize >= fontSize) {
                    setState(4)
                }
                break;
            case 4:
                if (pointsToAdd > 0) {
                    setPointsToAdd(0)
                }
                setState(-1)                
                break                   
        }
    }, [state, fontSize, expAnimation, plusExpAnimation])
    useEffect(() => {
        setLineWidth(expAnimation / currEdge * lineRef.current.offsetWidth)
    }, [expAnimation, currEdge])        
    return (
        <div style={{position: "relative"}}>
            <img style={typeIconStyle} src={gameIcon}></img>
            <div style={fullLineStyle} ref={lineRef}>
                <div style={{...experienceLevelLineStyle, width: lineWidth+"px"}}></div>
                <Typography style={experienceLevelNumbersStyle}>{props.expChangeInfo.CurrLevel !== undefined && <span>{props.expChangeInfo.CurrLevel} <FormattedMessage id="lev" /></span>} {Math.round(expAnimation)}/{currEdge}</Typography>
            </div>
            {true && plusExpAnimation > 0 && <div style={pointsToAddStyle}>+{Math.round(plusExpAnimation)}</div>}
        </div>
    );
}

export default ExpChangeBar;

import { Button, Grid, Typography } from '@mui/material';
import {useNavigate} from "react-router-dom"
import {FormattedMessage} from "react-intl";
import React, { useState } from 'react';
import RoomService from "../../services/rooms/RoomService"
import { SuccessMessage, } from '../../constants';







const AlreadyInTheRoom = (props)=>{
    const navigate = useNavigate();

    const gotothatroom = ()=>{
        props.onRestart()
        props.onClose()
        navigate("/room/"+props.roomKey)
    }
    const exit = ()=>{
        RoomService.leaveRoom().then((response)=>{
            if (response.data.Success == SuccessMessage) {
                props.onReload()
                props.onClose()
                navigate("/room/"+props.roomKeyNew)
            }
        })
    }
    return (
        <Grid
        container
        rowSpacing={2}
        spacing={{ xs: 1, sm: 2 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        direction="column"
        padding={1}
    >

        <Grid item xs={4} sm={8} md={12}>
            <Typography variant='h6'>
                <FormattedMessage id="you_are_already_in_another_room" />
            </Typography>			
        </Grid>

        <Grid item xs={4} sm={8} md={12}>
            <Button variant="contained" size="large" onClick={gotothatroom}>
                <FormattedMessage id="go_to_that_room" />
            </Button>
        </Grid>
        <br/>
        <Grid item xs={4} sm={8} md={12}>
            <Button variant="contained" size="large" onClick={exit}>
                <FormattedMessage id="exit_that_room" />
            </Button>
        </Grid>                    
    </Grid>)
}

export default AlreadyInTheRoom;

import {Box, Button, Grid, TextField, Typography, Snackbar, Alert} from '@mui/material';
import {useRecoilState} from "recoil";
import {SetGamePasswordModal, BirthDayModal, ShowTooYoung} from "../atoms/Common";
import GamesService from "../../services/games/GamesService";
import {FormattedMessage} from "react-intl";
import React, {useState} from 'react';
import {useNavigate} from "react-router-dom"
import {UserPersonalAtom} from "../atoms/UserAtoms";



const SetGamePassword = (props) => {
    const [password, setPassword] = useState("");
	const [showWrongPassword, setShowWrongPassword] = useState(false);
    const [showAlreadyInTheLobby, setShowAlreadyInTheLobby] = useState(false);
	const navigate = useNavigate();

    const [userPersonal, setUserPersonal] = useRecoilState(UserPersonalAtom);
    const [gamePasswordModal, setSetGamePasswordModal] = useRecoilState(SetGamePasswordModal)
	const [birthDayModal, setBirthDayModal] = useRecoilState(BirthDayModal)
	const [showTooYoung, setShowTooYoung] = useRecoilState(ShowTooYoung)

    const changePassword = (event) => {
		setPassword(event.target.value);
	}
    const joinRoom = () => {
        GamesService.joinLobby(gamePasswordModal.GameName, gamePasswordModal.EntityID, password).then(()=>{
            setSetGamePasswordModal({Open: false, EntityID: 0, GameName: "",})
            navigate("/room")
        }).catch((err)=>{
            if (err.response.data.ErrorCode == 50) {
                if (userPersonal && !userPersonal.UserPersonal.Birthday.Valid) {
                    setBirthDayModal({Open: true})
                    return
                } else {
                    setShowTooYoung({show: true})
                    return
                }									
            }
            if (err.response.data.ErrorCode == 100) {
                setShowWrongPassword(true)
            }
            if (!showAlreadyInTheLobby && err.response.data.ErrorCode == 2) {
                setShowAlreadyInTheLobby(true)
            }
        })
    }
    return(
        <Grid
            container
            rowSpacing={2}
            spacing={{ xs: 1, sm: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            direction="column"
            padding={1}
        >
            <Grid item xs={4} sm={8} md={12}>
                <Typography variant='h4'>
                    <FormattedMessage id="requires_password" />
                </Typography>
            </Grid>

            <Grid item xs={4} sm={8} md={12}>
                <TextField 
                        fullWidth 
                        id="password" 
                        label={<FormattedMessage id="password" />} 
                        type="password" 
                        variant="outlined"
                        size='small'
                        value={password}
                        onChange={changePassword}
                    />
            </Grid>

            <Grid item xs={4} sm={8} md={12}>
                <Button variant="contained" size="large" onClick={joinRoom}>
                    <FormattedMessage id="JoinRoom" />
                </Button>
            </Grid>

            <Snackbar open={showWrongPassword} 
				onClose={()=>{setShowWrongPassword(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
				  key={"showWrongPassword"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"showWrongPassword"}>
				  <FormattedMessage id="wrong_password" />
  				</Alert>
			</Snackbar>

            <Snackbar open={showAlreadyInTheLobby} 
				onClose={()=>{setShowAlreadyInTheLobby(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
				  key={"showAlreadyInTheLobby"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"showAlreadyInTheLobbyAlert"}>
				  <FormattedMessage id="YouAlreadyInTheRoom" />
  				</Alert>
			</Snackbar>	
        </Grid>
    )
}

export default SetGamePassword;
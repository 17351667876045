import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';
import LogInForm from './LogInForm/LogInForm';
import RegistrationForm from './RegistrationForm/RegistrationForm';
import {FormattedMessage} from "react-intl";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Auth = (props) => {
	const [value, setValue] = React.useState(props.startIndex);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <LogInForm />
  )

  // return (
  //   <Box sx={{ width: '100%' }}>
  //     <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
  //       <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
  //         <Tab label={<FormattedMessage id="login" />} {...a11yProps(0)} />
  //         <Tab label={<FormattedMessage id="registration" />} {...a11yProps(1)} />
  //       </Tabs>
  //     </Box>

  //     <TabPanel value={value} index={0}>
  //       <LogInForm />
  //     </TabPanel>

  //     <TabPanel value={value} index={1}>
  //       <RegistrationForm />
  //     </TabPanel>
  //   </Box>
  // );
};

export default Auth;
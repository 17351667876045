import {
    atom,
} from 'recoil';



export const AllExperience = atom({
    key: 'AllExperience',
    default: {
        Level: -1,
        Experience: -1,
        EdgeExperience: -1, 
    },
})

export const AddPointsNumber = atom({
    key: 'AddPointsNumber',
    default: {
        Points: 0, 
    },
})
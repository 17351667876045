


export const SuccessMessage = "Yeah"
export const EndSaltLength = 3
export const MaxAvatarSize = 3 * 1024 * 1024

export const MatesVersionsStep = 1000000

export const MaxParticipantsStandart = 10
export const MaxParticipantsPremium = 20

export const MaxNickNameLength = 30
export const MaxPasswordLength = 50
export const MaxEmailLength = 64

export const MaxAboutLength = 500
export const MaxCountryLength = 50
export const MaxRegionLength = 50
export const MaxCityLength = 50
export const MaxTelephoneLength = 15
export const MaxNameLength = 50
export const MaxSurnameLength = 50

export const MaxGameNameLength = 120
export const MaxRoomPasswordLength = 70

export const MaxRoomNameLength = 120
export const MaxRoomTagLength = 64

export const MaxChatMessageLength = 500
export const keepChatMessages = 50

export const MaxEventNameLength = 100
export const MaxEventDescriptionLength = 300
export const MaxCommunityNameLength = 50
export const MaxCommunityDescriptionLength = 300

export const MaxIamaliveWordLength = 50
export const MaxUserReportDescriptionLength = 500

export const MaxGeneralizeWordLength = 30
export const GeneralizationMillisecondsForVote = 2000
export const GeneralizeDefaultSecondsForLearning = 120
export const GeneralizeDefaultSecondsForWordANDNumber = 60
export const GeneralizeDefaultSecondsForGuessing = 60

export const VKAppID = 51708103

export const MiniChatWidth = 250
export const MiniChatMobileWidth = 50

export const BoxesSpacingHorizontal = { xs: 5, sm: 5, md: 8, lg: 10, xl: 15 } // { xs: 5, sm: 5, md: 8, lg: 10, xl: 12 }
export const BoxesSpacingVertical = { xs: 1, sm: 2, md: 4, lg: 6, xl: 8 }
export const BoxesSpacingGeneralizeHorizontal = { xs: 3, sm: 5, md: 8, lg: 10, xl: 15 }

export const DefaultLang = "ru"
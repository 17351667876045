import { Stack, Typography, Divider, Grid, useTheme } from "@mui/material";
import {FormattedMessage} from "react-intl";
import React, { useState, useEffect } from 'react';
import ColorModeContext from '../../../utils/ColorModeContext';
import {useNavigate} from "react-router-dom"






const RatingEntry = (props) => {
    const navigate = useNavigate();

    const theme = useTheme();
    const goToCard = ()=>{
        navigate('/user/'+props.nickname)
        //window.open('/user/'+props.nickname,'_blank')
    }
    return (
        <Stack direction="column" spacing={1} alignItems="center" style={{cursor: "pointer"}} onClick={goToCard}>
            <Stack direction="row" spacing={3} alignItems="center" style={{width: "100%"}} >
                <Grid container columns={{ xs: 25 }} spacing={2} direction="row" justifyContent="flex-start" style={props.myplace == props.place ? {backgroundColor: theme.palette.mode == "light" ? "#D0EBFC" : "#3f2f9c", maring: "0px", padding: "0px",} : null}>
                        <Grid item xs={1}>                    
                            <Typography variant="h4">{props.place}</Typography>
                        </Grid>
                        <Grid item xs={4} style={{ display: "flex", alignItems: "center"}}>
                            <Typography variant="h5" style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>{props.nickname}</Typography>
                        </Grid>
                        <Grid item xs={19}>
                            <Stack direction="column" spacing={1} alignItems="left" style={{width: "100%", marginTop: "-10px"}}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Typography variant="h8">{props.level} <FormattedMessage id="level" /></Typography>
                                    <Typography variant="h8">{props.experience} <FormattedMessage id="experience" /></Typography>
                                </Stack>
                                <div style={{height: "12px", backgroundColor: "#3B78DB", width: props.percents+"%"}}></div>
                            </Stack>
                        </Grid> 
                        <Grid item xs={1}>                    
                        </Grid>                                                                                      
                </Grid>
            </Stack>
            {props.divider ? <Divider style={{width: "100%"}}/> : null}
        </Stack>
    )
}

export default RatingEntry;

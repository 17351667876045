import axios from "axios";
import TokenService from "./TokenService";
import {domainNameStaticAPI, protocolAPI, staticAPIPort} from "../environment"

const instance = axios.create({
  baseURL: protocolAPI+domainNameStaticAPI+staticAPIPort+"/mkstatic",
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/auth/loginPassword" && originalConfig.url !== "/auth/register" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instance.post("/auth/refreshToken", {
            RefreshToken: TokenService.getLocalRefreshToken(),
          });

          TokenService.updateLocalAccessTokens(rs.data.Tokens.AuthToken, rs.data.Tokens.RefreshToken);

          originalConfig.headers = err.headers
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
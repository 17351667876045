import {
	useRecoilState,
  } from 'recoil';
import { Box, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import {FormattedMessage} from "react-intl";






const PointsChangeLog = ({
	className,
	nickname,
    word,
	guessed
}) => {

	return (
		<Stack className={className} direction="row" spacing={1} alignItems="center">
			<Typography variant="body">
				{nickname} <FormattedMessage id="changed" /> "{word}" <FormattedMessage id="to" />
			</Typography>

			<Stack direction="row">
					{guessed ?
					<Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '34px', height: '34px', cursor: "pointer"}}>
						<ThumbUpIcon color="success" />
					</Box>
					:
					<Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '34px', height: '34px', cursor: "pointer"}}>
						<ThumbDownIcon color="error"/>
					</Box> 
					}
			</Stack>
		</Stack>
	);
};

export default PointsChangeLog;
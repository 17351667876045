import {
	useRecoilState,
  } from 'recoil';
import { Button, FormControl, Grid, MenuItem, Select, Slider, Stack, Switch, Typography } from '@mui/material';
import {FormattedMessage} from "react-intl";
import React, { useState } from 'react';
import { HackwordGameSettings, TeamsState, HackwordSettingsOpened } from '../atoms/GameConfiguration';
import { HackwordWS } from '../atoms/Connection';
import {sendMsgHackword} from "../HackwordGameController";

const HackwordRoomSetting = () => {
	const [gameSettings, setGameSettings]  = useRecoilState(HackwordGameSettings); 
    const [teamsState, setTeamsState]  = useRecoilState(TeamsState);
	const [openSetting, setOpenSetting] = useRecoilState(HackwordSettingsOpened);
	const [hackwordWS, setHackwordWS]  = useRecoilState(HackwordWS);

	const [roundSeconds, setRoundSeconds] = useState(gameSettings.roundSeconds);
	const [winWordsNumber, setWinWordsNumber] = useState(gameSettings.winWordsNumber);
	const [words, setWords] = useState(gameSettings.wordsSet);
	let startNumOfTeams = Object.keys(teamsState.teams).length
	if (startNumOfTeams == 0) {startNumOfTeams = 1;}
	const [numberOfTeams, setNumberOfTeams] = useState(startNumOfTeams);
	const [startNew, setStartNew] = useState(false);
	const [shufflePlayers, setShufflePlayers] = useState(false);

	const switchStartNew = (event) => {
		setStartNew(event.target.checked);
	}
	const switchShufflePlayers = (event) => {
		setShufflePlayers(event.target.checked);
	}
	const roundSecondsHandler = (event, value) => {
		setRoundSeconds(value);
	};
	const winWordsNumberHandler = (event, value) => {
		setWinWordsNumber(value);
	};
	const wordsChangeHandler = (event) => {
		setWords(event.target.value);
	};
	const numberOfTeamsHeandler = (event, value) => {
		setNumberOfTeams(value);
	};

	const applyChanges = ()=>{
		if (startNew || gameSettings.phase == "starting") {
			sendMsgHackword(hackwordWS.ws, "ChangeSettings", {RoundSeconds: roundSeconds, WinWordsNumber: winWordsNumber, WordsSet: words, NumberOfTeams: numberOfTeams, ShufflePlayers: shufflePlayers})
			setOpenSetting({opened: false})
		}
	}
	return (
		<Grid
			container
			rowSpacing={2}
			spacing={{ xs: 1, sm: 2 }}
			columns={{ xs: 4, sm: 8, md: 12 }}
			direction="column"
			padding={1}
		>
			<Grid item xs={4} sm={8} md={12}>
				<Typography variant='h4'>
					<FormattedMessage id="settings" />
				</Typography>
			</Grid> 

			<Grid
					item 
					container
					rowSpacing={1}
					spacing={{ xs: 1, sm: 2 }}
					columns={{ xs: 4, sm: 8, md: 12 }}
				>
					<Grid item xs={3} sm={4} md={6}>
						<Typography variant='body1'>
							<FormattedMessage id="start_over" />:
						</Typography>
					</Grid>

					<Grid item xs={1} sm={4} md={6}>
						<Switch 
							checked={startNew}
							onChange={switchStartNew} 
						/>
					</Grid>
			</Grid>	

			<Grid
				item
				container
				rowSpacing={2}
				spacing={{ xs: 1, sm: 2 }}
				columns={{ xs: 4, sm: 8, md: 12 }}
				direction="column"
			>
				<Grid
					item 
					container
					rowSpacing={1}
					spacing={{ xs: 1, sm: 2 }}
					columns={{ xs: 4, sm: 8, md: 12 }}
				>
					<Grid item xs={4} sm={4} md={6}>
						<Typography variant='body1'>
							<FormattedMessage id="time_for_round" />:
						</Typography>
					</Grid>

					<Grid item xs={4} sm={4} md={6}>
						<Stack direction="row" spacing={2} alignItems="center">
							<Slider 
								disabled={!startNew && gameSettings.phase != "starting"}
								aria-label="Секунд на раунд" 
								value={roundSeconds} 
								min={20} 
								max={120}
								step={10}
								onChange={roundSecondsHandler}
							/>

							<Typography variant='body1'>{roundSeconds}</Typography>
						</Stack>
					</Grid>
				</Grid>

				<Grid
					item 
					container
					rowSpacing={1}
					spacing={{ xs: 1, sm: 2 }}
					columns={{ xs: 4, sm: 8, md: 12 }}
				>
					<Grid item xs={4} sm={4} md={6}>
						<Typography variant='body1'>
							<FormattedMessage id="words_to_victory" />:
						</Typography>
					</Grid>

					<Grid item xs={4} sm={4} md={6}>
						<Stack direction="row" spacing={2} alignItems="center">
							<Slider 
								disabled={!startNew && gameSettings.phase != "starting"}
								aria-label="Слов до победы" 
								value={winWordsNumber} 
								min={10}
								step={5}
								max={50} 
								onChange={winWordsNumberHandler}
							/>

							<Typography variant='body1'>{winWordsNumber}</Typography>
						</Stack>
					</Grid>
				</Grid>

				<Grid
					item 
					container
					rowSpacing={1}
					spacing={{ xs: 1, sm: 2 }}
					columns={{ xs: 4, sm: 8, md: 12 }}
				>
					<Grid item xs={4} sm={4} md={6}>
						<Typography variant='body1'>
							<FormattedMessage id="set_of_words" />:
						</Typography>
					</Grid>

					<Grid item xs={4} sm={4} md={6}>
						<FormControl fullWidth>
							<Select
								labelId="label"
								id="demo-simple-select-helper"
								value={words}
								disabled={!startNew && gameSettings.phase != "starting"}
								size="small"
								onChange={wordsChangeHandler}
							>
								<MenuItem value="easy">
									<FormattedMessage id="easy" />
								</MenuItem>

								<MenuItem value="normal">
									<FormattedMessage id="normal" />
								</MenuItem>								

								<MenuItem value="complex">
									<FormattedMessage id="complex" />
								</MenuItem>

								<MenuItem value="professions">
									<FormattedMessage id="professions" />
								</MenuItem>

								<MenuItem value="animals">
									<FormattedMessage id="animals" />
								</MenuItem>

								<MenuItem value="dota2_heroes">
									<FormattedMessage id="dota2_heroes" />
								</MenuItem>
							</Select>
						</FormControl>
					</Grid>
				</Grid>

				<Grid
					item 
					container
					rowSpacing={1}
					spacing={{ xs: 1, sm: 2 }}
					columns={{ xs: 4, sm: 8, md: 12 }}
				>
					<Grid item xs={4} sm={4} md={6}>
						<Typography variant='body1'>
							<FormattedMessage id="number_of_teams" />:
						</Typography>
					</Grid>

					<Grid item xs={4} sm={4} md={6}>
						<Stack direction="row" spacing={2} alignItems="center">
							<Slider 
								aria-label="Количество команд"
								disabled={!startNew && gameSettings.phase != "starting"} 
								value={numberOfTeams} 
								min={1} 
								max={4}
								step={1} 
								onChange={numberOfTeamsHeandler}
							/>

							<Typography variant='body1'>{numberOfTeams}</Typography>
						</Stack>
					</Grid>
				</Grid>	
				<Grid
					item 
					container
					rowSpacing={1}
					spacing={{ xs: 1, sm: 2 }}
					columns={{ xs: 4, sm: 8, md: 12 }}
				>
					<Grid item xs={3} sm={4} md={6}>
						<Typography variant='body1'>
							<FormattedMessage id="shuffle_players" />:
						</Typography>
					</Grid>

					<Grid item xs={1} sm={4} md={6}>
						<Switch
							disabled={!startNew && gameSettings.phase != "starting"}
							checked={shufflePlayers}
							onChange={switchShufflePlayers} 
						/>
					</Grid>
				</Grid>	
			</Grid>
			
			<Grid item xs={4} sm={8} md={12}>
				<Button variant="contained" size="large" onClick={applyChanges}>
					<FormattedMessage id="Apply" />
				</Button>
			</Grid>
		</Grid>
	);
};

export default HackwordRoomSetting;
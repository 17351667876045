import {IamaliveGameState, WordChangeState,} from "../atoms/GameAtoms"
import {useRecoilState,} from 'recoil';
import React, { useState, useEffect } from "react";
import { sendMsgIamalive } from '../IamaliveGameController';
import {IamaliveWS} from "../atoms/Connection";
import { Button, Chip, } from '@mui/material';
import {FormattedMessage} from "react-intl";
import {PositionsToMute} from "../../../../../livekit_prod/atoms";
import {MaxIamaliveWordLength} from "../../../../../constants";
import { Snackbar, Alert, Modal, Box} from '@mui/material';
import ModalStyle from '../../../../../utils/ModalStyle/ModalStyle';
import ModalClose from '../../../../../utils/ModalStyle/ModalClose';
import SetPreparedWords from "../SetPreparedWords/SetPrepraredWords"


const IamaliveGameBox = ({livekitBox, position, myposition, modal, iamaliveID})=>{
    const [box, setBox] = useState(livekitBox)

    useEffect(()=> {
        let boxUp = livekitBox
        if (React.isValidElement(boxUp)) {
            boxUp = React.cloneElement(boxUp, { modal: modal }); 
        }
        setBox(boxUp)   
    }, [livekitBox])

    const [showSetPreparedWord, setShowSetPreparedWord] = useState(false);

    const [playerWord, setPlayerWord] = useState("")
    const [showWordToCHange, setShowWordToCHange] = useState(false)
    const [showWordTooLong, setShowWordTooLong] = useState(false)

    const [iamaliveGameState, setIamaliveGameState]  = useRecoilState(IamaliveGameState); 
    const [wordChangeState, setWordChangeState]  = useRecoilState(WordChangeState);
    const [iamaliveWS, setIamaliveWS]  = useRecoilState(IamaliveWS);
    const [positionsToMute, setPositionsToMute]  = useRecoilState(PositionsToMute);

    let word = ""
    const onWordChange = (newWord)=>{
        if (newWord.length > MaxIamaliveWordLength) {
            setShowWordTooLong(true)
			return
		}
        if (newWord.length == 0) {
            return            
        }
        sendMsgIamalive(iamaliveWS.ws, "WordChange", {Word: newWord})
        setPlayerWord(newWord)
    }
    const appointWord = ()=>{
        if (playerWord.length == 0) {
            return            
        }
        sendMsgIamalive(iamaliveWS.ws, "AppointWord", {})
    }
    const hoverToChange = ()=>{
        if (iamaliveGameState.isAdmin && word != "") {
            setShowWordToCHange(true)
        }
    }
    const leaveHoverToChange = ()=>{
        setShowWordToCHange(false)
    }
    const changeWord = ()=>{
        sendMsgIamalive(iamaliveWS.ws, "ChangeWord", {Position: position})
        setPlayerWord("")
    }


    let containerStyle = {
        height: "100%",
        position: "relative"
    }
    let hasWordInput = false
    let showWordShow = false
    let showWho = false
    let showForWho = false
    if (iamaliveGameState.phase == "choose_word" && iamaliveGameState.positionToChooseWord == position) {
        showWho = true
    }
    if (iamaliveGameState.phase == "choose_word" && iamaliveGameState.positionToAddWord == position) {
        showForWho = true
        if (myposition == iamaliveGameState.positionToChooseWord) {
            hasWordInput = true
        } else {
            showWordShow = true
            word = wordChangeState.word
        }
    } else {
        for (let pos in iamaliveGameState.players) {
            if (pos == position && iamaliveGameState.players[pos].Word != "") {
                showWordShow = true
                word = iamaliveGameState.players[pos].Word
            }
        }
    }
    useEffect(() => {
        if (iamaliveGameState.phase == "choose_word" && iamaliveGameState.positionToAddWord == myposition && !positionsToMute.has(position)) {
            let newSet = new Set(positionsToMute)
            newSet.add(position)
            setPositionsToMute(newSet)
        }
        if (iamaliveGameState.phase == "choose_word" && iamaliveGameState.positionToAddWord != myposition && (!positionsToMute.has(iamaliveGameState.positionToAddWord) || positionsToMute.size > 1)) {
            let newSet = new Set()
            newSet.add(iamaliveGameState.positionToAddWord)
            setPositionsToMute(newSet)
        }
        if (iamaliveGameState.phase != "choose_word" && positionsToMute.size > 0) {
            setPositionsToMute(new Set())
        }
    } , [iamaliveGameState, positionsToMute])
    let wordInputPanelStyle = {
        position: "absolute",
        top: "10px",
        left: "50%",
        transform: "translate(-50%, 0%)",
        zIndex: 492,
    }
    let inputStyle = {
        fontSize: "16px",
    }
    let wordShowPanelStyle = {
        position: "absolute",
        top: "10px",
        left: "50%",
        transform: "translate(-50%, 0%)",
        zIndex: 492,
        padding: "5px 10px",
        backgroundColor: "black",
        opacity: "0.7",
        borderRadius: "6px",
    }
    let showTextStyle = {
        backgroundColor: "black",
        borderRadius: "6px",
        color: "#fff",
        whiteSpace: "normal",
        zIndex: 492,
    }
    let labelButton = {
        position: "absolute",
        bottom: "23px",
        left: "50%",
        transform: "translate(-50%, 0%)",
        zIndex: 191,
    }
    if (myposition == position) {
        word = ""
        showWordShow = false
    }
    let mobile = false
    if (window.matchMedia("(max-width: 899px)").matches && !modal) {
        mobile = true
    }

    const setPreparedWords = ()=>{
        setShowSetPreparedWord(true)
    }
    const handleCloseCreateRoom = ()=>{
        setShowSetPreparedWord(false)
    }
    if (mobile) {
        showTextStyle.fontSize = "6px"
        showTextStyle.padding = "0px"
        showTextStyle.lineHeight = "4px"
        showTextStyle.borderRadius = "0px"
        showTextStyle.overflowWrap = "break-word"
        wordShowPanelStyle.padding = "0px"
        wordShowPanelStyle.lineHeight = "4px"
        wordShowPanelStyle.width = "100%"
        wordShowPanelStyle.top = "-4px"
    }
    return (
        <div style={containerStyle}>
            {hasWordInput ?
            <div style={wordInputPanelStyle}>
                <input type="text" value={playerWord} onChange={e => onWordChange(e.target.value)} style={inputStyle}/><br/>
                <Button variant="contained" color="success" onClick={appointWord}>
                  <FormattedMessage id="appoint" />
                </Button><br/>
                <Button variant="contained" color="success" style={{marginTop: "10px"}} onClick={setPreparedWords}>
                  <FormattedMessage id="prepared_words" />
                </Button>                
            </div> : null}
            {showWordShow && word.length > 0 ?
            <div style={wordShowPanelStyle} onMouseEnter={hoverToChange} onMouseLeave={leaveHoverToChange}>
                <span style={showTextStyle}>{word}</span>
                {showWordToCHange ? 
                    <Button variant="contained" color="success" onClick={changeWord}>
                        <FormattedMessage id="change" />
                    </Button> : null}
            </div> : null}            
            {box}
            {showWho && <Button style={labelButton} variant="contained" color="success"><FormattedMessage id="who" /></Button>}
            {showForWho && <Button style={labelButton} variant="contained" color="success"><FormattedMessage id="for_who" /></Button>}
            <Snackbar open={showWordTooLong} 
				onClose={()=>{setShowWordTooLong(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                sx={{marginBottom: "100px"}}  
				  key={"ImaliveWordTooLong"}>
  				<Alert  severity="error" sx={{ width: '100%', zIndex: 99999 }} key={"ImaliveWordTooLong"}>
				  <FormattedMessage id="word_too_long" />
  				</Alert>
			</Snackbar>
            <Modal
                open={showSetPreparedWord}
                onClose={handleCloseCreateRoom}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
              >
                <Box sx={{ ...ModalStyle, width: 370 }}>
                    <ModalClose onClose={handleCloseCreateRoom}/>
                    <SetPreparedWords iamaliveID={iamaliveID} position={position} onClose={handleCloseCreateRoom}/>
                </Box>
            </Modal>                        
        </div>
    )
}

export default IamaliveGameBox;
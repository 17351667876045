import influxDB from "./client"
import {Point} from '@influxdata/influxdb-client';
import {influxdbORG, influxdbBucket} from "../../environment"

let type = 'navigation'

export const influxDBTest = function() {
    const writeApi = influxDB.getWriteApi(influxdbORG, influxdbBucket)
    // setup default tags for all writes through this API
    writeApi.useDefaultTags({location: 'browser'})
    console.log('\n*** WRITE ***')
    const point1 = new Point('test')
      .tag('type', type)
      .floatField('happened', 1)
    writeApi.writePoint(point1)
    console.log(` ${point1}`)
// flush pending writes and close writeApi
    writeApi
      .close()
      .then(() => {
        console.log('WRITE FINISHED')
      })
      .catch(e => {
        console.log('WRITE FAILED', e)
      })
}

export const influxDBButtonPush = function(userInfo, scope, buttonName) {
  let userID = "anonimous"
  if (userInfo.UserInfo != null) {
    userID = userInfo.UserInfo.UserID
  }
  const writeApi = influxDB.getWriteApi(influxdbORG, influxdbBucket)
  // setup default tags for all writes through this API
  writeApi.useDefaultTags({location: 'browser'})
  console.log('\n*** WRITE ***' + userID)
  const point1 = new Point(type)
    .tag('userid', userID)
    .tag('type', "button")
    .tag('scope', scope)    
    .floatField(buttonName, 1)
  writeApi.writePoint(point1)
  //console.log(` ${point1}`)
// flush pending writes and close writeApi
  writeApi
    .close()
    .then(() => {
      //console.log('WRITE FINISHED')
    })
    .catch(e => {
      console.log('WRITE FAILED', e)
    })
}

export const influxDBLoadPage = function() {
  const writeApi = influxDB.getWriteApi(influxdbORG, influxdbBucket)
  // setup default tags for all writes through this API
  writeApi.useDefaultTags({location: 'browser'})
  console.log('\n*** WRITE ***')
  const point1 = new Point("loadpage")
    .floatField("happened", 1)
  writeApi.writePoint(point1)
  //console.log(` ${point1}`)
// flush pending writes and close writeApi
  writeApi
    .close()
    .then(() => {
      //console.log('WRITE FINISHED')
    })
    .catch(e => {
      console.log('WRITE FAILED', e)
    })
}
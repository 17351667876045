import Api from "../Api";

const joinLobby = async function(gameName, entityID, passwd) {
    return await Api.post("/games/joinLobby", {GameName: gameName, EntityID: entityID, Passwd: passwd})
}

const playLobby = async function(gameName) {
    return await Api.post("/games/playLobby", {GameName: gameName})
}

const listLobbies = async function(gameName, filter) {
    return await Api.post("/lobbies", {GameName: gameName, Filter: filter})
}

const createRoom = async function(roomName, gameName, password) {
    return await Api.post("/games/createRoom", {RoomName: roomName, GameName: gameName, Password: password})
}


const GamesService = {
    joinLobby,
    playLobby,
    listLobbies,
    createRoom,
};

export default GamesService;
import { Button, Container, Modal, Stack, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import Navbar from '../../Navbar/Navbar';
import Borders from "./Borders/Borders"
import Profile from "./Profile/Profile"
import Chats from "./Chats/Chats"



function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`user-tabpanel-${index}`}
        aria-labelledby={`user-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

function a11yProps(index) {
    return {
      id: `user-tab-${index}`,
      'aria-controls': `user-tabpanel-${index}`,
    };
  }



const UserPage = (props)=>{
    const [tabValue, setTabValue] = useState(0);

	const handleChange = (event, newValue) => {
		setTabValue(newValue);
	};
    return (
        <Box
            sx={{
                minHeight: '100vh',
                bgcolor: 'background.default',
                color: 'text.primary',
            }}
        >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                    <Tab label="Сообщения" {...a11yProps(0)} />
                    <Tab label="Информация" {...a11yProps(1)} />
                    <Tab label="Стенки" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <Chats></Chats>                
            </TabPanel>            
            <TabPanel value={tabValue} index={1}>
                <Profile></Profile>                
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <Borders></Borders>                            
            </TabPanel>                                
        </Box>
    )
}

export default UserPage;
import React, {useState} from "react";
import { Stack, Typography, Button, Snackbar, Alert, Modal, Box } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import { FormattedMessage } from 'react-intl';
import CommunitiesService from '../../../services/CommunitiesService';
import {SuccessMessage} from "../../../constants";
import './community-header.scss';
import {CommunityStatus} from "../atoms/Community";
import {useRecoilState} from "recoil";
import {OpenEditCommunity} from "../atoms/Common";
import EditCommunityForm from "../EditCommunityForm/EditCommunityForm"
import ModalStyle from "../../../utils/ModalStyle/ModalStyle"

const CommunityHeader = ({
	id, 
	icon,
	pureIcon,
	name,
	type,
	description,
	countOfUsers,
}) => {
	const [showMadeRequest, setShowMadeRequest] = useState(false);

	const [requestedClan, setRequestedClan] = useState({show: false})
	const [joinedClan, setJoinedClan] = useState({show: false})

	const [communityStatus, setCommunityStatus] = useRecoilState(CommunityStatus);
	const [openEditCommunity, setOpenEditCommunity] = useRecoilState(OpenEditCommunity);

	const handleOpenEditCommunity = ()=>{
		setOpenEditCommunity(true)
	}
	const handleCloseEditCommunity = ()=>{
		setOpenEditCommunity(false)
	}

	const joinToCommunnity = ()=>{
		CommunitiesService.joinRequest(id).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				setShowMadeRequest(true)
				setCommunityStatus("request")
			}
		}).catch((err)=>{
			if (err.response.data.ErrorCode == 2) {
				setRequestedClan({show: true})
			}
			if (err.response.data.ErrorCode == 3) {
				setJoinedClan({show: true})
			}			
		})
	}
	const declineRequest = ()=>{
		CommunitiesService.declineMyRequest(id).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				setCommunityStatus("nothing")
			}
		})
	}
	return (
		<Stack 
			className="community-header"
			direction={{xs: 'column', md: 'row'}}
			spacing={{xs: 2, md: 4}}
		>
			<img src={icon} alt={name} className="community-header__img" />

			<Stack spacing={2}>
				<Typography variant="h4">
					<FormattedMessage id={type}/> {name}
				</Typography>

				<Stack direction="row" alignItems="center" spacing={1}>
					<Typography variant="body2">
						{countOfUsers}
					</Typography>

					<PersonIcon />					
				</Stack>
				<Stack
						direction={{xs: 'column', md: 'row'}}
						alignItems="center"
						spacing={{xs: 2, md: 4}}
					>
						<Typography variant="subtitle1">
							{description}
						</Typography>
						{communityStatus == "nothing" ? 
						<Button variant="contained" size="large" onClick={joinToCommunnity}>
							<FormattedMessage id="join" />
						</Button> : null}
						{communityStatus == "request" ? 
						<Button variant="contained" size="large" onClick={declineRequest}>
							<FormattedMessage id="decline_request" />
						</Button> : null}
						{communityStatus == "member" ? 
						<Button variant="outlined" size="large">
							<FormattedMessage id="you_are_member" />
						</Button> : null}
						{communityStatus == "admin" ? 
						<Button variant="outlined" size="large">
							<FormattedMessage id="you_are_admin" />
						</Button> : null}
						{communityStatus == "owner" ? 
						<Button variant="outlined" size="large">
							<FormattedMessage id="you_are_owner" />
						</Button> : null}
					</Stack>
					{communityStatus == "owner" ? 
					<Stack direction="row" alignItems="center" spacing={1}>
						<Button variant="outlined" size="large" onClick={handleOpenEditCommunity}>
							<FormattedMessage id="edit" />
						</Button>
					</Stack> : null}
			</Stack>
			<Snackbar open={showMadeRequest} 
				onClose={()=>{setShowMadeRequest(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
				  key={"MadeRequest"}>
  				<Alert  severity="info" sx={{ width: '100%' }} key={"MadeRequest"}>
				  <FormattedMessage id="made_request" />
  				</Alert>
			</Snackbar>	
			<Modal
				open={openEditCommunity}
				onClose={handleCloseEditCommunity}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
						<Box sx={{ ...ModalStyle }}>
							<EditCommunityForm c_id={id} c_pureicon={pureIcon} c_icon={icon} c_description={description}/>
				</Box>
			</Modal>
			<Snackbar open={requestedClan.show} 
				onClose={()=>{setRequestedClan({show: false})}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
				  key={"requestedClan"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"requestedClan"}>
				  <FormattedMessage id="requested_clan_cant_create" />
  				</Alert>
			</Snackbar>	
			<Snackbar open={joinedClan.show} 
				onClose={()=>{setJoinedClan({show: false})}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
				  key={"joinedClan"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"joinedClan"}>
				  <FormattedMessage id="joined_clan_cant_create" />
  				</Alert>
			</Snackbar>									
		</Stack>
	);
};

export default CommunityHeader;
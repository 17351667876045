import { Button, Container, Grid, Modal, Stack, Typography, Snackbar, Alert, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import ContentTree from "./ContentTree"
import Navbar from "../Navbar/Navbar"
import ContentPage from "./ContentPage"
import {useParams,} from "react-router-dom";







const KnowledgeBase = () => {
	let { current_page } = useParams();

    return (
        <Box
            sx={{
                minHeight: '100vh',
                bgcolor: 'background.default',
                color: 'text.primary',
            }}
        >
            <Grid container spacing={0} columns={{ xs: 4, sm: 7, md: 7}} paddingX={3} pt={3}>
                <Grid item xs={4} sm={2} md={1}> 
                    <ContentTree currentPage={current_page}/>
                </Grid>
                <Grid item xs={4} sm={5} md={6}>
                    <ContentPage/>
                </Grid>
            </Grid>
        </Box>
    )
}

export default KnowledgeBase;
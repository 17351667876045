import { Button, Grid, TextField, Typography, Snackbar, Alert } from '@mui/material';
import {FormattedMessage} from "react-intl";
import {copyTextToClipboard} from "../../utils/clipboard"
import React, { useState } from 'react';









const ShareLinkMessage = (props)=>{
    const [copied, setCopied] = useState(false)
    const [copyErr, setCopyErr] = useState(false)

    const copyLink = ()=>{
        copyTextToClipboard(props.link, ()=>{setCopied(true)}, ()=>{setCopyErr(true)})
    }
    return (
        <Grid
            container
            rowSpacing={2}
            spacing={{ xs: 1, sm: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            direction="column"
            padding={1}
        >
            <Grid item xs={4} sm={8} md={12}>
                <Typography variant='h6'>
                    <FormattedMessage id="invitational_link" />
                </Typography>			
            </Grid>

            <Grid
				item 
				container
				rowSpacing={1}
				spacing={{ xs: 1, sm: 2 }}
				columns={{ xs: 4, sm: 8, md: 12 }}
			>
				<Grid item xs={4} sm={8} md={12}>
					<Typography variant='body1' style={{ wordWrap: "break-word" }}>
						<FormattedMessage id="link" />: {props.link}
					</Typography>
				</Grid>
			</Grid>

            <Grid item xs={4} sm={8} md={12}>
                <Button variant="contained" color="success" size="large" onClick={copyLink}>
                    <FormattedMessage id="copy_link" />
                </Button>
            </Grid>
            <br/>
            <Grid item xs={4} sm={8} md={12}>
                <Button variant="contained" size="large" onClick={props.onClose}>
                    <FormattedMessage id="understood" />
                </Button>
            </Grid>

            <Snackbar open={copied} 
				onClose={()=>{setCopied(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
                sx={{marginBottom: "100px"}} 
				  key={"copied"}>
  				<Alert  severity="success" sx={{ width: '100%' }} key={"copied"}>
				  <FormattedMessage id="copied" />
  				</Alert>
			</Snackbar>   

            <Snackbar open={copyErr} 
				onClose={()=>{setCopyErr(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                sx={{marginBottom: "100px"}} 
				  key={"copyErr"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"copyErr"}>
				  <FormattedMessage id="copyErr" />
  				</Alert>
			</Snackbar>
        </Grid>
    )
}

export default ShareLinkMessage;
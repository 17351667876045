import React from 'react';
import UserItem from '../UserItem/UserItem';

const RequestToJoinItem = ({
	communityID,
	userID,
	name,
	avatar,
	role,
	myRole,
}) => {
	return (
		<UserItem
			name={name}
			avatar={avatar}
			role={role}
			isRequest={true}
			userID={userID}
			communityID={communityID}
			myRole={myRole}
		/>
	);
};

export default RequestToJoinItem;





const getDeviceColorTheme = () => {
    let deviceSettings = localStorage.getItem('DeviceSettings');
    if (deviceSettings === null) {
      return "light"
    }
    deviceSettings = JSON.parse(deviceSettings)
    return deviceSettings.theme
}

const DeviceService = {
    getDeviceColorTheme
}

export default DeviceService;
import { Button, Stack, Typography, Snackbar, Alert } from '@mui/material';
import { Box, Container } from '@mui/system';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import React, {useState} from 'react';
import {useNavigate} from "react-router-dom"
import RoomService from '../../../services/rooms/RoomService';
import { SuccessMessage } from '../../../constants';
import {FormattedMessage} from "react-intl";
import { useTheme } from '@emotion/react';
import {influxDBButtonPush} from "../../../services/influxdb/navigation"
import {useRecoilState,} from 'recoil';
import { CurrentRoom,  } from '../../atoms/Rooms';

const RoomBar = (props) => {
	const navigate = useNavigate();
	const [showLeaved, setShowLeaved] = useState(false)

	const [currentRoom, setCurrentRoom] = useRecoilState(CurrentRoom)

	const theme = useTheme();

	const goToRoom = () =>{
		influxDBButtonPush(props.userInfo, "roombar", "gotoroom")
		if (props.title == "Roulette") {
			navigate("/roulette")
		} else {
			navigate("/room/"+currentRoom.RoomKey)
		}
	}
	const leaveTheRoom = ()=>{
		influxDBButtonPush(props.userInfo, "roombar", "leave")
		RoomService.leaveRoom().then((response)=>{
			if (response.data.Success == SuccessMessage) {
				setShowLeaved(true)
			}
		})
	}
	return (
		<Box paddingY={2} style={{backgroundColor: theme.palette.mode == 'light' ? 'rgb(229, 246, 253)' : 'rgb(22, 0, 87)'}}>
			<Container maxWidth="xl">
				<Stack spacing={2} direction={{xs: 'column', md: 'row'}} justifyContent="space-between" alignItems="center">
					<Typography variant='body1' color={theme.palette.mode == 'light' ? "grey" : "#90caf9"}>
					<FormattedMessage id="YouAreInTheRoon" />: <b>{props.title}</b>
					</Typography>

					<Stack spacing={2} direction="row">
						<Button 
							variant="text"
							
							onClick={goToRoom}
						>
							<FormattedMessage id="GoTo" />
						</Button>

						<Button 
							variant="text" 
							endIcon={<ExitToAppIcon />}
							onClick={leaveTheRoom}
						>
							<FormattedMessage id="exit_from_room" />
						</Button>
					</Stack>
				</Stack>
			</Container>
			<Snackbar open={showLeaved} autoHideDuration={6000} >
  				<Alert  severity="success" sx={{ width: '100%' }}>
				  <FormattedMessage id="YouLeavedTheRoom" />
  				</Alert>
			</Snackbar>
		</Box>
	)
};

export default RoomBar;
import Webcam from "react-webcam";
import React, {useEffect, useRef, useState} from 'react';
import {FormattedMessage, FormattedNumber} from 'react-intl'
import {
    MyTrackStateStreamReady, ShowVideoAudioSettings, VideoConstraints, AudioConstraints,
    AppliedVideoConstraints, AppliedAudioConstraints, HasNoCameras, UseVideo, UseAudio,
    LivekitComponentUpdateCounter, Preflight, MyMediaStream
} from "../atoms";
import {
    ThemeAtom
} from "../../main/atoms/Common";
import {
    useRecoilState,
} from 'recoil';
import {myGlobalStream} from "../LivekitComponent"
import { Button, Grid, Stack, Typography, Switch } from '@mui/material';


var videoProcessError = false

function handleError(error) {
    if (error.name == "NotAllowedError") {
        videoProcessError = true
    }
    console.log('ASASAS navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
}

function handleErrorEnumerate(error) {
    console.log('ASASAS Enumerate navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
}


function VideoAudioSettings(props) {
    const [myTrackStateStreamReady, setMyTrackStateStreamReady] = useRecoilState(MyTrackStateStreamReady);
    const [showVideoAudioSettings, setShowVideoAudioSettings] = useRecoilState(ShowVideoAudioSettings);
    const [videoConstraints, setVideoConstraints] = useRecoilState(VideoConstraints);
    const [audioConstraints, setAudioConstraints] = useRecoilState(AudioConstraints);
    const [appliedVideoConstraints, setAppliedVideoConstraints] = useRecoilState(AppliedVideoConstraints);
    const [appliedAudioConstraints, setAppliedAudioConstraints] = useRecoilState(AppliedAudioConstraints);
    const [hasNoCameras, setHasNoCameras] = useRecoilState(HasNoCameras)
    const [useVideo, setUseVideo] = useRecoilState(UseVideo);
    const [useAudio, setUseAudio] = useRecoilState(UseAudio);
    const [livekitComponentUpdateCounter, setLivekitComponentUpdateCounter] = useRecoilState(LivekitComponentUpdateCounter)
    const [preflight, setPreflight] = useRecoilState(Preflight);
    const [myMediaStream, setMyMediaStream] = useRecoilState(MyMediaStream);

    
    const [theme, _] = useRecoilState(ThemeAtom);

    const [webcamKey, setWebcamKey] = useState(1)
    const [cantStartSource, setCantStartSource] = useState(false)

    const webcamRef = useRef(null);
    const audioRef = useRef(null);

    const videoElement = useRef(null);
    const audioInputSelect = useRef(null);
    const videoSelect = useRef(null);
    const selectors = [audioInputSelect, videoSelect];

    useEffect(()=> {
        let audioConstraints = localStorage.getItem('AudioConstraints');
        if (audioConstraints !== null) {
            setAudioConstraints({constraints: JSON.parse(audioConstraints)})
        }
        let videoConstraints = localStorage.getItem('VideoConstraints');
        if (videoConstraints !== null) {
            console.log("ASASAS PLAIN RENDER SET setVideoConstraints")
            setVideoConstraints({constraints: JSON.parse(videoConstraints)})
        }
    }, []);

    const play = function() {
        if (window.stream) {
            window.stream.getTracks().forEach(track => {
                track.stop();
            });
        }
        const audioSource = audioInputSelect.current.value;
        const videoSource = videoSelect.current.value;
        const constraints = {
            audio: {deviceId: audioSource ? {exact: audioSource} : false},
            video: {deviceId: videoSource ? {exact: videoSource} : false},
            // onMicrophoneGranted,
            // onMicrophoneDenied
        };
        console.log("videoSelect = ", videoSource);
        console.log("audioSource = ", audioSource);
        console.log("ASASAS constraints", constraints)
        if (!constraints.video.deviceId || constraints.video.deviceId.exact.includes("need_allow_camera")) {
            constraints.video = false
        }
        if (constraints.video !== videoConstraints.constraints) {
            console.log("ASASAS SETTINGS TEST videoConstraints.constraints SET ", videoSource, constraints.video, videoConstraints.constraints)
            if (videoSource) {
                setVideoConstraints({constraints: constraints.video})
            } else if (videoConstraints.constraints) {
                setVideoConstraints({constraints: false})
            }
        }
        if (constraints.audio != audioConstraints.constraints) {
            setAudioConstraints({constraints: constraints.audio})
        }

        navigator.mediaDevices.getUserMedia(constraints).then(changeStream).then(gotDevices).catch(handleError);
    }
    console.log("ASASAS PLAIN RENDER")
    const gotDevices = function(deviceInfos) {
        // Handles being called several times to update labels. Preserve values.
        if (!audioInputSelect || !videoSelect || audioInputSelect == null || audioInputSelect.current == null || videoSelect.current == null || videoSelect == null || !deviceInfos) {
            return;
        }
        const values = selectors.map(select => select.current != null && select.current.value);
        console.log("values", values, videoConstraints.constraints);
        // ? Set current device as current value of selects
        if (values.length == 2 && videoConstraints.constraints && videoConstraints.constraints.deviceId && videoConstraints.constraints.deviceId.exact && 
            values[1] != videoConstraints.constraints.deviceId) {
            values[1] = videoConstraints.constraints.deviceId.exact
        }
        if (values.length == 2 && audioConstraints.constraints && audioConstraints.constraints.deviceId && audioConstraints.constraints.deviceId.exact && 
            values[0] != audioConstraints.constraints.deviceId) {
            values[0] = audioConstraints.constraints.deviceId.exact
        }
        // ? Clean previous lists
        selectors.forEach(select => {
            if (select.current == null) {
                return
            }
            while (select.current.firstChild) {
                select.current.removeChild(select.current.firstChild);
            }
        });
        let hasAudio = 0
        let hasVideo = 0
        for (let i = 0; i !== deviceInfos.length; ++i) {
            const deviceInfo = deviceInfos[i];
            const option = document.createElement('option');
            option.value = deviceInfo.deviceId;
            if (deviceInfo.kind === 'audioinput') {
                option.text = deviceInfo.label || `need_allow_microphone ${audioInputSelect.current.length + 1}`;
                audioInputSelect.current.appendChild(option);
                if (deviceInfo.label) {
                    hasAudio = true
                }
            } else if (deviceInfo.kind === 'videoinput') {
                option.text = deviceInfo.label || `need_allow_camera ${videoSelect.current.length + 1}`;
                videoSelect.current.appendChild(option);
                // if (deviceInfo.label) {
                //     hasVideo = true
                // }
                hasVideo = true
            } else if (deviceInfo.kind !== 'audiooutput') {
                console.log('Some other kind of source/device: ', deviceInfo);
            }
        }
        if (!hasVideo && videoConstraints.constraints) {
            console.log("ASASAS SETTINGS TEST  !hasVideo videoConstraints.constraints", videoConstraints.constraints)
            setVideoConstraints({constraints: false})
            if (appliedVideoConstraints.constraints) {
                setAppliedVideoConstraints({constraints: false})
            }
            localStorage.setItem('VideoConstraints', JSON.stringify(false))
            setWebcamKey(webcamKey + 1)
        } else if (hasVideo && !videoConstraints.constraints) {
            console.log("ASASAS SETTINGS TEST  videoConstraints.constraints", videoConstraints.constraints)
            setVideoConstraints({constraints: videoSelect.current.value ? {exact: videoSelect.current.value} : false})
            setWebcamKey(webcamKey + 1)
            play()
        }
        selectors.forEach((select, selectorIndex) => {
            if (Array.prototype.slice.call(select.current.childNodes).some(n => n.value === values[selectorIndex])) {
                select.current.value = values[selectorIndex];
            }
        });

        if (!videoProcessError && !videoConstraints.constraints && (!audioRef || !audioRef.current || !audioRef.current.stream)) {
            console.log("ASASAS SETTINGS TEST play gotDevices")
            play()
        }
       // play() - Need to run one time
    }
    const colorPids = function(vol) {
        const allPids = [...document.querySelectorAll('.pid')];
        const numberOfPidsToColor = Math.round(vol / 10);
        const pidsToColor = allPids.slice(0, numberOfPidsToColor);
        for (const pid of allPids) {
            pid.style.backgroundColor = "#e6e7e8";
        }
        for (const pid of pidsToColor) {
            // console.log(pid[i]);
            pid.style.backgroundColor = "#69ce2b";
        }
    }
    const onMicrophoneDenied = function() {
        console.log('microphone denied')
    }
    const onMicrophoneGranted = async function(stream) {
        // Instanciate just in the first time
        // when button is pressed
        // Initialize AudioContext object
        const audioContext = new AudioContext()
        // Adding an AudioWorkletProcessor
        // from another script with addModule method
        await audioContext.audioWorklet.addModule('vumeter-processor.js').catch((err) => { console.log("VUMETERerr", err); })

        // Creating a MediaStreamSource object
        // and sending a MediaStream object granted by
        // the user
        let microphone = audioContext.createMediaStreamSource(stream)

        // Creating AudioWorkletNode sending
        // context and name of processor registered
        // in vumeter-processor.js
        const node = new AudioWorkletNode(audioContext, 'vumeter')

        // Listing any message from AudioWorkletProcessor in its
        // process method here where you can know
        // the volume level
        node.port.onmessage  = event => {
            let _volume = 0
            let _sensibility = 500 // Just to add any sensibility to our ecuation
            if (event.data.volume)
                _volume = event.data.volume;
            colorPids((_volume * 100) / _sensibility)
        }

        // Now this is the way to
        // connect our microphone to
        // the AudioWorkletNode and output from audioContext
        microphone.connect(node).connect(audioContext.destination)


        // // Just to know if button is on or off
        // // and stop or resume the microphone listening
        // let audioButton = document.getElementsByClassName('audio-control')[0]
        // if (listeing) {
        //     audioContext.suspend()
        //     audioButton.style.boxShadow = "-2px -2px 4px 0px #a7a7a73d, 2px 2px 4px 0px #0a0a0e5e"
        //     audioButton.style.fontSize = "25px"
        // } else {
        //     audioContext.resume()
        //     audioButton.style.boxShadow = "5px 2px 5px 0px #0a0a0e5e inset, -2px -2px 1px 0px #a7a7a73d inset"
        //     audioButton.style.fontSize = "24px"
        // }
        //
        // listeing = !listeing
    }
    const audioLevel = function(stream) {
        //console.log("audioLevel 1")
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;

        microphone.connect(analyser);
        analyser.connect(scriptProcessor);
        scriptProcessor.connect(audioContext.destination);
        scriptProcessor.onaudioprocess = function() {
            const array = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(array);
            const arraySum = array.reduce((a, value) => a + value, 0);
            const average = arraySum / array.length;
           // console.log(Math.round(average));
            colorPids(average);
        };
     //   console.log("audioLevel 2")
    }
    const changeStream = function(stream){
        if (!webcamRef || !webcamRef.current) {
            if (audioRef && audioRef.current) {
                console.log("ASASAS change stream audioRef")
                audioRef.current.stream = stream
            }
        } else {
            console.log("ASASAS change stream webcamRef")
            webcamRef.current.stream = stream
        }
        console.log("ASASAS change stream")
        //myGlobalStream = webcamRef.current.stream;// stream;
        audioLevel(stream)
        let newTrackState = Object.assign({}, myTrackStateStreamReady);
        newTrackState.streamReady = true;
        setMyTrackStateStreamReady(newTrackState);
    }


    const selectorItem = {
        padding: "5px",
    }
    const audioPidsContainerStyle = {
        width: "100%",
        //height: "10px",
    }
    const pidStyle = {
        width: "calc(10% - 10px)",
        height: "10px",
        display: "inline-block",
        margin: "5px",
        backgroundColor: "#D3D3D3",
    }
    navigator.mediaDevices.enumerateDevices().then(gotDevices).catch(handleErrorEnumerate);

    const handleUserMedia = ()=>{
        console.log("handleUserMedia");
        setHasNoCameras({hasNoCameras: false})
        setCantStartSource(false)
        console.log("ASASAS SETTINGS TEST play() handleUserMedia")
        play()
    }
    const save = ()=>{
        console.log("ASASAS test save constraints", videoConstraints.constraints, audioConstraints.constraints)
        setAppliedVideoConstraints({constraints: videoConstraints.constraints})
        setAppliedAudioConstraints({constraints: audioConstraints.constraints})
        console.log("ASASAS save constraints 2")
        localStorage.setItem('AudioConstraints', JSON.stringify(audioConstraints.constraints))
        localStorage.setItem('VideoConstraints', JSON.stringify(videoConstraints.constraints))
        console.log("ASASAS save constraints 3")
        setShowVideoAudioSettings({show: false})
        // if (!useVideo && props.livekitRoomGlobal && props.livekitRoomGlobal.localParticipant.videoTracks.size > 0) {
        //     props.livekitRoomGlobal.localParticipant.unpublishTrack(props.livekitRoomGlobal.localParticipant.videoTracks.get(Array.from(props.livekitRoomGlobal.localParticipant.videoTracks.keys())[0]).track)
        // }
        // if (!useAudio && props.livekitRoomGlobal && props.livekitRoomGlobal.localParticipant.audioTracks.size > 0) {
        //     props.livekitRoomGlobal.localParticipant.unpublishTrack(props.livekitRoomGlobal.localParticipant.audioTracks.get(Array.from(props.livekitRoomGlobal.localParticipant.audioTracks.keys())[0]).track)
        // }
        //if (!preflight) {
            setLivekitComponentUpdateCounter(livekitComponentUpdateCounter + 1)
       // }
        setPreflight(false)
        // window.location.reload(false);
        console.log("ASASAS test save END")
    }

    const onEr = (err)=>{
        console.log("ASASAS OnDeviceError: ", err, videoConstraints.constraints);
        let errorMsg = err + ""
        if (errorMsg.includes("Requested device not found")) {
            setHasNoCameras({hasNoCameras: true})
        }
        if (errorMsg.includes("Could not start video source")) {
            setCantStartSource(true)
        }
        // if (videoConstraints.constraints) {
        //     setVideoConstraints({constraints: false})
        //     if (appliedVideoConstraints.constraints) {
        //         setAppliedVideoConstraints({constraints: false})
        //     }
        //     localStorage.setItem('VideoConstraints', JSON.stringify(videoConstraints.constraints))
        //     setWebcamKey(webcamKey + 1)
        // }
    }
    const switchUseVideo = (e)=>{
		setUseVideo(e.target.checked);
    }
    const switchUseAudio = (e)=>{
		setUseAudio(e.target.checked);
    }
    return (
		<Stack
			direction="column"
			spacing={1}
            width="100%"
            alignItems="center"
		>
            	<Typography variant='h4'>
				    <FormattedMessage id="videoAudioSettings" />
			    </Typography>
                <div>
                    {videoConstraints.constraints ? <Webcam
                        ref={webcamRef}
                        key={webcamKey}
                        width={props.width}
                        height={props.height}
                        mirrored={true}
                        style={{
                            // width: props.width,
                            // height: props.height,
                            // position: "absolute",
                            // left: "50%",
                            // transform: "translate(-50%, 0%)",
                            // top: "80px",
                             borderRadius: "25px",
                        }}                        
                        onUserMediaError={onEr}
                        onUserMedia={handleUserMedia}
                        videoConstraints={videoConstraints.constraints}
                        audioConstraints={audioConstraints.constraints}
                    /> :   <audio ref={audioRef}></audio>
                }
                </div>
                {hasNoCameras.hasNoCameras ? <div><FormattedMessage id="hasNoCameras"/></div> : null}
                {cantStartSource ? <div><FormattedMessage id="device_not_ready_or_busy"/></div> :  null}
                <div>
                    <div style={selectorItem}>
                        <label><FormattedMessage id="camera"/>: </label><select onChange={play} ref={videoSelect}></select>
                    </div>
                    <div style={selectorItem}>
                        <label><FormattedMessage id="microphone"/>: </label><select onChange={play} ref={audioInputSelect}></select>
                    </div>
                    <div style={audioPidsContainerStyle} className="pids-wrapper">
                        <div style={pidStyle} className="pid"></div>
                        <div style={pidStyle} className="pid"></div>
                        <div style={pidStyle} className="pid"></div>
                        <div style={pidStyle} className="pid"></div>
                        <div style={pidStyle} className="pid"></div>
                        <div style={pidStyle} className="pid"></div>
                        <div style={pidStyle} className="pid"></div>
                        <div style={pidStyle} className="pid"></div>
                        <div style={pidStyle} className="pid"></div>
                        <div style={pidStyle} className="pid"></div>
                    </div>
                </div>
                {preflight && <Typography variant='body1' sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}><FormattedMessage id="game_without_cam" /></Typography>}
				<Grid					 
					container
					rowSpacing={1}
					spacing={{ xs: 1, sm: 2 }}
					columns={{ xs: 4, sm: 8, md: 12 }}
				>
					<Grid item xs={3} sm={4} md={6}>
						<Typography variant='body1'>
							<FormattedMessage id="use_video" />:
						</Typography>
					</Grid>

					<Grid item xs={1} sm={4} md={6}>
						<Switch  
							checked={useVideo}
							onChange={switchUseVideo}
						/>
					</Grid>
				</Grid> 
				{!preflight && <Grid					 
					container
					rowSpacing={1}
					spacing={{ xs: 1, sm: 2 }}
					columns={{ xs: 4, sm: 8, md: 12 }}
				>
					<Grid item xs={3} sm={4} md={6}>
						<Typography variant='body1'>
							<FormattedMessage id="use_audio" />:
						</Typography>
					</Grid>

					<Grid item xs={1} sm={4} md={6}>
						<Switch  
							checked={useAudio}
							onChange={switchUseAudio}
						/>
					</Grid>
				</Grid>}                               
                <Button variant="contained" size="large" onClick={save}>
				    {preflight ? <FormattedMessage id="ready_o" /> : <FormattedMessage id="save" />}
			    </Button>
        </Stack>
    )
}

export default VideoAudioSettings;

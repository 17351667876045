import {
	useRecoilState,
  } from 'recoil';
import { Box, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { RoundPhase } from '../atoms/GameConfiguration';
import { HackwordWS } from '../atoms/Connection';
import { TrendingUpOutlined } from '@mui/icons-material';
import { sendMsgHackword } from '../HackwordGameController';
import {MyInfo} from "../../../../../livekit_prod/atoms";

const Word = ({
	className,
	active,
	children,
	guessed
}) => {
	const [roundPhase, setRoundPhase]  = useRecoilState(RoundPhase);
	const [hackwordWS, setHackwordWS]  = useRecoilState(HackwordWS);
    const [myInfo, setMyInfo] = useRecoilState(MyInfo);

	const changePoints = (toGuessed)=>{
		if (myInfo.role == "spectator") {
			return
		}
		if (roundPhase.Phase != "running") {
			sendMsgHackword(hackwordWS.ws, "ChangePoints", {Word: children, ToGuessed: toGuessed,})
		}
	}

	return (
		<Stack className={className} direction="row" spacing={1} alignItems="center">
			<Typography variant="body">
				{children}
			</Typography>

			<Stack direction="row">
				{active ? (
					<>
					{guessed ?
					<> 
						<IconButton aria-label="up" size="small">
							<ThumbUpIcon color="success" />
						</IconButton>

						<IconButton aria-label="down" size="small">
							<ThumbDownIcon color="action" />
						</IconButton>
					</>
					: 
					<> 					
					<IconButton aria-label="up" size="small">
						<ThumbUpIcon  color="action" />
					</IconButton>

					<IconButton aria-label="down" size="small">
						<ThumbDownIcon color="error"/>
					</IconButton>
					</>
					}
					</>
				):
				(
					<>
					{guessed ?
					<>
					<Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '34px', height: '34px', cursor: "pointer"}}>
						<ThumbUpIcon color="success" />
					</Box>

					<Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '34px', height: '34px', cursor: "pointer"}}>
						<ThumbDownIcon color="action" onClick={(e)=>changePoints(false)}/>
					</Box>
					</> :
					<>
					<Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '34px', height: '34px', cursor: "pointer"}}>
						<ThumbUpIcon color="action" onClick={(e)=>changePoints(true)}/>
					</Box>

					<Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '34px', height: '34px', cursor: "pointer"}}>
						<ThumbDownIcon color="error"/>
					</Box>
					</> 
					}
					</>
				)}
				
			</Stack>
		</Stack>
	);
};

export default Word;
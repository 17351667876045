import React, { useState, useEffect } from 'react';
import {GeneralizeWS} from "../atoms/Connection"
import {GeneralizeGameState} from "../atoms/GameState"
import {sendMsgGeneralize} from "../GeneralizeGameController"
import { Button, Grid, Stack, Switch, Typography } from '@mui/material';
import {FormattedMessage} from "react-intl";
import {useRecoilState} from "recoil";






function AllowNewPlayersDialog(props) {
	const [generalizeWS, setGeneralizeWS] = useRecoilState(GeneralizeWS);

    const allowAndStart = ()=>{
        sendMsgGeneralize(generalizeWS.ws, "AllowNewPlayers", {Allow: true})
		sendMsgGeneralize(generalizeWS.ws, "StartGame", {})
        props.onClose()
    }
    const disallowAndStart = ()=>{
        sendMsgGeneralize(generalizeWS.ws, "AllowNewPlayers", {Allow: false})
		sendMsgGeneralize(generalizeWS.ws, "StartGame", {})
        props.onClose()
    }
    return (
        <Grid
            container
            rowSpacing={2}
            spacing={{ xs: 1, sm: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            direction="column"
            padding={1}
        >
            <Grid item xs={4} sm={8} md={12}>
				<Typography variant='h6'>
					<FormattedMessage id="allow_new_players_dialog" />
				</Typography>
			</Grid>

            <Grid
					item 
					container
					rowSpacing={1}
					spacing={{ xs: 1, sm: 2 }}
					columns={{ xs: 4, sm: 8, md: 12 }}
				>
                    <Grid item xs={4} sm={4} md={6}>
                        <Button variant="contained" size="large" onClick={allowAndStart}>
					        <FormattedMessage id="allow" />
				        </Button>
                    </Grid>
                    <Grid item xs={4} sm={4} md={6}>
                        <Button variant="contained" size="large" onClick={disallowAndStart}>
					        <FormattedMessage id="disallow" />
				        </Button>                        
                    </Grid>                    
            </Grid>
        </Grid>
    )
}

export default AllowNewPlayersDialog;
import { Avatar, Box, Stack, Typography } from '@mui/material';
import React from 'react';
import './chat-message.scss';

const ChatMessage = ({
	self,
	userName,
	message,
	time,
	avatar
}) => {
	return (
		<Stack 
			spacing={2} 
			direction={`${self ? 'row-reverse' : 'row'}`} 
			className={`chat-message ${self ? 'chat-message__reverse' : ''}`}
		>
			<Avatar alt={userName} src={avatar} />
			
			<Stack spacing={0.5} alignItems={`${self ? 'flex-end' : 'flex-start'}`}>
				<Box className="chat-message__body">
					<Typography variant='body2'>
						{message}
					</Typography>
				</Box>				
				
				<Typography variant='caption' color="grey">
					{time}
				</Typography>
			</Stack>
		</Stack>
	)
};

export default ChatMessage;

import {
	useRecoilState,
  } from 'recoil';
import { Button, FormControl, Grid, MenuItem, Select, Slider, Stack, Switch, Typography } from '@mui/material';
import {FormattedMessage} from "react-intl";
import React, { useState, useEffect } from 'react';
import {GeneralizeDefaultSecondsForLearning, GeneralizeDefaultSecondsForWordANDNumber, GeneralizeDefaultSecondsForGuessing} from "../../../../../constants"
import {GeneralizeWS} from "../atoms/Connection"
import {GeneralizeGameState} from "../atoms/GameState"
import {sendMsgGeneralize} from "../GeneralizeGameController"



function GameSettings(props) {
    const [generalizeWS, setGeneralizeWS]  = useRecoilState(GeneralizeWS);
    const [generalizeGameState, setGeneralizeGameState] = useRecoilState(GeneralizeGameState);

    const [learningSeconds, setLearningSeconds] = useState(generalizeGameState.SecondsForLearning);
    const [makeWordSeconds, setMakeWordSeconds] = useState(generalizeGameState.SecondsForWordAndNumber);
    const [guessingSeconds, setGuessingSeconds] = useState(generalizeGameState.SecondsForGuessing);
	const [startNew, setStartNew] = useState(false);
	const [shufflePlayers, setShufflePlayers] = useState(false);
	const [onlyOneTeam, setOnlyOneTeam] = useState(false);
	const [allowNewPlayers, setAllowNewPlayers] = useState(generalizeGameState.AllowNewPlayers);

	useEffect(()=> {
		setLearningSeconds(generalizeGameState.SecondsForLearning)
		setMakeWordSeconds(generalizeGameState.SecondsForWordAndNumber)
		setGuessingSeconds(generalizeGameState.SecondsForGuessing)
	}, [generalizeGameState])

    useEffect(()=> {
		setOnlyOneTeam(generalizeGameState.ForcedOneTeam)
    }, [generalizeGameState]);

	const switchStartNew = (event) => {
		setStartNew(event.target.checked);
	}
	const switchShufflePlayers = (event) => {
		setShufflePlayers(event.target.checked);
	}
    const switchOnlyOneTeam = (event) => {
		setOnlyOneTeam(event.target.checked);
	}

    const learningSecondsHandler = (e, value)=>{
		setLearningSeconds(value);
    }
    const makeWordSecondsHandler = (e, value)=>{
        setMakeWordSeconds(value)
    }
    const guessingSecondsHandler = (e, value)=>{
        setGuessingSeconds(value)
    }
    
    const applyChanges = (e)=>{
        sendMsgGeneralize(generalizeWS.ws, "SetSettings", {LearningSeconds: learningSeconds, MakeWordSeconds: makeWordSeconds, GuessingSeconds: guessingSeconds,
        StartNew: startNew, ShufflePlayers: shufflePlayers, OnlyOneTeam: onlyOneTeam, AllowNewPlayers: allowNewPlayers})
		props.OnCloseModal()
    }

	const switchAllowNewPlayers = (e)=>{
		setAllowNewPlayers(e.target.checked)
	}

    return (
		<Grid
			container
			rowSpacing={2}
			spacing={{ xs: 1, sm: 2 }}
			columns={{ xs: 4, sm: 8, md: 12 }}
			direction="column"
			padding={1}
		>
			<Grid item xs={4} sm={8} md={12}>
				<Typography variant='h4'>
					<FormattedMessage id="settings" />
				</Typography>
			</Grid>

			{generalizeGameState.Phase != "picking" && <Grid
					item 
					container
					rowSpacing={1}
					spacing={{ xs: 1, sm: 2 }}
					columns={{ xs: 4, sm: 8, md: 12 }}
				>
					<Grid item xs={3} sm={4} md={6}>
						<Typography variant='body1'>
							<FormattedMessage id="start_over" />:
						</Typography>
					</Grid>

					<Grid item xs={1} sm={4} md={6}>
						<Switch 
							checked={startNew}
							onChange={switchStartNew} 
						/>
					</Grid>
			    </Grid>}

            <Grid
					item 
					container
					rowSpacing={1}
					spacing={{ xs: 1, sm: 2 }}
					columns={{ xs: 4, sm: 8, md: 12 }}
				>
					<Grid item xs={4} sm={4} md={6}>
						<Typography variant='body1'>
							<FormattedMessage id="settings_time_learinig" />:
						</Typography>
					</Grid>

					<Grid item xs={4} sm={4} md={6}>
						<Stack direction="row" spacing={2} alignItems="center">
							<Slider 
								disabled={generalizeGameState.Phase != "picking" && generalizeGameState.Phase != "ended" && !startNew}
								aria-label="Секунд" 
								value={learningSeconds} 
								min={60} 
								max={240}
								step={10}
								onChange={learningSecondsHandler}
							/>

							<Typography variant='body1'>{learningSeconds}</Typography>
						</Stack>
					</Grid>
				</Grid>

                <Grid
					item 
					container
					rowSpacing={1}
					spacing={{ xs: 1, sm: 2 }}
					columns={{ xs: 4, sm: 8, md: 12 }}
				>
					<Grid item xs={4} sm={4} md={6}>
						<Typography variant='body1'>
							<FormattedMessage id="settings_time_word_number" />:
						</Typography>
					</Grid>

					<Grid item xs={4} sm={4} md={6}>
						<Stack direction="row" spacing={2} alignItems="center">
							<Slider 
								disabled={generalizeGameState.Phase != "picking" && generalizeGameState.Phase != "ended" && !startNew}
								aria-label="Секунд" 
								value={makeWordSeconds} 
								min={30} 
								max={210}
								step={10}
								onChange={makeWordSecondsHandler}
							/>

							<Typography variant='body1'>{makeWordSeconds}</Typography>
						</Stack>
					</Grid>
				</Grid>

            <Grid
					item 
					container
					rowSpacing={1}
					spacing={{ xs: 1, sm: 2 }}
					columns={{ xs: 4, sm: 8, md: 12 }}
				>
					<Grid item xs={4} sm={4} md={6}>
						<Typography variant='body1'>
							<FormattedMessage id="settings_time_guessing" />:
						</Typography>
					</Grid>

					<Grid item xs={4} sm={4} md={6}>
						<Stack direction="row" spacing={2} alignItems="center">
							<Slider 
								disabled={generalizeGameState.Phase != "picking" && generalizeGameState.Phase != "ended" && !startNew}
								aria-label="Секунд" 
								value={guessingSeconds} 
								min={15} 
								max={180}
								step={5}
								onChange={guessingSecondsHandler}
							/>

							<Typography variant='body1'>{guessingSeconds}</Typography>
						</Stack>
					</Grid>
				</Grid>                                

                <Grid
					item 
					container
					rowSpacing={1}
					spacing={{ xs: 1, sm: 2 }}
					columns={{ xs: 4, sm: 8, md: 12 }}
				>
					<Grid item xs={3} sm={4} md={6}>
						<Typography variant='body1'>
							<FormattedMessage id="only_one_team" />:
						</Typography>
					</Grid>

					<Grid item xs={1} sm={4} md={6}>
						<Switch 
                        	disabled={generalizeGameState.Phase != "picking" && generalizeGameState.Phase != "ended" && !startNew}
							checked={onlyOneTeam}
							onChange={switchOnlyOneTeam} 
						/>
					</Grid>
			    </Grid>

				<Grid
					item 
					container
					rowSpacing={1}
					spacing={{ xs: 1, sm: 2 }}
					columns={{ xs: 4, sm: 8, md: 12 }}
				>
					<Grid item xs={3} sm={4} md={6}>
						<Typography variant='body1'>
							<FormattedMessage id="allow_new_players_dialog" />:
						</Typography>
					</Grid>

					<Grid item xs={1} sm={4} md={6}>
						<Switch
							checked={allowNewPlayers}
							onChange={switchAllowNewPlayers} 
						/>
					</Grid>
				</Grid>									

				<Grid
					item 
					container
					rowSpacing={1}
					spacing={{ xs: 1, sm: 2 }}
					columns={{ xs: 4, sm: 8, md: 12 }}
				>
					<Grid item xs={3} sm={4} md={6}>
						<Typography variant='body1'>
							<FormattedMessage id="shuffle_players" />:
						</Typography>
					</Grid>

					<Grid item xs={1} sm={4} md={6}>
						<Switch
							disabled={generalizeGameState.Phase != "picking" && generalizeGameState.Phase != "ended" && !startNew}
							checked={shufflePlayers}
							onChange={switchShufflePlayers} 
						/>
					</Grid>
				</Grid>	


			<Grid item xs={4} sm={8} md={12}>
				<Button variant="contained" size="large" onClick={applyChanges}>
					<FormattedMessage id="Apply" />
				</Button>
			</Grid>
		</Grid>            
    )
}

export default GameSettings






//export const domainNameAPI = "localhost"
//export const domainNameAPI = "192.168.56.1"
// export const apiPort = ":8088"
// export const staticAPIPort = ":8088"
export const protocolAPI = "https://"

export const influxdbORG = "mnogokomnat"
export const influxdbBucket = "web_ui"
export const apiPort = ":443"
export const staticAPIPort = ":443"

// Dev
// export const influxdbToken = "49e3u-qbjBNXXLxJ2_zyTmuvgmHoEkdlhSNSgCYuky0-SuZ5J-fabCzewOYVYtcPAY5RAX3Ie9KsK83DWrEbaw=="
// export const vkRegisterRedirectUrl = "https://dev.mnogokomnat.ru/getVKCodeToRegisterLogin"
// export const influxdbURL = "https://dev.mnogokomnat.ru:8087"
// export const domainNameAPI = "dev.mnogokomnat.ru"
// export const domainNameStaticAPI = "dev.mnogokomnat.ru"

// Prod
export const influxdbToken = "0OZZtuN-BfmZQxYxZ29NE4F1w9nY_Sh83hr6-goi-UFGrqgOjzje78ASqDA8KiN8dKvor9avr1EXQ3RlWbJeIQ=="
export const vkRegisterRedirectUrl = "https://mnogokomnat.ru/getVKCodeToRegisterLogin"
export const vkLoginRedirectUrl = "https://mnogokomnat.ru/getVKCodeToLogin"
export const influxdbURL = "https://mnogokomnat.ru:8087"
export const domainNameAPI = "mnogokomnat.ru"
export const domainNameStaticAPI = "mnogokomnat.ru"
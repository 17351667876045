import {
    atom,
} from 'recoil';


export const RouletteSettings = atom({
    key: 'RouletteSettings',
    default: {
        Find: false,
        Type: "unset",
        YourSex: "NoMatter",
        MateSex: "NoMatter",
        YourAge: "NoMatter",
        MateAge: "NoMatter",
        EighteenPlus: false,
    },
})

export const RoulettesWSOpened = atom({
    key: 'RoulettesWSOpened',
    default: {
        opened: false,
    },
})

export const RoulettesWS = atom({
    key: 'RoulettesWS',
    default: {
        ws: null,
    },
})

export const OnRoulettePage = atom({
    key: 'OnRoulettePage',
    default: false,
})

export const RouletteWSRestartTimeout = atom({
    key: 'RouletteWSRestartTimeout',
    default: {
        timeout: 0,
    },
})

export const RouletteMateInfo = atom({
    key: 'RouletteMateInfo',
    default: {
        Sex: "Female",
        Age: "Young",
        UserID: 0,
        Nickname: "<not available>",
    },
})

export const RouletteChatHistory = atom({
    key: 'RouletteChatHistory',
    default: []
})
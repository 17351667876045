import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography, Slider } from '@mui/material';
import React, {useState} from 'react';
import { FormattedMessage } from 'react-intl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment'
import {convertLocalToUTCDateMomentJS} from "../../../utils/DateUtils"
import CommunitiesService from '../../../services/CommunitiesService';
import {SuccessMessage} from "../../../constants";
import { useLocation } from "react-router-dom";
import {useRecoilState} from "recoil";
import {OpenCreateEvent} from "../atoms/Common";
import {MaxEventNameLength, MaxEventDescriptionLength} from "../../../constants"
import { ruRU } from '@mui/x-date-pickers/locales';


const CreateEventForm = ({communityID}) => {
	const [eventType, setEventType] = useState('talk');
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
    const [datetime, setDatetime] = useState(moment());
	const [maxUsersCount, setMaxUsersCount] = useState(8);
	const [nameError, setNameError] = useState(null)
	const [descriptionError, setDescriptionError] = useState(null)

	const [openCreateEvent, setOpenCreateEvent] = useRecoilState(OpenCreateEvent);

	const location = useLocation();

	const changeMaxUsersCount = (event, newValue) => {
		setMaxUsersCount(newValue);
  	};
	const handleChangeType = (event) => {
		setEventType(event.target.value);
	};
	const changeName = (e)=>{		
		if (e.target.value.length > MaxEventNameLength) {
			setNameError(<span><FormattedMessage id="cantbelonger"/> {MaxEventNameLength}</span>)
			return 
		}
		setName(e.target.value)
		setNameError(null)
	}
	const changeDescription = (e)=>{
		if (e.target.value.length > MaxEventDescriptionLength) {
			setDescriptionError(<span><FormattedMessage id="cantbelonger"/> {MaxEventDescriptionLength}</span>)
			return 
		}
		setDescription(e.target.value)
		setDescriptionError(null)
	}
	const changeDateTime = (newValue)=>{
		setDatetime(newValue)
	}
	const createEvent = ()=>{
		// communityID, name, eventType, datetime, description, maxUsers
		CommunitiesService.createEvent(parseInt(communityID), name, eventType, convertLocalToUTCDateMomentJS(datetime), description, maxUsersCount).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				setOpenCreateEvent(false)
			}
		})
	}
	return (
		<Grid
			container
			rowSpacing={2}
			spacing={{ xs: 1, sm: 2 }}
			columns={{ xs: 4, sm: 8, md: 12 }}
			direction="column"
			padding={1}
		>
			<Grid item xs={4} sm={8} md={12}>
				<Typography variant='h4'>
					<FormattedMessage id="creating_event" />
				</Typography>
			</Grid>			

			<Grid item xs={4} sm={8} md={12}>
				<TextField 
					fullWidth 
					id="name" 
					label={<FormattedMessage id="title" />}
					variant="outlined" 
					size='small'
					value={name}
					onChange={changeName}
					helperText={nameError}
					error= {nameError != null}					
				/>
			</Grid>

			<Grid
				item 
				container
				rowSpacing={1}
				spacing={{ xs: 1, sm: 2 }}
				columns={{ xs: 4, sm: 8, md: 12 }}
			>
				<Grid item xs={4} sm={3} md={5}>
					<Typography variant='body1'>
						<FormattedMessage id="view_room" />:
					</Typography>
				</Grid>

				<Grid item xs={4} sm={5} md={7}>
					<FormControl 
						fullWidth
						size='small'
					>
						<InputLabel id="roomType-label">
							<FormattedMessage id="view" />
						</InputLabel>

						<Select
							labelId="roomType-label"
							id="roomType"
							value={eventType}
							label={<FormattedMessage id="view" />}
							onChange={handleChangeType}
						>
							<MenuItem value="talk"><FormattedMessage id="talks" /></MenuItem>
							<MenuItem value="hackword"><FormattedMessage id="hackword" /></MenuItem>
							<MenuItem value="iamalive"><FormattedMessage id="iamalive" /></MenuItem>
							<MenuItem value="generalize"><FormattedMessage id="generalize" /></MenuItem>
						</Select>
					</FormControl>
				</Grid>
			</Grid>	

			<Grid
				item 
				container
				rowSpacing={1}
				spacing={{ xs: 1, sm: 2 }}
				columns={{ xs: 4, sm: 8, md: 12 }}
			>
				<Grid item xs={4} sm={4} md={6}>
					<Typography variant='body1'>
						<FormattedMessage id="max_number_participants" />:
					</Typography>
				</Grid>

				<Grid item xs={4} sm={4} md={6}>
					<Stack direction="row" spacing={2} alignItems="center">
						<Slider 
							aria-label={<FormattedMessage id="max_number_participants" />} 
							value={maxUsersCount} 
							min={2} 
							max={8} 
							onChange={changeMaxUsersCount} 
						/>

						<Typography variant='body1'>{maxUsersCount}</Typography>
					</Stack>
				</Grid>
			</Grid>			

			<Grid
				item 
				container
				rowSpacing={1}
				spacing={{ xs: 1, sm: 2 }}
				columns={{ xs: 4, sm: 8, md: 12 }}
			>
				<Grid item xs={4} sm={3} md={5}>
					<Typography variant='body1'>
						<FormattedMessage id="date_and_time" />:
					</Typography>
				</Grid>

				<Grid item xs={4} sm={5} md={7}>
					<FormControl 
						fullWidth
					>
						<DateTimePicker 
							format="DD.MM.YYYY HH:mm:ss"
							ampm={false}
							slotProps={{ textField: { size: 'small' } }} 
							value={datetime}
							onChange={changeDateTime}	
							localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
							firstDayOfWeek={0}						
						/>
					</FormControl>					
				</Grid>
			</Grid>	

			<Grid item xs={4} sm={8} md={12}>
				<TextField
					id="description"
					label={<FormattedMessage id="description" />}
					multiline
					rows={3}
					fullWidth
					value={description}
					onChange={changeDescription}
					helperText={descriptionError}
					error= {descriptionError != null}						
				/>
			</Grid>

			<Grid item xs={4} sm={8} md={12}>
				<Button variant="contained" size="large" fullWidth onClick={createEvent}>
					<FormattedMessage id="create" />
				</Button>
			</Grid>
		</Grid>
	);
};

export default CreateEventForm;
import {
	useRecoilState
  } from 'recoil';
import { Tooltip, Typography, Modal, Box } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import HelpIcon from '@mui/icons-material/Help';
import './game-item.scss';
import { ThemeAtom } from '../../atoms/Common';
import { CurrentGameName } from '../../atoms/GamesAtoms';
import {influxDBButtonPush} from "../../../services/influxdb/games"
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import {sendMsgSite} from "../../SiteController"
import {SiteWS, SiteWSOpened} from "../../atoms/SiteAtoms"
import ModalStyle from '../../../utils/ModalStyle/ModalStyle';
import ModalClose from '../../../utils/ModalStyle/ModalClose';
import CreateGame from "../CreateGame"

const CustomWidthTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
  ))({
	[`& .${tooltipClasses.tooltip}`]: {
	  maxWidth: 500,
	},
  });

const GameItem = ({
	userInfo,
	gameName,
	img,
	description,
	activeUsers,	
}) => {
	const [openNewGame, setOpenNewGame] = React.useState(false);

	const [theme, _] = useRecoilState(ThemeAtom);
    const [siteWS, setSiteWS]  = useRecoilState(SiteWS);

	const [currentGameName, setCurrentGameName] = useRecoilState(CurrentGameName);

	const chooseGame = ()=>{
		influxDBButtonPush(userInfo, "games", gameName, "choose_game")
		setCurrentGameName({gameName: gameName})
		sendMsgSite(siteWS.ws, "Action", {})
		setOpenNewGame(true)
	}

	let style = {
		backgroundImage: `url(${img})`,
		minHeight: "276px",
	}
	// if (currentGameName.gameName == gameName) {
	// 	style.border = "solid #00ff00 4px"
	// }
	return (
		<div 
			className="game-item" 
			onClick={chooseGame}
			style={style}
		>
			<Typography variant='h4'>{""}</Typography>

			<Stack direction="row" justifyContent="space-between" className="game-item__footer">
				{description &&
					<CustomWidthTooltip 
						title={
							<>
								{<img src={"/themes/"+theme.theme+"/imgs/brief-rules.png"} alt="" style={{ display: "block", maxWidth: "220px", margin: "10px auto" }} />}
								<h1>{description}</h1>
							</>
						} 
					>
						<HelpIcon />
					</CustomWidthTooltip>
				}

				{activeUsers &&
					<Stack direction="row" alignItems="center">
						<Typography variant='body'>{activeUsers}</Typography>
						<PersonIcon />
					</Stack>
				}				
			</Stack>

			<Modal
				open={openNewGame}
				onClose={(e)=>{setOpenNewGame(false); e.stopPropagation();}}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
			<Box sx={{ ...ModalStyle, width: 370, }}>
				<ModalClose onClose={(e)=>{setOpenNewGame(false); e.stopPropagation();}}/>
				<CreateGame gameName={currentGameName.gameName}/>
			</Box>
		</Modal>
		</div>
	);
};

export default GameItem;
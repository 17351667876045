import { Grid, Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, RadioGroup, Radio,  Typography, FormControlLabel, Alert } from '@mui/material';
import React, {useState} from 'react';
import {influxDBButtonPush} from "../../../../../services/influxdb/iamalive"
import {IamaliveWS} from "../atoms/Connection"
import {useRecoilState,} from 'recoil';
import {UserInfoAtom} from "../../../../atoms/UserAtoms"
import { sendMsgIamalive } from '../IamaliveGameController';
import {FormattedMessage} from "react-intl";







const SetPreparedWords = (props) => {
    const [categoryType, setCategoryType] = useState("cartoons_characters")
    const [playersGroup, setPlayersGroup] = useState("everyWithoutWord")

    const [iamaliveWS, setIamaliveWS]  = useRecoilState(IamaliveWS);
    const [userInfo, setUserInfo]  = useRecoilState(UserInfoAtom);


    const handleChangeType = (event)=>{
		setCategoryType(event.target.value);
    }
    const handleChangePlayersGroup = (event)=>{
        setPlayersGroup(event.target.value)
    }
    const assignWords = ()=>{
        influxDBButtonPush(userInfo, props.iamaliveID, "assign_prepared_words")
        sendMsgIamalive(iamaliveWS.ws, "AssignPreparedWords", {Category:  categoryType, TargetType: props.position == undefined ? playersGroup : "onePlayer", Position: props.position})
		props.onClose()
    }
    return (
		<Stack
			direction="column"
			spacing={3}
		>
			<Typography variant='h4'>
				<FormattedMessage id="set_prepared_words" />
			</Typography>

            <Grid item xs={4} sm={5} md={7}>
					<FormControl 
						fullWidth
						size='small'
					>
						<InputLabel id="category-label">
							<FormattedMessage id="category" />
						</InputLabel>

						<Select
							labelId="category-label"
							id="category"
							value={categoryType}
							label={<FormattedMessage id="category" />}
							onChange={handleChangeType}
						>
							<MenuItem value="cartoons_characters"><FormattedMessage id="cartoons_characters" /></MenuItem>
							<MenuItem value="films_characters"><FormattedMessage id="films_characters" /></MenuItem>
                            <MenuItem value="professions"><FormattedMessage id="professions" /></MenuItem>
							<MenuItem value="animals"><FormattedMessage id="animals" /></MenuItem>
						</Select>
					</FormControl>
			</Grid>
            <br/><br/>
            {props.position == undefined && <><Typography variant='h5'><FormattedMessage id="who_assign_word" /></Typography>
            <RadioGroup
                aria-labelledby="players-group"
                name="players-group"
                value={playersGroup}
                onChange={handleChangePlayersGroup}
            >
                <FormControlLabel value="everyWithoutWord" control={<Radio />} label={<FormattedMessage id="every_without_word" />} />
                <FormControlLabel value="everyBody" control={<Radio />} label={<FormattedMessage id="everybody" />} />
            </RadioGroup> 
            <br/><br/></>}

			<Grid item xs={4} sm={8} md={12}>
				<Button variant="contained" size="large" fullWidth onClick={assignWords}>
					<FormattedMessage id="assign" />
				</Button>
			</Grid>                                   
        </Stack>            
    )
}


export default SetPreparedWords
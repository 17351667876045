import { Button, Chip, Grid, Slider, Stack, Switch, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import TalkService from '../../../services/rooms/TalkService';
import {SuccessMessage, DefaultLang, MaxRoomNameLength, MaxRoomTagLength, MaxParticipantsPremium, MaxParticipantsStandart} from "../../../constants";
import {useNavigate} from "react-router-dom"
import {useRecoilState} from "recoil";
import {UserInfoAtom, UserPersonalAtom} from "../../atoms/UserAtoms";
import {BirthDayModal, ShowTooYoung} from "../../atoms/Common";
import {underAgeValidate}from "../../../utils/DateUtils"
import {checkForMate} from "../../../utils/TextFilters"
import {lightLogout} from "../../../utils/common"
import {ShareRoomLinkModal} from "../../../livekit_prod/atoms"

const CreateRoom = () => {
	const [userCount, setUserCount] = useState(8);
	const [tag, setTag] = useState();
	const [tags, setTags] = useState([]);
	const [roomName, setRoomName] = useState("");
	const [eighteenPlus, setEighteenPlus] = useState(false);
	const navigate = useNavigate();

	const [userPersonal, setUserPersonal] = useRecoilState(UserPersonalAtom);
	const [birthDayModal, setBirthDayModal] = useRecoilState(BirthDayModal)
	const [showTooYoung, setShowTooYoung] = useRecoilState(ShowTooYoung)
	const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);
    const [showShareRoomLink, setShowShareRoomLink] = useRecoilState(ShareRoomLinkModal);

	const [roomNameError, setRoomNameError] = useState(null);
	const [tagError, setTagError] = useState(null);


	const changeUserCount = (event, newValue) => {
   	 	setUserCount(newValue);
  	};

	const changeRoomName = (event) => {
		if (event.target.value.length > MaxRoomNameLength) {
			setRoomNameError(<span><FormattedMessage id="cantbelonger"/> {MaxRoomNameLength}</span>)
			return
		} else {
			setRoomNameError(null)
		}
		if (checkForMate(DefaultLang, event.target.value)) {
			setRoomNameError(<span><FormattedMessage id="cant_use_mate"/></span>)		
		} else {
			setRoomNameError(null)
		}
		setRoomName(event.target.value);
	}

	const switchEighteenPlus = (event) => {
		if (userPersonal && !userPersonal.UserPersonal.Birthday.Valid) {
			setBirthDayModal({Open: true})
			return
		}
		if (!underAgeValidate(userPersonal.UserPersonal.Birthday.Time)) {
			setShowTooYoung({show: true})
			return
		}
		setEighteenPlus(event.target.checked);
	}

	const addTag = () => {
		if(tag !== "" && tagError == null) {
			setTags(tags => [...tags, tag]);
			setTag("");
		}
	};

	const handleAddTagKeyDown = (event) => {
		if(event.keyCode === 13) {
			addTag();
		}    
  	};

	const createTalkRoom = () => {
		TalkService.createTalk(roomName, userCount, eighteenPlus, "video", tags, "default").then((response)=>{
			if (response.data.Success == SuccessMessage) {
				setShowShareRoomLink(true)
				navigate("/room/"+response.data.RoomKey)
			}
		}).catch((err)=>{
			if (err.response.data.ErrorCode == 50) {
				if (userPersonal && !userPersonal.UserPersonal.Birthday.Valid) {
					setBirthDayModal({Open: true})
					return
				} else {
					lightLogout()
					setShowTooYoung({show: true})
					return
				}									
			}
		});
	}

	return (
		<Grid
			container
			rowSpacing={2}
			spacing={{ xs: 1, sm: 2 }}
			columns={{ xs: 4, sm: 8, md: 12 }}
			direction="column"
			padding={1}
		>
			<Grid item xs={4} sm={8} md={12}>
				<Typography variant='h4'>
					<FormattedMessage id="creating_room" />
				</Typography>
			</Grid>

            <Grid
                item 
                container
                rowSpacing={0}
                spacing={{ xs: 0, sm: 0 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
            >
                    <Typography variant='body1'>
						<FormattedMessage id="max_number_of_users_is" />: {userInfo.UserInfo.AccountType != "standart" ? MaxParticipantsPremium : MaxParticipantsStandart}
                    </Typography>									
            </Grid>			

			{false && <Grid item xs={4} sm={8} md={12}>
				<TextField 
					value={roomName}
					onChange={changeRoomName}
					fullWidth 
					id="name" 
					label={<FormattedMessage id="room_name" />}
					variant="outlined" 
					size='small'
					helperText={roomNameError}
                    error= {roomNameError != null}
				/>
			</Grid>}

			{false && <Grid
				item 
				container
				rowSpacing={1}
				spacing={{ xs: 1, sm: 2 }}
				columns={{ xs: 4, sm: 8, md: 12 }}
			>
				<Grid item xs={4} sm={4} md={6}>
					<Typography variant='body1'>
						<FormattedMessage id="number_participants" />:
					</Typography>
				</Grid>

				<Grid item xs={4} sm={4} md={6}>
					<Stack direction="row" spacing={2} alignItems="center">
						<Slider 
							aria-label={<FormattedMessage id="number_participants" />} 
							value={userCount} 
							min={2} 
							max={userInfo.UserInfo.AccountType != "standart" ? 20 : 8} 
							onChange={changeUserCount} 
						/>

						<Typography variant='body1'>{userCount}</Typography>
					</Stack>
				</Grid>
			</Grid>}

			{false && <Grid
				item 
				container
				rowSpacing={1}
				spacing={{ xs: 1, sm: 2 }}
				columns={{ xs: 4, sm: 8, md: 12 }}
			>
				<Grid item xs={3} sm={4} md={6}>
					<Typography variant='body1'>
						<FormattedMessage id="age_restrictions" />:
					</Typography>
				</Grid>

				<Grid item xs={1} sm={4} md={6}>
					<Switch
						checked={eighteenPlus}
						onChange={switchEighteenPlus}
					/>
				</Grid>
			</Grid>}

			{false && <Grid
				item 
				container
				rowSpacing={1}
				spacing={{ xs: 1, sm: 2 }}
				columns={{ xs: 4, sm: 8, md: 12 }}
			>
				<Grid item xs={4} sm={5} md={8}>
					<TextField 
						fullWidth 
						id="name" 
						label={<FormattedMessage id="tag_text" />}
						variant="outlined" 
						size='small'
						value={tag || ''}
						onChange={(e) => {
							if (e.target.value.length > MaxRoomTagLength) {
								setTagError(<span><FormattedMessage id="cantbelonger"/> {MaxRoomTagLength}</span>)
								return
							} else {
								setTagError(null)
							}							
							if (checkForMate(DefaultLang, e.target.value)) {
								setTagError(<span><FormattedMessage id="cant_use_mate"/></span>)		
							} else {
								setTagError(null)
							}
							setTag(e.target.value)}}
						helperText={tagError}
						error= {tagError != null}
						onKeyDown={handleAddTagKeyDown}
					/>
				</Grid>

				<Grid item xs={4} sm={3} md={4}>
					<Button variant="contained" size="large" color="success" disabled={tagError != null} onClick={addTag}>
						<FormattedMessage id="add" />
					</Button>
				</Grid>
			</Grid>}

			{false && <Grid 
				item 
				xs={4} 
				sm={8} 
				md={12}
				style={{width: '100%'}}
			>
				{tags.map((item, index) => (
					<Chip label={item} size="small" key={index} style={{margin: '4px 4px 0 0'}} />
				))}					
			</Grid>}			

			<Grid item xs={4} sm={8} md={12}>
				<Button variant="contained" size="large" onClick={createTalkRoom} disabled={roomNameError != null}>
					<FormattedMessage id="create" />
				</Button>
			</Grid>
		</Grid>
	);
};

export default CreateRoom;
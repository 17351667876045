import React, { useState, useEffect } from 'react';
import Webcam from "react-webcam";
import {FormattedMessage} from "react-intl";
import { Typography, Stack, Chip,} from '@mui/material';
import {GeneralizeGameState,  } from "../atoms/GameState"
import {GeneralizeWS} from "../atoms/Connection"
import {useRecoilState,} from 'recoil';
import {sendMsgGeneralize} from "../GeneralizeGameController"



function GeneralizeGameBox(props) {
    const [generalizeGameState, setGeneralizeGameState] = useRecoilState(GeneralizeGameState);
    const [generalizeWS, setGeneralizeWS]  = useRecoilState(GeneralizeWS);

    let containerStyle = {
        height: "100%",
    }
    const [box, setBox] = useState(props.livekitBox)

    useEffect(()=> {
        let boxUp = props.livekitBox
        if (React.isValidElement(boxUp)) {
            boxUp = React.cloneElement(boxUp, { color: props.color, modal: props.modal }); 
        }
        setBox(boxUp)
    }, [props.livekitBox,])

    let masterTextStyle = {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%"
    }
    let mobile = false
    if (window.matchMedia("(max-width: 899px)").matches && !props.modal) {
        mobile = true
    }    

    return (
        <div style={containerStyle}>
            {props.ismaster && generalizeGameState.Phase != "picking" && generalizeGameState.Phase != "ended" && 
                <div style={{width:"100%", height:"100%", backgroundColor: "#6d6d6d", color: "white", position: "relative", zIndex: 300}}>
                    {mobile ? 
                        <span style={{fontSize: 'xx-small', lineHeight: "initial"}}>
                            <FormattedMessage id="generalize_master" />
                        </span> : <div style={masterTextStyle}>
               <div><Typography variant='h5'><FormattedMessage id="generalize_its_master" /></Typography></div>
               <div><Typography variant='h8'><FormattedMessage id="generalize_no_show_video_reason" /></Typography></div>
            </div>}</div>}
            {box}
           
        </div>
    )
}

// {sendAdmin && <div style={{width:"100%", height: "100%", backgroundColor:"rgba(111, 111, 111, 0.5)", zIndex:9999, position: "absolute", top:0, left:0, cursor: "pointer"}} onClick={changeAdmin}>
// <div
// style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)"}}><FormattedMessage id="send_admin_player" /></div>
// </div>} 

//             {generalizeGameState.AdminPosition == props.position && !mobile && <Chip sx={{color: "white"}} label={<FormattedMessage id="admin" />}  size="small" key={1} className="admin-label-style"/>}


export default GeneralizeGameBox
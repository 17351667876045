import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Button, Container, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Navbar from '../Navbar/Navbar';

import AvatarImg2 from '../../assets/images/avatar2.png';
import EventItem from './EventItem/EventItem';
import FlatChat from '../common/FlatChat/FlatChat';
import {useParams} from "react-router-dom";
import CommunitiesService from '../../services/CommunitiesService';
import {SuccessMessage} from "../../constants";
import {ThemeAtom} from "../atoms/Common";
import {ruTypeTime} from "../../utils/DateUtils"
import {useRecoilState,} from 'recoil';

const Event = () => {
	const [themeAtom, setThemeAtom] = useRecoilState(ThemeAtom);

	const navigate = useNavigate();
	let { community_id, event_id } = useParams();
	const [eventDetails, setEventDetails] = useState({Name: "", Description: "", EventType: "talk", Datetime: "0000-00-00T00:00:00Z", MaxUsers: 0, Status: "no", JoinedUsers: 0, RoomID: 0});

	const joinEvent = ()=>{
		CommunitiesService.joinEvent(parseInt(community_id), parseInt(event_id)).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				setEventDetails((curr)=>{
					return {...curr, Status: "joined", JoinedUsers: curr.JoinedUsers + 1}
				})
			}
		})
	}
	const declineEvent = ()=>{
		CommunitiesService.declineEvent(parseInt(community_id), parseInt(event_id)).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				setEventDetails((curr)=>{
					return {...curr, Status: "no", JoinedUsers: curr.JoinedUsers - 1}
				})
			}
		})
	}
	const comeInToRoom = ()=>{
		CommunitiesService.joinEventRoom(parseInt(event_id)).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				navigate("/room")
			}
		})
	}


	useEffect(() => {
		CommunitiesService.eventDetails(parseInt(community_id), parseInt(event_id)).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				setEventDetails(response.data.Event)
			}
		})
	}, [])
	return (
		<Box
			sx={{
				minHeight: '100vh',
				bgcolor: 'background.default',
				color: 'text.primary',
			}}
		>
			<Container maxWidth="lg">
				<Stack spacing={4} paddingY={3}>	
					<Box>
						<Button variant="outlined" size="large" onClick={() => navigate('/community/'+community_id)}>
							<FormattedMessage id="back_to_events" />
						</Button>
					</Box>

					<EventItem
						onClick={() => {}}
						date={ruTypeTime(eventDetails.Datetime)}
						icon={"/themes/"+themeAtom.theme+"/imgs/features/"+eventDetails.EventType+".png"}
						name={eventDetails.Name}
						roomSize={eventDetails.MaxUsers}
						users={eventDetails.JoinedUsers}					
					/>

					<Stack
						direction={{xs: 'column', md: 'row'}}
						alignItems="center"
						spacing={{xs: 2, md: 4}}
					>
						<Typography variant='body1' align="left"  sx={{ width: '80%' }}>
							{eventDetails.Description}
						</Typography>
						{eventDetails.Status == "no" ? 
						<Button variant="contained" size="large"  onClick={joinEvent}>
							<FormattedMessage id="join" />
						</Button> : null}
						{eventDetails.Status == "joined" ? 
						<Button variant="contained" size="large" onClick={declineEvent}>
							<FormattedMessage id="joined_unjoin" />
						</Button> : null}						
					</Stack>

					<Stack
						direction={{xs: 'column', md: 'row'}}
						alignItems="center"
						spacing={{xs: 2, md: 4}}
					>
						{eventDetails.RoomID == 0 && eventDetails.Status == "joined" ? <FormattedMessage id="willbe_comein_event_button" /> : 
							null}
						{eventDetails.RoomID != 0 && eventDetails.Status == "joined" ?
						<Button variant="contained" size="large" onClick={comeInToRoom}>
							<FormattedMessage id="comein" />
						</Button> : null}
					</Stack>

					<Stack spacing={1}>	
						<Typography variant='h5'>
							<FormattedMessage id="chat_events" />:
						</Typography>						

						<FlatChat key={"event"+event_id} occasionID={parseInt(event_id)} occasionType="event"/>
					</Stack>
				</Stack>
			</Container>			
		</Box>
	)
};

export default Event;

import Api from "../Api"



const leaveRoom = async function() {
    return await Api.post("/rooms/leave", {})
}

const checkCurrent = async function() {
  return await Api.post("/rooms/checkCurrent", {})
}

const spectate = async function(entityType, entityID) {
  return await Api.post("/rooms/spectate", {EntityType: entityType, EntityID: entityID})
}


const RoomService = {
    leaveRoom,
    checkCurrent,
    spectate,
  };
  
export default RoomService;
import {
    useRecoilState,
} from 'recoil';
import {HackwordWSOpened, HackwordWS, HackwordWSRestartTimeout} from "./atoms/Connection"
import TokenService from "../../../../services/TokenService"
import React, { useState, useEffect } from 'react';
import {HackwordGameSettings, TeamsState, TeamToPlay, RoundSecondsPassed, WordToHack, WordsHistory, RoundPhase, DoWinCondition, GameFinished, ChangePointsLogs, FinishChangeExpPointsInfo} from "./atoms/GameConfiguration"
import {UnAccesibleOnePlayerInTeam, YouHaveMaximumPlayers, ShowCantStartGamS, ErrorOccured, NewPointsChanges} from "./atoms/Snackbar"
import Api from "../../../../services/Api";
import {FormattedMessage} from "react-intl";
import { Snackbar, Alert, Modal, Box } from '@mui/material';
import {ThemeAtom, SoundTheme, UseSoundAtom, SoundLevelAtom} from "../../../atoms/Common"
import { makeString } from '../../../../utils/common';
import {RoomInfo, CanBecameCommonParticipant} from "../../../../livekit_prod/atoms"
import {domainNameAPI, protocolAPI, apiPort} from "../../../../environment"
import ModalStyle from "../../../../utils/ModalStyle/ModalStyle";
import Victory from "./Victory/Victory"
import PointsChangeLog from "./AllWords/PointsChangeLog"
import useSound from 'use-sound';

let ws = null
let hackwordID = 0
let roomSeed = ""

export const sendMsgHackword = (ws, type, obj)=>{
    if (ws == null) {
        console.log("webRTCClient is null");
        return
    }
    try {
        if (ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({'Type': type, 'Payload': obj}));
        }
    } catch (error) {
        console.error(error);
    }
}

function connect(atomValues, atomSets, melodies, props, reconnect) {
    let {hackwordWS, wsOpened, hackwordWSRestartTimeout, wordsHistory, useSoundAtom, roomInfo} = atomValues
    let {setWSOpened, setGameSettings, setTeamsState, setShowCantStartGame, setYouHaveMaximumPlayers, setUnAccesibleOnePlayerInTeam, setErrorOccured, 
        setHackwordWS, setHackwordWSRestartTimeout, setTeamToPlay, setRoundSecondsPassed, setWordToHack, setWordsHistory, setRoundPhase, setDoWinCondition,        
        setGameFinished, setOpenFinalModal, setChangePointsLogs, setNewPointsChanges, setFinishChangeExpPointsInfo, setCanBecameCommonParticipant,} = atomSets
    let {playPositive, playNegative1, pointsSwitch} = melodies   

    if (reconnect) {
        ws = new WebSocket(props.apiUrl)
        setHackwordWS({ws: ws})
    } else if (ws == null) {
        return
    } else if (ws.readyState === WebSocket.OPEN && (hackwordID != roomInfo.entityID || roomSeed !== props.roomSeed)){
        hackwordID = roomInfo.entityID
        roomSeed = props.roomSeed
        restartGameContext(setWordsHistory, setTeamsState, setTeamToPlay, setRoundPhase, setDoWinCondition, setGameFinished)
        ws.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), 'HackwordID': roomInfo.entityID, 'Nickname': props.myname, 'Position': props.myposition, "RoomID": props.roomID, "Role": props.myrole }}));
    }

    ws.onopen = () => {
        let newState = Object.assign({}, wsOpened);
        newState.opened = true;
        setWSOpened(newState);
        console.log('Hackword WebSocket Client Connected');
        if (hackwordID != roomInfo.entityID) {
            hackwordID = roomInfo.entityID
            restartGameContext(setWordsHistory, setTeamsState, setTeamToPlay, setRoundPhase, setDoWinCondition, setGameFinished)
        }
        ws.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), 'HackwordID': roomInfo.entityID, 'Nickname': props.myname, 'Position': props.myposition, "RoomID": props.roomID, "Role": props.myrole }}));
    };

    ws.onclose = () => {
        if (hackwordWSRestartTimeout.timeout != 0) {
            clearInterval(hackwordWSRestartTimeout.timeout)
        }
        let newState = Object.assign({}, wsOpened);
        newState.opened = false;
        setWSOpened(newState);
        let rTimeOut = setTimeout(function() {
            connect(atomValues, atomSets, melodies, props, true);
        }, 2000);
        setHackwordWSRestartTimeout({timeout: rTimeOut})
    };

    ws.onmessage = (message) => {
        let msg = JSON.parse(message.data);
        switch (msg.Type) {
            case "InitGame":
                processInitGame(msg, setGameSettings)
                break;
            case "TeamsState":
                processTeamsState(msg, setTeamsState)
                break;
            case "TeamToPlay":
                processTeamToPlay(msg, setTeamToPlay)
                break;
            case "RoundStart":
                processRoundStart(msg, setRoundPhase, setWordsHistory, setChangePointsLogs)
                break;
            case "EndOfRound":
                processRoundEnd(msg, setRoundPhase)
                break;
            case "WordToHack":
                processWordToHack(msg, setWordToHack)
                break; 
            case "LastWord":
                processLastWord(msg, wordsHistory, setWordsHistory, useSoundAtom, playPositive, playNegative1)      
                break; 
            case "RoundSecondsPassed":
                processRoundSecondsPassed(msg, setRoundSecondsPassed)
                break;
            case "RoundState":
                processRoundState(msg, setRoundPhase, setWordsHistory)
                break;
            case "DoWinCondition":
                processDoWinCondition(msg, setDoWinCondition)
                break;   
            case "DontWinCondition":
                processDontWinCondition(msg, setDoWinCondition)
                break;
            case "GameFinished":
                processGameFinished(msg, setGameFinished, setOpenFinalModal, setDoWinCondition, setFinishChangeExpPointsInfo)
                break;
            case "ChangePointsLogs":
                processChangePointsLogs(msg, setChangePointsLogs, setNewPointsChanges, useSoundAtom, pointsSwitch)
                break;
            case "CanParticipate":
                setCanBecameCommonParticipant({can: true})
                break;
            case "CannotParticipate":
                setCanBecameCommonParticipant({can: false})
                break;                                                                     
            case "ErrorCode":
                if (msg.Payload.Code == 800) {
                    Api.post("/auth/refreshToken", {
                        RefreshToken: TokenService.getLocalRefreshToken(),
                    }).then(response=> {
                        TokenService.updateLocalAccessTokens(response.data.Tokens.AuthToken, response.data.Tokens.RefreshToken);
                        ws.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), 'HackwordID': roomInfo.entityID, 'Nickname': props.myname, 'Position': props.myposition, "RoomID": props.roomID, "Role": props.myrole}}));
                    });
                } else if (msg.Payload.Code == 100) {
                    setShowCantStartGame(true)
                } else if (msg.Payload.Code == 102) {
                    setYouHaveMaximumPlayers(true)
                } else if (msg.Payload.Code == 101) {
                    setUnAccesibleOnePlayerInTeam(true)
                } else if (msg.Payload.Code == 103) {
                    setErrorOccured(true)
                }
                break;                
        }
    }
}

function HackwordGameController(props) {
    const [wsOpened, setWSOpened] = useRecoilState(HackwordWSOpened); 
    const [gameSettings, setGameSettings]  = useRecoilState(HackwordGameSettings); 
    const [teamsState, setTeamsState]  = useRecoilState(TeamsState);
    const [hackwordWS, setHackwordWS]  = useRecoilState(HackwordWS);
    const [hackwordWSRestartTimeout, setHackwordWSRestartTimeout]  = useRecoilState(HackwordWSRestartTimeout);
    const [teamToPlay, setTeamToPlay]  = useRecoilState(TeamToPlay);
    const [roundSecondsPassed, setRoundSecondsPassed]  = useRecoilState(RoundSecondsPassed);
    const [wordToHack, setWordToHack]  = useRecoilState(WordToHack);
    const [wordsHistory, setWordsHistory]  = useRecoilState(WordsHistory);
    const [roundPhase, setRoundPhase]  = useRecoilState(RoundPhase);
    const [doWinCondition, setDoWinCondition]  = useRecoilState(DoWinCondition);
    const [roomInfo, setRoomInfo]  = useRecoilState(RoomInfo);
    const [theme, _] = useRecoilState(ThemeAtom);
    const [gameFinished, setGameFinished] = useRecoilState(GameFinished);
    const [changePointsLogs, setChangePointsLogs] = useRecoilState(ChangePointsLogs);
    const [soundTheme, setSoundTheme]  = useRecoilState(SoundTheme);
	const [useSoundAtom, setUseSoundAtom]  = useRecoilState(UseSoundAtom);
    const [soundLevelAtom, setSoundLevelAtom] = useRecoilState(SoundLevelAtom);
    const [finishChangeExpPointsInfo, setFinishChangeExpPointsInfo] = useRecoilState(FinishChangeExpPointsInfo);

    const [showCantStartGame, setShowCantStartGame] = useRecoilState(ShowCantStartGamS);
    const [youHaveMaximumPlayers, setYouHaveMaximumPlayers] = useRecoilState(YouHaveMaximumPlayers);
    const [unAccesibleOnePlayerInTeam, setUnAccesibleOnePlayerInTeam] = useRecoilState(UnAccesibleOnePlayerInTeam);
    const [errorOccured, setErrorOccured] = useRecoilState(ErrorOccured);
    const [newPointsChanges, setNewPointsChanges] = useRecoilState(NewPointsChanges);

    const [canBecameCommonParticipant, setCanBecameCommonParticipant] = useRecoilState(CanBecameCommonParticipant)

    const [openFinalModal, setOpenFinalModal] = useState(false);

    const [playPositive] = useSound(
		'/sounds/themes/'+soundTheme.theme+'/hackword/positive_short.mp3',
		{ volume: soundLevelAtom }
	  );
	const [playNegative1] = useSound(
		'/sounds/themes/'+soundTheme.theme+'/hackword/negative_short_1.mp3',
		{ volume: soundLevelAtom }
	);
    const [pointsSwitch] = useSound(
		'/sounds/themes/'+soundTheme.theme+'/hackword/switch.mp3',
		{ volume: soundLevelAtom }
	);

    const onCloseFinishModal = ()=>{
        setOpenFinalModal(false)
    }

    useEffect(()=> {
        if (hackwordWS.ws == null) {
            ws = new WebSocket(props.apiUrl)
            setHackwordWS({ws: ws}) // , TokenService.getLocalAccessToken()
        }
    }, [hackwordWS]);

    useEffect(()=> {
        if (props.myid != 0) {
            connect({hackwordWS, wsOpened, hackwordWSRestartTimeout, wordsHistory, useSoundAtom, roomInfo}, {setWSOpened, setGameSettings, setTeamsState, setShowCantStartGame, setYouHaveMaximumPlayers, setUnAccesibleOnePlayerInTeam, setErrorOccured, 
                setHackwordWS, setHackwordWSRestartTimeout, setTeamToPlay, setRoundSecondsPassed, setWordToHack, setWordsHistory, setRoundPhase, setDoWinCondition, setGameFinished, setOpenFinalModal,
                setChangePointsLogs, setNewPointsChanges, setFinishChangeExpPointsInfo, setCanBecameCommonParticipant,
            }, {playPositive, playNegative1, pointsSwitch}, props, false)
        }
    }, [wordsHistory, props.myid, roomInfo, props.roomSeed, setWordsHistory, useSoundAtom, soundLevelAtom]);
    let gameControllerStyle = {
        Position: "fixed",
        Width: 0,
        Height: 0,
    }

    let apiUrl = protocolAPI+domainNameAPI+apiPort
    let myTeamPlayers = []
    for (let teamNum in teamsState.teams) {
        let team = teamsState.teams[teamNum]
        let myTeam = false
        for (let player of team.Players) {
            if (player == props.myposition) {
                myTeam = true
            }
        }
        if (myTeam) {
            for (let player of team.Players) {
                for (let identity in roomInfo.roomMates) {
                    if (roomInfo.roomMates[identity].Position == player) {
                        let iconUrl = ""
                        if (roomInfo.roomMates[identity].Icon != "") {
                            iconUrl = apiUrl+"/mkstatic/shared/avatars/"+roomInfo.roomMates[identity].Icon+"?dop"+makeString(5)
                        }
                        myTeamPlayers.push({
                            name: identity,
                            icon: iconUrl,
                        })
                    }
                }
            }
        }
    }

    let finishIcon = "/themes/"+theme.theme+"/imgs/games/"
    let iconsNames = ["win.svg", "win2.svg", "win3.svg", "win4.svg"]

    const [playWin] = useSound(
		'/sounds/themes/'+soundTheme.theme+'/hackword/win1.mp3',
		{ volume: soundLevelAtom }
	  );      
    const [playLoose] = useSound(
        '/sounds/themes/'+soundTheme.theme+'/hackword/loose1.mp3',
        { volume: soundLevelAtom }
    );      
    return (
        <span style={gameControllerStyle}>
	  		<Snackbar open={showCantStartGame} 
				onClose={()=>{setShowCantStartGame(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
                sx={{marginBottom: "100px"}}
				  key={"CantStartGameWithOnePlayerK"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"CantStartGameWithOnePlayer"}>
				  <FormattedMessage id="CantStartGameWithOnePlayer" />
  				</Alert>
			</Snackbar>	
            <Snackbar open={youHaveMaximumPlayers} 
				onClose={()=>{setYouHaveMaximumPlayers(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
                sx={{marginBottom: "100px"}} 
				  key={"YouHaveMaximumPlayers"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"YouHaveMaximumPlayers"}>
				  <FormattedMessage id="YouAlreadyHaveMaximumPlayers" />
  				</Alert>
			</Snackbar>	
            <Snackbar open={unAccesibleOnePlayerInTeam} 
				onClose={()=>{setUnAccesibleOnePlayerInTeam(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                sx={{marginBottom: "100px"}} 
				  key={"UnAccesibleOnePlayerInTeam"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"1UnAccesibleOnePlayerInTeam"}>
				  <FormattedMessage id="UnAccesibleOnePlayerInTeam" />
  				</Alert>
			</Snackbar>	
            <Snackbar open={errorOccured} 
				onClose={()=>{setErrorOccured(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                sx={{marginBottom: "100px"}} 
				  key={"ErrorOccured"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"ErrorOccured"}>
				  <FormattedMessage id="ErrorOccured" />
  				</Alert>
			</Snackbar>	
            <Snackbar open={newPointsChanges} 
				onClose={()=>{setNewPointsChanges(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                sx={{marginBottom: "100px"}} 
				  key={"newPointsChanges"}>
  				<Alert  severity="info" sx={{ width: '100%' }} key={"alertNewPointsChanges"}>
				  {changePointsLogs && changePointsLogs.length > 0 && <PointsChangeLog nickname={changePointsLogs[0].Nickname} word={changePointsLogs[0].Word} guessed={changePointsLogs[0].Guessed} />}
  				</Alert>
			</Snackbar>	                         
            

            <Modal
                open={openFinalModal}
                onClose={onCloseFinishModal}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...ModalStyle, width: 400, }}>
                <Victory
                    title={gameFinished.Place == 1 ? <FormattedMessage id="you_win" /> : <FormattedMessage id="your_place" />}
                    icon={finishIcon+iconsNames[gameFinished.Place-1]}
                    team={myTeamPlayers}
                    changeExpInfo={finishChangeExpPointsInfo}
                    keepPlaying={onCloseFinishModal}
                    melody={gameFinished.Place == 1 ? playWin : playLoose}
                />
                </Box>
            </Modal>
        </span>
    );
}

export default HackwordGameController

const restartGameContext = (setWordsHistory, setTeamsState, setTeamToPlay, setRoundPhase, setDoWinCondition, setGameFinished)=>{
    setWordsHistory({Words: [],})
    setTeamsState({teams: []})
    setTeamToPlay({Guess: [],Explains: 0,Ready: [],TeamNum: 0,PointsTeam: 0,})
    setRoundPhase({Phase: "stopped",})
    setDoWinCondition({DoWinCondition: false,})
    setGameFinished({Place: 1, Finished: false})
}

const processGameFinished = (msg, setGameFinished, setOpenFinalModal, setDoWinCondition, setFinishChangeExpPoints)=>{
    setGameFinished({Place: msg.Payload.Place, Finished: true})
    setDoWinCondition({DoWinCondition: false})
    setFinishChangeExpPoints(msg.Payload.ChangeRatingInfo)
    setOpenFinalModal(true)
}

const processDontWinCondition = (msg, setDoWinCondition)=>{
    setDoWinCondition({DoWinCondition: false})
}

const processDoWinCondition = (msg, setDoWinCondition)=>{
    setDoWinCondition({DoWinCondition: true})
}

const processRoundState = (msg, setRoundPhase, setWordsHistory)=>{
    setRoundPhase({Phase: msg.Payload.Phase})
    setWordsHistory({Words: msg.Payload.WordsHistory})
}

const processRoundSecondsPassed = (msg, setRoundSecondsPassed)=>{
    setRoundSecondsPassed({
        RoundSecondsPassed: msg.Payload.RoundSecondsPassed,
        Updated: true,
    }) 
}

const processLastWord = (msg, wordsHistory, setWordsHistory, useSoundAtom, playPositive, playNegative1)=>{
    if (useSoundAtom && !msg.Payload.RoundEnd) {
        if (msg.Payload.Points > 0) {
            playPositive()
        } else {
            playNegative1()
        }
    }
    setWordsHistory({Words: [{Word: msg.Payload.LastWord, Points: msg.Payload.Points}, ...wordsHistory.Words]})
}

const processWordToHack = (msg, setWordToHack)=>{
    setWordToHack({Word: msg.Payload.Word})
}

const processRoundEnd = (msg, setRoundPhase)=>{
    setRoundPhase({Phase: "stopped"})
}

const processRoundStart = (msg, setRoundPhase, setWordsHistory, setChangePointsLogs)=>{
    setRoundPhase({Phase: "running"})
    setWordsHistory({Words: []})
    setChangePointsLogs([])
}

const processInitGame = (msg, setGameSettings)=>{
    setGameSettings({
        wordsSet: msg.Payload.WordsSet,
        roundSeconds: msg.Payload.RoundSeconds,
        winWordsNumber: msg.Payload.WinWordsNumber,
        roundNumber: msg.Payload.RoundNumber,
        phase: msg.Payload.Phase,
        isAdmin: msg.Payload.IsAdmin,
        adminPosition: msg.Payload.AdminPosition,
    })
}

const processTeamsState = (msg, setTeamsState)=>{
    setTeamsState({
        teams: msg.Payload.Teams,
    })
}

const  processTeamToPlay = (msg, setTeamToPlay)=>{
    setTeamToPlay(
        {
            Guess: msg.Payload.Guess,
            Explains: msg.Payload.Explains,
            Ready: msg.Payload.Ready,
            TeamNum: msg.Payload.TeamNum,
            PointsTeam: msg.Payload.PointsTeam,
        }
    )
}

const processChangePointsLogs = (msg, setChangePointsLogs, setNewPointsChanges, useSoundAtom, pointsSwitch)=>{
    if (useSoundAtom) {
        pointsSwitch()
    }
    setChangePointsLogs(msg.Payload.PointsChangesLogs)
    setNewPointsChanges(true)
}
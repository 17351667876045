import { FormattedMessage } from 'react-intl';
import { Typography, Button, useTheme } from '@mui/material';
import UserService from '../../../../services/UserService';
import {SuccessMessage} from "../../../../constants";
import {useRecoilState,} from 'recoil';
import {UserBorders, UserInfoAtom} from "../../../atoms/UserAtoms"
import {AllExperience,} from "../../../atoms/Ratings";










function BorderItem(props) {
    const theme = useTheme();

    const [userBorders, setUserBorders] = useRecoilState(UserBorders)
    const [allExperience, setAllExperience] = useRecoilState(AllExperience)
    const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);

    const applyBorder = ()=>{
        UserService.applyBorders(props.page, props.name).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                setUserBorders({Borders: response.data.Borders})
            }
        })
    }

    return (
        <div style={{position: "relative", aspectRatio: 16/10, border: props.name == "none" ? "2px solid black" : undefined, backgroundColor: userBorders.Borders.Name == props.name ? theme.palette.mode == "light" ? "#D0EBFC" : "#3f2f9c": undefined}}>
            <Typography variant='h6' style={{position: "absolute", top: "30px", left: "50%", transform: "translate(-50%, 0%)",}}>
                <FormattedMessage id={"borders_"+props.name} />
            </Typography>
            {props.requireLevel > 0 && <Typography variant='h4' style={{position: "absolute", top: "50%", left: "25%", transform: "translate(-50%, -50%)",}}>
                ≥ {props.requireLevel} <FormattedMessage id="lev" />
            </Typography>}
            {props.requirePremium && <img style={{position: "absolute", top: "50%", right: "25%", transform: "translate(50%, -50%)", width: "120px", }} src="/show_state/premium.png"></img>}           
            {props.name != "none" && <img style={{position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%"}} src={"/borders/"+props.name+".png"}></img>}
            {userBorders.Borders.Name != props.name && allExperience.Level >= props.requireLevel && (!props.requirePremium || userInfo.UserInfo.AccountType != "standart") && <Button  onClick={applyBorder} variant="contained" color="success" style={{position: "absolute", bottom: "40px", left: "50%", transform: "translate(-50%, 0%)",}}><FormattedMessage id="install" /></Button>}
            {userBorders.Borders.Name == props.name && <Button variant="outlined" color="success" style={{position: "absolute", bottom: "40px", left: "50%", transform: "translate(-50%, 0%)",}}><FormattedMessage id="installed" /></Button>}
        </div>
    )
}

export default BorderItem;
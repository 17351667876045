import {useRecoilState} from "recoil";
import TokenService from "../../../../services/TokenService"
import React, {useState, useEffect} from 'react';
import Api from "../../../../services/Api";
import {FormattedMessage} from "react-intl";
import { Snackbar, Alert, Modal, Box } from '@mui/material';
import {GeneralizeWSOpened, GeneralizeWS, GeneralizeWSRestartTimeout} from "./atoms/Connection"
import {GeneralizeGameState, GeneralizeTeamsState, MasterGameMap, VisibleGameMap, VotesState, ProcessTime, GameFinishedState, FinishChangeExpPointsInfo } from "./atoms/GameState"
import ModalStyle, { ModalStyleXL } from "../../../../utils/ModalStyle/ModalStyle";
import Victory from "./Victory/Victory"
import useSound from 'use-sound';
import {SoundTheme, UseSoundAtom, SoundLevelAtom} from "../../../atoms/Common"
import {RoomInfo, CanBecameCommonParticipant} from "../../../../livekit_prod/atoms"


let ws = null
let generalizeID = 0
let roomSeed = ""

export const sendMsgGeneralize = (ws, type, obj)=>{
    if (ws == null) {
        console.log("webRTCClient is null");
        return
    }
    try {
        if (ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({'Type': type, 'Payload': obj}));
        }
    } catch (error) {
        console.error(error);
    }
}

function connect(atomValues, atomSets, melodies, props, reconnect) {
    let {wsOpened, generalizeWS, generalizeWSRestartTimeout, useSoundAtom, generalizeTeamsState, roomInfo} = atomValues
    let {setWSOpened, setGeneralizeWS, setGeneralizeWSRestartTimeout, setShowCantStartGame, setGeneralizeGameState, 
        setGeneralizeTeamsState, setShowHasNoMaster, setTooLessPlayers, setMasterGameMap, setVisibleGameMap, setVotesState, setProcessTime, setGameFinishedState,
        setFinishChangeExpPointsInfo, setCanBecameCommonParticipant} = atomSets
    let {playPositive, playNegativeNeutral, playNegativeOther, playNegativeBlack, playNewWord} = melodies  

    if (reconnect) {
        ws = new WebSocket(props.apiUrl)
        setGeneralizeWS({ws: ws})
    } else if (ws == null) {
        return
    } else if (ws.readyState === WebSocket.OPEN && (generalizeID != roomInfo.entityID || roomSeed != props.roomSeed)){
        generalizeID = roomInfo.entityID
        roomSeed = props.roomSeed
        restartGameContext({setGeneralizeGameState, setGeneralizeTeamsState, setMasterGameMap, setVisibleGameMap, setVotesState, setProcessTime, setGameFinishedState,})
        console.log("ASASAS generalize RESTART")
        ws.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), 'GeneralizeID': roomInfo.entityID, 'Nickname': props.myname, 'Position': props.myposition, "RoomID": props.roomID, "Role": props.myrole }}));
    }
    console.log("ASASAS generalize ", roomSeed, props.roomSeed)

    ws.onopen = () => {
        let newState = Object.assign({}, wsOpened);
        newState.opened = true;
        setWSOpened(newState);
        console.log('Generalize WebSocket Client Connected');
        if (generalizeID != roomInfo.entityID) {
            generalizeID = roomInfo.entityID
            restartGameContext({setGeneralizeGameState, setGeneralizeTeamsState, setMasterGameMap, setVisibleGameMap, setVotesState, setProcessTime, setGameFinishedState,})
        }
        ws.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), 'GeneralizeID': roomInfo.entityID, 'Nickname': props.myname, 'Position': props.myposition, "RoomID": props.roomID, "Role": props.myrole }}));
    };

    ws.onclose = () => {
        if (generalizeWSRestartTimeout.timeout != 0) {
            clearInterval(generalizeWSRestartTimeout.timeout)
        }
        let newState = Object.assign({}, wsOpened);
        newState.opened = false;
        setWSOpened(newState);
        let rTimeOut = setTimeout(function() {
            connect(atomValues, atomSets, melodies, props, true);
        }, 2000);
        setGeneralizeWSRestartTimeout({timeout: rTimeOut})
    };

    ws.onmessage = (message) => {
        let msg = JSON.parse(message.data);
        switch (msg.Type) {
            case "GameState":
                processGameState(msg, setGeneralizeGameState)
                break;
            case "TeamsState":
                console.log("ASASAS generalize TeamsState", msg.Payload)
                processTeamsState(msg, generalizeTeamsState, setGeneralizeTeamsState, useSoundAtom, playNewWord)
                break;
            case "GameMapForMaster":
                processGameMapForMaster(msg, setMasterGameMap)
                break;
            case "GameMapVisible":
                processGameMapVisible(msg, setVisibleGameMap, setMasterGameMap)
                break;
            case "VoteFinishResults":
                processVoteFinishResults(msg, useSoundAtom, playPositive, playNegativeNeutral, playNegativeOther, playNegativeBlack)
                break;                
            case "VotesState":
                processVotesState(msg, setVotesState)
                break; 
            case "RoundSeconds":
                processRoundSeconds(msg, setProcessTime)
                break;
            case "GameFinished":
                processGameFinished(msg, setGameFinishedState, setFinishChangeExpPointsInfo)
                break;
            case "CanParticipate":
                setCanBecameCommonParticipant({can: true})
                break;
            case "CannotParticipate":
                setCanBecameCommonParticipant({can: false})
                break;                
            case "ErrorCode":
                if (msg.Payload.Code == 800) {
                    Api.post("/auth/refreshToken", {
                        RefreshToken: TokenService.getLocalRefreshToken(),
                    }).then(response=> {
                        TokenService.updateLocalAccessTokens(response.data.Tokens.AuthToken, response.data.Tokens.RefreshToken);
                        ws.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), 'GeneralizeID': roomInfo.entityID, 'Nickname': props.myname, 'Position': props.myposition, "RoomID": props.roomID, "Role": props.myrole}}));
                    })
                    // .catch((err) => {
                    //     ws.send(JSON.stringify({'Type': 'InitRequest', 'Payload': {'SessionID': TokenService.getLocalAccessToken(), 'GeneralizeID': props.generalizeID, 'Nickname': props.myname, 'Position': props.myposition, "RoomID": props.roomID}}));
                    // })
                } else if (msg.Payload.Code == 100) {
                    setShowCantStartGame(true)
                } else if (msg.Payload.Code == 102) {
                    setShowHasNoMaster(true)
                } else if (msg.Payload.Code == 103) {
                    setTooLessPlayers(true)
                }                    
                break         
        }
    }
}

function GeneralizeGameController(props) {
    const [wsOpened, setWSOpened] = useRecoilState(GeneralizeWSOpened); 
    const [generalizeWSRestartTimeout, setGeneralizeWSRestartTimeout]  = useRecoilState(GeneralizeWSRestartTimeout);
    const [generalizeWS, setGeneralizeWS]  = useRecoilState(GeneralizeWS);
    const [generalizeGameState, setGeneralizeGameState]  = useRecoilState(GeneralizeGameState);
    const [generalizeTeamsState, setGeneralizeTeamsState]  = useRecoilState(GeneralizeTeamsState);
    const [masterGameMap, setMasterGameMap]  = useRecoilState(MasterGameMap);
    const [visibleGameMap, setVisibleGameMap]  = useRecoilState(VisibleGameMap);
    const [votesState, setVotesState]  = useRecoilState(VotesState);
    const [processTime, setProcessTime] = useRecoilState(ProcessTime);
    const [gameFinishedState, setGameFinishedState] = useRecoilState(GameFinishedState);
    const [soundTheme, setSoundTheme]  = useRecoilState(SoundTheme);
	const [useSoundAtom, setUseSoundAtom]  = useRecoilState(UseSoundAtom);
    const [soundLevelAtom, setSoundLevelAtom] = useRecoilState(SoundLevelAtom);
    const [roomInfo, setRoomInfo]  = useRecoilState(RoomInfo);
    const [finishChangeExpPointsInfo, setFinishChangeExpPointsInfo]  = useRecoilState(FinishChangeExpPointsInfo);

    const [canBecameCommonParticipant, setCanBecameCommonParticipant] = useRecoilState(CanBecameCommonParticipant)

    const [showCantStartGame, setShowCantStartGame] = useState(false);
    const [showHasNoMaster, setShowHasNoMaster] = useState(false);
    const [showTooLessPlayers, setTooLessPlayers] = useState(false);

    const [playPositive] = useSound(
		'/sounds/themes/'+soundTheme.theme+'/generalize/positive.mp3',
		{ volume: soundLevelAtom }
	  );
	const [playNegativeNeutral] = useSound(
		'/sounds/themes/'+soundTheme.theme+'/generalize/negative0.mp3',
		{ volume: soundLevelAtom }
	);
    const [playNegativeOther] = useSound(
		'/sounds/themes/'+soundTheme.theme+'/generalize/negative1.mp3',
		{ volume: soundLevelAtom }
	);
    const [playNegativeBlack] = useSound(
		'/sounds/themes/'+soundTheme.theme+'/generalize/negative2.mp3',
		{ volume: soundLevelAtom }
	);
    const [playNewWord] = useSound(
		'/sounds/themes/'+soundTheme.theme+'/generalize/new_word.mp3',
		{ volume: soundLevelAtom }
	);

    
    useEffect(()=> {
        if (generalizeWS.ws == null) {
            ws = new WebSocket(props.apiUrl)
            setGeneralizeWS({ws: ws}) // , TokenService.getLocalAccessToken()
        }
    }, []);

    useEffect(()=> {
        if (generalizeGameState.Phase != "guessing") {
            setVotesState({Votes: []})
        }

    }, [generalizeGameState,])
    useEffect(()=> {
        if (gameFinishedState.FinishGameState != 0) {
            setOpenFinalModal(true)
        }
    }, [gameFinishedState,])

    useEffect(()=> {
        if (props.myid != 0) {
            connect({wsOpened, generalizeWS, generalizeWSRestartTimeout, useSoundAtom, generalizeTeamsState, roomInfo,}, {setWSOpened, setGeneralizeWS, setGeneralizeWSRestartTimeout, setShowCantStartGame, setGeneralizeGameState, 
                setGeneralizeTeamsState, setShowHasNoMaster, setTooLessPlayers, setMasterGameMap, setVisibleGameMap, setVotesState, setProcessTime, setGameFinishedState, setFinishChangeExpPointsInfo, setCanBecameCommonParticipant,}, 
                {playPositive, playNegativeNeutral, playNegativeOther, playNegativeBlack, playNewWord}, props, false)
        }
    }, [soundLevelAtom, useSoundAtom, generalizeTeamsState, roomInfo, props.myid, props.generalizeID, props.roomSeed]);

    const [openFinalModal, setOpenFinalModal] = useState(false);

    const onCloseFinishModal = ()=>{
        setOpenFinalModal(false)
        setGameFinishedState({FinishGameState: 0})
    }
    return (
        <span>
            <Snackbar open={showCantStartGame} 
				onClose={()=>{setShowCantStartGame(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
                sx={{marginBottom: "100px"}}                 
				  key={"CantStartGameWithOnePlayerK"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"CantStartGameWithOnePlayer"}>
				  <FormattedMessage id="CantStartGameWithOnePlayer" />
  				</Alert>
			</Snackbar>

            <Snackbar open={showHasNoMaster} 
				onClose={()=>{setShowHasNoMaster(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
                sx={{marginBottom: "100px"}} 
				  key={"HasNoMasterK"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"HasNoMaster"}>
				  <FormattedMessage id="has_no_master" />
  				</Alert>
			</Snackbar>   

            <Snackbar open={showTooLessPlayers} 
				onClose={()=>{setTooLessPlayers(false)}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                sx={{marginBottom: "100px"}} 
				  key={"TooLessPlayersK"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"TooLessPlayers"}>
				  <FormattedMessage id="too_less_players" />
  				</Alert>
			</Snackbar>

            <Modal
                open={openFinalModal}
                onClose={onCloseFinishModal}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...ModalStyleXL}}>
                <Victory
                    myposition={props.myposition}
                    keepPlaying={onCloseFinishModal}
                    onClose={onCloseFinishModal}
                />
                </Box>
            </Modal>                             	
        </span>
    )
}
export default GeneralizeGameController

const processVoteFinishResults = (msg, useSoundAtom, playPositive, playNegativeNeutral, playNegativeOther, playNegativeBlack)=>{
    if (useSoundAtom) {
        switch (msg.Payload.Result) {
            case -2:
                playNegativeBlack()
                break;
            case -1:
                playNegativeOther()
                break;
            case 0:
                playNegativeNeutral()
                break;
            case 1:
                playPositive()
                break;
        }
    }
}


const processGameFinished = (msg, setGameFinishedState, setFinishChangeExpPointsInfo)=>{
    setFinishChangeExpPointsInfo(msg.Payload.ChangeRatingInfo)
    setGameFinishedState({FinishGameState: msg.Payload.FinishGameState})
}

const processGameState = (msg, setGeneralizeGameState)=>{
    setGeneralizeGameState(msg.Payload)
}

const processTeamsState = (msg, generalizeTeamsState, setGeneralizeTeamsState, useSoundAtom, playNewWord)=>{
    if (useSoundAtom) {
        for (let team in generalizeTeamsState.Teams) {
            for (let team2 in msg.Payload.Teams) {
                if (team == team2 && ((msg.Payload.Teams[team2].Words != null && generalizeTeamsState.Teams[team].Words != null && msg.Payload.Teams[team2].Words.length > generalizeTeamsState.Teams[team].Words.length) || (generalizeTeamsState.Teams[team].Words == null && msg.Payload.Teams[team2].Words != null))) {
                    playNewWord()
                }
            }
        }
    }
    setGeneralizeTeamsState(msg.Payload)
}

const processGameMapForMaster = (msg, setMasterGameMap)=>{
    setMasterGameMap(msg.Payload)
}

const processGameMapVisible = (msg, setVisibleGameMap, setMasterGameMap)=>{
    setVisibleGameMap(msg.Payload)
    setMasterGameMap({Cells: []})
}

const processVotesState = (msg, setVotesState)=>{
    setVotesState(msg.Payload)
}

const processRoundSeconds = (msg, setProcessTime)=>{
    setProcessTime(msg.Payload)
}

const restartGameContext = ({setGeneralizeGameState, setGeneralizeTeamsState, setMasterGameMap, setVisibleGameMap, setVotesState, setProcessTime, setGameFinishedState,})=>{
    setGeneralizeGameState({IsAdmin: false, Phase: "picking", FinishGameState: 0, MadeWordAndNumber: false,})
    setGeneralizeTeamsState({Teams: [],})
    setMasterGameMap({Cells: []})
    setVisibleGameMap({Cells: []})
    setVotesState({Votes: []})
    setProcessTime(0)
    setGameFinishedState({FinishGameState: 0,})
}
import {useRecoilState,} from 'recoil';
import React, { useEffect, useState } from 'react';
import {UseVideo, MyMediaStream, LivekitDevicesRequested} from "../atoms"

function WebcamProcessor(props) {

  const [useVideo, setUseVideo] = useRecoilState(UseVideo);
  const [myMediaStream, setMyMediaStream] = useRecoilState(MyMediaStream);

  const [livekitDevicesRequested, setLivekitDevicesRequested] = useRecoilState(LivekitDevicesRequested)
  const [mediaStreamAlreadySet, setMediaStreamAlreadySet] = useState(false)

    // useEffect(() => {
    //     // Everything around if statement
    //     console.log("ASASAS useEffect of AudioProcessor")
  
    //     navigator.mediaDevices.getUserMedia({audio: props.audioConstraints, video: false}).then(props.onUserMedia).catch(props.onUserMediaError);
    //   }, [])
    
    useEffect(() => {
      if (myMediaStream == null) {
        setMediaStreamAlreadySet(false)
      }
    }, [myMediaStream])

    useEffect(() => {
      if (props.room && props.room != null) {
        if (props.room.localParticipant && !livekitDevicesRequested) {

          props.room.localParticipant.setCameraEnabled(true)
          // Turns microphone track on
          props.room.localParticipant.setMicrophoneEnabled(true)
          setLivekitDevicesRequested(true)
        }        
        if (myMediaStream != null && !mediaStreamAlreadySet) {
            const videoElementsT = document.getElementsByClassName("MyWebcamVideo")
            for (let videoElement of videoElementsT) {
               videoElement['srcObject'] = myMediaStream;
               videoElement.play()
            }
            setMediaStreamAlreadySet(true)
        }
      }    
    }, [props.audioConstraints, props.videoConstraints, props.room, myMediaStream, livekitDevicesRequested])


    // useEffect(() => {
    //     // Everything around if statement 
    //     if (!alreadyCreated) {
    //       setAlreadyCreated(true)
    //       navigator.mediaDevices.getUserMedia({audio: props.audioConstraints, video: props.videoConstraints}).then(props.onUserMedia).catch(props.onUserMediaError);
    //     }
    //   }, [props.audioConstraints, props.videoConstraints, props.sref.current, props.useVideo, alreadyCreated])

    // useEffect(() => {
    //   return ()=>{
    //     const videoElements = document.getElementsByClassName("MyWebcamVideo")
    //     for (let videoElement of videoElements) {
    //       if (videoElement['srcObject']) {
    //         videoElement['srcObject'].getTracks().forEach(function(track) {
    //           videoElement['srcObject'].removeTrack(track);
    //           track.stop();
    //         })
    //       }          
    //     }
    //   }
    // }, [])

    return <div style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", height: "100%",}}><video class="MyWebcamVideo" style={{height: "100%", transform: "rotateY(180deg)", display: useVideo ? "inline-block" : "none",}} autoPlay muted/>
    <audio class="MyAudio"  preload="true" autoPlay/></div>

    // return <video 
    // style={{
    //     display: useVideo ? "inline-block" : "none",
    //     height: "100%",
    //       //zIndex: 9999999,
    //       // display: "none",
    //   }}
    // autoplay muted ref={props.sref} class="MyWebcamVideo"></video>           
}



export default WebcamProcessor
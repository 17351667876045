import {
    useRecoilState,
} from 'recoil';
import { Button, Container, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import {FormattedMessage} from "react-intl";
import Chat from '../../../utils/Chat/Chat';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MicIcon from '@mui/icons-material/Mic';
import VideocamIcon from '@mui/icons-material/Videocam';
import { RoomInfo } from '../../../livekit_prod/atoms';
import RouletteController from './RouletteController';
import UserWindow from '../../../livekit_prod/CallBoxes/Layouts/UserWindow/UserWindow';
import {sendRouletteMsg} from "./RouletteController"
import { RouletteMateInfo, RouletteChatHistory, RoulettesWS, OnRoulettePage } from '../../atoms/RoulettesAtoms';
import { MaxChatMessageLength } from '../../../constants';
import { useEffect } from 'react';
import {influxDBButtonPush} from "../../../services/influxdb/rooms"
import {UserInfoAtom,} from "../../atoms/UserAtoms"

const RoulettePage = ({roommates, apiURL, myid}) => {
	const [roomInfo, setRoomInfo]  = useRecoilState(RoomInfo);
	const [rouletteMateInfo, setRouletteMateInfo] = useRecoilState(RouletteMateInfo)
    const [rouletteChatHistory, setRouletteChatHistory] = useRecoilState(RouletteChatHistory)
    const [roulettesWS, setRoulettesWS] = useRecoilState(RoulettesWS);
	const [onRoulettePage, setOnRoulettePage] = useRecoilState(OnRoulettePage);
	const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);

	useEffect(()=>{
		setOnRoulettePage(true)
	}, [])

	let findNext = ()=>{
		influxDBButtonPush(userInfo, "casual_interlocutors", "next_mate")
		sendRouletteMsg(roulettesWS.ws, "NextMate", {})
	  } 
	  
	const sendChatMsg = (e, msg)=>{
		if (msg.length < MaxChatMessageLength && msg.length > 0) {
			sendRouletteMsg(roulettesWS.ws, "SendChatMessage", {Message: msg})
		}
	}  
	return (
		<Box
			sx={{
				minHeight: '100vh',
				bgcolor: 'background.default',
				color: 'text.primary',
			}}
		>
			<RouletteController myid={myid} apiURL={apiURL}></RouletteController>
			<Container maxWidth="lg" style={{bottom: "60px"}}>
				<Grid container xs={12} spacing={2} direction={{xs: 'column', md: 'row'}} paddingTop={4} paddingBottom={1}>
					<Grid item xs={12} md={8} order={{md: 2}}>
						<Typography variant='h5'>
							Chat
						</Typography>
					</Grid>

					<Grid item xs={12} md={4} order={{md: 1}}>
						<Stack spacing={2} direction="row">
							<Typography variant='body2'>
								<FormattedMessage id="gender" />: <b><FormattedMessage id={rouletteMateInfo.Sex == "" ? "NotKnown" : rouletteMateInfo.Sex} /></b>
							</Typography>

							<Typography variant='body2'>
								<FormattedMessage id="age" />: <b><FormattedMessage id={rouletteMateInfo.Age == "" ? "NotKnown" : rouletteMateInfo.Age} /></b>
							</Typography>
						</Stack>					
					</Grid>
				</Grid>

				<Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
					<Grid container xs={12} md={4} spacing={2} direction={{xs: 'row', md: 'column'}} justifyContent="center">
						<Grid item container xs={6}  md={4} order={{md: 1}} style={{minHeight: '140px', flexBasis: 'calc(50% - 30px)'}}>
							<UserWindow matebox={roommates.get(0)}/>
						</Grid>	

						<Grid item container xs={6}  md={4} order={{md: 3}} style={{minHeight: '140px', flexBasis: 'calc(50% - 30px)'}}>
							<UserWindow matebox={roommates.get(1)}/>
						</Grid>	

						<Grid item xs={12}  md={4} order={{md: 2}} style={{flexBasis: '58px'}}>
							<Button 
								variant="contained"
								color="success"
								size="large"
								fullWidth
								onClick={findNext}
							>
								<FormattedMessage id="next" />
							</Button>
						</Grid>
					</Grid>	

					<Grid container xs={12} md={8}>
						<Chat messages={rouletteChatHistory} sendChatMsg={sendChatMsg}/>
					</Grid>
				</Stack>	
			</Container>
		</Box>
	)
};

export default RoulettePage;
import {
	useRecoilState,
  } from 'recoil';
import { Button, Grid, Switch, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, {useState} from 'react';
import { FormattedMessage } from 'react-intl';
import { RouletteSettings } from '../../atoms/RoulettesAtoms';
import { UserInfoAtom } from '../../atoms/UserAtoms';
import {useNavigate,} from "react-router-dom"
import {influxDBButtonPush} from "../../../services/influxdb/rooms"
import {sendMsgSite} from "../../SiteController"
import {SiteWS, SiteWSOpened} from "../../atoms/SiteAtoms"

const CasualInterlocutors = () => {
    const [rouletteSettings, setRouletteSettings] = useRecoilState(RouletteSettings);
    const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);
	const [siteWS, setSiteWS]  = useRecoilState(SiteWS);

    const navigate = useNavigate();

	const [yourGender, setYourGender] = useState('NoMatter');
	const [genderIinterlocutor, setGenderIinterlocutor] = useState('NoMatter');
	const [yourAge, setYourAge] = useState('NoMatter');
	const [ageIinterlocutor, setAgeIinterlocutor] = useState('NoMatter');
	const [eighteenPlus, setEighteenPlus] = useState(false);

  	const handleChangeYourGender = (event, newYourGender) => {
    	setYourGender(newYourGender);
  	};
	const handleChangeGenderIinterlocutor = (event, newGenderIinterlocutor) => {
    	setGenderIinterlocutor(newGenderIinterlocutor);
  	};
	const handleChangeYourAge = (event, newYourAge) => {
    	setYourAge(newYourAge);
 	};
	const handleChangeAgeIinterlocutor = (event, newAgeIinterlocutor) => {
    	setAgeIinterlocutor(newAgeIinterlocutor);
 	};

	const switchEighteenPlus = (event) => {
		setEighteenPlus(event.target.checked);
	}

  	const findMates = ()=>{
		influxDBButtonPush(userInfo, "casual_interlocutors", "find_mates")
		sendMsgSite(siteWS.ws, "Action", {})
        setRouletteSettings({
            Find: true,
            Type: "tetatet",
            YourSex: yourGender,
            MateSex: genderIinterlocutor,
            YourAge: yourAge,
            MateAge: ageIinterlocutor,
            EighteenPlus: eighteenPlus,
        })
        let userInfoNickname = "<donotknown>"
        if (userInfo.UserInfo != null) {
            userInfoNickname = userInfo.UserInfo.Nickname
        }
        localStorage.setItem('TetatetSettings', JSON.stringify({
            YourSex: yourGender,
            MateSex: genderIinterlocutor,
            YourAge: yourAge,
            MateAge: ageIinterlocutor,
            EighteenPlus: eighteenPlus,
            Nickname: userInfoNickname,
        }))

        navigate('/roulette')
  	}

	return (
		<Grid
			container
			rowSpacing={2}
			spacing={{ xs: 1, sm: 2 }}
			columns={{ xs: 4, sm: 8, md: 12 }}
			direction="column"
			padding={1}
		>
			<Grid item xs={4} sm={8} md={12}>
				<Typography variant='h4'>
					<FormattedMessage id="casual_interlocutors" />
				</Typography>
			</Grid> 

			{false && <Grid
				item 
				container
				rowSpacing={1}
				spacing={{ xs: 1, sm: 2 }}
				columns={{ xs: 4, sm: 8, md: 12 }}
			>
				<Grid item xs={3} sm={4} md={5}>
					<Typography variant='body1'>
						<FormattedMessage id="age_restrictions" />:
					</Typography>
				</Grid>

				<Grid item xs={1} sm={4} md={7}>
					<Switch 
						checked={eighteenPlus}
						onChange={switchEighteenPlus}
					/>
				</Grid>
			</Grid>}	

			<Grid
				item 
				container
				rowSpacing={1}
				spacing={{ xs: 1, sm: 2 }}
				columns={{ xs: 4, sm: 8, md: 12 }}
			>
				<Grid item xs={4} sm={4} md={5}>
					<Typography variant='body1'>
						<FormattedMessage id="your_gender" />:
					</Typography>
				</Grid>

				<Grid item xs={4} sm={4} md={7}>
					<ToggleButtonGroup
						color="primary"
						value={yourGender}
						exclusive
						onChange={handleChangeYourGender}
						aria-label="Platform"
						size="small"
					>
						<ToggleButton value="Male">&nbsp;<FormattedMessage id="male" />&nbsp;</ToggleButton>
						<ToggleButton value="Female">&nbsp;<FormattedMessage id="female" />&nbsp;</ToggleButton>
						<ToggleButton value="NoMatter"><FormattedMessage id="no_matter" /></ToggleButton>
					</ToggleButtonGroup>
				</Grid>
			</Grid>

			<Grid
				item 
				container
				rowSpacing={1}
				spacing={{ xs: 1, sm: 2 }}
				columns={{ xs: 4, sm: 8, md: 12 }}
			>
				<Grid item xs={4} sm={4} md={5}>
					<Typography variant='body1'>
						<FormattedMessage id="gender_interlocutor" />:
					</Typography>
				</Grid>

				<Grid item xs={4} sm={4} md={7}>
					<ToggleButtonGroup
						color="primary"
						value={genderIinterlocutor}
						exclusive
						onChange={handleChangeGenderIinterlocutor}
						aria-label="Platform"
						size="small"
					>
						<ToggleButton value="Male">&nbsp;<FormattedMessage id="male" />&nbsp;</ToggleButton>
						<ToggleButton value="Female">&nbsp;<FormattedMessage id="female" />&nbsp;</ToggleButton>
						<ToggleButton value="NoMatter"><FormattedMessage id="no_matter" /></ToggleButton>
					</ToggleButtonGroup>
				</Grid>
			</Grid>

			<Grid
				item 
				container
				rowSpacing={1}
				spacing={{ xs: 1, sm: 2 }}
				columns={{ xs: 4, sm: 8, md: 12 }}
			>
				<Grid item xs={4} sm={4} md={5}>
					<Typography variant='body1'>
						<FormattedMessage id="your_age" />:
					</Typography>
				</Grid>

				<Grid item xs={4} sm={4} md={7}>
					<ToggleButtonGroup
						color="primary"
						value={yourAge}
						exclusive
						onChange={handleChangeYourAge}
						aria-label="Platform"
						size="small"						
					>
						<ToggleButton value="Young"><FormattedMessage id="young" /></ToggleButton>
						<ToggleButton value="Old"><FormattedMessage id="mature" /></ToggleButton>
						<ToggleButton value="NoMatter"><FormattedMessage id="no_matter" /></ToggleButton>
					</ToggleButtonGroup>
				</Grid>
			</Grid>

			<Grid
				item 
				container
				rowSpacing={1}
				spacing={{ xs: 1, sm: 2 }}
				columns={{ xs: 4, sm: 8, md: 12 }}
			>
				<Grid item xs={4} sm={4} md={5}>
					<Typography variant='body1'>
						<FormattedMessage id="age_interlocutor" />:
					</Typography>
				</Grid>

				<Grid item xs={4} sm={4} md={7}>
					<ToggleButtonGroup
						color="primary"
						value={ageIinterlocutor}
						exclusive
						onChange={handleChangeAgeIinterlocutor}
						aria-label="Platform"
						size="small"						
					>
						<ToggleButton value="Young"><FormattedMessage id="young" /></ToggleButton>
						<ToggleButton value="Old"><FormattedMessage id="mature" /></ToggleButton>
						<ToggleButton value="NoMatter"><FormattedMessage id="no_matter" /></ToggleButton>
					</ToggleButtonGroup>
				</Grid>
			</Grid>

			<Grid item xs={4} sm={8} md={12}>
				<Button variant="contained" size="large" onClick={findMates}>
					<FormattedMessage id="search" />
				</Button>
			</Grid>
		</Grid>
	)
};

export default CasualInterlocutors;
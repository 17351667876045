import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import './features-item.scss';
import React from 'react';
import { NavLink } from 'react-router-dom';
import {influxDBButtonPush} from "../../../../services/influxdb/navigation"

const FeaturesItem = ({userInfo, icon, description, url}) => {
	const onClickLink = ()=>{
		switch (url) {
			case "/games":
				influxDBButtonPush(userInfo, "home", "games")
				break
			case "/rooms":
				influxDBButtonPush(userInfo, "home", "rooms")
				break
			case "/communities":
				influxDBButtonPush(userInfo, "home", "communities")
				break						
		}
	}

	return (
		<NavLink
			to={url}
			className="features-item" 
			onClick={onClickLink}
		>
			<Stack 				
				alignItems="center" 
				spacing={2} 
				padding={4}
			>
				<img src={icon} alt="icon" />

				<Typography variant='body2' paddingTop={0.5} textAlign="center">
					{description}
				</Typography>
			</Stack>
		</NavLink>	
	)
};

export default FeaturesItem;
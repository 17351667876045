import Api from "./Api"
import StaticApi from "./StaticAPI"







const getCommunitiesList =  async function(page, numToShow) {
    return await Api.post(`/communities/list`, {Page: page, NumberOnOnePage: numToShow})
}

const createCommunity =  async function(name, communityType, icon, description, telegram, acceptEveryone) {
    return await Api.post(`/community/create`, {Name: name, CommunityType: communityType, Icon: icon, Description: description, Telegram: telegram, AcceptEveryone: acceptEveryone})
}

const uploadAvatar = async function(avatarFile, communityID) {
    return await StaticApi.post(`/community/uploadAvatar`, avatarFile, {headers:{"Content-Type": "multipart/form-data", "x-community-id": communityID}})
}

const communityDetails = async function(communityID) {
    return await Api.post(`/communities/communityDetails`, {CommunityID: communityID})
}

const listEvents = async function(communityID) {
    return await Api.post(`/community/listEvents`, {CommunityID: communityID})
}

const leaveCommunity = async function(communityID) {
    return await Api.post(`/community/leaveCommunity`, {CommunityID: communityID})
}

const createEvent = async function(communityID, name, eventType, datetime, description, maxUsers) {
    return await Api.post(`/community/createEvent`, {CommunityID: communityID, Name: name, EventType: eventType, Datetime: datetime, 
        Description: description, MaxUsers: maxUsers})
}

const listUsers = async function(communityID) {
    return await Api.post(`/communities/communityUsers`, {CommunityID: communityID})
}

const listRequest = async function(communityID) {
    return await Api.post(`/community/listRequests`, {CommunityID: communityID})
}

const joinRequest = async function(communityID) {
    return await Api.post(`/community/joinRequest`, {CommunityID: communityID})
}

const userStatus = async function(communityID) {
    return await Api.post(`/community/userStatus`, {CommunityID: communityID})
}

const declineMyRequest = async function(communityID) {
    return await Api.post(`/community/declineMyRequest`, {CommunityID: communityID})
}

const declineRequest = async function(userID, communityID) {
    return await Api.post(`/community/declineRequest`, {UserID: userID, CommunityID: communityID})
}

const acceptRequest = async function(userID, communityID) {
    return await Api.post(`/community/acceptRequest`, {UserID: userID, CommunityID: communityID})
}

const banUser = async function(userID, communityID) {
    return await Api.post(`/community/banUser`, {UserID: userID, CommunityID: communityID})
}

const eventDetails = async function(communityID, eventID) {
    return await Api.post(`/community/eventDetails`, {CommunityID: communityID, EventID: eventID})
}

const joinEvent = async function(communityID, eventID) {
    return await Api.post(`/community/joinEvent`, {CommunityID: communityID, EventID: eventID})
}

const declineEvent = async function(communityID, eventID) {
    return await Api.post(`/community/declineEvent`, {CommunityID: communityID, EventID: eventID})
}

const myEvents = async function() {
    return await Api.post(`/community/myEvents`, {})
}

const myCommunities = async function() {
    return await Api.post(`/community/myCommunities`, {})
}

const editCommunity = async function(id, icon, description,) {
    return await Api.post(`/community/editCommunity`, {ID: id, Icon: icon, Description: description,})
}

const joinEventRoom = async function(eventID) {
    return await Api.post(`/community/joinEventRoom`, {EventID: eventID,})
}

const transferOwnership = async function(communityID, userID) {
    return await Api.post(`/community/transferOwnership`, {UserID: userID, CommunityID: communityID,})
}

const CommunitiesService = {
    getCommunitiesList,
    createCommunity,
    uploadAvatar,
    communityDetails,
    listEvents,
    createEvent,
    listUsers,
    listRequest,
    joinRequest,
    userStatus,
    declineMyRequest,
    declineRequest,
    acceptRequest,
    banUser,
    eventDetails,
    joinEvent,
    declineEvent,
    myEvents,
    myCommunities,
    editCommunity,
    joinEventRoom,
    leaveCommunity,
    transferOwnership,
}

export default CommunitiesService;
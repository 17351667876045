import {
    useRecoilState,
  } from 'recoil';
import {Authorized, AuthTokens} from './atoms/AuthAtoms'
import {UserInfoAtom} from './atoms/UserAtoms'
import {RouletteSettings} from './atoms/RoulettesAtoms'
import {SuccessMessage, EndSaltLength, MaxAvatarSize} from "../constants";
import AuthService from "../services/AuthService";
import TalkService from "../services/rooms/TalkService";
import UserService from '../services/UserService';
import RoomService from '../services/rooms/RoomService';
import HackwordService from '../services/games/HackwordService';
import {makeString} from "../utils/common"
import React, { useState } from "react";
import { SmartCaptcha } from '@yandex/smart-captcha';
import {ErrorBoundary} from 'react-error-boundary'
import {domainNameAPI, protocolAPI, apiPort} from "../environment"
import {redirect, useNavigate} from "react-router-dom"


function ErrorFallback({error, resetErrorBoundary}) {
    return (
      <span role="alert">
      </span>
    )
  }

function Index() {
    const [authorized, setAuthorized] = useRecoilState(Authorized);
    const [authTokens, setAuthTokens] = useRecoilState(AuthTokens);
    const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);
    const [token, setToken] = useState('');
    const navigate = useNavigate();


    let clickRefreshToken = ()=>{
        AuthService.refreshToken(authTokens.AuthToken, authTokens.RefreshToken).then(response=>{
            if (response.Success == SuccessMessage) {
                localStorage.setItem('AuthToken', JSON.stringify(response.data.Tokens.AuthToken+makeString(EndSaltLength)));
                localStorage.setItem('RefreshToken', JSON.stringify(response.data.Tokens.RefreshToken+makeString(EndSaltLength)));
                setAuthTokens({AuthToken: response.data.Tokens.AuthToken, RefreshToken: response.data.Tokens.RefreshToken})
            }
        })
    }

    let createRoom = ()=>{
        TalkService.createTalk("Room1", 3, false, "video", [], "").then(()=>{navigate("/room")})
    }

    const [login, setlogin] = useState("");
    const [password, setpassword] = useState("");

    let changeUser = ()=>{
        setUserInfo({UserInfo: null})
        if (token === "") {
            console.log("Token does not set");
           // return;
        }
        console.log("Token = "+token)
        AuthService.loginPassword(login, password).then(response=>{
            console.log("response", response)

            if (response.data.Success == SuccessMessage) {
                localStorage.setItem('AuthToken', response.data.Tokens.AuthToken+makeString(EndSaltLength));
                localStorage.setItem('RefreshToken', response.data.Tokens.RefreshToken+makeString(EndSaltLength));
                localStorage.setItem('DeviceKey', response.data.Tokens.DeviceKey);
                setAuthTokens({AuthToken: response.data.Tokens.AuthToken, RefreshToken: response.data.Tokens.RefreshToken})
                setAuthorized({Authorized: true})
            }
        })
    }

    const [roomid, setroomid] = useState("");
    let joinRoom = ()=>{
        TalkService.joinTalk(parseInt(roomid)).then(()=>{
            navigate("/room")
        })
    }
    const captchaStyle = {
        width: "300px",
        height: "100px",
    }

    const [newpassword, setnewpassword] = useState("");
    const changePassword = ()=>{
        UserService.changePassword(newpassword).then(response=>{
            console.log("response", response)

            if (response.data.Success == SuccessMessage) {
            }
        })
    }


    let apiUrl = protocolAPI+domainNameAPI+apiPort
    const [selectedFile, setSelectedFile] = useState(null);
    let avaSrc = ""
    if (userInfo.UserInfo && userInfo.UserInfo != null) {
        avaSrc=apiUrl+"/mkstatic/shared/avatars/"+userInfo.UserInfo.IconUrl
    }
    const avaStyle = {
        width: "50px"
    }
    const uploadAvatar = (event)=>{
        event.preventDefault()
        const formData = new FormData();
        formData.append("avatarFile", selectedFile);
        UserService.uploadAvatar(formData).then(()=>{
            UserService.getUserInfo().then(response=>{
                if (response.data.Success == SuccessMessage) {
                    setUserInfo({UserInfo: response.data.UserInfo})
                }
            })
        })
    }
    const handleFileSelect = (event) => {
        console.log(event.target.files[0])
        if (event.target.files[0].size > MaxAvatarSize){
            alert("File is too big!");
            // Show message
         } else {
            setSelectedFile(event.target.files[0])
         }
    }


    const leaveRoom = ()=>{
        RoomService.leaveRoom()
    }
    const createHackword = ()=>{
        HackwordService.createHackword("Hackword room", 3, true, false, true, "123") // name, numOfPlayers, everybodyHear, eighteenPlus, closed, passwd
    }
    
    const [hackwordid, sethackwordid] = useState("");
    const joinHackword = ()=>{
        HackwordService.joinHackword(parseInt(hackwordid))
    }

    const playHackword = ()=>{
        HackwordService.playHackword()
    }

    const [rouletteSettings, setRouletteSettings] = useRecoilState(RouletteSettings);
    const findRoulette = ()=>{
        setRouletteSettings({
            Find: true,
            Type: "tetatet",
            YourSex: "Female",
            MateSex: "Female",
            YourAge: "Young",
            MateAge: "Young",
            EighteenPlus: true,
        })
        let userInfoNickname = "<donotknown>"
        if (userInfo.UserInfo != null) {
            userInfoNickname = userInfo.UserInfo.Nickname
        }
        localStorage.setItem('TetatetSettings', JSON.stringify({
            // YourSex: "Male",
            // MateSex: "Mate",
            // YourAge: "Old",
            // MateAge: "Young",
            YourSex: "Male",
            MateSex: "Male",
            YourAge: "Young",
            MateAge: "NoMatter",
            EighteenPlus: true,
            Nickname: userInfoNickname,
        }))

        navigate('/roulette')
    }

    const buyPremium = ()=>{
        UserService.buyPremiumMonth().then(response=>{
            if (response.data.Success == SuccessMessage) {
                console.log("Bought premium")
            } else {
                alert("Error: "+JSON.stringify(response.data))
            }
        })
    }
    return (
        
        <div>
            {authorized.Authorized ? <div>Yeah - Авторизован</div> : <div>Nope - He aвторизован</div>}
            <div>UserName: {userInfo.UserInfo ? userInfo.UserInfo.Nickname : "<n/a>"}</div>
            <div>Auth: {authTokens.AuthToken}</div>
            <div>Refresh: {authTokens.RefreshToken}</div>
            <div onClick={clickRefreshToken}>Обновить</div>
            <div><button onClick={createRoom}>Создать комнату</button></div>
            <div><input type="text" size="40" value={login} onChange={e => setlogin(e.target.value)}/>
            <input type="text" size="40" value={password} onChange={e => setpassword(e.target.value)}/>
            <button onClick={changeUser}>Change User</button></div>
            <div><input type="text" size="40" value={roomid} onChange={e => setroomid(e.target.value)}/>  
                <button onClick={joinRoom}>Зайти</button></div>
            <div><input type="text" size="40" value={newpassword} onChange={e => setnewpassword(e.target.value)}/>  
                <button onClick={changePassword}>Сменить пароль</button></div>
        <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}>
                    <div style={captchaStyle}><SmartCaptcha style={captchaStyle} sitekey="eRXeoPX94pvnjAHgL76fKRVnHnQ25eoRdcndRDwG" onSuccess={setToken} />  </div>
        </ErrorBoundary>

        <div>
            <img style={avaStyle} src={avaSrc}></img>
            <input type="file" onChange={handleFileSelect} accept="image/*"/>
            <button onClick={uploadAvatar}>Загрузить аву</button>
        </div>
        <div><button onClick={leaveRoom}>Покинуть комнату</button></div>
        <div><button onClick={createHackword}>Создать Hackword</button></div>
        <div><input type="text" size="40" value={hackwordid} onChange={e => sethackwordid(e.target.value)}/> <button onClick={joinHackword}>Присоединиться Hackword</button></div>
        <div><button onClick={playHackword}>Играть</button></div>
        <div><button onClick={findRoulette}>В рулетку</button></div>
        <div><button onClick={buyPremium}>Купить премиум</button></div>
        </div>
    );
}

export default Index; 
import { LOCALES } from '../locales';

export default {
  [LOCALES.ENGLISH]: {
    is_visible_others: `Is it visible to others?`,
    games: `Games`,
    rooms: `Rooms`,
    articles: `Articles`,
    login: `LogIn`,
    registration: `Sign up`,
    about: `About me`,
    name: `Name`,
    surname: `Surname`,
    phone: `Phone`,
    country: `Country`,
    region: `Region`,
    city: `City`,
    change_password: `Change Password`,
    cancel: `Cancel`,
    save: `Save`,
    username: `Username`,
    password: `Password`,
    repeat_password: `Repeat the password`,
    invalid_password: `Invalid password`,
    sign_up: `Sign up`,
    email: `Email`,
    nickname: `Nickname`,
    play: `Play`,
    create_your_own: `Create your own`,
    lobby_hackword_room: `Lobby Hackword Room`,
    hackword_room: `Hackword Room`,
    who_am_i: `Who am I?`,
    for_their_own: `For their own`,
    everyone_over_here: `Everyone over here!`,
    creating_game: `Creating a game`,
    name_game: `Name of the game`,
    number_players: `Number of players`,
    can_hear_everyone: `You can hear everyone and always`,
    just_your_team: `or just your team`,
    age_restrictions: `18+ (mat, vulgarity)`,
    closed: `Closed`,
    create: `Create`,
    teams: `Teams`,
    score: `Score`,
    set_of_words: `A set of words`,    
    sec: `sec`,
    time_for_round: `Time for the round`,
    words_to_victory: `Words to victory`,
    settings: `Settings`,
    easy: `Easy`,
    complex: `Complex`,
    professions: `Professions`,
    animal: `Animal`,
    places_on_earth: `Places on Earth`,
    number_of_teams: `Number of teams`,
    start_over: `Start over`,
    shuffle_players: `Shuffle players`,
    ready: `Ready`,
    round: `Round`,
    round_words: `Кщгтв words`,
    you_won: `You have won`,
    your_team: `Your team`,
    your_place: `Your place:`,
    exit: `Exit`,
    another_one: `Another one`,
    create_room: `Create a room`,
    casual_interlocutors: `Casual interlocutors`,
    creating_room: `Creating a room`,
    room_name: `Room name`,
    number_participants: `Number of participants`,
    tag_text: `Tag text`,
    add: `Add`,
    your_gender: `Your gender`,
    gender_interlocutor: `Gender of the interlocutor`,
    your_age: `Your age`,
    age_interlocutor: `The age of the interlocutor`,
    search: `Search`,
    male: `M`,
    female: `F`,
    no_matter: `No matter`,
    young: `Young`,
    mature: `Mature`,
  },
};
import {
    atom,
} from 'recoil';

export const ChatWSOpened = atom({
    key: 'ChatWSOpened',
    default: {
        opened: false,
    },
})

export const ChatWS = atom({
    key: 'ChatWS',
    default: {
        ws: null,
    },
})

export const ChatWSRestartTimeout = atom({
    key: 'ChatWSRestartTimeout',
    default: {
        timeout: 0,
    },
})
import {
    useRecoilState,
  } from 'recoil';
import { HackwordGameSettings, TeamToPlay, RoundPhase } from '../atoms/GameConfiguration';
import React, { useState, useEffect } from 'react';
import {FormattedMessage} from "react-intl";
import {sendMsgHackword} from "../HackwordGameController";
import {HackwordWS} from "../atoms/Connection"
import { Button, Chip, } from '@mui/material';
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';

export const checkIfInTeamToPlay = (position, teamToPlay)=>{
    let inTeamToPlay = false
    if (position == teamToPlay.Explains) {
        inTeamToPlay = true
    } else {
      for (let guess of teamToPlay.Guess) {
        if (guess == position) {
            inTeamToPlay = true
          break
        }
      }
    }
    return inTeamToPlay
}

export const checkIfReadyToPlay = (position, teamToPlay)=>{
    let readyToPlay = false
    for (let ready of teamToPlay.Ready) {
        if (ready == position) {
            readyToPlay = true
            break
        }
    }
    return readyToPlay
}

const HackwordGameBox = ({livekitBox, position, modal})=>{
    const [box, setBox] = useState(livekitBox)

    useEffect(()=> {
        let boxUp = livekitBox
        if (React.isValidElement(boxUp)) {
            boxUp = React.cloneElement(boxUp, { modal: modal }); 
        }
        setBox(boxUp)   
    }, [livekitBox])


    const [gameSettings, setGameSettings]  = useRecoilState(HackwordGameSettings); 
    const [teamToPlay, setTeamToPlay]  = useRecoilState(TeamToPlay);
    const [hackwordWS, setHackwordWS]  = useRecoilState(HackwordWS);
    const [roundPhase, setRoundPhase]  = useRecoilState(RoundPhase);

    let inTeamToPlay = checkIfInTeamToPlay(position, teamToPlay)

    let containerStyle = {
        height: "100%",
    }
    let readyToPlay = false
    let explaining = false
    let guessing = false
    if (inTeamToPlay) {
        readyToPlay = checkIfReadyToPlay(position, teamToPlay)
        if (position == teamToPlay.Explains) {
            explaining = true
          } else {
            for (let guess of teamToPlay.Guess) {
              if (guess == position) {
                guessing = true
                break
              }
            }
          }
    }

    const readyButtonStyle = {
        position: "absolute",
        bottom: "23px",
        left: "50%",
        transform: "translate(-50%, 0%)",
    }
    const roleChipStyle = {
        position: "absolute", 
        bottom: "23px",
        right: "23px",
        backgroundСolor: "rgb(200, 200, 200)",
    }
    let mobile = false
    if (window.matchMedia("(max-width: 899px)").matches && !modal) {
        mobile = true
    }
    return (
        <div style={containerStyle}>
            {box}
            {inTeamToPlay && readyToPlay && roundPhase.Phase != "running" && <Button style={readyButtonStyle} variant="contained" color="success"><FormattedMessage id="ready" /></Button>}
            {inTeamToPlay && !readyToPlay && roundPhase.Phase != "running" && <Button style={readyButtonStyle} variant="contained" color="error"><FormattedMessage id="not_ready" /></Button>}
            {explaining && !mobile && <Chip sx={{color: "white"}} label={<FormattedMessage id="game_explaining" />} style={roleChipStyle}/>}
            {guessing && !mobile && <Chip sx={{color: "white"}} label={<FormattedMessage id="game_guessing" />} style={roleChipStyle}/>}
        </div>
    )
}

export default HackwordGameBox
import { Button, Stack, Typography, Avatar, } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import React, { useState, useEffect } from 'react';
import FlatChat from "../FlatChat/FlatChat"
import Minimize from '@mui/icons-material/Minimize';
import Close from '@mui/icons-material/Close';
import Chat from '@mui/icons-material/Chat';
import {useRecoilState,} from 'recoil';
import {OpenedPersonalChats} from "../../atoms/UserAtoms"
import {NotWatchedPersonalChats} from "../FlatChat/atoms/Chat"
import {domainNameAPI, protocolAPI, apiPort} from "../../../environment"
import {ThemeAtom,} from "../../atoms/Common"
import {makeString} from "../../../utils/common"
import ChatMenu from "./ChatMenu"







const SmallChat = (props)=>{
    const [openedPersonalChats, setOpenedPersonalChats] = useRecoilState(OpenedPersonalChats);
	const [notWatched, setNotWatched] = useRecoilState(NotWatchedPersonalChats);

    const [innerTheme, _] = useRecoilState(ThemeAtom);

    const handleMinimize = ()=>{
        let newChats = [...openedPersonalChats]
        let i = 0
        while (i < newChats.length) {
            if (newChats[i].occasionID == props.occasionID && newChats[i].occasionType == props.occasionType) {
                newChats[i] = {...newChats[i], opened: false}
                break
            }
            i++
        }
        setOpenedPersonalChats(newChats)
    }
    const handleClose = ()=>{
        let newChats = [...openedPersonalChats]
        let i = 0
        while (i < newChats.length) {
            if (newChats[i].occasionID == props.occasionID && newChats[i].occasionType == props.occasionType) {
                newChats.splice(i, 1)
                break
            }
            i++
        }
        setOpenedPersonalChats(newChats)
    }
    const handleMaximize = ()=>{
        if (!props.opened) {
            let newChats = [...openedPersonalChats]
            let i = 0
            while (i < newChats.length) {
                if (newChats[i].occasionID == props.occasionID && newChats[i].occasionType == props.occasionType) {
                    newChats[i] = {...newChats[i], opened: true}
                    break
                }
                i++
            }
            setOpenedPersonalChats(newChats)
        }
    }
    let newMessages = false
    if (!props.opened) {
        for (let notWatchedChat of notWatched) {
            if (props.occasionType == "personal" && props.occasionID == notWatchedChat.UserID) {
                newMessages = true
                break
            }
        }
    }
    let apiUrl = protocolAPI+domainNameAPI+apiPort
    let avaSrc = "/themes/"+innerTheme.theme+"/imgs/icons/user.png";
    if (props.icon && props.icon !== "") {
      avaSrc=apiUrl+"/mkstatic/shared/avatars/"+props.icon+"?dop"+makeString(5)
    }
    let mobile = false
    if (window.matchMedia("(max-width: 899px)").matches) {
        mobile = true
    }
    if (mobile) {
        if (props.opened) {
            return (<Stack direction="column"
            style={{height: "100%",}}
            >
                <Stack direction="row"
                style={{padding: "7px", cursor: props.opened ? undefined : "pointer"}}
                onClick={handleMaximize}
                >
                    {props.occasionType == "personal" && props.opened && <Avatar
                        key={"akey"}
                        alt={props.title}
                        src={avaSrc}
                        sx={{ width: 31, height: 31, position:"absolute", left: "1px", top: "0px", bgcolor: 'background.default', color: 'text.primary', border: "1px solid black"}}
                    />}
                    <div style={{marginLeft: props.occasionType == "personal" && props.opened ? "33px" : undefined}}>{props.title}</div>
                    {newMessages && <div style={{backgroundColor: "#f73636", borderRadius: "30px", width: "20px", height: "20px", marginLeft: "10px", textAlign: "center", verticalAlign: "middle", lineHeight: "20px"}}>+</div>}
                </Stack>
                <div style={{position: "absolute", top: "5px",right: "5px"}}>
                        <ChatMenu occasionID={props.occasionID} occasionType={props.occasionType} toClose={handleClose}/>
                        {props.opened && <Minimize onClick={handleMinimize} style={{cursor: "pointer", width: "40px"}}/>}
                        <Close onClick={handleClose} style={{cursor: "pointer", width: "40px"}}/>                
                </div>
                <FlatChat hide={!props.opened} fullHeight={true} key={props.occasionType+props.occasionID} occasionID={props.occasionID} occasionType={props.occasionType}/>
            </Stack>)
        } else {
            return (
                <div style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", cursor: "pointer"}} onClick={handleMaximize}>
                    <FlatChat key={props.occasionType+props.occasionID} hide={true} occasionID={props.occasionID} occasionType={props.occasionType}/>
                    {props.occasionType == "personal" ? <Avatar
                        key={"akey"}
                        alt={props.title}
                        src={avaSrc}
                        sx={{ width: 50, height: 50, bgcolor: 'background.default', color: 'text.primary',}}
                    /> : 
                        <Chat style={{width: 50, cursor: "pointer"}} onClick={handleMinimize} />
                    }
                </div>
            )
        }
    }
    return (
        <Stack direction="column"
        >
            <Stack direction="row"
            style={{padding: "7px", cursor: props.opened ? undefined : "pointer"}}
            onClick={handleMaximize}
            >
                {props.occasionType == "personal" && props.opened && <Avatar
                    key={"akey"}
                    alt={props.title}
                    src={avaSrc}
                    sx={{ width: 33, height: 33, position:"absolute", left: "-7px", top: "0px", bgcolor: 'background.default', color: 'text.primary', border: "1px solid black"}}
                />}
                <div style={{marginLeft: props.occasionType == "personal" && props.opened ? "24px" : undefined}}>{props.title}</div>
                {newMessages && <div style={{backgroundColor: "#f73636", borderRadius: "30px", width: "20px", height: "20px", marginLeft: "10px", textAlign: "center", verticalAlign: "middle", lineHeight: "20px"}}>+</div>}
            </Stack>
            <div style={{position: "absolute", top: "5px",right: "5px"}}>
                    <ChatMenu occasionID={props.occasionID} occasionType={props.occasionType} toClose={handleClose}/>
                    {props.opened && <Minimize onClick={handleMinimize} style={{cursor: "pointer"}}/>}
                    <Close onClick={handleClose} style={{cursor: "pointer"}}/>                
            </div>
            <FlatChat key={props.occasionType+props.occasionID} hide={!props.opened} occasionID={props.occasionID} occasionType={props.occasionType}/>
        </Stack>
    )
}

export default SmallChat;
import { Box,  IconButton, Menu, MenuItem,  } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {FormattedMessage} from "react-intl";
import React, { useState, useEffect } from 'react';
import {useRecoilState,} from 'recoil';
import {ChatWS} from "../FlatChat/atoms/Connection"
import {sendMsgChat} from "../FlatChat/ChatController"












const ChatMenu = (props)=>{
    const [anchorElNav, setAnchorElNav] = useState(null);
	const [chatWS, setChatWS]  = useRecoilState(ChatWS);

    const handleOpenNavMenu = (event) => {        
        setAnchorElNav(event.currentTarget);
      };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
      };
     
    const blockChat = ()=>{
        sendMsgChat(chatWS.ws, "BlockChat", {OccasionID: props.occasionID, OccasionType: props.occasionType})        
        handleCloseNavMenu()
        props.toClose()
    }  
    return (
        <Box style={{cursor: "pointer", width: "40px", display: props.occasionType == "personal" ? "inline-block" : "none", position: "relative", padding: 0, top: "-7px"}}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: 'block', },
            color: 'inherit',
          }}
        >
            <MenuItem key={1} onClick={blockChat}>
                <FormattedMessage id="block_chat" />
            </MenuItem>
        </Menu>
      </Box>
    )
}

export default ChatMenu

import {
	useRecoilState,
  } from 'recoil';
import { IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import React, { useState } from 'react';
import { sendMsgHackword } from '../HackwordGameController';
import {HackwordWS} from "../atoms/Connection"
import {SoundTheme, UseSoundAtom, SoundLevelAtom} from "../../../../atoms/Common"
import useSound from 'use-sound';

const CurrentWord = ({ children, }) => {
	const [hackwordWS, setHackwordWS]  = useRecoilState(HackwordWS);
	const [soundTheme, setSoundTheme]  = useRecoilState(SoundTheme);
	const [useSoundAtom, setUseSoundAtom]  = useRecoilState(UseSoundAtom);
    const [soundLevelAtom, setSoundLevelAtom] = useRecoilState(SoundLevelAtom);

	const [playPositive] = useSound(
		'/sounds/themes/'+soundTheme.theme+'/hackword/positive_short.mp3',
		{ volume: soundLevelAtom }
	  );
	const [playNegative1] = useSound(
		'/sounds/themes/'+soundTheme.theme+'/hackword/negative_short_1.mp3',
		{ volume: soundLevelAtom }
	);


	const handleClick = (e, guessed) => {
		if (useSoundAtom) {
			if (guessed) {
				playPositive()
			} else {
				playNegative1()
			}
		}
		sendMsgHackword(hackwordWS.ws, "HackResult", {Guessed: guessed})
  	};

	return (
		<Stack 
			direction="row" 
			alignItems="center" 
			justifyContent="center" 
			spacing={2} 
			style={{ padding: '30px 0', margin: '20px 0' }}
		>
			<IconButton aria-label="up" onClick={(e)=>handleClick(e, true)}>
				<ThumbUpIcon color="success" />
			</IconButton>

			<Typography variant="h4" style={{ wordBreak: "break-all" }}>
				{children}
			</Typography>			

			<IconButton aria-label="down" onClick={(e)=>handleClick(e, false)}>
				<ThumbDownIcon color="error"/>
			</IconButton>
		</Stack>
	);
};

export default CurrentWord;

import React, { useState } from 'react';
import {FormattedMessage} from "react-intl";
import { Button, Grid, Typography } from '@mui/material';
import {sendMsg} from "../../../WSController"





function BanModal(props) {

    const clickBan = ()=>{
        sendMsg("AdminBan", {UserID: props.userid})
        props.closeModal()
    }
    return (
        <Grid
        container
        rowSpacing={2}
        spacing={{ xs: 1, sm: 2 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        direction="column"
        padding={1}
        alignItems="center" justifyContent="center"
    >
            <Grid item xs={4} sm={8} md={12}>
                <Typography variant='h4'>
                    <FormattedMessage id="ban_user" /> {props.identity}
                </Typography>
            </Grid>

            <Grid item xs={4} sm={8} md={12}>
                <FormattedMessage id="if_ban_then_leave" />
			</Grid> 
            <Grid item xs={4} sm={8} md={12} >
				<Button variant="contained" size="large" onClick={clickBan}>
					<FormattedMessage id="ban" />
				</Button>
			</Grid>
		</Grid>              
    )
}

export default BanModal;
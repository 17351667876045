import {
  useRecoilState,
} from 'recoil';
import { Button, IconButton, Modal, Stack, Typography, Tooltip, Grid } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import Navbar from "../../../Navbar/Navbar"
import Auth from '../../../user/Auth/Auth';
import {FormattedMessage} from "react-intl";
import ModalStyle, { ModalStyleXL } from "../../../../utils/ModalStyle/ModalStyle"
import HackwordRoomTeams from "./HackwordRoomTeams/HackwordRoomTeams"
import Headphones from '@mui/icons-material/Headphones';
import Mic from '@mui/icons-material/Mic';
import AllWords from "./AllWords/AllWords"
import Word from "./Word/Word"
import HackwordGameController from './HackwordGameController';
import {HackwordGameSettings, TeamsState, HackwordSettingsOpened, TeamToPlay, RoundSecondsPassed, WordToHack, WordsHistory, RoundPhase, DoWinCondition, GameFinished} from "./atoms/GameConfiguration"
import {HackwordWS} from "./atoms/Connection"
import {UserInfoAtom} from "../../../atoms/UserAtoms"
import { sendMsgHackword } from './HackwordGameController';
import InfoNote from "./InfoNote"
import HelpIcon from '@mui/icons-material/Help';
import HackwordRoomSetting from "./HackwordRoomSetting/HackwordRoomSetting"
import SettingsIcon from '@mui/icons-material/Settings';
import CurrentWord from "./CurrentWord/CurrentWord";
import RadialChart from "./RadialChart/RadialChart"
import {influxDBButtonPush} from "../../../../services/influxdb/hackword"
import {checkIfReadyToPlay} from "./HackwordGameBox/HackwordGameBox"
import {SoundTheme, UseSoundAtom, SoundLevelAtom} from "../../../atoms/Common"
import useSound from 'use-sound';
import PreRunningRoundPhase from "./PreRunningRoundPhase/PreRunningRoundPhase"
import {shuffle} from "../../../../utils/common"
import {ShowChangeRoomType, SendAdmin} from "../../../../livekit_prod/atoms"
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


const InHackwordGame = (props) => {
  const [gameSettings, setGameSettings]  = useRecoilState(HackwordGameSettings); 
  const [hackwordWS, setHackwordWS]  = useRecoilState(HackwordWS);
  const [teamToPlay, setTeamToPlay]  = useRecoilState(TeamToPlay);
  const [wordToHack, setWordToHack]  = useRecoilState(WordToHack);
  const [wordsHistory, setWordsHistory]  = useRecoilState(WordsHistory);
  const [roundPhase, setRoundPhase]  = useRecoilState(RoundPhase);
  const [doWinCondition, setDoWinCondition]  = useRecoilState(DoWinCondition);
  const [gameFinished, setGameFinished] = useRecoilState(GameFinished);
  const [userInfo, setUserInfo]  = useRecoilState(UserInfoAtom);
  const [sendAdmin, setSendAdmin]  = useRecoilState(SendAdmin);
	const [soundTheme, setSoundTheme]  = useRecoilState(SoundTheme);
	const [useSoundAtom, setUseSoundAtom]  = useRecoilState(UseSoundAtom);

	const [open, setOpen] = useState(false);
  const [openAllWords, setOpenAllWords] = useState(false);
  const [openSetting, setOpenSetting] = useRecoilState(HackwordSettingsOpened);
  const [soundLevelAtom, setSoundLevelAtom] = useRecoilState(SoundLevelAtom);

  const [showChangeRoomType, setShowChangeRoomType] = useRecoilState(ShowChangeRoomType)

  const [playReady] = useSound(
		'/sounds/themes/'+soundTheme.theme+'/hackword/ready1.mp3',
		{ volume: soundLevelAtom }
	  );

    const [play3] = useSound(
      '/sounds/themes/'+soundTheme.theme+'/hackword/3.mp3',
      { volume: soundLevelAtom, }
  );
  const [play2] = useSound(
      '/sounds/themes/'+soundTheme.theme+'/hackword/2.mp3',
      { volume: soundLevelAtom, }
  );
  const [play1] = useSound(
      '/sounds/themes/'+soundTheme.theme+'/hackword/1.mp3',
      { volume: soundLevelAtom, }
  );
  const [playGo] = useSound(
      '/sounds/themes/'+soundTheme.theme+'/hackword/go.mp3',
      { volume: soundLevelAtom, }
  );    

	const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenSetting = () => {
    influxDBButtonPush(userInfo, props.hackwordID, "open_settings")
    setOpenSetting({opened: true});
  };

  const handleCloseSetting = () => {
    setOpenSetting({opened: false});
  };

  const handleOpenAllWords = () => {
    influxDBButtonPush(userInfo, props.hackwordID, "all_words")
    setOpenAllWords(true);
  };

  const handleCloseAllWords = () => {
    setOpenAllWords(false);
  };

  const stopPickingPlayers = ()=>{
    influxDBButtonPush(userInfo, props.hackwordID, "stop_picking_players")
    sendMsgHackword(hackwordWS.ws, "StopPickingPlayers", {})
  }
  const startGame = ()=>{
    influxDBButtonPush(userInfo, props.hackwordID, "start_game")
    sendMsgHackword(hackwordWS.ws, "StartGame", {})
  }
  const startPickingPlayers = ()=>{
    influxDBButtonPush(userInfo, props.hackwordID, "start_picking_players")
    sendMsgHackword(hackwordWS.ws, "StartPickingPlayers", {})
  }
  const beReady = ()=>{
    influxDBButtonPush(userInfo, props.hackwordID, "be_ready")
    if (useSoundAtom) {
      playReady()
    }
    sendMsgHackword(hackwordWS.ws, "IamReady", {})
  }
  const mixUpEnteredPlayers = ()=>{
    influxDBButtonPush(userInfo, props.hackwordID, "mixup_entered_players")
    sendMsgHackword(hackwordWS.ws, "MixUpEnteredPlayers", {})
  }


  const [firrstWord, setFirstWord] = useState("");
  const [wordsList, setWordsList] = useState([]);
  useEffect(()=>{
    if (roundPhase.Phase != "running") {
      setWordsList([])
    }
  }, [roundPhase])
  useEffect(()=>{
    let i = 0;
    setFirstWord("words-list-transition--first");
    let lastWords = []
    for (let word of wordsHistory.Words) {
      if (i<3) {
        lastWords.push(word)
      }
      i++
    }
    setWordsList(lastWords)
    setTimeout(() => {
      setFirstWord("");
    }, 500);
  }, [wordsHistory])

  let haveReadyButton = false
  let guessing = false
  let explaining = false
  if (props.myposition == teamToPlay.Explains) {
    haveReadyButton = true
    explaining = true
  } else {
    for (let guess of teamToPlay.Guess) {
      if (guess == props.myposition) {
        haveReadyButton = true
        guessing = true
        break
      }
    }
  }
  let buttonMargin = {
    marginTop: "15px",
  }

  const finishGame = ()=>{
    influxDBButtonPush(userInfo, props.hackwordID, "finish_game")
    setDoWinCondition({DoWinCondition: false})
    sendMsgHackword(hackwordWS.ws, "FinishGame", {})
  }
  const clickSendAdmin = ()=>{
    setSendAdmin(true)
  }
  const keepAdmin = ()=>{
    setSendAdmin(false)
  }
	let [imgsNames, setImgsNames] = useState(["HackwordTeams1.png", "HackwordTeams2.png", "HackwordTeams3.png", "HackwordTeams4.png"])
	useEffect(()=>{
	  setImgsNames(shuffle(imgsNames))
	}, []);

  console.log("ASASAS gameSettings.phase roundPhase.Phase", gameSettings.phase, roundPhase.Phase)
	return (
		<Box
      sx={{
        minHeight: '100vh',
        bgcolor: 'background.default',
        color: 'text.primary',
      }}
      style={{position:"relative"}}
    >
          <HackwordGameController myrole={props.myrole} roomSeed={props.roomSeed} myid={props.myid} hackwordID={props.hackwordID} roomID={props.roomID} myname={props.myname} myposition={props.myposition} apiUrl={props.apiUrl}></HackwordGameController>
          <Stack spacing={3} paddingY={2}>

          {gameSettings.phase == "picking" ?
            <div>
                { gameSettings.isAdmin ? <div><FormattedMessage id="YouAreAnAdmin" /></div> : null}
                <div><FormattedMessage id="RecruitingPlayers" /></div><br/>
                { gameSettings.isAdmin && props.scope && props.scope == "lobby" ? 
                <Button variant="contained" color="success" onClick={stopPickingPlayers}>
                  <FormattedMessage id="StopPickingPlayers" />
                </Button>	: null}
                { gameSettings.isAdmin ? sendAdmin ? 
                <Box component="span" style={{ display: 'block', width: '100%', textAlign: 'center'}}>
                <Button 
                  variant="contained" 
                  color="success"
                  onClick={keepAdmin}
                  style={buttonMargin}
                >
                  <FormattedMessage id="keep_admin" />                
                </Button>	
              </Box>                
                :                                 
                <Box component="span" style={{ display: 'block', width: '100%', textAlign: 'center'}}>
                  <Button 
                    variant="outlined" 
                    color="success"
                    onClick={clickSendAdmin}
                    style={buttonMargin}
                  >
                    <FormattedMessage id="send_admin" />                
                  </Button>	
                </Box> : null}
            </div>
            :
            <span>
              {roundPhase.Phase == "stopped" ?
                <Box component="span" style={{ display: 'block', width: '100%', textAlign: 'center', marginTop: "-20px"}}>

                  <Tooltip 
                      title={
                        <>
                          <InfoNote></InfoNote>
                        </>
                      }
                    >
                      <Button variant="contained" color="success">
                        <FormattedMessage id="teams" />
                        <HelpIcon/>
                      </Button>
                  </Tooltip>			
                </Box> : null }
              {roundPhase.Phase == "running" ? <RadialChart maxValue={gameSettings.roundSeconds} /> : null }
              {roundPhase.Phase == "stopped" ? <HackwordRoomTeams isAdmin={gameSettings.isAdmin} myPosition={props.myposition} imgsNames={imgsNames}/> : null}
            </span>
          }
            
          {gameSettings.phase == "waitready" || (gameSettings.phase == "playing" && roundPhase.Phase == "stopped") ?
            <Grid container columns={{ xs: 12}}>   
              <Grid item xs={6}>
                <span>
                    {haveReadyButton && roundPhase.Phase == "stopped" ? 
                    <Box component="span" style={{ display: 'block', width: '100%', textAlign: 'center'}}>
                      <Button 
                        variant={checkIfReadyToPlay(props.myposition, teamToPlay) ?  "outlined"  : "contained" }
                        color="success"
                        size='large'
                        onClick={beReady}
                        endIcon={props.myposition != teamToPlay.Explains ? <Headphones /> : <Mic />} 
                      >
                        {checkIfReadyToPlay(props.myposition, teamToPlay) ? 
                          <FormattedMessage id="you_are_ready" /> 
                          :
                          props.myposition != teamToPlay.Explains ? <FormattedMessage id="ready_to_guess" /> : <FormattedMessage id="ready_to_propose" />
                        }
                                            
                      </Button>
                    </Box> : null} 

                    {wordsHistory.Words && wordsHistory.Words.length > 0 && <Box component="span" style={{ display: 'block', width: '100%', textAlign: 'center'}}>
                      <Button 
                        variant="outlined" 
                        color="success"
                        onClick={handleOpenAllWords}
                        style={buttonMargin}
                      >
                        <FormattedMessage id="round_words" />                
                      </Button>	
                    </Box>  }
                </span>                
              </Grid>
              <Grid item xs={6}>
                  <Stack>
                    {gameSettings.isAdmin ?
                  <Typography variant='body1'>
                      <b><FormattedMessage id="YouAreAdmin" /></b> 
                    </Typography> : null
                    }

                    <Typography variant='body1'>
                      <FormattedMessage id="set_of_words" />:
                      &nbsp;
                      <b><FormattedMessage id={gameSettings.wordsSet} /></b> 
                    </Typography>

                    <Typography variant='body1'>
                      <FormattedMessage id="time_for_round" />:
                      &nbsp;
                      <b>{gameSettings.roundSeconds} <FormattedMessage id="sec" /></b> 
                    </Typography>

                    <Typography variant='body1'>
                      <FormattedMessage id="words_to_victory" />: 
                      &nbsp;
                      <b>{gameSettings.winWordsNumber}</b>
                    </Typography>

                    <Typography variant='body1'>
                      <FormattedMessage id="round" />:
                      &nbsp;
                      <b>{gameSettings.roundNumber}</b>
                    </Typography>
                    {gameSettings.isAdmin ? 
                    <IconButton onClick={handleOpenSetting}>
                      <SettingsIcon />
                    </IconButton>	: null }
                  </Stack>
              </Grid>               
            </Grid> : null	
            }

            {gameSettings.phase == "playing" && roundPhase.Phase == "3" ?
                <Box component="span" style={{ display: 'block', width: '100%', textAlign: 'center', margin: '50px 0'}}>
                    <PreRunningRoundPhase key="3" state={"3"} sound={play3}></PreRunningRoundPhase>
                </Box> : null
            } 
            {gameSettings.phase == "playing" && roundPhase.Phase == "2" ?
                <Box component="span" style={{ display: 'block', width: '100%', textAlign: 'center', margin: '50px 0'}}>
                    <PreRunningRoundPhase key="2" state={"2"} sound={play2}></PreRunningRoundPhase>
                </Box> : null
            } 
            {gameSettings.phase == "playing" && roundPhase.Phase == "1" ?
                <Box component="span" style={{ display: 'block', width: '100%', textAlign: 'center', margin: '50px 0'}}>
                    <PreRunningRoundPhase key="1" state={"1"} sound={play1}></PreRunningRoundPhase>
                </Box> : null
            }    
            {gameSettings.phase == "playing" && roundPhase.Phase == "go" ?
                <Box component="span" style={{ display: 'block', width: '100%', textAlign: 'center', margin: '50px 0'}}>
                    <PreRunningRoundPhase state={"go"} sound={playGo}></PreRunningRoundPhase>
                </Box> : null
            }                       
            {gameSettings.phase == "playing" && roundPhase.Phase == "running" && guessing ?
                <Box component="span" style={{ display: 'block', width: '100%', textAlign: 'center', margin: '50px 0'}}>
                  <Typography variant="h1">
                    ?
                  </Typography>
                </Box> : null
            } 
            {gameSettings.phase == "playing" && roundPhase.Phase == "running" && explaining ?
                  <CurrentWord>
                        {wordToHack.Word}
                  </CurrentWord> : null
            }
            {roundPhase.Phase == "running" ? 
            <Stack alignItems="center" spacing={2}>
              <Stack alignItems="flex-end">
                {wordsList.map((item, index) => (
                  <>
                  {index === 0 ? (
                    <Word key={index} className={firrstWord} guessed={item.Points > 0}>{item.Word}</Word>
                  )
                  :
                  (
                    <Word key={index} guessed={item.Points > 0}>{item.Word}</Word> 
                  )}                 
                  </>
                ))}                
              </Stack>
            </Stack>   : null }
            {gameSettings.phase == "playing" && roundPhase.Phase == "running" && gameSettings.isAdmin ? 
              <IconButton onClick={handleOpenSetting}>
                <SettingsIcon />
              </IconButton>	: null }

            {doWinCondition.DoWinCondition && gameSettings.phase != "starting" && gameSettings.phase != "picking"?
                  <Button 
                    variant="contained" 
                    color="success"
                    size='large'
                    onClick={finishGame}                     
                  >
                    <FormattedMessage id="HaveWinnerFinishGame" />
                  </Button>
            : null}        

            {true || gameSettings.phase == "picking" || gameSettings.phase == "starting" ?
              null : 
              <span>
                {haveReadyButton && roundPhase.Phase == "stopped" ? 
                <Box component="span" style={{ display: 'block', width: '100%', textAlign: 'center'}}>
                  <Button 
                    variant={checkIfReadyToPlay(props.myposition, teamToPlay) ?  "outlined"  : "contained" }
                    color="success"
                    size='large'
                    onClick={beReady}
                    endIcon={props.myposition != teamToPlay.Explains ? <Headphones /> : <Mic />} 
                  >
                    {checkIfReadyToPlay(props.myposition, teamToPlay) ? 
                      <FormattedMessage id="you_are_ready" /> 
                      :
                      props.myposition != teamToPlay.Explains ? <FormattedMessage id="ready_to_guess" /> : <FormattedMessage id="ready_to_propose" />
                    }
                                        
                  </Button>
                </Box> : null} 

                {wordsHistory.Words && wordsHistory.Words.length > 0 && <Box component="span" style={{ display: 'block', width: '100%', textAlign: 'center'}}>
                  <Button 
                    variant="outlined" 
                    color="success"
                    onClick={handleOpenAllWords}
                    style={buttonMargin}
                  >
                    <FormattedMessage id="round_words" />                
                  </Button>	
                </Box>  }
            </span>
          }
          { gameSettings.phase == "starting" ?          
          <Grid container columns={{ xs: 12}}>
            <Grid item xs={6}>
              {gameSettings.isAdmin ? 
              <span>
                  <Box component="span" style={{ display: 'block', width: '100%', textAlign: 'center'}}>
                    <Button 
                      variant="contained" 
                      color="success"
                      onClick={startGame}
                    >
                      <FormattedMessage id="StartGame" />                
                    </Button>	
                  </Box> 
                  {props.scope && props.scope == "lobby" ?  
                  <Box component="span" style={{ display: 'block', width: '100%', textAlign: 'center'}}>
                    <Button 
                      variant="outlined" 
                      color="success"
                      onClick={startPickingPlayers}
                      style={buttonMargin}
                    >
                      <FormattedMessage id="StartPickingPlayers" />                
                    </Button>	
                  </Box> : 
                        <Box component="span" style={{ display: 'block', width: '100%', textAlign: 'center'}}>
                          <Button 
                            variant="outlined" 
                            color="success"
                            onClick={mixUpEnteredPlayers}
                            style={buttonMargin}
                          >
                            <FormattedMessage id="mixup_entered_players" />                
                          </Button>	
                        </Box>              
                  }
                  {sendAdmin ? 
                  <Box component="span" style={{ display: 'block', width: '100%', textAlign: 'center'}}>
                  <Button 
                    variant="contained" 
                    color="success"
                    onClick={keepAdmin}
                    style={buttonMargin}
                  >
                    <FormattedMessage id="keep_admin" />                
                  </Button>	
                </Box>                
                  :                                 
                  <Box component="span" style={{ display: 'block', width: '100%', textAlign: 'center'}}>
                    <Button 
                      variant="outlined" 
                      color="success"
                      onClick={clickSendAdmin}
                      style={buttonMargin}
                    >
                      <FormattedMessage id="send_admin" />                
                    </Button>	
                  </Box>} 
                </span>   : <span><FormattedMessage id="AdminCanStartGameOrPicking" /></span>} 
              </Grid>
              <Grid item xs={6}>
              <Stack>
                {gameSettings.isAdmin ?
                  <Typography variant='body1'>
                      <b><FormattedMessage id="YouAreAdmin" /></b> 
                  </Typography> : null
                }

                <Typography variant='body1'>
                  <FormattedMessage id="set_of_words" />:
                  &nbsp;
                  <b><FormattedMessage id={gameSettings.wordsSet} /></b> 
                </Typography>

                <Typography variant='body1'>
                  <FormattedMessage id="time_for_round" />:
                  &nbsp;
                  <b>{gameSettings.roundSeconds} <FormattedMessage id="sec" /></b> 
                </Typography>

                <Typography variant='body1'>
                  <FormattedMessage id="words_to_victory" />: 
                  &nbsp;
                  <b>{gameSettings.winWordsNumber}</b>
                </Typography>

                <Typography variant='body1'>
                  <FormattedMessage id="round" />:
                  &nbsp;
                  <b>{gameSettings.roundNumber}</b>
                </Typography>
                {gameSettings.isAdmin ? 
                <IconButton onClick={handleOpenSetting}>
                  <SettingsIcon />
                </IconButton>	: null }
              </Stack>
            </Grid>              
          </Grid>
            : null
          }
          </Stack>

          {gameSettings.isAdmin && (gameSettings.phase == "picking" || gameSettings.phase == "starting" || gameSettings.phase == "playing" || gameSettings.phase == "waitready" || gameSettings.phase == "ended") && (roundPhase.Phase != "running" || gameSettings.phase == "starting") && roundPhase.Phase != "3" && roundPhase.Phase != "2" && roundPhase.Phase != "1" && roundPhase.Phase != "go" &&
            <Button variant="outlined" onClick={()=>{setShowChangeRoomType(true)}} size="small" style={{position: "absolute", right: "0px", top: "-20px", zIndex: 99}} id="change_game_btn" endIcon={<ChevronRightIcon />}>
              <FormattedMessage id="change_game" />
            </Button>          
          }

			<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...ModalStyle, width: 400 }}>
          <Auth />
        </Box>
      </Modal>

      <Modal
        open={openAllWords}
        onClose={handleCloseAllWords}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...ModalStyleXL, width: 700 }}>
          <AllWords count={wordsHistory.Words.reduce((a, b) => (b.Points + a), 0)}>
          {wordsHistory.Words.map((item, index) => (
              <Word key={index + 100} guessed={item.Guessed || item.Points > 0}>{item.Word}</Word>
          ))}
          </AllWords>
        </Box>
      </Modal>

      <Modal
        open={openSetting.opened}
        onClose={handleCloseSetting}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...ModalStyle, width: 400}}>
          <HackwordRoomSetting />
        </Box>
      </Modal>
		</Box>
	)
};

export default InHackwordGame;

// {gameFinished.Finished ?           
//   <span>
//         <Box component="span" style={{ display: 'block', width: '100%', textAlign: 'center'}}>
//           <Button 
//             variant="outlined" 
//             color="success"
//             size='large'
//           >
//             <FormattedMessage id="exit" />
//           </Button>
//         </Box>
//         <Box component="span" style={{ display: 'block', width: '100%', textAlign: 'center'}}>

//         <Button 
//           variant="contained" 
//           color="success"
//           size='large'
//         >
//           <FormattedMessage id="another_one" />
//         </Button>
//         </Box>
//   </span>
// : null}
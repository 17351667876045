import { LOCALES } from '../locales';

export default {
  [LOCALES.RUSSIAN]: {
    is_visible_others: 'Видно другим?',
    games: `Игры`,
    rooms: `Комнаты`,
    articles: `Статьи`,
    login: `Вход`,
    registration: `Регистрация`,
    about: `О себе`,
    name: `Имя`,
    surname: `Фамилия`,
    phone: `Телефон`,
    country: `Страна`,
    region: `Регион`,
    city: `Город`,
    change_password: `Изменить пароль`,
    cancel: `Отменить`,
    save: `Сохранить`,
    username: `Имя пользователя`,
    password: `Пароль`,
    repeat_password: `Повторите пароль`,
    invalid_password: `Неверный пароль`,
    sign_up: `Зарегистрироваться`,
    email: `Почта`,
    nickname: `Никнейм`,
    play: `Играть`,
    create_your_own: `Создать свою`,
    lobby_hackword: `Комнаты Hackword`,
    lobby_iamalive: `Комнаты Я живое?`,
    lobby_generalize: `Комнаты Обобщение`, 
    hackword: `Hackword`,
    who_am_i: `Я живое?`,
    for_their_own: `Для своих`,
    everyone_over_here: `Все сюда!`,
    creating_game: `Создание игры`,
    name_game: `Название игры`,
    number_players: `Количество игроков`,
    max_number_of_players_is: `Максимальное количество игроков`,
    max_number_of_users_is: `Максимальное количество пользователей`,
    spectators_are_possible: `Пользователи, которые не являются игроками, становятся наблюдателями`,
    you_are_already_in_another_room: `Вы уже в другой комнате`,
    go_to_that_room: `Перейти в ту комнату`,
    first_game_will_be: `Первая игра будет`,
    hackword_name: `Hackword`,
    iamalive_name: `Я живое?`,
    generalize_name: `Обобщение`,
    can_hear_everyone: `Слышно всех и всегда`,
    just_your_team: `или только свою команду`,
    age_restrictions: `18+ (мат, пошлости)`,
    closed: `Закрытая`,
    create: `Создать`,
    teams: `Команды`,
    score: `Счет`,
    set_of_words: `Набор слов`,    
    sec: `сек`,
    time_for_round: `Время на раунд`,
    words_to_victory: `Слов для победы`,
    settings: `Настройки`,
    easy: `Легкие`,
    complex: `Сложные`,
    professions: `Профессии`,
    animals: `Животные`,
    places_on_earth: `Места на Земле`,
    number_of_teams: `Количество команд`,
    start_over: `Начать заново`,
    shuffle_players: `Перемешать игроков`,
    ready: `Готов`,
    round: `Раунд`,
    round_words: `Слова раунда`,
    you_won: `Вы победили`,
    your_team: `Ваша команда`,
    your_place: `Ваше место:`,
    exit: `Выйти`,
    another_one: `Еще одну`,
    create_room: `Создать комнату`,
    casual_interlocutors: `Случайные собеседники`,
    creating_room: `Создание комнаты`,
    room_name: `Название комнаты`,
    number_participants: `Количество участников`,
    tag_text: `Текст тэга`,
    add: `Добавить`,
    your_gender: `Ваш пол`,
    gender_interlocutor: `Пол собеседника`,
    your_age: `Ваш возраст`,
    age_interlocutor: `Возраст собеседника`,
    search: `Искать`,
    male: `М`,
    female: `Ж`,
    no_matter: `Не важно`,
    young: `Молод`,
    mature: `Зрел`,
    your_account_has_been_blocked: `Ваш аккаунт заблокирован по причине`,
    inappropriate_behavior: `неподобаемое поведение`,
    you_can_protest: `Вы можете опротестовать это в чате. Просто опишите ситуацию как её видите вы.`,
    account_will_be_unblocked: `Аккаунт будет разблокирован`,
    by_popularity: `По популярности`,
    by_time: `По времени`,
    cycle: `Цикл`,
    gender: `Пол`,
    age: `Возраст`,
    youth: `молодость`,
    next: `Следующий`,
    home_title: `Играйте, общайтесь, отдыхайте`,
    home_description: `В нашей жизни кроме материального капитала очень важно заработать и социальный капитал. А также хорошо провести время с друзьями или просто в компании еще недавно совсем незнакомых людей. Со всем этим мы вам поможем. Присоединяйтесь.`,
    home_description_start: `Мы только начинаем свой путь, поэтому просим рассказывать о нас своим друзьям и приглашать их играть. Есть грандиозные планы по развитию сервиса: новые режимы и новые игры, новые форматы общения и многое другое. Увидимся в одной из комнат.`,
    home_description_try: "Здесь мы весело проводим время в компании друзей",
    get_acquainted: `Знакомиться`,
    feature_games: `Играйте с друзьями или со случайными людьми из интернета`,
    feature_soc: `Обсудите важную тему или знакомьтесь с новыми людьми`,
    feature_com: `Cоберите клуб по интересам или создайте сообщество друзей`,
    communities_title: `Общайтесь, участвуйте в событиях и находите друзей в сообществах!`,
    communities_desc: `Вступайте или создавайте своё`,
    create_community: `Создать сообщество`,
    my_communities: `Мои сообщества`,
    my_events: `Мои события`,
    communities: `Сообщества`,
    creating_community: `Создание сообщества`,
    type: `Тип`,
    title: `Название`,
    upload_logo: `Загрузить эмблему`,
    accept_everyone: `Принимать всех подряд`,
    description: `Описание`,
    date_and_time: `Дата и время`,
    view_room: `Вид комнаты`,
    view: `Вид`,
    creating_event: `Создание события`,
    back_to_events: `Обратно к списку событий`,
    join: `Присоединиться`,
    chat_events: `Чат события`,
    account: `аккаунт`,
    your_tariff: `Ваш тариф`,
    still_valid: `Действует еще`,
    subscription: `Подписка`,

    // Self part
    Female: `женский`,
    Male: `мужской`,
    Old: `зрелый`,
    Young: `молодой`,
    NotKnown: "-",
    NoMatter: "не важно",
    videoAudioSettings: "Видео/Аудио",
    camera: "Камера",
    microphone: "Микрофон",
    dynamic: "Динамик",
    hasNoCameras: "Похоже у вас нет доступных камер",
    from: "из",
    tel: "тел.",
    havenoanything: "ничего о себе не указал",
    complain: "Пожаловаться",
    YouAreInTheRoon: "Вы в комнате",
    GoTo: "Перейти",
    YouLeavedTheRoom: "Вы вышли из комнаты",
    YouAlreadyInTheRoom: "Вы уже в комнате",
    ToEndRegisterCheckEmail: "Для завершения регистрации перейдите по ссылке в письме, которое мы вам отправили.",
    PressingRegisterYouAccept: "Нажимая «Зарегистрироваться», вы подтверждаете, что прочли и принимаете условия ",
    PressingEnterYouAccept: "Нажимая «Войти», вы подтверждаете, что прочли и принимаете условия ",
    userAgreement: "пользовательского соглашения",
    andProvisions: " и положения ",
    privaciesPolicy: "политики конфиденциальности",
    notMatchingPasswords: "Пароли не совпадают",
    notARobotCheck: "Подтвердите, что вы не робот",
    СantEmptyNickname: "Никнейм не может быть пустым",
    Saved: "Сохранено",
    imageIsTooBigMoreThen3Mb: "Файл слишком большой (>3 мегабайт)",
    NowPassword: "Пароль сейчас",
    NewPassword: "Новый пароль",
    OneMoreNewPassword: "Еще раз новый пароль",
    Change: "Изменить",
    PasswordChanged: "Пароль изменен",
    PasswordIsNotTrue: "Неправильный пароль",
    cantbelonger: "не может быть длиннее",
    cantbeempty: "не может быть пустым",
    EmailOrPasswordIncorrect: "Неверный логин или пароль",
    Exit: "Выйти",
    YouAreAnAdmin: "Вы админ",
    RecruitingPlayers: "Идет набор игроков",
    StartGame: "Начать игру",
    YouAreAdmin: "Вы админ",
    CantStartGameWithOnePlayer: "Нельзя начать игру с одним игроком",
    BriefNote: "Краткая справка",
    HackwordNote: "Чтобы переместиться в команду кликните на неё. Админ может перетаскивать любых игроков из команды в команду. Также админ может менять настройки и начинать игру заново.",
    StopPickingPlayers: "Остановить набор игроков (перейти к игре)",
    StartPickingPlayers: "Начать набор игроков",
    AdminCanStartGameOrPicking: "Админ может начать игру или набор игроков",
    YouAlreadyHaveMaximumPlayers: "В игре итак заданное количество игроков",
    UnAccesibleOnePlayerInTeam: "Нельзя чтобы в команде был один игрок",
    Apply: "Применить",
    ErrorOccured: "Произошла ошибка",
    HaveWinnerFinishGame: "Есть победитель. Закончить игру",
    JoinRoom: "Присоединиться",
    SpectatorMode: "Режим зрителя",

    // Self but formatted
    register_by_email: "Зарегистрироваться по почте",
    is_recruiting: "Идет набор",
    already_recruited: "Уже набрано",
    set_birth_day: "Установить день рождения",
    birth_day: "День рождения",
    you_are_too_young_come_back_older: "Вы слишком молоды, приходите когда вам будет 18",
    you_need_to_be_18_plus: "Возрастное ограничение: 18+",
    only_one_time_to_do_it: "Это потребуется только один раз",
    you_are_too_young: "Вы слишком молоды",
    requires_password: "Требуется пароль",
    wrong_password: "Неверный пароль",
    game_panel: "Панель игры",
    admin_panel: "Панель админа",
    your_notes: "Ваши заметки",
    just_stop_picking_players: "Остановить набор игроков",
    appoint: "Назначить",
    choosing_word: "Выбор слова",
    for_whom_we_choose: "Кому выбирают",
    who_choose: "Кто выбирает",
    playing_now: "Идет игра",
    change: "Изменить",
    to_change_word_do_this: "Чтобы изменить загаданное слово наведите на него курсор и нажмите ИЗМЕНИТЬ",
    not_hear_anyone: "никого не слышит и мы его",
    change_own_word: "изменить свое слово",
    players_is_not_picking: "Набор игроков прекращен",
    club: "Клуб",
    community: "Сообщество",
    clan: "Клан",
    cartel: "Картель",
    team: "Команда",
    company: "Компания",
    max_number_participants: "Максимальное количество участников",
    talks: "Общение",
    iamalive: "Я живое?",
    made_request: "Заявка отправлена",
    decline_request: "Отменить запрос",
    you_are_member: "Вы состоите",
    you_are_admin: "Вы админ",
    you_are_owner: "Вы владелец",
    joined_unjoin: "Вы участвуете (перестать)",
    edit: "Изменить",
    editing_community: "Редактирование сообщества",
    willbe_comein_event_button: "Здесь будет кнопка ВОЙТИ, когда событие начнется",
    comein: "Войти",
    dota2_heroes: "Герои Dota2",
    mixup_entered_players: "Замешать зашедших игроков",
    sign_up_vk: "Зарегистрироваться через VK",
    alreadytaken: "Уже занят",
    login_vk: "Войти через VK",
    set_nickname: "Установить никнейм",
    days: "д.",
    minutes: "мин.",
    hours: "ч.",
    standart_max_room_size: "Максимальный размер комнат для общения: 10 человек",
    standart_dont_see_over_rooms: "Вы не видите комнаты, которые завершили набор собеседников",
    standart_cant_create_communities: "Нельзя создавать сообщества",
    premium_create_big_rooms: "Cоздание комнат для общения большего размера",
    premium_join_all_open_rooms: "Вы видите все открытые комнаты и можете в них заходить в качестве зрителя",
    premium_can_create_communities: "Вы можете создавать сообщества (владеть можно не более чем 3мя)",
    premium_buy_button_text: "490 руб в месяц",
    premium_call_to_action_text: "Внеси свой вклад в развитие сервиса",
    hackword_rules: "Игра очень похожа на Alias или Шляпу. Игроки делятся на команды. Один человек из команды видит слово и пытается его объяснить, не используя однокоренные, чтобы сокомандники его угадали. Загадывающий отмечает, что слово угадано или пропускает его. Если кто-то не согласен с тем, что слово засчитали, можно поменять это после завершения раунда.",
    iamalive_rules: "Игра похожа на игру 'Кто я?', Каждому игроку придумывают слово или словосочетание, которое он не видит и впоследствии должен угадать. Все остальные игроки видят эти слова. Игроки по очереди задают вопросы на которые можно ответить да или нет и пытаются угадать свои слова. Можно загадывать по одному вопросу за ход или пока не будет ответ 'нет', максимум 3 вопроса за ход.",
    generalize_rules: "Игра похожа на игру Codenames, есть 25 слов и одна или две команды. В командах есть один ведущий, который видит какие слова принадлежат его команде, а какие нет. Он пишет слово и цифру так, чтобы слово обобщало несколько слов его команды, а цифра значила их количество. Остальные члены его команды пытаются угадать какие слова имел ввиду ведущий. Если открывают не свое слово, ход переходит команде соперников. Если открывается черное слово - команда открывшая его проиграла. Задача - открыть все свои слова, когда команда открыла все свои слова - она победила и игра закончена.",
    communities_after_create: "После создания сообщества вы сможете добавить картинку эмблемы: на странице сообщества нажмите ИЗМЕНИТЬ",
    register_present_title: "Только зашел, а уже подарок!",
    register_present_text: "Вы получаете 15 дней подписки. Теперь вы можете намного больше, например создавать сообщества, а ваши комнаты могут вместить больше людей.",
    thankyou: "Спасибо",
    cant_create_more_communities: "Нельзя владеть больше чем 3 сообществами",
    games_chat: "Чат (договориться об играх)",
    sroll_down: "Прокрутить вниз",
    in_chat_now: "В чате сейчас",
    use_video: "Использовать камеру",
    use_audio: "Использовать микрофон",
    ready_o: `Готово`,
    game_without_cam: "Вы можете играть или общаться без камеры",
    team_red: "Красные",
    team_blue: "Синие",
    empty_master: "Стать Ведущим",
    has_no_master: "У одной из команд нет ведущего",
    too_less_players: "В одной из команд слишком мало игроков",

    // Generalize
    your_turn: "Ваш ход",
    master_turn: "Ход ведущего",
    players_turn: "Ход игроков",
    learn_words_and_make_a_turn: "Изучите слова и придумайте обобщающее слово и запишите вместе с количеством слов",
    make_a_turn_as_master: "Придумайте обобщающее слово и запишите вместе с количеством слов",
    guess_which_words_master_generalized: "Отгадайте какие слова обобщил ведущий",
    generalize_word: "Слово n",
    your_team_assasinated: "Ваша команда наткнулась на черное слово-недотрогу, которое лучше обходить стороной и ПРОИГРАЛА. Ну ничего, повезет потом.",
    another_team_assasinated: "Другая команда наткнулось на черное слово-недотрогу и поэтому вы ПОБЕДИЛИ! Поздравляем!",
    your_team_guessed_all: "Ваша команда отгадала все ваши скрытые слова и ПОБЕДИЛА! Поздравляем!",
    another_team_guessed_all: "Другая команда отгадала все свои скрытые слова, поэтому вы ПРОИГРАЛИ! Ну ничего, повезет потом.",
    generalize_admin_note: "Админ может перетаскивать любых игроков из команды в команду и с роли ведущего и на неё, если она пуста. Также вы можете менять настройки игры и начинать игру заново.",
    generalize_user_note: "Вы можете перемещаться из команды в команду кликнув на другую команду или на свободное место ведущего",
    settings_time_learinig: "Время на изучение слов и первый ход",
    settings_time_word_number: "Время на загадывание слова",
    settings_time_guessing: "Время на угадывание слов",
    only_one_team: "Только одна команда",
    generalize_master: "Ведущий",
    generalize_its_master: "Это ведущий",
    generalize_no_show_video_reason: "(не видно камеру чтобы не мог нечайно или незаметно подсказать)",
    admin_can_start_new_game: "Админ может начать новую игру", 
    start_next: "Начать новую",
    complain_to_user: "Жалоба на пользователя ",
    if_complain_enough_then_leave: "Если половина или большая часть людей в комнате также пожалуются, то пользователь будет исключен из комнаты",
    new_msgs: "новых",
    you_are_blocked_in_room: "Вы заблокированы в этой комнате (слишком много жалоб)",
    generalize: "Обобщение",
    end_turn: "Закончить ход",

    you_win: "Вы победили",
    send_admin: "Передать админство",
    keep_admin: "Оставить админство",
    send_admin_player: "Передать админство игроку (кликни сюда)",
    admin_can_change_the_word: "Если понадобиться Админ может изменить Вам слово",
    generalize_teams_note: "Чтобы переместиться в команду кликните на неё. То же самое с ролью ведущего, если она свободна. Админ может перетаскивать любых игроков из команды в команду. Также админ может менять настройки и начинать игру заново. У админа написано что он админ.",
    exit_from_room: `Выйти из комнаты`,
    ready_to_guess: "Готов отгадывать",
    ready_to_propose: "Готов загадывать",
    not_ready: "Не готов",
    you_are_ready: "Вы готовы",
    changed: "изменил",
    to: "на",
    hackword_round_words_note: "После окончания раунда вы можете менять статус слов, кликнув на противоположную иконку. Так угаданное слово может стать не угаданным и наоборот. Это удобно при спорных ситуациях. Например: если случайно произнесли однокоренное слово и не заметили",
    admin: "Админ",
    complained_more: "Пожаловались",
    complained_one: "Пожаловался",
    admin_can_start_game: "Админ может начать игру",
    go_go_go: "Поехали",
    rules: "Правила",
    lev: "ур.",
    device_not_ready_or_busy: "Устройство занято или не работает",
    ratings: "Рейтинги",
    level: "уровень",
    experience: "опыт",
    game_notes: "Игровые заметки",

    borders_none: "Отсутствуют",
    borders_summer: "Кусочек лета",
    borders_winter: "Кусочек зимы",
    borders_spring: "Кусочек весны",
    borders_autumn: "Кусочек осени",
    borders_water: "Стихия воды",
    borders_fire: "Стихия огня",
    borders_flowers: "Цветы",

    install: "Установить",
    installed: "Установлены",
    Experience: "Опыт",
    talk: "Общение",
    talking: "Общаться",
    all: "Все",
    user_communities: "Сообщества где пользователь является участником",
    has_no_user_communities: "Нет таких сообществ",
    game_explaining: "загадывает",
    game_guessing: "отгадывает",
    who: "кто",
    for_who: "кому",
    word_too_long: "слово слишком длинное",
    set_prepared_words: "Подобрать готовые слова",
    category: "Категория",
    assign: "Присвоить",
    every_without_word: "Все у кого слова не назвачены",
    everybody : "Все игроки" ,
    who_assign_word: "Кто получит слово",
    prepared_words: "Готовые слова",
    allow_new_players_dialog: "Позволить заходить новым игрокам?",
    allow: "Позволить",
    disallow: "Запретить",
    forgot_password: "Забыли пароль?",
    recover_password: "Восстановить пароль",
    recover_password_sent: "На почту отправили ссылку для восстановления пароля",
    set_new_password: "Установите новый пароль",
    knowledge_base_menu: "База знаний",
    about_project: "О проекте",
    borders: "Стенки",
    communities_views: "Типы сообществ",
    communities_views_descriptions: "Пользователь может вступить в любое количество клубов. И только в один клан.",
    requested_clan_cant_create: "Вы уже подали заявку на вступление в клан",
    joined_clan_cant_create	:	"Вы уже являетесь членом клана",
    leave_community: "Покинуть",
    transfer_ownership: "Передать во владение",
    transfer: "Передать",
    to_leave_for_owner: "Чтобы покинуть нужно перестать владеть сообществом",
    you_own: "Вы владеете",
    you_are_not_member: "Вы нигде не состоите",
    has_no_user_clan: "Не состоит в клане",
    chat: "Чат",
    personal_messages: "Сообщение",
    common_chat: "Общий чат",
    others: "оставшиеся",
    block_chat: "Заблокировать",
    you_was_blocked: "Пользователь вас заблокировал. Он не увидит ваши сообщения.",
    blocked: "Заблокированные",
    unblock: "разблокировать",
    open: "открыть",
    films_characters: "Персонажи фильмов",
    cartoons_characters: "Персонажи мультфильмов",
    normal: "Средние",
    month_of_premium: "Месяц Премиум подписки",
    get_paid: "Оформить",
    auto_prolongate: "Автоматически продлевать подписку",
    so_to_pay: "490 рублей за 30 дней",
    cant_create_payment: "Не получается сделать платеж, напишите в поддержку в вк",
    failed_to_pay: "Не удалось оформить подписку (проверьте баланс и не заблокирована ли карта)",
    success_paied_30: "Успешно приобретена подписка на 30 дней",
    cant_create_payment_already_have_many_days: "Нельзя купить подписку на больше чем 2а месяца",
    you_have_subusciption_prolongation: "У вас подключен автоплатеж",
    turn_off_prolongation: "Отключить автоплатеж",
    payment_needs_phone_or_email: "Для банка потребуются телефон или почта",
    continue: "Продолжить",
    or: "или",
    wrong_email_format: "Неверный формат почты",
    wrong_phone_format: "Формат телефона: +{Ц}",
    cant_use_mate: "Нельзя использовать мат",
    cant_use_spaces: "Нельзя использовать пробелы",
    profile: "Профиль",
    messages: "Сообщения",
    report_user: "Пожаловаться",
    report_description: "Описание жалобы",
    understood: "Понятно",
    room_is_already_closed: "Комната уже закрыта",
    cant_place_you_will_spectate: "Вы наблюдатель. Вас никто не слышит и не видит. Возможно вам просто не хватило место в игре или она уже началась.",
    have_no_place_in_room: "К сожалению, все места в комнате уже заняты.",
    invitational_link: "Пригласительная ссылка",
    link: "Ссылка",
    copied: "Скопировано",
    copyErr: "Ошибка копирования",
    copy_link: "Скопировать ссылку",
    participate: "Участвовать",
    change_game: "Поменять игру",
    entry_conditions: "Зайти смогут только те у кого есть ссылка, вы её получите после создания комнаты",
    сhoose_game_and_create_room: "Выберите игру и создайте комнату",
    can_change_game_in_room: "вы сможете поменять игру внутри комнаты",
    exit_that_room: "выйти из той комнаты",
    ban: "Забанить",
    ban_user: "Забанить пользователя",
    if_ban_then_leave: "Если вы забаните пользователя, его выкинет из комнаты и он больше не сможет войти в эту комнату",
    spectate: "Стать наблюдателем",
    if_spectate_warning: "Если стать наблюдателем во время игры, игра может закончиться"
  },
};
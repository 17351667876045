import { Outlet } from "react-router-dom";
import Navbar from "./Navbar"; // ⚠️ verify it's the correct path
import { useLocation } from 'react-router-dom'

const Layout = () => {
    const location = useLocation();

  return (
    <>
      {location.pathname != "/" && !location.pathname.startsWith("/room/") && <Navbar/>}
      <Outlet />
    </>
  );
};

export default Layout;
import { Avatar, Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from '@mui/material';
import React, {useState} from 'react';
import { FormattedMessage } from 'react-intl';
import CommunitiesService from '../../../services/CommunitiesService';
import {SuccessMessage, MaxAvatarSize} from "../../../constants";
import {useRecoilState,} from 'recoil';
import { ShowFileTooBig } from '../../atoms/Common';
import {domainNameAPI, protocolAPI, apiPort} from "../../../environment"
import { makeString } from '../../../utils/common';
import {useNavigate} from "react-router-dom"
import {OpenEditCommunity} from "../atoms/Common";
import {MaxCommunityNameLength, MaxCommunityDescriptionLength} from "../../../constants"

const EditCommunityForm = ({c_id, c_pureicon, c_icon, c_description}) => {
	const [communityType, setCommunityType] = useState('');
	const [description, setDescription] = useState(c_description);
	const [icon, setIcon] = useState(c_pureicon);
	const [avaSrc, setAvaSrc] = useState(c_icon);
	const [acceptEveryone, setAcceptEveryone] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);	
	const [showTooBig, setShowTooBig] = useRecoilState(ShowFileTooBig);

	const [descriptionError, setDescriptionError] = useState(null)

	const [openEditCommunity, setOpenEditCommunity] = useRecoilState(OpenEditCommunity);

	const changeDescription = (e)=>{
		if (e.target.value.length > MaxCommunityDescriptionLength) {
			setDescriptionError(<span><FormattedMessage id="cantbelonger"/> {MaxCommunityDescriptionLength}</span>)
			return 
		}
		setDescription(e.target.value)
		setDescriptionError(null)
	}
	const switchAcceptEveryone = (e)=>{
		setAcceptEveryone(e.target.checked)
	}

	const editCommunity = ()=>{
		CommunitiesService.editCommunity(c_id, icon, description,).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				setOpenEditCommunity(false)
			}
		})
	}

    const handleFileSelect = (event) => {
        console.log(event.target.files[0])
        if (event.target.files[0].size > MaxAvatarSize){
            setShowTooBig({show: true})
        } else {
            setSelectedFile(event.target.files[0])
			const formData = new FormData();
			formData.append("avatarFile", event.target.files[0]);
			CommunitiesService.uploadAvatar(formData, c_id).then((response)=>{
				if (response.data.Success == SuccessMessage) {
					setIcon(response.data.Icon)
					let apiUrl = protocolAPI+domainNameAPI+apiPort
					setAvaSrc(apiUrl+"/mkstatic/shared_communities/icons/"+response.data.Icon+"?dop"+makeString(5))
				}
			})
         }
    }
	return (
		<Stack
			direction="column"
			spacing={3}
		>
			<Typography variant='h4'>
				<FormattedMessage id="editing_community" />
			</Typography>

			<Stack
				direction={{xs: 'column', md: 'row'}}
				alignItems="center"
				spacing={{xs: 2, md: 4}}
			>
				<Button variant="contained" component="label">
					<FormattedMessage id="upload_logo" />
					<input
    					type="file"
    					hidden
						onChange={handleFileSelect} accept="image/*"
  					/>					
				</Button>

				<Avatar
					sx={{ width: 156, height: 156 }}
					src={avaSrc}
				/>
			</Stack>

			<TextField
				id="description"
				label={<FormattedMessage id="description" />}
				multiline
				rows={3}
				value={description}
				onChange={changeDescription}
				helperText={descriptionError}
				error= {descriptionError != null}				
			/>
			
			<Button variant="contained" size="large" onClick={editCommunity}>
				<FormattedMessage id="edit" />
			</Button>
		</Stack>
	);
};

export default EditCommunityForm;
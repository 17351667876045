import React, {useState} from 'react';
import './active-game-item.scss';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LockIcon from '@mui/icons-material/Lock';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Stack } from '@mui/system';
import { Typography, Button } from '@mui/material';
import { red } from '@mui/material/colors';
import {FormattedMessage} from "react-intl";

const ActiveGameItem = ({
	isPremium,
	onClick,
	closed,
	roomSize,
	ageLimit,
	users,
	title,
	numberOfTeams,
	onClickSpectator,
	lobbyType,
}) => {
	let [showButtons, setShowButtons] = useState(false);
	const mouseOverHandler = () => {
		setShowButtons(true)
	}
	const mouseLeaveHandler = () => {
		setShowButtons(false)
	}
	return (
		<div className="active-game-item" onMouseEnter={mouseOverHandler} onMouseLeave={mouseLeaveHandler}>
			<Stack direction="row" spacing={3} alignItems="center" style={{width: "100%", overflow: "hidden"}}>
				<Stack direction="row" alignItems="center" spacing={0.5}>
					<Typography variant='body2'>{users}/{roomSize}</Typography>
					<PersonIcon fontSize='small' />
				</Stack>

				<Typography variant='body' noWrap>{title}</Typography>

				{ageLimit &&
					<Typography variant='body' color={red[500]}>{ageLimit}</Typography>
				}
			</Stack>
			
			{showButtons ? 
					<Stack direction="row" sx={{ zIndex: 'modal', position: "absolute", right: "50px", top: "0px",}} spacing={3} alignItems="right">
						{isPremium && !closed ? 
							<Button variant="outlined" color="success" onClick={onClickSpectator}
							sx={{bgcolor: 'background.default',"&:hover": {bgcolor: 'background.default',},}}>							
								<FormattedMessage id="SpectatorMode" />
							</Button>
						: null}
						{lobbyType == "is_recruiting" ? 
						<Button variant="contained" color="success" onClick={onClick}>
							<FormattedMessage id="JoinRoom" />
						</Button>
						: null}						
					</Stack>
				: null}

			{closed ?
				<LockIcon fontSize='small' />
				:
				<PlayCircleOutlineIcon fontSize='small' />
			}
		</div>
	);
};

export default ActiveGameItem;
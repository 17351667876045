import {
    useRecoilState,
  } from 'recoil';
import { Button, Stack, TextField } from '@mui/material';
import React, {useState} from 'react';
import UserService from "../../../services/UserService";
import {FormattedMessage} from "react-intl";
import { ShowChangePassword, ShowPasswordChangedAlert } from '../../atoms/Common';
import { SuccessMessage } from '../../../constants';
import {influxDBButtonPush} from "../../../services/influxdb/profile"

const ChangePassword = (props) => {
    const [nowPassword, setNowPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [oneMoreNewPassword, setOneMoreNewPassword] = useState("");
	const [password2Error, SetPassword2Error] = useState(null);
    const [passwordIsNotTrue, SetPasswordIsNotTrue] = useState(null);
	const [showChangePassword, setShowChangePassword] = useRecoilState(ShowChangePassword);
    const [showPasswordChangedAlert, setShowPasswordChangedAlert] = useRecoilState(ShowPasswordChangedAlert);

	const changeNowPassword = (event) => {
		setNowPassword(event.target.value);
	}
    const changeNewPassword = (event) => {
		setNewPassword(event.target.value);
	}
    const changeOneMoreNewPassword = (event) => {
		setOneMoreNewPassword(event.target.value);
	}

    const doChange = ()=>{
		influxDBButtonPush(props.userInfo, "change_password")
        if (oneMoreNewPassword === newPassword) {
            UserService.changePassword(nowPassword, newPassword).then((response)=>{
                if (response.data.Success == SuccessMessage) {
                    setShowChangePassword({show: false})
                    setShowPasswordChangedAlert({show: true})
                } 
            }).catch((response)=>{
                if (response.response.data.ErrorCode === 4) {
                    SetPasswordIsNotTrue(<FormattedMessage id="PasswordIsNotTrue" />)
                }
            })
        } else {
            SetPassword2Error(<FormattedMessage id="notMatchingPasswords" />)
        }
    }

    return (
		<Stack spacing={2} className="login-form">
			<TextField 
				id="nowPassword" 
				value={nowPassword}
				onChange={changeNowPassword}
				label={<FormattedMessage id="NowPassword" />}
				type="password" 
				variant="outlined" 
				defaultValue="12345" 
                helperText={passwordIsNotTrue}
				error= {passwordIsNotTrue != null} 
			/>
            <TextField 
				id="newPassword" 
				value={newPassword}
				onChange={changeNewPassword}
				label={<FormattedMessage id="NewPassword" />}
				type="password" 
				variant="outlined" 
				defaultValue="12345" 
			/>
            <TextField 
				id="oneMoreNewPassword" 
				value={oneMoreNewPassword}
				onChange={changeOneMoreNewPassword}
				label={<FormattedMessage id="OneMoreNewPassword" />}
				type="password" 
				variant="outlined" 
				defaultValue="12345"
                helperText={password2Error}
				error= {password2Error != null} 
			/>            
			
			<Button variant="contained" size="large" onClick={doChange}>
				{<FormattedMessage id="Change" />}
			</Button>
		</Stack>
	);
}

export default ChangePassword;
import { Grid, Box, Modal, } from '@mui/material';
import React, { useState, useEffect } from 'react';
import UserWindow from './UserWindow/UserWindow';
import ModalStyle from '../../../utils/ModalStyle/ModalStyle';
import {BoxesSpacingHorizontal, BoxesSpacingVertical} from "../../../constants"
import ModalClose from "../../../utils/ModalStyle/ModalClose"




const Donut10 = ({ roommates, children }) => {
    const [choosedBox, setChoosedBox] = useState(<span>No choosed</span>)
    const [openBoxModal, setOpenBoxModal] = useState(false)
	const [choosedBoxPosition, setChoosedBoxPosition] = useState(0)
    const handleCloseBoxModal = ()=>{
        setOpenBoxModal(false)
    }
	const mobileShowBoxByClick = (e, box, position)=>{
        if (window.matchMedia("(max-width: 899px)").matches && box != undefined) {
            let boxUp = box
            if (React.isValidElement(boxUp)) {
                boxUp = React.cloneElement(boxUp, { modal: true }); 
            }
            setChoosedBox(boxUp)
			setChoosedBoxPosition(position)
            setOpenBoxModal(true)
        }
    }
	return (
		<Box
		sx={{
		  minHeight: '100vh',
		  bgcolor: 'background.default',
		  color: 'text.primary',
		}}>
		<Grid container spacing={0} paddingX={3} pt={3}>
			<Grid container item xs={12} spacing={BoxesSpacingHorizontal} style={{width: "100%"}}>
				<Grid item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(1), 1)}}>
					<UserWindow position={1} matebox={roommates.get(1)}/>
				</Grid>

				<Grid item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(2), 2)}}>
					<UserWindow position={2} matebox={roommates.get(2)}/>
				</Grid>

				<Grid item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(3), 3)}}>
					<UserWindow position={3} matebox={roommates.get(3)}/>
				</Grid>

				<Grid item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(4), 4)}}>
					<UserWindow position={4} matebox={roommates.get(4)}/>
				</Grid>
			</Grid>

			<Grid container item xs={12} spacing={BoxesSpacingHorizontal} pt={BoxesSpacingHorizontal} style={{width: "100%"}}>
				<Grid item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(5), 5)}}>
					<UserWindow position={5} matebox={roommates.get(5)}/>
				</Grid>

				<Grid item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(6), 6)}}>
					<UserWindow position={6} matebox={roommates.get(6)}/>
				</Grid>

				<Grid item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(7), 7)}}>
					<UserWindow position={7} matebox={roommates.get(7)}/>
				</Grid>

				<Grid item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(8), 8)}}>
					<UserWindow position={8} matebox={roommates.get(8)}/>
				</Grid>
			</Grid> 

			<Grid container item xs={12} spacing={BoxesSpacingHorizontal} pt={BoxesSpacingHorizontal} style={{width: "100%"}}>
				<Grid item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(9), 9)}}>
					<UserWindow position={9} matebox={roommates.get(9)}/>
				</Grid>

				<Grid item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(10), 10)}}>
					<UserWindow position={10} matebox={roommates.get(10)}/>
				</Grid>

				<Grid item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(11), 11)}}>
					<UserWindow position={11} matebox={roommates.get(11)}/>
				</Grid>

				<Grid item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(12), 12)}}>
					<UserWindow position={12} matebox={roommates.get(12)}/>
				</Grid>
			</Grid>   

			<Grid container item xs={12} spacing={BoxesSpacingHorizontal} pt={BoxesSpacingHorizontal} style={{width: "100%"}}>
				<Grid item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(13), 13)}}>
					<UserWindow position={13} matebox={roommates.get(13)}/>
				</Grid>

				<Grid item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(14), 14)}}>
					<UserWindow position={14} matebox={roommates.get(14)}/>
				</Grid>

				<Grid item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(15), 15)}}>
					<UserWindow position={15} matebox={roommates.get(15)}/>
				</Grid>

				<Grid item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(16), 16)}}>
					<UserWindow position={16} matebox={roommates.get(16)}/>
				</Grid>
			</Grid>  

			<Grid container item xs={12} spacing={BoxesSpacingHorizontal} pt={BoxesSpacingHorizontal} style={{width: "100%"}}>
				<Grid item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(17), 17)}}>
					<UserWindow position={17} matebox={roommates.get(17)}/>
				</Grid>

				<Grid item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(18), 18)}}>
					<UserWindow position={18} matebox={roommates.get(18)}/>
				</Grid>

				<Grid item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(19), 19)}}>
					<UserWindow position={19} matebox={roommates.get(19)}/>
				</Grid>

				<Grid item xs={3} onClick={(e)=>{mobileShowBoxByClick(e, roommates.get(20), 20)}}>
					<UserWindow position={20} matebox={roommates.get(20)}/>
				</Grid>
			</Grid>              
		</Grid>
        <Modal
            open={openBoxModal}
            onClose={handleCloseBoxModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{ ...ModalStyle, width: 300, padding: "0"}}>
				<ModalClose onClose={handleCloseBoxModal}/>
				<div style={{width: "256px", marginTop: "40px",}}>
                	<UserWindow position={choosedBoxPosition} matebox={choosedBox} />
				</div>
            </Box>
        </Modal>		
		</Box>
	);
};

export default Donut10;
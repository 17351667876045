import influxDB from "./client"
import {Point} from '@influxdata/influxdb-client';
import {influxdbORG, influxdbBucket} from "../../environment"

let type = 'iamalive'

export const influxDBButtonPush = function(userInfo, iamaliveID, buttonName) {
    let userID = "anonimous"
    if (userInfo.UserInfo != null) {
      userID = userInfo.UserInfo.UserID
    }
    const writeApi = influxDB.getWriteApi(influxdbORG, influxdbBucket)
    // setup default tags for all writes through this API
    writeApi.useDefaultTags({location: 'browser'})
    console.log('\n*** WRITE ***' + userID)
    const point1 = new Point(type)
      .tag('userid', userID)
      .tag('iamaliveid', iamaliveID)
      .tag('type', "button")
      .floatField(buttonName, 1)
    writeApi.writePoint(point1)
    //console.log(` ${point1}`)
  // flush pending writes and close writeApi
    writeApi
      .close()
      .then(() => {
        //console.log('WRITE FINISHED')
      })
      .catch(e => {
        console.log('WRITE FAILED', e)
      })
  }
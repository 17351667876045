import {
    atom,
} from 'recoil';


// IamaliveGameState

export const IamaliveGameState = atom({
    key: 'IamaliveGameState',
    default: {
        phase: "picking",
        isAdmin: false,
        adminPosition: 0,
        players: {},
        allowNewPlayers: true,
        positionToAddWord: 0,
    },
})

export const WordChangeState = atom({
    key: 'IamaliveWordChangeState',
    default: {
        word: ""
    }
})

export const ShowCantStartGame = atom({
    key: 'IamaliveShowCantStartGame',
    default: false
})
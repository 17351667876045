import { AppBar, Avatar, Box, Button, Container, IconButton, Menu, MenuItem, Stack, Toolbar, Typography, Modal, Snackbar, Alert, } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { createContext, useContext, useState, useEffect } from 'react';
import {FormattedMessage} from "react-intl";
import { Link, NavLink } from "react-router-dom";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import "./navbar.scss";
import { useTheme } from '@emotion/react';
import ColorModeContext from '../../utils/ColorModeContext';
import RoomService from '../../services/rooms/RoomService';
import { SuccessMessage, MiniChatWidth, MiniChatMobileWidth } from '../../constants';
import RoomBar from '../rooms/RoomBar/RoomBar';
import { CurrentRoom, CurrentlyInRoom, CheckCurrentInterval, ListRoomsInterval } from '../atoms/Rooms';
import {useRecoilState,} from 'recoil';
import {Authorized, OpenAuth} from "../atoms/AuthAtoms"
import {UserInfoAtom, UserPersonalAtom, UserAvatarSuffix, ShowRegisterGiftSubscription, UserBorders, OpenedPersonalChats} from "../atoms/UserAtoms"
import Auth from "../user/Auth/Auth"
import ModalStyle from '../../utils/ModalStyle/ModalStyle';
import {ModalStyleLG} from '../../utils/ModalStyle/ModalStyle';
import {OthersAudioBoxes} from "../../livekit_prod/atoms"
import {domainNameAPI, protocolAPI, apiPort} from "../../environment"
import {BirthDayModal, ThemeAtom, ShowTooYoung, SetPasswordModal, NickNameModal} from "../atoms/Common"
import {useNavigate, useLocation} from "react-router-dom"
import UserService from '../../services/UserService';
import { makeString, numOfWidthSlots } from '../../utils/common';
import { ListLobbiesInterval } from '../atoms/GamesAtoms';
import SetBirthDay from "./SetBirthDay";
import {influxDBButtonPush} from "../../services/influxdb/navigation"
import {SiteWS, SiteWSOpened} from "../atoms/SiteAtoms"
import {sendMsgSite} from "../SiteController"
import SetNickNameModalPage from "./SetNickNameModalPage"
import RegisterGiftSubscription from './ShowState/RegisterGiftSubscription';
import ExperienceIndicator from "./ExperienceIndicator/ExperienceIndicator"
import SmallChat from "../common/SmallChat/SmallChat"
import Others from "../common/SmallChat/Others"
import FlatChat from "../common/FlatChat/FlatChat"

const pages = [
  {
    title: <FormattedMessage id="games" />,
    url: '/games',
    metric: "games"
  }, 
  {
    title: <FormattedMessage id="talks" />,
    url: '/rooms',
    metric: "rooms"
  }, 
  {
    title: <FormattedMessage id="communities" />,
    url: '/communities',
    metric: "communities"
  },
  {
    title: <FormattedMessage id="ratings" />,
    url: '/ratings',
    metric: "ratings"
  },  
  {
    title: <FormattedMessage id="subscription" />,
    url: '/subscription',
    metric: "subscription"
  },
  {
    title: <FormattedMessage id="knowledge_base_menu" />,
    url: '/knowledge_base',
    metric: "knowledge_base"
  },  
  
];

const userPages = [
  {
    title: <FormattedMessage id="profile" />,
    url: '/profile',
    metric: "profile"
  }, 
  {
    title: <FormattedMessage id="messages" />,
    url: '/messages',
    metric: "messages"
  }, 
  {
    title: <FormattedMessage id="borders" />,
    url: '/walls',
    metric: "walls"
  },
]

const Navbar = ({ openModal, home, inTheRoom, audioBoxes }) => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorUserNav, setAnchorUserNav] = useState(null);
  const [authIndex, setAuthIndex] = useState(0)
  const navigate = useNavigate();

  const [currentlyInRoom, setCurrentlyInRoom] = useRecoilState(CurrentlyInRoom)
  const [currentRoom, setCurrentRoom] = useRecoilState(CurrentRoom)
  const [open, setOpen] = useRecoilState(OpenAuth);
  const [checkCurrentInterval, setCheckCurrentInterval] = useRecoilState(CheckCurrentInterval)
  const [userPersonal, setUserPersonal] = useRecoilState(UserPersonalAtom);
  const [authorized, setAuthorized] = useRecoilState(Authorized);
  const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);
  const [userAvatarSuffix, setUserAvatarSuffix] = useRecoilState(UserAvatarSuffix);
  const [innerTheme, _] = useRecoilState(ThemeAtom);
  const [listRoomsInterval, setListRoomsInterval] = useRecoilState(ListRoomsInterval)
  const [listLobbiesInterval, setListLobbiesInterval] = useRecoilState(ListLobbiesInterval)
  const [birthDayModal, setBirthDayModal] = useRecoilState(BirthDayModal)
  const [showTooYoung, setShowTooYoung] = useRecoilState(ShowTooYoung)
  const [siteWS, setSiteWS]  = useRecoilState(SiteWS);
  const [wsOpened, setWSOpened] = useRecoilState(SiteWSOpened); 
  const [nickNameModal, setNickNameModal] = useRecoilState(NickNameModal)
  const [showRegisterGiftSubscription, setShowRegisterGiftSubscription] = useRecoilState(ShowRegisterGiftSubscription)
  const [userBorders, setUserBorders] = useRecoilState(UserBorders)
  const [othersAudioBoxes, setOthersAudioBoxes] = useRecoilState(OthersAudioBoxes)
  const [openedPersonalChats, setOpenedPersonalChats] = useRecoilState(OpenedPersonalChats);


  const location = useLocation();
  if (location.pathname != "/rooms") {
    if (listRoomsInterval.interval != 0) {
			clearInterval(listRoomsInterval.interval)
		}
  }
  if (location.pathname != "/games") {
    if (listLobbiesInterval.interval != 0) {
			clearInterval(listLobbiesInterval.interval)
		}
  }
  if (location.pathname == "/room") {
    if (checkCurrentInterval.interval != 0) {
			clearInterval(checkCurrentInterval.interval)
		}
  }


  let apiUrl = protocolAPI+domainNameAPI+apiPort

  const handleOpenNavMenu = (event) => {
    influxDBButtonPush(userInfo, "navbar", "open_navmenu")
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenLogin = () => {
    influxDBButtonPush(userInfo, "navbar", "open_login")
    setAuthIndex(0)
    setOpen({Open: true});
  };

  const handleOpenRegister = () => {
    influxDBButtonPush(userInfo, "navbar", "open_register")
    setAuthIndex(1)
    setOpen({Open: true});
  };

  const handleClose = () => {
    setOpen({Open: false});
  };

  const handleBirthDayClose = ()=>{
    setBirthDayModal({Open: false})
  }

  const handleNickNameClose = ()=>{
    if (userInfo.UserInfo.Nickname != null) {
      setNickNameModal({Open: false})
    }
  }

  const handleRegisterGiftSubscriptionClose = ()=>{
    UserService.setShowState({...(userInfo.UserInfo && userInfo.UserInfo.ShowState), ShowRegisterGiftSubscription: 1}).then((response)=>{
			if (response.data.Success == SuccessMessage) {
        setShowRegisterGiftSubscription({Open: false})
      }
    })
  }

  let avaSrc = "/themes/"+innerTheme.theme+"/imgs/icons/user.png";
  if (userInfo.UserInfo && userInfo.UserInfo != null && userInfo.UserInfo.IconUrl !== "") {
      avaSrc=apiUrl+"/mkstatic/shared/avatars/"+userInfo.UserInfo.IconUrl+"?dop"+userAvatarSuffix
  }

  const goToProfile = ()=>{
    influxDBButtonPush(userInfo, "navbar", "profile")
    navigate("/profile")
  }

  useEffect(() => {
    if (userInfo.UserInfo != null && userInfo.UserInfo.AccountStatus == "banned") {
      navigate("/banned")
    }	}, [userInfo])

    useEffect(() => {
      if (audioBoxes) {
        setOthersAudioBoxes(audioBoxes)
      }
    }, [audioBoxes])    

  useEffect(() => {
    sendMsgSite(siteWS.ws, "CheckCurrent", {})
	}, [siteWS, wsOpened])
  useEffect(() => {
    if (checkCurrentInterval.interval != 0) {
      clearInterval(checkCurrentInterval.interval);
    }
    if (authorized.Authorized && userPersonal.UserPersonal == null) {
      UserService.getUserPersonal().then((response)=>{
        if (response.data.Success == SuccessMessage) {
          setUserPersonal({UserPersonal: response.data.UserPersonal})
        }
      })
    }
    if (authorized.Authorized && userInfo.UserInfo === null) {
      UserService.getUserInfo().then(response=>{
          if (response.data.Success == SuccessMessage) {
              if (response.data.UserInfo.Nickname === null) {
                setNickNameModal({Open: true})
              }
              if (!(response.data.UserInfo.ShowedState && response.data.UserInfo.ShowedState.ShowRegisterGiftSubscription)) {
                // setShowRegisterGiftSubscription({Open: true})
              }
              if (response.data.UserInfo.AccountStatus == "banned") {
                navigate("/banned")
              }
              setUserInfo({UserInfo: response.data.UserInfo})
              setUserBorders({Borders: response.data.Borders})
          }
      })
    }
    if (authorized.Authorized) {
      let updateCheckCurrent = setInterval(() => {
        sendMsgSite(siteWS.ws, "CheckCurrent", {})
      }, 1000)
      setCheckCurrentInterval({interval: updateCheckCurrent})
    }
	}, [authorized]);
  let mobile = false
  if (window.matchMedia("(max-width: 899px)").matches) {
      mobile = true
  }
  let audioBoxesElements = []
  if (othersAudioBoxes) {
    othersAudioBoxes.forEach((value, key, map)=>{
      audioBoxesElements.push(value)
    });
  }
  const handleCloseUserNavMenu = ()=>{
    setAnchorUserNav(null)
  }
  const openUserNavMenu = (event)=>{
    setAnchorUserNav(event.currentTarget);
  }

  let chatsSlots = numOfWidthSlots(mobile ? MiniChatMobileWidth : MiniChatWidth)
	return (
    <div>
      {audioBoxesElements}
		<AppBar 
      position="static" 
      className={`navbar ${home ? 'navbar--home': ''}`}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={()=>{navigate("/")}}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              cursor: "pointer",
            }}
          >
            {userInfo.UserInfo && userInfo.UserInfo.AccountType != "standart" ? 
            <span><img src="/logos/logo_prem_light.png" style={{height: "50px"}}/></span> :
            <span><img src="/logos/logo_light.png" style={{height: "50px"}}/></span>}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
                color: 'inherit',
              }}
            >
              {pages.map((page, index) => (
                <MenuItem key={index} 
                  onClick={()=>{
                    influxDBButtonPush(userInfo, "navbar", page.metric);
                    sendMsgSite(siteWS.ws, "Action", {})
                    navigate(page.url)
                    handleCloseNavMenu()
                  }}>
                  <NavLink
                    to={page.url}
                    style={{
                      color: 'inherit',
                      textDecoration: 'none',
                    }}
                    onClick={()=>{
                      influxDBButtonPush(userInfo, "navbar", page.metric);
                      sendMsgSite(siteWS.ws, "Action", {})
                    }}
                  >
                    {page.title}
                  </NavLink>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {userInfo.UserInfo && userInfo.UserInfo.AccountType != "standart" ?
                <span><img src="/logos/logo_prem_light.png" style={{height: "50px"}}/></span> :
                <span><img src="/logos/logo_light.png" style={{height: "50px"}}/></span>}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, index) => (
              <NavLink
                to={page.url}
                className="navbar__nav-link"
                key={index}
                onClick={()=>{
                  influxDBButtonPush(userInfo, "navbar", page.metric);
                  sendMsgSite(siteWS.ws, "Action", {})
                }}
              >
                {page.title}
              </NavLink>
            ))}
          </Box>

          <Stack direction="row" spacing={2} alignItems="center">
            {currentRoom.HaveCurrent && inTheRoom && <Typography variant={mobile ? "h10" : "h6"} noWrap>{currentRoom.Title}</Typography>}
            {!inTheRoom && authorized.Authorized && <ExperienceIndicator></ExperienceIndicator>}
            <IconButton color="inherit" onClick={()=>{
              influxDBButtonPush(userInfo, "navbar", "theme")
              colorMode.toggleColorMode()}}>
              {theme.palette.mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
            </IconButton>     

            {authorized.Authorized ? 
            null:
              <Button variant="outlined" color="inherit" onClick={handleOpenLogin} size="small">
                <FormattedMessage id="login" />
              </Button>
            }

            {authorized.Authorized ? 
              null
            :
              <Button variant="outlined" color="inherit" onClick={handleOpenRegister} size="small">
                <FormattedMessage id="registration" />
              </Button>
            }

            {authorized.Authorized ?
                <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
                  <span style={userInfo.UserInfo && userInfo.UserInfo.AccountType != "standart" ? {border: "2px solid #FFD700", borderRadius: "30px"} : {}}> 
                      <Avatar onClick={openUserNavMenu} alt="Remy Sharp" src={avaSrc} style={{cursor: "pointer"}}/>
                  </span>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorUserNav}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorUserNav)}
                    onClose={handleCloseUserNavMenu}
                    sx={{
                      display: { xs: 'block', },
                      color: 'inherit',
                    }}
                  >
                    {userPages.map((page, index) => (
                      <MenuItem key={index} onClick={()=>{
                        influxDBButtonPush(userInfo, "navbar", page.metric);
                        sendMsgSite(siteWS.ws, "Action", {})
                        navigate(page.url)
                        handleCloseUserNavMenu()
                      }}>
                        <NavLink
                          to={page.url}
                          style={{
                            color: 'inherit',
                            textDecoration: 'none',
                          }}
                          onClick={()=>{
                            influxDBButtonPush(userInfo, "navbar", page.metric);
                            sendMsgSite(siteWS.ws, "Action", {})
                          }}
                        >
                          {page.title}
                        </NavLink>
                      </MenuItem>
                    ))}
                  </Menu>                  
                </Box>
              : null
            }
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>

    { currentRoom.HaveCurrent && !inTheRoom ? <RoomBar userInfo={userInfo} title={currentRoom.Title} id={currentRoom.ID}/>	: null }

   {!location.pathname.startsWith("/room/") && !home && <>{openedPersonalChats.map((item, index) => 
      index < chatsSlots ? mobile ?       
      <Box 
        style={item.opened ? {width: "100%", height: "90%", zIndex: 290, position: "fixed",} : {position: "fixed", bottom: "3px", width: MiniChatMobileWidth+"px", height: MiniChatMobileWidth+"px", right: (index)*(MiniChatMobileWidth+5)+5+"px", zIndex: 290, borderRadius: "40px 40px", border: "1px solid black"}}
        sx={{
          bgcolor: 'background.default',
          color: 'text.primary',
        }}>
          <SmallChat key={index} opened={item.opened} title={item.title} icon={item.icon} occasionID={item.occasionID} occasionType={item.occasionType} style={{position: "absolute", bottom: 0, width: MiniChatWidth+"px"}}></SmallChat>
          </Box> :       
      
      <Box 
        style={{position: "fixed", bottom: 0, width: MiniChatWidth+"px", right: (index)*(MiniChatWidth+10)+"px", zIndex: 290, borderRadius: "15px 15px 0px 0px", border: "1px solid black"}}
        sx={{
          bgcolor: 'background.default',
          color: 'text.primary',
        }}
      >
        <SmallChat key={index} opened={item.opened} title={item.title} icon={item.icon} occasionID={item.occasionID} occasionType={item.occasionType} style={{position: "absolute", bottom: 0, width: MiniChatWidth+"px"}}></SmallChat>
      </Box> : null
    )}

    {openedPersonalChats.length > chatsSlots && !home && location.pathname != "/room" &&
      <Others/>
    }</>}
    {false && openedPersonalChats.length == 0 && !home && 
        <FlatChat hide={true}  key={"page1"} occasionID={1} occasionType={"page"}/>
    }
    
    <Modal
        open={open.Open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...ModalStyleLG, width: 420 }}>
          <Auth startIndex={authIndex}/>
        </Box>
      </Modal>
      <Modal
          open={birthDayModal.Open}
          onClose={handleBirthDayClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...ModalStyle, width: 400,}}>
          <SetBirthDay onClose={()=>{setBirthDayModal({Open: false})}}/>
        </Box>
      </Modal>
      <Modal
          open={nickNameModal.Open}
          onClose={handleNickNameClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...ModalStyle, width: 400 }}>
          <SetNickNameModalPage/>
        </Box>
      </Modal>      
      <Modal
          open={showRegisterGiftSubscription.Open}
          onClose={handleRegisterGiftSubscriptionClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...ModalStyleLG, width: 400 }}>
          <RegisterGiftSubscription ShowState={userInfo.UserInfo && userInfo.UserInfo.ShowState}/>
        </Box>
      </Modal> 
      

      <Snackbar open={showTooYoung.show} 
				onClose={()=>{setShowTooYoung({show: false})}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
				  key={"showTooYoung"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"showTooYoung"}>
				  <FormattedMessage id="you_are_too_young" />
  				</Alert>
			</Snackbar>	


    </div>
	)
};

export default Navbar;
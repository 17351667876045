import { Button, Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import {FormattedMessage} from "react-intl";
import RoomService from "../../../../../services/rooms/RoomService"
import { SuccessMessage } from '../../../../../constants';
import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom"
import {RoomSeed} from "../../../../../livekit_prod/atoms"
import {useRecoilState} from "recoil";
import {UseSoundAtom} from "../../../../atoms/Common"
import ExpChangeBar from "../../../../common/ExpChangeBar/ExpChangeBar"

const Victory = ({
	title,
	icon,
	team,
	keepPlaying,
	melody,
	changeExpInfo
}) => {
	const navigate = useNavigate();
    const [roomSeed, setRoomSeed]  = useRecoilState(RoomSeed);
    const [useSoundAtom, setUseSoundAtom]  = useRecoilState(UseSoundAtom);
	

    useEffect(()=> {
		if (useSoundAtom) {
			melody()
		}
	},[])

	const leaveGame = ()=>{
		RoomService.leaveRoom().then((response)=>{
			if (response.data.Success == SuccessMessage) {
				setRoomSeed("")
				navigate("/games")
			}
		})
	}
	return (
		<Grid
			container
			rowSpacing={2}
			spacing={{ xs: 1, sm: 2 }}
			columns={12}
			direction="column"
			padding={1}
		>
			<Grid 
				item
				container
				alignItems="center"
				spacing={2}
			>
				<Grid item>
					<Typography variant='h4'>
						{title}
					</Typography>
				</Grid>	

				<Grid item>
					<img src={icon} alt="icon" style={{width: '56px', height: '56px'}} />
				</Grid>				
			</Grid> 

			<Grid 
				item
			>
				<Stack spacing={1}>
					<Typography variant='h6'>
						<FormattedMessage id="your_team" />:
					</Typography>

					{team.map((item, index) => 
						<Stack direction="row" spacing={1} alignItems="center" key={index}>
							{item.icon != "" ? <img src={item.icon} alt="icon" style={{width: '32px', height: '32px', borderRadius: '50%'}} /> : null}
							<Typography variant='body1'>{item.name}</Typography>
						</Stack>
					)}					
				</Stack>
			</Grid>

			<Grid 
				item
			>
				<ExpChangeBar expChangeInfo={changeExpInfo} featureType={"hackword"}></ExpChangeBar>
			</Grid>

			<Grid 
				item
			>
				<Stack direction="row" spacing={1} alignItems="center" paddingTop={2}>
					<Button 
						variant="outlined" 
						color="success"
						size='large'
						onClick={leaveGame}
					>
						<FormattedMessage id="exit" />
          			</Button>

					<Button 
						variant="contained" 
						color="success"
						size='large'
						onClick={keepPlaying}
					>
						<FormattedMessage id="another_one" />
          			</Button>
				</Stack>
			</Grid>
		</Grid>
	);
};

export default Victory;
import {
    atom,
} from 'recoil';

export const HackwordWSOpened = atom({
    key: 'HackwordWSOpened',
    default: {
        open: false,
    },
})

export const HackwordWS = atom({
    key: 'HackwordWS',
    default: {
        ws: null,
    },
})

export const HackwordWSRestartTimeout = atom({
    key: 'HackwordWSRestartTimeout',
    default: {
        timeout: 0,
    },
})

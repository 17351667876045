
import InHackwordGame from "../main/games/hackword/InHackwordGame/InHackwordGame"
import PShapedGrid from "./CallBoxes/Layouts/PShapedGrid"
import {domainNameAPI, protocolAPI, apiPort} from "../environment"
import HackwordGameBox from "../main/games/hackword/InHackwordGame/HackwordGameBox/HackwordGameBox"
import RoulettePage from "../main/rooms/roulettes/RoulettePage"
import InIamaliveGame from "../main/games/iamalive/InIamaliveGame/InIamaliveGame"
import IamaliveGameBox from "../main/games/iamalive/InIamaliveGame/IamaliveGameBox/IamaliveGameBox"
import Donut10 from "./CallBoxes/Layouts/Donut10"
import Cells20 from "./CallBoxes/Layouts/Cells20"
import GeneralizeLayout from "../main/games/generalize/InGeneralizeGame/GeneralizeLayout"


function LayoutsResolver(props) {
    let children = <span></span>
    if (props.type == "hackword") {
        for (let [position, box] of props.roommates) {
            props.roommates.set(position, <HackwordGameBox livekitBox={box} position={position}></HackwordGameBox>)
        }
        children = <InHackwordGame key={"hackwordgame"+props.entityID} myrole={props.myrole} roomSeed={props.roomSeed} myid={props.myid} hackwordID={props.entityID} roomID={props.roomID} myname={props.myname} myposition={props.myposition} scope={props.scope} apiUrl={"wss://"+domainNameAPI+apiPort+"/api/hackwordws"}></InHackwordGame>
    }
    if (props.type == "iamalive") {
        for (let [position, box] of props.roommates) {
            props.roommates.set(position, <IamaliveGameBox  myid={props.myid} livekitBox={box} position={position} myposition={props.myposition} scope={props.scope} iamaliveID={props.entityID}></IamaliveGameBox>)
        }
        children = <InIamaliveGame myrole={props.myrole} roomSeed={props.roomSeed} myid={props.myid} iamaliveID={props.entityID} roomID={props.roomID} myname={props.myname} myposition={props.myposition} apiUrl={"wss://"+domainNameAPI+apiPort+"/api/iamalivews"} scope={props.scope}/>
    }
    if (props.type == "generalize") {
        return <GeneralizeLayout myrole={props.myrole} roomSeed={props.roomSeed} myid={props.myid} roommates={props.roommates} generalizeID={props.entityID} roomID={props.roomID} myname={props.myname} myposition={props.myposition} apiUrl={"wss://"+domainNameAPI+apiPort+"/api/generalizews"} scope={props.scope}></GeneralizeLayout>
    }
    if (props.roulette) {
        return <RoulettePage myid={props.myid} roommates={props.roommates} apiURL={"wss://"+domainNameAPI+apiPort+"/api/roulette/tetatet/ws"}/>
    }
    if (props.layout == "pshaped8") {
        return (<PShapedGrid roommates={props.roommates} children={children}></PShapedGrid>)
    }
    if (props.layout == "donut10") {
        return (<Donut10 roommates={props.roommates} children={children}></Donut10>)
    }
    if (props.layout == "cells20") {
        return (<Cells20 roommates={props.roommates} ></Cells20>)
    }
    return (<span></span>)//(<PShapedGrid roommates={props.roommates} children={children}></PShapedGrid>)
}

export default LayoutsResolver
import { Grid } from '@mui/material';
import UserService from "../../../../services/UserService"
import {SuccessMessage} from "../../../../constants";
import React, { useState, useEffect } from 'react';
import BorderItem from "./BorderItem"











const Borders = (props)=>{
	const [page, setPage] = useState(1);
    const [bordersEntries, setBordersEntries] = useState([]);


    useEffect(() => {
        UserService.getBorders(page).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                let bordersArr = []
                for (let name in response.data.BordersPage) {
                    bordersArr.push(response.data.BordersPage[name])
                }
                setBordersEntries(bordersArr)
            }
        })
    }, [page])

    return (
        <Grid
            container
            spacing={4}
            columns={{ xs: 4, sm: 4, md: 12, lg: 12}}
            padding={4}
        >
                {bordersEntries.map((item, index) => (
                    <Grid item xs={4} sm={2} md={4} lg={3} key={index}> 
                        <BorderItem
                            name={item.Name}
                            page={page}
                            requireLevel={item.RequireLevel}
                            requirePremium={item.RequirePremium}
                        />
                    </Grid>
                ))}
        </Grid>
    )
}

export default Borders;
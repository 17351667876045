import { Button, Grid, Slider, Stack, Switch, TextField, Typography, Box, IconButton, Tooltip, Modal } from '@mui/material';
import React, { useState, useEffect } from 'react';
import {FormattedMessage} from "react-intl";
import {GeneralizeGameState, GeneralizeTeamsState,} from "../atoms/GameState"
import {useRecoilState} from "recoil";
import {sendMsgGeneralize} from "../GeneralizeGameController"
import {GeneralizeWS} from "../atoms/Connection"
import { RoomInfo, ShowChangeRoomType, SendAdmin } from '../../../../../livekit_prod/atoms';
import PersonAddAlt from '@mui/icons-material/PersonAddAlt';
import InfoNote from "./InfoNote"
import HelpIcon from '@mui/icons-material/Help';
import SettingsIcon from "@mui/icons-material/Settings"
import ModalStyle, { ModalStyleXL } from "../../../../../utils/ModalStyle/ModalStyle";
import GameSettings from "../GameSettings/GameSettings"
import AllowNewPlayersDialog from "../AllowNewPlayersDialog/AllowNewPlayersDialog"
import ModalClose from "../../../../../utils/ModalStyle/ModalClose"
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function TeamsPicking(props) {
    const [generalizeGameState, setGeneralizeGameState] = useRecoilState(GeneralizeGameState);
    const [generalizeTeamsState, setGeneralizeTeamsState] = useRecoilState(GeneralizeTeamsState);
	const [generalizeWS, setGeneralizeWS] = useRecoilState(GeneralizeWS);
    const [roomInfo, setRoomInfo] = useRecoilState(RoomInfo);
	const [sendAdmin, setSendAdmin]  = useRecoilState(SendAdmin);

	const [showChangeRoomType, setShowChangeRoomType] = useRecoilState(ShowChangeRoomType)

	const [currentTeam, setCurrentTeam] = useState(null);
	const [currentUser, setCurrentUser] = useState(null);
	const [myTeamID, setMyTeamID] = useState(0)
	const [showSettingsModal, setShowSettingsModal] = useState(false)
	const [showAllowNewUsersDialog, setShowAllowNewUsersDialog] = useState(false)

	// SET myTeamName

	const [teams, setTeams] = useState([]);
	useEffect(()=> {
		let teams = []
		for (let teamID in generalizeTeamsState.Teams) {
			let background = "#c94d4d"
			if (teamID == 2) {
				background =  "#4b60c9"
			} 
			if (teamID == 3) {
				background =  "#19b37f"
			} 			
			teams.push({id: teamID, userPoses: generalizeTeamsState.Teams[teamID].Players, background: background, masterPos: generalizeTeamsState.Teams[teamID].Master})
		}
		const getNickByPos = (pos)=>{
			for (let identity in roomInfo.roomMates) {
				if (roomInfo.roomMates[identity].Position == pos) {
					return identity
				}
			}
			return "<user "+pos+">"
		}

		for (let team of teams) {
			let users = []
			for (let userPos of team.userPoses) {
				if (userPos == props.myposition) {
					setMyTeamID(team.id)
				}
				if (team.masterPos != userPos) {
					users.push({name: getNickByPos(userPos), id: userPos})
				}
			}
			if (team.masterPos > 0) {
				team.master = {name: getNickByPos(team.masterPos), id: team.masterPos}
			} else {
				team.master = {name: <FormattedMessage id="empty_master"/>, id: 0}
			}
			team.users = users
		}
		setTeams(teams)
	}, [generalizeTeamsState, roomInfo])

	function dragStartHandler(e, team, user) {
		setCurrentTeam(team);
		setCurrentUser(user);
	}

	function dragEndHandler(e) {
	}

	function dragOverHandler(e) {
		e.preventDefault();
	}

	function dropHandler(e, team, isMaster) {
		e.preventDefault();
		e.stopPropagation();
		sendMsgGeneralize(generalizeWS.ws, "MovePlayer", {PlayerPosition: currentUser.id, NewTeamID: parseInt(team.id), IsMaster: isMaster})
	}

	function dragOverCardHandler(e) {
		e.preventDefault();
	}
	function dropCardHandler(e, team) {
		e.preventDefault();
		if (generalizeGameState.Phase == "making_word_number" || generalizeGameState.Phase == "guessing") {
			return
		}
		sendMsgGeneralize(generalizeWS.ws, "MovePlayer", {PlayerPosition: currentUser.id, NewTeamID: parseInt(team.id), IsMaster: false})
	}
	const onCardClick = (e, teamID, isMaster)=>{
		if (generalizeGameState.Phase == "making_word_number" || generalizeGameState.Phase == "guessing") {
			return
		}
		if (props.myposition > 0) {
			sendMsgGeneralize(generalizeWS.ws, "MovePlayer", {PlayerPosition: props.myposition, NewTeamID: parseInt(teamID), IsMaster: isMaster})
		}
	}
	
	const mouseLeaveMaster = (e)=>{
		e.stopPropagation()
		e.target.style.opacity = "100%";
	}
	const mouseEnterMaster = (e)=>{
		e.stopPropagation()
		e.target.style.opacity = "50%";
	}
	const [showTeamJoin, setShowTeamJoin] = useState(0)
	const mouseLeaveUsers = (e, teamID)=>{
		e.stopPropagation()
		setShowTeamJoin(0)
	}
	const mouseEnterUsers = (e, teamID)=>{
		e.stopPropagation()
		setShowTeamJoin(teamID)
	}
	const startGame = ()=>{
		if (generalizeGameState.AllowNewPlayers && !generalizeGameState.AskedAboutNewPlayers) {
			setShowAllowNewUsersDialog(true)
			return
		}
		sendMsgGeneralize(generalizeWS.ws, "StartGame", {})
	}
	const onCloseGameSettingsModal = ()=>{
		setShowSettingsModal(false)
	}
	const handleOpenSetting = ()=>{
		setShowSettingsModal(true)
	}
	const clickSendAdmin = ()=>{
		setSendAdmin(true)
	}
	const keepAdmin = ()=>{
		setSendAdmin(false)
	}
	const onCloseAllowNewUsersDialogModal = ()=>{
		setShowAllowNewUsersDialog(false)
	}	  
    return (
        <Stack
            direction="column"
			mt={7}
        >
            {!generalizeGameState.IsAdmin && <Stack direction="row" alignItems="center" justifyContent="center" sx={{padding: "15px"}}>
				<Button variant="contained" color="success">
                    <FormattedMessage id="teams" />
                </Button>
				<Tooltip 
                      title={
                        <>
                          <InfoNote noteID={"generalize_teams_note"}></InfoNote>
                        </>
                      } 
                    >
					   <HelpIcon />
                  </Tooltip>
            </Stack>}

            {generalizeGameState.IsAdmin && <Stack direction="row" alignItems="center" justifyContent="center" sx={{padding: "15px", position: "relative"}}>
                <FormattedMessage id="you_are_admin"/>
				<Tooltip 
                      title={
                        <>
                          <InfoNote noteID={"generalize_admin_note"}></InfoNote>
                        </>
                      } 
                    >
					   <HelpIcon />
                  </Tooltip>
				<IconButton onClick={handleOpenSetting}>
                	<SettingsIcon />
              	</IconButton>
				<Button variant="contained" color="success" onClick={startGame}><FormattedMessage id="StartGame"/></Button>
                {sendAdmin ? 
					<Button 
					variant="contained" 
					color="success"
					onClick={keepAdmin}
					style={{marginLeft: "15px"}}
					>
						<FormattedMessage id="keep_admin" />                
					</Button>              
                :                                 
					<Button 
						variant="outlined" 
						color="success"
						onClick={clickSendAdmin}
						style={{marginLeft: "15px"}}
					>
						<FormattedMessage id="send_admin" />                
					</Button>}
					{generalizeGameState.IsAdmin && <Button variant="outlined" onClick={()=>{setShowChangeRoomType(true)}} size="small" style={{position: "absolute", right: "0px", top: "-20px", zIndex: 99}} id="change_game_btn" endIcon={<ChevronRightIcon />}>
              			<FormattedMessage id="change_game" />
            		</Button>}
            </Stack>}

            <div>
			<Grid container spacing={1} alignItems="center" justifyContent="center" style={{padding: "15px"}}>
				{teams.map(team => 
					<Grid 
						item 
						xs={6} 
						sm={3} 
						md={6} 
						lg={3} 
						key={team.id}
						style={{position: "relative"}}
					>
						<div 
							style={{ height: '220px', backgroundColor: team.background, padding: "6px", color: "#ffffff", cursor: "pointer", borederRadius: "4px"}}							
						>
							<Typography variant='h6' align="center" onClick={(e)=> onCardClick(e, team.id, true)} 							
								{...(
									generalizeGameState.IsAdmin &&
									{
										onDragStart: (e) => dragStartHandler(e, team, team.master),
										onDragLeave: (e) => dragEndHandler(e),
										onDragEnd: (e) => dragEndHandler(e),
										onDragOver: (e) => dragOverHandler(e),
										onDrop: (e) => dropHandler(e, team, true),
										draggable: true,
									}
								)}							
								onMouseLeave={mouseLeaveMaster} onMouseEnter={mouseEnterMaster}>
								{team.master.name}
							</Typography>
							<div onClick={(e)=> onCardClick(e, team.id, false)} style={{width: "100%", height: "100%"}} 
								{...(
									generalizeGameState.IsAdmin &&
									{
										onDragOver: (e) => dragOverCardHandler(e),
										onDrop: (e) => dropCardHandler(e, team)
									}
								)}							
								onMouseLeave={(e)=>mouseLeaveUsers(e, team.id)} onMouseEnter={(e)=>mouseEnterUsers(e, team.id)}>
								{team.users.map((user, index) => 
									<div onMouseLeave={mouseLeaveUsers}
										{...(
											generalizeGameState.IsAdmin &&
											{
												onDragStart: (e) => dragStartHandler(e, team, user),
												onDragLeave: (e) => dragEndHandler(e),
												onDragEnd: (e) => dragEndHandler(e),
												onDragOver: (e) => dragOverHandler(e),
												onDrop: (e) => dropHandler(e, team, false),
												draggable: true,
											}
										)}									
										style={{cursor: 'pointer', marginBottom: '4px', paddingLeft: '15px'}}
										key={index}
									>
										<Grid container direction="row" alignItems="center">
											<Grid item>
											</Grid>
											<Grid item>
												<Typography variant='body2'>
													{user.name}
												</Typography>
											</Grid>
										</Grid>
									</div>	
								)}

							</div>
						</div>
						{team.id == showTeamJoin && <div>
							<IconButton aria-label="delete" style={{position: "absolute", bottom: "20px", left:"50%", transform: "translate(-50%, 0%)"}}>
  								<PersonAddAlt />
							</IconButton>
						</div>}
					</Grid>
				)}
			</Grid>
		</div>

			<Modal
                open={showSettingsModal}
                onClose={onCloseGameSettingsModal}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...ModalStyleXL }}>
					<ModalClose onClose={onCloseGameSettingsModal}/>
                	<GameSettings OnCloseModal={onCloseGameSettingsModal}></GameSettings>               
                </Box>
            </Modal>
			<Modal
                open={showAllowNewUsersDialog}
                onClose={onCloseAllowNewUsersDialogModal}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...ModalStyleXL }}>
					<ModalClose onClose={onCloseAllowNewUsersDialogModal}/>
                	<AllowNewPlayersDialog OnCloseModal={onCloseGameSettingsModal} onClose={onCloseAllowNewUsersDialogModal}></AllowNewPlayersDialog>               
                </Box>
            </Modal> 			
        </Stack>
    )
}

export default TeamsPicking

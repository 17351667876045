import React, {useState, useEffect} from 'react';
import './user-window.scss';
import {useRecoilState,} from 'recoil';
import {RoomInfo, ActiveSpeakers} from "../../../atoms"

const UserWindow = ({position, noRatio, matebox}) => {
	const [borders, setBorders] = useState({Name: "none"});
    const [activeSpeakingNow, setActiveSpeakingNow] = useState(false);

    const [roomInfo, setRoomInfo] = useRecoilState(RoomInfo);  
	const [activeSpeakers, setActiveSpeakers] = useRecoilState(ActiveSpeakers);  

    useEffect(() => {
		for (let nickname in roomInfo.roomMates) {
			if (roomInfo.roomMates[nickname].Position == position && roomInfo.roomMates[nickname].Borders) {
				let borders = roomInfo.roomMates[nickname].Borders
				setBorders(borders)
			}
		}
	}, [roomInfo, position])
	useEffect(() => {
		for (let nickname in roomInfo.roomMates) {
			if (roomInfo.roomMates[nickname].Position == position && roomInfo.roomMates[nickname].Borders) {
				let haveIdentity = false
				for (let speaker of activeSpeakers) {
					if (speaker == nickname) {
						haveIdentity = true
					}
				}
				setActiveSpeakingNow(haveIdentity)
				return
			}
		}
		if (matebox == undefined) {
			setActiveSpeakingNow(false)
		}
	}, [roomInfo, position, activeSpeakers, matebox])
	return (
		<div className={`user-window ${noRatio ? 'user-window--no-ratio' : ''}`} style={{height: "100%", padding: "6% 8%", backgroundColor: matebox == undefined && "rgba(0,0,0,0)", outline: activeSpeakingNow ? "7px solid green" : undefined}}>
            {matebox != undefined ? matebox: <div style={{padding: '20px', color: '#ffffff', height: "100%",}}></div>}
			{matebox != undefined && borders.Name != "none" ? <img src={"/borders/"+borders.Name+".png"} style={{zIndex: 91, width: "100%", height: "100%", position: "absolute", top: "0px", left: "0px"}}/> : null}
		</div>
	);
};

// border: "20px solid", borderImage: "url('/borders/BoxBorder.png') 20",
//			{matebox != undefined ? <img src={"/borders/BoxBorder.png"} style={{zIndex: 900, width: "calc(100%+20px)", height: "calc(100%+20px)", position: "absolute", top: "-20px", left: "-20px"}}/> : null}

export default UserWindow;
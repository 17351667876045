
import React, { useState } from 'react';
import {FormattedMessage} from "react-intl";
import { Button, Grid, Typography } from '@mui/material';
import {sendMsg} from "../../../WSController"
import {useParams} from "react-router-dom";
import TokenService from "../../../../services/TokenService";





function SpectateWarningModal(props) {

    let { room_key } = useParams();

    const clickSpectate = ()=>{
        sendMsg("Spectate", {'SessionID': TokenService.getLocalAccessToken(), "RoomKey": room_key})
        props.closeModal()
    }
    return (
        <Grid
        container
        rowSpacing={2}
        spacing={{ xs: 1, sm: 2 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        direction="column"
        padding={1}
        alignItems="center" justifyContent="center"
    >
            <Grid item xs={4} sm={8} md={12}>
                <Typography variant='h4'>
                    <FormattedMessage id="spectate" />
                </Typography>
            </Grid>

            <Grid item xs={4} sm={8} md={12}>
                <FormattedMessage id="if_spectate_warning" />
			</Grid> 
            <Grid item xs={4} sm={8} md={12} >
				<Button variant="contained" size="large" onClick={clickSpectate}>
					<FormattedMessage id="spectate" />
				</Button>
			</Grid>
		</Grid>              
    )
}

export default SpectateWarningModal;

import {
    atom,
} from 'recoil';



export const UnAccesibleOnePlayerInTeam = atom({
    key: 'HackwordUnAccesibleOnePlayerInTeam',
    default: false
})

export const YouHaveMaximumPlayers = atom({
    key: 'HackwordyouHaveMaximumPlayers',
    default: false
})

export const ShowCantStartGamS = atom({
    key: 'HackwordShowCantStartGamS',
    default: false
})

export const ErrorOccured = atom({
    key: 'HackwordErrorOccured',
    default: false
})

export const NewPointsChanges = atom({
    key: 'HackwordNewPointsChanges',
    default: false
})


import { Alert } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import UserInfo from '../UserPages/Profile/UserInfo/UserInfo';

const AccountBlockedInfo = ({
	timeToUnlock,
	avatar,
	nickname,
	email
}) => {
	return (
		<Stack spacing={2}>
			{timeToUnlock && <Alert severity="info">
				<FormattedMessage id="account_will_be_unblocked" /> &nbsp;
				<b>
					<FormattedDate 
						value={timeToUnlock} 
						day="2-digit" 
						month="long" 
						year="numeric"
					/>
				</b>				
			</Alert>}	

			<UserInfo
				avatar={avatar}
				nickname={nickname}
				email={email}
			/>
		</Stack>
	)
};

export default AccountBlockedInfo;









export const Games = {
    "games": `
### Приятный разговор может скрасить любую игру
    `,
    "games_hackword": `
# Hackword

Похожие игры: Alias, Шляпа

### Правила:

Игроки делятся на команды. 

Один человек из команды видит загаданное слово. Он пытается объяснить его, не используя однокоренные, чтобы сокомандники его угадали. 

Загадывающий отмечает в интерфейсе, что слово угадано, кликнув на руку с большим пальцем вверх. Также он может пропустить это слово нажав на руку с большим пальцем вниз. 

Задача: угадать как можно больше слов.

Если после окончания раунда кто-то не согласен с тем, что слово засчитано или пропущено, можно открыть ВСЕ СЛОВА и там внести изменения кликнув на соответствующую руку.
    `,
    "games_iamalive": `
# Я Живое?

Похожие игры: "Кто я?" или как её еще называют в интернете "Бумажки"

### Правила:

Каждому игроку придумывают слово или словосочетание, которое он не видит и впоследствии должен угадать. 

Все остальные видят эти слова.

Игроки по очереди задают вопросы на которые можно ответить да или нет и пытаются угадать свои слова.

Самая популярная вариация: когда задают вопросы пока не будет ответ 'нет', максимум 3 вопроса за ход.

Также можно играть с правилом: что просто задается по одному вопросу

Если нужно ускорить игру - можно подсказывать)
    `,
    "games_generalize": `
# Обобщение

Похожие игры: Code Names

### Правила:

Игроки делятся на 2 команды. Также можно играть одной командой, но так делают редко, это непопулярно.

Есть 25 слов, в основном это слова, котрые имеют одновременно несколько значений. 

В каждой команде есть один ведущий, который видит какие слова принадлежат его команде, а какие нет. Также он видит "черное слово" - если сокомандники решат, что это из слово - они проиграют.

Ведущий пишет слово и цифру так, чтобы слово обобщало несколько слов его команды, а цифра значила их количество.

Остальные члены его команды пытаются угадать какие слова имел ввиду ведущий. Если открывают не свое слово, ход переходит команде соперников. Если открывается черное слово, команда открывшая его, проиграла. 

Задача - открыть все свои слова. Когда команда открыла все свои слова - она победила и игра заканчивается.
    `,
}


import { Box, Stack, Typography, Grid, Button, FormControl, TextField } from '@mui/material';
import {FormattedMessage} from 'react-intl'
import React, {useState, useEffect} from 'react';
import UserService from '../../../services/UserService';
import {SuccessMessage, MaxUserReportDescriptionLength} from "../../../constants"








const ReportUserModal = (props)=>{

	const [reportDescription, setReportDescription] = useState("");
	const [reportDescriptionError, setReportDescriptionError] = useState(null);


    const changeReportDescription = (event)=>{
        if (event.target.value.length > MaxUserReportDescriptionLength) {
			setReportDescriptionError(<span><FormattedMessage id="cantbelonger"/> {MaxUserReportDescriptionLength}</span>)
            return
		} else {
			setReportDescriptionError(null)
		}
        setReportDescription(event.target.value);
    }
    const reportuser = ()=>{
        UserService.reportUser(props.profileUserID, reportDescription).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                props.onClose()
            }
        }).catch((err) => {
            props.onClose()
        })
    }
    return (
        <Grid
            container
            rowSpacing={4}
            spacing={{ xs: 2, sm: 4 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            direction="column"
            justifyContent="center"
            alignItems="center"
            padding={1}
        >
            <Grid item xs={4} sm={8} md={12}>
				<Typography variant='h4'>
					<FormattedMessage id="report_user" />
				</Typography>
			</Grid> 

            <Grid item xs={4} sm={8} md={12} sx={{ width: '100%' }}>
                <FormControl fullWidth sx={{ m: 1 }}>
                    <TextField
                        id="about"
                        value={reportDescription}
                        onChange={changeReportDescription}
                        label={<FormattedMessage id="report_description" />}
                        multiline
                        rows={4}
                        helperText={reportDescriptionError}
                        error= {reportDescriptionError != null}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4} sm={8} md={12}>
                <Button variant="contained" size="large" onClick={reportuser}>
					<FormattedMessage id="report_user" />
				</Button>
            </Grid>
        </Grid>
    )
}

export default ReportUserModal;


import {GeneralizeGameState, GeneralizeTeamsState, MasterGameMap, VisibleGameMap } from "../atoms/GameState"
import {GeneralizeWS } from "../atoms/Connection"

import React, { useState, useEffect } from 'react';
import {useRecoilState} from "recoil";
import { Button, Grid, Slider, Stack, Switch, TextField, Typography, Box, IconButton, Modal } from '@mui/material';
import MapCell from "./MapCell"
import {sendMsgGeneralize} from "../GeneralizeGameController"
import {FormattedMessage} from "react-intl";
import SettingsIcon from "@mui/icons-material/Settings"
import ModalStyle, { ModalStyleXL } from "../../../../../utils/ModalStyle/ModalStyle";
import GameSettings from "../GameSettings/GameSettings"
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ShowChangeRoomType } from '../../../../../livekit_prod/atoms';



function GameMap(props) {
    const [masterGameMap, setMasterGameMap]  = useRecoilState(MasterGameMap);
    const [visibleGameMap, setVisibleGameMap]  = useRecoilState(VisibleGameMap);
    const [generalizeTeamsState, setGeneralizeTeamsState]  = useRecoilState(GeneralizeTeamsState);
    const [generalizeGameState, setGeneralizeGameState]  = useRecoilState(GeneralizeGameState);
    const [generalizeWS, setGeneralizeWS]  = useRecoilState(GeneralizeWS);
	const [showChangeRoomType, setShowChangeRoomType] = useRecoilState(ShowChangeRoomType)

    const [cells, setCells] = useState([])
    const [mapType, setMapType] = useState("master")
	const [showSettingsModal, setShowSettingsModal] = useState(false)
    const [activeTurn, setActiveTurn] = useState(false)

    useEffect(()=> {
        let c = []
        let isMaster = false
        if (masterGameMap.Cells.length > 0) {
            isMaster = true
            c = masterGameMap.Cells
        } else if (visibleGameMap.Cells.length > 0) {
            c = visibleGameMap.Cells
        }
        let activeCell = false
        if (generalizeTeamsState.Teams && generalizeTeamsState.Teams[generalizeGameState.TeamIDTurn] && generalizeGameState.Phase == "guessing") {
            if (generalizeTeamsState.Teams[generalizeGameState.TeamIDTurn].Master != props.myposition) {
                for (let player of generalizeTeamsState.Teams[generalizeGameState.TeamIDTurn].Players) {
                    if (player == props.myposition) {
                        activeCell = true
                    }
                } 
            }
        }
        setActiveTurn(activeCell)
     
        let gameMap = []
        let i = 0
        let key = 0
        for (let row of c) {
            let j = 0
            for (let cell of row) {
                key++;
                let cursor = "default"
                if (cell.OwnID == -2) {
                    cursor = "pointer"
                }
                let background = "#e8e8cf"
                if (cell.OwnID == 1) {
                    background = "#fc8181"
                    if (isMaster && cell.Opened) {
                        background = "#630101"
                    }
                } else if (cell.OwnID == 2) {
                    background = "#61b8ff"
                    if (isMaster && cell.Opened) {
                        background = "#011452"
                    }
                } else if (cell.OwnID == 0) {
                    background = "#ebebe6" // #ebebe6
                    if (isMaster && cell.Opened || cell.Opened) {
                        background = "#d6a56d"
                    }
                } else if (cell.OwnID == -1) {
                    background = "#999999"
                    if (isMaster && cell.Opened) {
                        background = "#030300"
                    }
                }
                let clickCell = (e)=>{
                    console.log("Is not interactive")
                }
                if (activeCell) {
                    clickCell = (e, i, j)=>{
                        if (cell.OwnID != -2) {
                            return
                        }
                        sendMsgGeneralize(generalizeWS.ws, "VoteForCell", {I: i, J: j})
                    }
                }                
                gameMap.push(<MapCell cell={cell} k={key} key={key} i={i} j={j} background={background} cursor={cursor} onClick={clickCell}></MapCell>)
                j++
            }
            i++
        }
        setCells(gameMap)
    }, [mapType, masterGameMap, visibleGameMap, generalizeGameState, generalizeTeamsState])

    const onCloseGameSettingsModal = ()=>{
		setShowSettingsModal(false)
	}
	const handleOpenSetting = ()=>{
		setShowSettingsModal(true)
	}
    let startGame = ()=>{
        sendMsgGeneralize(generalizeWS.ws, "SetSettings", {LearningSeconds: 0, MakeWordSeconds: 0, GuessingSeconds: 0,
            StartNew: true, ShufflePlayers: false, OnlyOneTeam: generalizeGameState.ForcedOneTeam})
    }
    useEffect(()=> {
        startGame = ()=>{
            sendMsgGeneralize(generalizeWS.ws, "SetSettings", {LearningSeconds: 0, MakeWordSeconds: 0, GuessingSeconds: 0,
                StartNew: true, ShufflePlayers: false, OnlyOneTeam: generalizeGameState.ForcedOneTeam})
        }
    }, [generalizeGameState])


    const endTurnClick = ()=>{
        if (activeTurn) {
            sendMsgGeneralize(generalizeWS.ws, "VoteForCell", {I: 100, J: 100})
        }
    }
    return (
        <Stack 
            direction="column"
            alignItems="center" justifyContent="center"
            mt={5}
        >

            {generalizeGameState.IsAdmin && <Stack direction="row" alignItems="center" justifyContent="center" sx={{padding: "15px", width: "100%", position: "relative"}}>
                <FormattedMessage id="you_are_admin"/>
				<IconButton onClick={handleOpenSetting}>
                	<SettingsIcon />
              	</IconButton>
                {generalizeGameState.Phase == "ended" ? <Button variant="contained" color="success" onClick={startGame}><FormattedMessage id="start_next"/></Button> : null}
                {generalizeGameState.Phase == "ended" && <Button variant="outlined" onClick={()=>{setShowChangeRoomType(true)}} size="small" style={{position: "absolute", right: "30px", top: "-20px", zIndex: 99}} id="change_game_btn" endIcon={<ChevronRightIcon />}>
              			<FormattedMessage id="change_game" />
            		</Button>}
            </Stack>}
            {!generalizeGameState.IsAdmin && generalizeGameState.Phase == "ended" && <Typography variant='h6'><FormattedMessage id="admin_can_start_new_game"/></Typography>}


            <Grid container spacing={0} columns={{ xs: 5, sm: 5, md: 5 }} alignItems="center" justifyContent="center" style={{maxWidth: "920px", marginBottom: "15px"}}>
                {cells}
            </Grid>

            <Grid container spacing={0} columns={{ xs: 5, sm: 5, md: 5 }} alignItems="center" justifyContent="center" style={{maxWidth: "920px", marginBottom: "15px"}}>
                <MapCell cell={{Word: <FormattedMessage id="end_turn"/>, OwnID: 0, Opened: false}} k={100} key={100} i={100} j={100} background={"#ebebe6"} cursor={activeTurn ? "pointer" : "default"} onClick={endTurnClick}></MapCell>
            </Grid>

            <Modal
                open={showSettingsModal}
                onClose={onCloseGameSettingsModal}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...ModalStyleXL }}>
                	<GameSettings OnCloseModal={onCloseGameSettingsModal}></GameSettings>               
                </Box>
            </Modal>             
        </Stack>
    )
}

export default GameMap

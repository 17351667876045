import React from "react";
import { Stack, Typography } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';

import './event-item.scss';

const EventItem = ({
	onClick,
	date,
	icon,
	name,
	roomSize,
	users,
	privet,
}) => {
	return (
		<Stack 
			className="event-item"
			direction={{xs: 'column', md: 'row'}}
			justifyContent="space-between"
			alignItems={{xs: 'flex-start', md: 'center'}}
			padding={2}
			onClick={onClick}
			spacing={2}
		>
			<Stack 
				spacing={2} 
				direction={{xs: 'column', md: 'row'}} 
				alignItems={{xs: 'flex-start', md: 'center'}}
			>
				<Typography variant="body2" className="event-item__date">
					{date}
				</Typography>

				<Stack 
					spacing={2} 
					direction='row'
					alignItems='center'
				>
					<img src={icon} alt={name} className="event-item__icon"/>

					<Typography variant='body1'>{name}</Typography>
				</Stack>
			</Stack>

			<Stack 
				spacing={1} 
				direction='row'
				alignItems='center'
			>
				<Stack direction="row" alignItems="center" spacing={0.5}>
					<Typography variant='body2'>{users}/{roomSize}</Typography>
					<PersonIcon fontSize='small' />
				</Stack>

				{privet &&
					<LockIcon fontSize='small' />
				}
			</Stack>
		</Stack>
	);
};

export default EventItem;

import ReactMarkdown from 'react-markdown'


const markdown = `
# Пользовательское согашение

Настоящее Пользовательское соглашение (далее — «Соглашение») устанавливает условия и правила использования пользователями Сети сайта https://mnogokomnat.ru/ или https://многокомнат.рф/ (далее — «Сайт») и представляет собой Соглашение между ИП Гаркавенко Сергей Владимирович и любым лицом, являющимся пользователем Сайта (далее — «Пользователь»).

## Термины и определения
1.1 **Пользовательское соглашение** - представляет собой договор в соответствии со ст. 437 ГК РФ между Пользователем и оператором относительно порядка использования Сайта.

1.2 **Сайт** -  совокупность информации, содержащейся в информационной системе, доступ к которой обеспечивается посредством информационно-телекоммуникационной сети «Интернет», расположенная на доменных именах https://mnogokomnat.ru/ и https://многокомнат.рф/.

1.3 **Учетная запись** - персональное пространство Пользователя на Сайте для осуществления Заказов.

1.4 **Удаление** - действия администрации сайта, направленные на прекращение существования содержимого.

В настоящем Соглашении могут быть использованы термины, которые присутствуют в тексте Политики конфиденциальности, а также иные термины и определения, которые не указаны в п.1 Соглашения.
В этом случае толкование такого термина производится в соответствии с текстом Соглашения.

## Предмет соглашения

2.1 Администрация сайта предоставляет Пользователю право использовать Сайт в порядке и на условиях данного соглашения и Политики конфиденциальности сайта.

2.2 Принимая условия Пользовательского соглашения, вы подтверждаете своё согласие со всеми ее положениями. Моментом подтверждения согласия считается регистрация Учетной записи на Сайте или авторизованный вход на Сайт с помощью стороннего сервиса при котором также создается Учетная запись, что приравнивается к регистрации.

2.3 Если Пользователь не согласен с настоящим Соглашением, то он должен прекратить использование сайта. Если Пользователь уже является зарегистрированным Пользователем, он должен удалить свою учетную запись и прекратить использование сайта.

2.4 Удаление учетной записи осуществляется путем нажатия соответсвующей кнопки в Учетной записи пользователя.

2.5 Вопросы, не урегулированные Соглашением, подлежат разрешению в соответствии с законодательством РФ.

## Порядок использования сайта Пользователем

3.1 Пользователь Cайта обязуется не предпринимать действия, которые могут рассматриваться как нарушение российского законодательства или норм международного права, а также любые действия, которые могут привести к нарушению нормальной работы Сайта.

3.2 При цитировании материалов сайта ссылка на сайт обязательна.

3.3 Пользователь обязуется ознакомиться со всеми актами, регулирующими использование сайта и приложения, в том числе, с политикой конфиденциальности, и самостоятельно несет риск такого не ознакомления.

3.4 Пользователь обязуется регулярно, не реже одного раза в четырнадцать дней, знакомиться с содержанием настоящего Соглашения в целях своевременного ознакомления с его изменениями
и/или дополнениями.

3.5 Если Пользователю нет 18 лет, он обязан оветить "Нет" на вопрос "Есть ли вам 18 лет?"

3.6 Пользователь обязуется ввести правильную дату своего рождения и пользоваться сайтом, только если он не младше 18 лет

3.7 Пользователь обязан сообщать о найденных ошибках Сайта, влияющих на его работоспособность, и не использовать подобные ошибки.

3.8 Пользователь сайта обязуется не публиковать и не транслировать контент с изображением голых тел, порнографического содержания, насилие, контент защещенный авторским правом или любой другой контент нарушающий законодательство Российской Федерации.

3.9 Пользователь обязуется соблюдать Правила Сайта (https://mnogokomnat.ru/knowledge_base/rules)

## Права и обязанности администрации сайта

4.1 Администрация имеет право, в случае нарушения или подозрения в нарушении Пользователем Пользовательского соглашения или Политики конфиденциальности, заблокировать учетную запись Пользователя на Сайте.

4.2 Администрация вправе вносить изменения в Пользовательское соглашение. Новая редакция Пользовательского соглашения вступает в силу с момента ее размещения на сайте, если иное не предусмотрено новой редакцией.

4.3 Администрация сайта вправе удалять информацию, размещенную на сайте, по запросу от правообладателей, их представителей или ответственных органов государственной власти РФ, в случае нарушения закона, Пользовательского соглашения или Политики конфиденциальности.

4.4 Администрация сайта обязуется соблюдать законодательство РФ, нормы международного права и политику конфиденциальности.

## Отказ от гарантий

5.1 Пользователь соглашается с тем, что доступ к услуге и ее использование осуществляется с определенным риском. Кроме случаев, установленных действующим законодательством, ИП Гаркавенко Сергей Владимирович не дает каких-либо гарантий, как явных, так и невыраженных, включая, помимо прочего, гарантии качества услуг, пригодности их для той или иной цели и ненарушения прав интеллектуальной собственности, достоверности, компетентности, полезности и своевременности предоставления. ИП Гаркавенко Сергей Владимирович не дает никаких гарантий и не делает заявлений относительно точности или полноты содержания Услуг.

## Ответственность

6.1 Администрация не несет ответственности за неправомерные действия Пользователей.

6.2 Администрация не несет ответственности за ущерб, убытки или расходы, в том числе, упущенную выгоду, возникшие в связи с использованием или невозможностью использования Cайта.

6.3 Администрация не несет ответственности за посещение и использование Пользователем внешних ресурсов, ссылки на которые могут содержаться на Cайте.

6.4 Администрация не несет ответственности за задержки или сбои в процессе совершения операции.

6.5 Администрация не несет ответственности за любые ошибки, которые могут содержаться в опубликованном, скаченном, переданном контенте, который доступен на Cайте, а также вред любого характера, причиненный вследствие таких ошибок и/или использования такого контента.

6.6 Администрация не несет ответственность за любой несанкционированный доступ или использование наших безопасных серверов и/или любой информации, хранящейся на них.

6.7 Администрация не несет ответственность за ошибки или неточности содержания услуг.

## Реквизиты компании

ИП Гаркавенко Сергей Владимирович

ИНН 366230073258

ОГРНИП 318366800120949 

*Дата последнего изменения: 08.01.2024*`


function UserAgrementRU() {

    return (
        <div style={{textAlign: "left", padding: "20px"}}>
            <ReactMarkdown children={markdown} />,
        </div>
    )
}

export default UserAgrementRU;

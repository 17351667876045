import {RoomToken, MePublished, ConnectedToRoom, RoomInfo, MyInfo, ShowVideoAudioSettings, VideoConstraints, AudioConstraints,
  AppliedVideoConstraints, AppliedAudioConstraints, StreamSourceChanged, MatesInfoVersions, LivekitComponentUpdateCounter, UseVideo, UseAudio, Preflight,
   RoomSeed, LivekitParticipantsMuteUpdateCounter, MyWebcamUpdateCounter, MyMediaStream, ActiveSpeakers, LivekitDevicesRequested, OthersAudioBoxes, 
   CanBecameCommonParticipant, ShowChangeRoomType} from './atoms'
import {ThemeAtom, UseSoundAtom, SoundLevelAtom} from "../main/atoms/Common"
import {
  useRecoilState,
} from 'recoil';
import { Button, Box, Container, Grid, IconButton, Stack, Typography, Modal,} from '@mui/material';
import ModalClose from "../utils/ModalStyle/ModalClose"
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import MusicNote from '@mui/icons-material/MusicNote';
import MusicOff from '@mui/icons-material/MusicOff';
import Settings from '@mui/icons-material/Settings';
import Chat from '@mui/icons-material/Chat';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import {FormattedMessage} from "react-intl";
import DeviceService from '../services/DeviceService';
import {
    connect,
    Room,
    RoomEvent,
    RemoteParticipant,
    RemoteTrackPublication,
    RemoteTrack,
    Participant,
    Track,
    VideoPresets,
    setLogLevel,
    LogLevel
  } from 'livekit-client';
import React, { useState, useEffect, useRef, } from 'react';
import {useParams} from "react-router-dom";
import OthersMediaBox from "./CallBoxes/MediaBoxes/OtherMediaBox";
import {VIDEO_BOX_WIDTH, VIDEO_BOX_HEIGHT, CircleType, GetPositionStyle} from "./CallBoxes/PositionStyles";
import Webcam from "react-webcam";
import WSController from './WSController';
import VideoAudioSettings from './VideoAudioSettings/VideoAudioSettings';
import {sendMsg} from "./WSController"
import TokenService from "../services/TokenService";
import LayoutsResolver from './LayoutsResolver';
import {MatesVersionsStep} from "../constants";
import BanMate from "./CallBoxes/MediaBoxes/BanMate";
import { ListRoomsInterval } from '../main/atoms/Rooms';
import { ListLobbiesInterval } from '../main/atoms/GamesAtoms';
import { CheckCurrentInterval, CurrentRoom } from '../main/atoms/Rooms';
import RoomService from '../services/rooms/RoomService';
import {useNavigate,} from "react-router-dom"
import { SuccessMessage } from '../constants';
import { RoulettesWS, OnRoulettePage } from '../main/atoms/RoulettesAtoms';
import ModalStyle, { ModalStyleXL } from '../utils/ModalStyle/ModalStyle';
import MyMediaBox from './CallBoxes/MediaBoxes/MyMediaBox';
import {HackwordWS} from "../main/games/hackword/InHackwordGame/atoms/Connection"
import {IamaliveWS} from "../main/games/iamalive/InIamaliveGame/atoms/Connection"
import {GeneralizeWS} from "../main/games/generalize/InGeneralizeGame/atoms/Connection"
import {makeString} from '../utils/common';
import FlatChat from '../main/common/FlatChat/FlatChat'
import {NewMessagesCount} from "../main/common/FlatChat/atoms/Chat"
import SoundVolumeSlider from "./ControlPanelStaff/SoundVolumeSlider"
import Navbar from "../main/Navbar/Navbar"
import AuthService from '../services/AuthService';
import AudioProcessor from "./AudioProcessor/AudioProcessor"
import {isFirefox} from "../utils/IsBrowser"
import WebcamProcessor from "./WebcamProcessor/WebcamProcessor"
import OthersAudioMediaBox from './CallBoxes/MediaBoxes/OtherAudioMediaBox';
import ChooseTypeModal from '../main/common/ChooseTypeModal/ChooseTypeModal';

  export let livekitRoomGlobal = null;
  export let myGlobalStream = null;
  let myAudioTrack = null
  let myVideoTrack = null

  let requestedStream = false;
  let isNotPublishedAfterConnect = false;
  let mediaElementStore = null

  function publishTracks(stream, room, useVideo, useAudio) {
    if (!stream) return;

    stream.getTracks().forEach(track => {
      try{
        if (track.kind == "video") {
          if (livekitRoomGlobal && room.localParticipant.videoTracks.length > 0) {
            room.localParticipant.unpublishTrack(livekitRoomGlobal.localParticipant.videoTracks.get(Array.from(livekitRoomGlobal.localParticipant.videoTracks.keys())[0]).track).then(()=>{
              room.localParticipant.publishTrack(track).then((t)=>{    
                myVideoTrack = t.track
                if (!useVideo) {
                  myVideoTrack.mute()
                }
              }) 
            })
          } else {
            room.localParticipant.publishTrack(track).then((t)=>{    
              myVideoTrack = t.track
              if (!useVideo) {
                myVideoTrack.mute()
              }
            }) 
          }

        } else if (track.kind == "audio") {
          if (livekitRoomGlobal && room.localParticipant.audioTracks.length > 0) {
            room.localParticipant.unpublishTrack(livekitRoomGlobal.localParticipant.audioTracks.get(Array.from(livekitRoomGlobal.localParticipant.audioTracks.keys())[0]).track).then(()=>{
              room.localParticipant.publishTrack(track).then((t)=>{    
                myVideoTrack = t.track
                if (!useVideo) {
                  myVideoTrack.mute()
                }
              })              
            })
          } else {
            room.localParticipant.publishTrack(track).then((t)=>{
              myAudioTrack = t.track
              if (!useAudio) {
                myAudioTrack.mute()
              }
            })
          }   
        }
      } catch(e) {
        console.log("Error set TRACK", e);
      }
    });
  }

  //create your forceUpdate hook
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return [value, () => setValue(value => value + 1)]; // update state to force render
    // An function that increment 👆🏻 the previous state like here 
    // is better than directly setting `value + 1`
}

// props.max_participants  (10)
// props.livekitURL  (localhost)
// props.apiURL  (localhost)
function LivekitComponent(props) {

  const navigate = useNavigate();

  let { room_key } = useParams();

    const livekitURL = props.livekitURL;
    let webcamRef = useRef(null);
    let camera = null;
    const [myAtom, setMyAtom] = useState(0);
    const [showRoomChat, setShowRoomChat] = useState(0);
    const [myMediaStream, setMyMediaStream] = useRecoilState(MyMediaStream);
    const [activeSpeakers, setActiveSpeakers] = useRecoilState(ActiveSpeakers);
    const [localParticipant, setLocalParticipant] = useState(null);


    setLogLevel(LogLevel.debug);

    const [listRoomsInterval, setListRoomsInterval] = useRecoilState(ListRoomsInterval)
    const [listLobbiesInterval, setListLobbiesInterval] = useRecoilState(ListLobbiesInterval)
    const [checkCurrentInterval, setCheckCurrentInterval] = useRecoilState(CheckCurrentInterval)
  
    useEffect(()=>{
      if (listRoomsInterval.interval != 0) {
        clearInterval(listRoomsInterval.interval)
      }
      if (listLobbiesInterval.interval != 0) {
            clearInterval(listLobbiesInterval.interval)
      }
      if (checkCurrentInterval.interval != 0) {
        clearInterval(checkCurrentInterval.interval)
      }
    }, [])

    const [useVideo, setUseVideo] = useRecoilState(UseVideo);
    const [useAudio, setUseAudio] = useRecoilState(UseAudio);
    const [useSoundAtom, setUseSoundAtom] = useRecoilState(UseSoundAtom);
    const [livekitParticipantsMuteUpdateCounter, setLivekitParticipantsMuteUpdateCounter] = useRecoilState(LivekitParticipantsMuteUpdateCounter);
    const [livekitDevicesRequested, setLivekitDevicesRequested] = useRecoilState(LivekitDevicesRequested)

    const [hackwordWS, setHackwordWS]  = useRecoilState(HackwordWS);
    const [iamaliveWS, setIamaliveWS]  = useRecoilState(IamaliveWS);
	  const [generalizeWS, setGeneralizeWS]  = useRecoilState(GeneralizeWS);
    const [roomSeed, setRoomSeed]  = useRecoilState(RoomSeed);
    const [newMessagesCount, setNewMessagesCount] = useRecoilState(NewMessagesCount)

    if (roomSeed == "") {
      setRoomSeed(makeString(5))
    }
    
    let matesBoxesMap = new Map();
    let matesAudioBoxesMap = new Map();

    const [roomToken, setRoomToken] = useRecoilState(RoomToken);
    const [themeAtom, setThemeAtom] = useRecoilState(ThemeAtom);
    const [mePublished, setMePublished] = useRecoilState(MePublished); 
    const [connectedToRoom, setConnectedToRoom] = useRecoilState(ConnectedToRoom); 
    const [roomInfo, setRoomInfo] = useRecoilState(RoomInfo);
    const [myInfo, setMyInfo] = useRecoilState(MyInfo);
    const [showVideoAudioSettings, setShowVideoAudioSettings] = useRecoilState(ShowVideoAudioSettings);
    const [appliedVideoConstraints, setAppliedVideoConstraints] = useRecoilState(AppliedVideoConstraints);
    const [appliedAudioConstraints, setAppliedAudeoConstraints] = useRecoilState(AppliedAudioConstraints);
    const [videoConstraints, setVideoConstraints] = useRecoilState(VideoConstraints);
    const [audioConstraints, setAudeoConstraints] = useRecoilState(AudioConstraints);    
    const [streamSourceChanged, setStreamSourceChanged] = useRecoilState(StreamSourceChanged);
    const [matesInfoVersions, setMatesInfoVersions] = useRecoilState(MatesInfoVersions);
    const [livekitComponentUpdateCounter, setLivekitComponentUpdateCounter] = useRecoilState(LivekitComponentUpdateCounter)
    const [roulettesWS, setRoulettesWS] = useRecoilState(RoulettesWS);
    const [onRoulettePage, setOnRoulettePage] = useRecoilState(OnRoulettePage);
    const [soundLevelAtom, setSoundLevelAtom] = useRecoilState(SoundLevelAtom);
    const [myWebcamUpdateCounter, setMyWebcamUpdateCounter] = useRecoilState(MyWebcamUpdateCounter);
    const [currentRoom, setCurrentRoom] = useRecoilState(CurrentRoom)
    const [canBecameCommonParticipant, setCanBecameCommonParticipant] = useRecoilState(CanBecameCommonParticipant)
    const [showChangeRoomType, setShowChangeRoomType] = useRecoilState(ShowChangeRoomType)

    let mobile = false
    if (window.matchMedia("(max-width: 899px)").matches) {
        mobile = true
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])

    const [preflight, setPreflight] = useRecoilState(Preflight);
    useEffect(()=>{
      console.log("ASASA LIVEKIT SPEC FOR PREFLIGHT")
      if (myInfo.role == "spectator") { //  || mobile
        setPreflight(false)
      }
    }, [myInfo])
    useEffect(()=>{
      let roomSettings = localStorage.getItem('RoomSettings');
      if (roomSettings !== null) {
        roomSettings = JSON.parse(roomSettings)
        if (roomSettings.preflight !== undefined){
          setPreflight(roomSettings.preflight)
        }
        if (roomSettings.useVideo !== undefined){
          setUseVideo(roomSettings.useVideo)
        }
        if (roomSettings.useAudio !== undefined){
          setUseAudio(roomSettings.useAudio)
        }
        if (roomSettings.useSound !== undefined){
          setUseSoundAtom(roomSettings.useSound)
        }
        if (roomSettings.soundLevel !== undefined){
          setSoundLevelAtom(roomSettings.soundLevel)
        }
      }
    }, [])
    useEffect(()=>{
      console.log("ASASA LIVEKIT ROOMSETTINGS")
      if (roomToken.name == "") {
        return
      }
      setRoomSettings(preflight, useVideo, useAudio, roomToken.name, useSoundAtom, soundLevelAtom)
    }, [preflight, useVideo, useAudio, roomToken, useSoundAtom, soundLevelAtom]) 


    useEffect(()=> {
      console.log("ASASA LIVEKIT livekitComponentUpdateCounter")
      if (livekitComponentUpdateCounter != myAtom) {
        if (livekitRoomGlobal != null) {
          livekitRoomGlobal.disconnect().then(()=>{
            livekitRoomGlobal = null
            requestedStream = false;
            setConnectedToRoom(false)
            setMePublished({isPublished: false});
            setRoomToken({name: "", token: ""})
            isNotPublishedAfterConnect = false
            myGlobalStream = null
            setLivekitDevicesRequested(false)
            setMyMediaStream(null)
            console.log("ASASAS RECONCILE LIVEKIT CONN")
            sendMsg("InitRequest", {'SessionID': TokenService.getLocalAccessToken(), "RoomKey": room_key})
            setMyAtom(livekitComponentUpdateCounter)
          })         
        } else {
          setMyAtom(livekitComponentUpdateCounter)
        }
      }
    }, [livekitComponentUpdateCounter] );


    let { sessionid } = useParams();
    const [value, forceUpdate] = useForceUpdate();
    useEffect(()=> {
        let audioConstraints = localStorage.getItem('AudioConstraints');
        if (audioConstraints !== null) {
          setAppliedAudeoConstraints({constraints: JSON.parse(audioConstraints)})
          audioConstraints = {constraints: JSON.parse(audioConstraints)}
        }
        let videoConstraints = localStorage.getItem('VideoConstraints');
        if (videoConstraints !== null) {
          setAppliedVideoConstraints({constraints: JSON.parse(videoConstraints)})
          videoConstraints = {constraints: JSON.parse(videoConstraints)}
        }
        if (hasGetUserMedia()) {
        } else {
            alert("getUserMedia() is not supported by your browser");
        }
        initMediaDevices();
        setRoomEvents(forceUpdate, setLivekitParticipantsMuteUpdateCounter, setLivekitComponentUpdateCounter, setMyMediaStream, videoConstraints, audioConstraints, useVideo, useAudio, setActiveSpeakers,)
    }, [myAtom, useVideo, useAudio,]);
    let othersBoxes = [];
    let myPosition = 0;
    let myId = 0;
    let isMeAdmin = false

    // ****************************************************************************************************************************************
    // ***** CONNECTION TO ROOM ***************************************************************************************************************
    // ****************************************************************************************************************************************
    if (!connectedToRoom && !preflight) {
      console.log("!connectedToRoom token = "+roomToken.token);
      if (roomToken.token !== "" && livekitRoomGlobal != null) {
        // CONNECT HERE WILL BE DONE
        console.log("LIVEKIT TOKEN =",roomToken.token)
        livekitRoomGlobal.connect(livekitURL, roomToken.token).then(()=>{
          console.log("ASASA LIVEKIT CONNECTED =",roomToken.token)
          setConnectedToRoom(true)
          // Connected
          if (requestedStream && !mePublished.isPublished && myGlobalStream != null) {
             // Rerender Publish AFTER CONNECT
             publishTracks(myGlobalStream, livekitRoomGlobal, useVideo, useAudio);
             setMePublished({isPublished: true});
           } else {
             isNotPublishedAfterConnect = true;
           }
           forceUpdate();
        }).catch((err) => {
          console.log("ASASA LIVEKIT ERROR =",roomToken.token)
          console.error(err);
          setConnectedToRoom(false);
          forceUpdate();
        }); // Handle error during connect??
      }
    }

    // ****************************************************************************************************************************************
    // ***** GETIING/SETTING ROOM TRACKS FOR OTHERS MEDIA BOXES *******************************************************************************
    // ****************************************************************************************************************************************
    if (connectedToRoom && livekitRoomGlobal != null) {
      //console.log("Rerender Room is not null");
      if (isNotPublishedAfterConnect && requestedStream && !mePublished.isPublished && myGlobalStream != null) {
        setMePublished({isPublished: true});
        publishTracks(myGlobalStream, livekitRoomGlobal, useVideo, useAudio);
        console.log("ASASA LIVEKIT PUBLISH")
      }
      if (roomInfo.roomMates != null) {
          for (let identity in roomInfo.roomMates) {
            if (identity == myInfo.name) {
              isMeAdmin = roomInfo.roomMates[identity].IsAdmin;
              break
            }
          }

          let i = 0;
          for (let identity in roomInfo.roomMates) {
            i++;
            if (identity == myInfo.name) {
              myPosition = roomInfo.roomMates[identity].Position;
              myId = roomInfo.roomMates[identity].UserID;
              continue
            }
            let haveParticipant = false
            if (livekitRoomGlobal.participants) {
              for (const participant of livekitRoomGlobal.participants.values()) {
                console.log("participant "+identity, participant);
                if (participant.identity === identity) {
                  let pos = roomInfo.roomMates[identity]
                  // console.log("Passsed identity: "+participant.identity+" Position = "+pos, participant);

                  let videoTrack;
                  let audioTrack;
                  for (const trackPublication of participant.getTracks()) {
                    if (trackPublication.kind == Track.Kind.Video && trackPublication.track) { //  && trackPublication.track.streamState === "active"
                      videoTrack = trackPublication.track;
                    }
                    if (trackPublication.kind == Track.Kind.Audio && trackPublication.track) {
                      audioTrack = trackPublication.track;
                    }
                  }
                  if (videoTrack || audioTrack) {
                    haveParticipant = true
                    let pos = roomInfo.roomMates[identity].Position
                    if (props.roulette) {
                      pos = 0
                    }
                    console.log("ASASAS OTHERS KEY WITH TRACKS", pos, identity, videoTrack, audioTrack, )                   
                    matesBoxesMap.set(pos, <OthersMediaBox key={MatesVersionsStep * roomInfo.roomMates[identity].Position + livekitComponentUpdateCounter} isMeAdmin={isMeAdmin} isAdmin={roomInfo.roomMates[identity].IsAdmin} userid={roomInfo.roomMates[identity].UserID} identity={participant.identity} videoTrack={videoTrack} audioTrack={audioTrack} position={roomInfo.roomMates[identity].Position} roulette={props.roulette} ></OthersMediaBox>) 
                    matesAudioBoxesMap.set(pos, <OthersAudioMediaBox key={MatesVersionsStep * roomInfo.roomMates[identity].Position + livekitComponentUpdateCounter} audioTrack={audioTrack} position={roomInfo.roomMates[identity].Position}></OthersAudioMediaBox>)
                  }
                }
              }
            }
            if (!haveParticipant) {
              let pos = roomInfo.roomMates[identity].Position
              if (props.roulette) {
                pos = 0
              }   
              console.log("ASASAS OTHERS KEY WITHOUT TRACKS", pos, identity)                              
              matesBoxesMap.set(pos, <OthersMediaBox key={MatesVersionsStep * roomInfo.roomMates[identity].Position + livekitComponentUpdateCounter} isMeAdmin={isMeAdmin} isAdmin={roomInfo.roomMates[identity].IsAdmin} userid={roomInfo.roomMates[identity].UserID} identity={identity} videoTrack={null} audioTrack={null} position={roomInfo.roomMates[identity].Position} roulette={props.roulette}></OthersMediaBox>)
              matesAudioBoxesMap.set(pos, <OthersAudioMediaBox key={MatesVersionsStep * roomInfo.roomMates[identity].Position + livekitComponentUpdateCounter} audioTrack={null} position={roomInfo.roomMates[identity].Position}></OthersAudioMediaBox>)
            }
          }
      }
    }

    // ****************************************************************************************************************************************
    // ***** SETTING MY MEDIA BOXES ***********************************************************************************************************
    // ****************************************************************************************************************************************

    // console.log("VERSION: "+value+" MyPosition = "+myPosition);
    let identityPositionStyle = {
      position: "absolute",
      bottom: "1rem",
      left: "1rem",
      color: "white",
      padding: "0.2rem", 
  }

  if (window.matchMedia("(max-width: 899px)").matches) {
    identityPositionStyle.display = 'none'
  }
    if (props.roulette) {
      myPosition = 1
    }
    if (myInfo.role != "spectator") {
      let webcam = null
      if (appliedVideoConstraints.constraints) {
        webcam = <WebcamProcessor
            key={myWebcamUpdateCounter}
            room={livekitRoomGlobal}
            videoConstraints={appliedVideoConstraints.constraints}
            audioConstraints={appliedAudioConstraints.constraints}
        />
        matesBoxesMap.set(myPosition, <MyMediaBox isAdmin={isMeAdmin} webcam={webcam} myPosition={myPosition} myId={myId}></MyMediaBox>);
      } else {
          webcam =  <AudioProcessor 
                          key={livekitComponentUpdateCounter}
                          room={livekitRoomGlobal}
                          audioConstraints={appliedAudioConstraints.constraints}
                          connectedToRoom={connectedToRoom}
                          />
          matesBoxesMap.set(myPosition, <MyMediaBox isAdmin={isMeAdmin} webcam={webcam} myPosition={myPosition} myId={myId}></MyMediaBox>);
      }
    }   

    // ****************************************************************************************************************************************
    // ***** BOTTOM FOOTER MENU ***************************************************************************************************************
    // ****************************************************************************************************************************************

    let theme = DeviceService.getDeviceColorTheme()
    let menuFooterStyle = {
      padding: "15px 30px 10px 30px",
      position: "fixed",
      bottom: "0px",
      width: "100%",
      backgroundColor: theme == "light" ? "white" : "#121212",
      zIndex: 9999,
    }
    let exitBtnStyle = {
      align: "right",
      right: "30px",
      position: "fixed",
    }
    let exit = ()=>{
      if (roulettesWS.ws != null) {
        roulettesWS.ws.close()
        setOnRoulettePage(false)
        setRoulettesWS({ws: null})
      }
      RoomService.leaveRoom().then((response)=>{
        if (response.data.Success == SuccessMessage) {
          let type = roomInfo.type
          setRoomSeed("")
          setRoomInfo({})
          livekitRoomGlobal.disconnect()
          livekitRoomGlobal = null
          setLivekitDevicesRequested(false)
          setMyMediaStream(null)
          setConnectedToRoom(false)
          setMePublished({isPublished: false});
          setRoomToken({name: "", token: ""})
          setCurrentRoom({HaveCurrent: false})
          isNotPublishedAfterConnect = false
          if (myGlobalStream != null) {
            myGlobalStream.getTracks().forEach(function(track) {
              track.stop();
            });
          }
          myGlobalStream = null;
          myAudioTrack = null
          myVideoTrack = null
          requestedStream = false;
          isNotPublishedAfterConnect = false;
          matesBoxesMap.clear()
          matesAudioBoxesMap.clear() 
          console.log("ASASAS--------------------------------------------------------------------------------------------------")
          console.log("ASASAS LEAVE COMPONENT", type)
          if (type == "talkroom" || props.roulette) {
            navigate("/rooms")
          } else {
            navigate("/games")
          }
        }
      })
    }
    let muteSelf = ()=>{
      if (useAudio) {  
        setUseAudio(false)
        if (livekitRoomGlobal && livekitRoomGlobal.localParticipant.audioTracks.size > 0) {
          for (let track of livekitRoomGlobal.localParticipant.getTracks()) {
            if (track.kind == Track.Kind.Audio) {
              track.track.mute()
            }
          }
        }
      } else {
        if (livekitRoomGlobal && livekitRoomGlobal.localParticipant.audioTracks.size > 0) {
          for (let track of livekitRoomGlobal.localParticipant.getTracks()) {
            if (track.kind == Track.Kind.Audio) {
              track.track.unmute()
            }
          }
        }      
        setUseAudio(true)
      }
    }
    let stopVideo = ()=>{
      if (useVideo) {
        setUseVideo(false)
        if (livekitRoomGlobal && livekitRoomGlobal.localParticipant.audioTracks.size > 0) {
          for (let track of livekitRoomGlobal.localParticipant.getTracks()) {
            if (track.kind == Track.Kind.Video) {
              track.track.mute().then(()=>{
                setMyMediaStream(null)
              })
            }
          }
        }
      } else {
        if (livekitRoomGlobal && livekitRoomGlobal.localParticipant.audioTracks.size > 0) {
          for (let track of livekitRoomGlobal.localParticipant.getTracks()) {
            if (track.kind == Track.Kind.Video) {
              track.track.unmute().then(()=>{
                setMyMediaStream(track.track.mediaStream)
              })
            }
          }
        }  
        setUseVideo(true)
      }
    }
    let turnSound = ()=>{
      setUseSoundAtom(!useSoundAtom)
    }  
    let [showSoundLevelPanel, setShowSoundLevelPanel] = useState(false);
    const mouseOverSoundIconHandler = ()=>{
      setShowSoundLevelPanel(true)
    }
    const mouseLeaveSoundIconHandler = ()=>{
       setShowSoundLevelPanel(false)
    }
    const openSettings = ()=>{
      setShowVideoAudioSettings({show: true})
    }
    let handleSetVideoSettingsClose = ()=>{
      if (!preflight) {
        setPreflight(false)
        setShowVideoAudioSettings({show: false})
        if (!videoConstraints.constraints) {
          localStorage.setItem('VideoConstraints', JSON.stringify(videoConstraints.constraints))
          setAppliedVideoConstraints({constraints: videoConstraints.constraints})
          setLivekitComponentUpdateCounter(livekitComponentUpdateCounter + 1)
        }
      }
    }
    const openRoomChat = ()=>{
      setShowRoomChat(true)
      setNewMessagesCount(0)
    }
    const handleRoomChatClose = ()=>{
      setShowRoomChat(false)
      setNewMessagesCount(0)
    }
    const participate = ()=>{
      sendMsg("Participate", {'SessionID': TokenService.getLocalAccessToken(), "RoomKey": room_key})
      setCanBecameCommonParticipant({can: false})
    }

    if (mobile) {
      exitBtnStyle.marginRight = "-15px"
    }
    return (
        <Box sx={{
          bgcolor: 'background.default',
          color: 'text.primary',
        }}>
            {mediaElementStore}
          	<Navbar inTheRoom={true} audioBoxes={matesAudioBoxesMap}/>
            <WSController apiURL={props.apiURL} roulette={props.roulette} roomKey={room_key}></WSController>
            <LayoutsResolver key={roomSeed} roomSeed={roomSeed} roommates={matesBoxesMap} type={roomInfo.type} layout={roomInfo.layout} entityID={roomInfo.entityID} roomID={roomInfo.roomID} myname={myInfo.name} myposition={myPosition} myid={myId} roulette={props.roulette} scope={roomInfo.scope} myrole={myInfo.role}></LayoutsResolver>
            <Stack paddingY={2} spacing={2} direction="row" justifyContent="space-between" style={menuFooterStyle} sx={{
				bgcolor: 'background.default',
				color: 'text.primary',
			}}>
        {myInfo.role != "spectator" ? 
                      <Stack spacing={mobile ? 0: 2} direction="row" style={mobile ? {marginLeft: "-23px"} : {}}> 
                          <IconButton aria-label="Mic" onClick={muteSelf}>
                            {useAudio ? <MicIcon /> : <MicOffIcon />}
                          </IconButton>
                          <IconButton aria-label="Video" onClick={stopVideo}>
                            {useVideo ? <VideocamIcon /> : <VideocamOffIcon /> }
                          </IconButton>
                          <IconButton aria-label="Settings" onClick={openSettings}>
                            <Settings /> 
                          </IconButton>
                          <IconButton aria-label="Sound" onMouseEnter={mouseOverSoundIconHandler} onMouseLeave={mouseLeaveSoundIconHandler}>
                            {showSoundLevelPanel && <SoundVolumeSlider setShowPanel={setShowSoundLevelPanel}/>}
                            {useSoundAtom ? <MusicNote style={{zIndex: 999}} onClick={turnSound}/> : <MusicOff style={{zIndex: 999}} onClick={turnSound}/> }
                          </IconButton>
                          <IconButton aria-label="Chat" onClick={openRoomChat} style={{position: "relative"}}>
                            {newMessagesCount > 0 && <div style={{position: "absolute", top: "-1px", right: "-1px", width: "16px", height: "16px", backgroundColor: "#ba2929", color: "#fcfafa", borderRadius: "10px", fontSize: "small"}}>+</div>}                            
                            <Chat /> 
                          </IconButton>                          
                      </Stack>
        : <Stack spacing={0} direction="row">
              <IconButton aria-label="Sound" onMouseEnter={mouseOverSoundIconHandler} onMouseLeave={mouseLeaveSoundIconHandler} style={mobile ? {marginLeft: "-20px"} : {}}>
                  {showSoundLevelPanel && <SoundVolumeSlider setShowPanel={setShowSoundLevelPanel}/>}
                  {useSoundAtom ? <MusicNote style={{zIndex: 999}} onClick={turnSound}/> : <MusicOff style={{zIndex: 999}} onClick={turnSound}/> }
               </IconButton>
               {(!mobile || (mobile && !canBecameCommonParticipant.can)) && <Typography variant={mobile ? "h6" : "h4"} style={{marginRight: "15px"}}>
							  <FormattedMessage id="SpectatorMode" />
						  </Typography>}
              {canBecameCommonParticipant.can && <Button variant="contained" color="success" size="large" endIcon={<PersonAddIcon />} onClick={participate}>
                <FormattedMessage id="participate" />
              </Button>}
          </Stack>
      }
              <Button 
                variant="outlined"
                size="large"
                endIcon={<ExitToAppIcon />}
                style = {exitBtnStyle}
                onClick={exit}
              >
                <FormattedMessage id="exit" />
              </Button>
				    </Stack>
            <Box style={{height: "115px"}}></Box>

            <Modal
              open={showVideoAudioSettings.show || (preflight && roomToken.name != "")}
              onClose={handleSetVideoSettingsClose}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box sx={{ ...ModalStyleXL, width: "550px" }}>
                <ModalClose onClose={handleSetVideoSettingsClose}/>
                <VideoAudioSettings width={VIDEO_BOX_WIDTH} height={VIDEO_BOX_HEIGHT} livekitRoomGlobal={livekitRoomGlobal}></VideoAudioSettings>
              </Box>
            </Modal>   
            <Modal
              open={showRoomChat}
              onClose={handleRoomChatClose}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...ModalStyleXL, height: 400 }}>
                  <ModalClose onClose={handleRoomChatClose}/>
                  <FlatChat key={"room"+roomInfo.roomID} occasionID={roomInfo.roomID} occasionType="room" fullHeight={true}></FlatChat>
                </Box>
            </Modal>

            <Modal
              open={showChangeRoomType}
              onClose={()=>{setShowChangeRoomType(false)}}
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box sx={{ ...ModalStyleXL, width: 400}}>
                <ModalClose onClose={()=>{setShowChangeRoomType(false)}}/>
                <ChooseTypeModal />
              </Box>
            </Modal>                      
        </Box>
    );
}


export default LivekitComponent;

function hasGetUserMedia() {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
  }
  
function initMediaDevices() {
  if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {};
  }
  if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function(constraints) {
          var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

          if (!getUserMedia) {
              return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
          }
          return new Promise(function(resolve, reject) {
              getUserMedia.call(navigator, constraints, resolve, reject);
          });
      }
  }
}

function HTMLContainer(props) {
    let conRef = useRef(null);
    useEffect(()=> {
      if (conRef.current != null) {
        conRef.current.appendChild(props.child)
      }
    }, [conRef.current])
    return <div ref={conRef}></div>;
}

function createMediaElement(stream) {
  var mediaElement = document.createElement(stream.isAudio ? 'audio' : 'video');
  mediaElement.id = stream.streamid;
  mediaElement.style.position = "fixed"
  mediaElement.style.left = "50%"
  mediaElement.style.top = "50%"
  mediaElement.style.width = "100px"
  mediaElement.style.zIndex = 999999

  console.log("ASASAS createMediaElement isFirefox = ", isFirefox)
  // "mozSrcObject" is always preferred over "src"!!
  mediaElement[isFirefox ? 'mozSrcObject' : 'srcObject'] = stream;

  mediaElement.controls = true;
  mediaElement.autoplay = true;
  mediaElement.muted = true;

  // http://goo.gl/WZ5nFl
  // Firefox don't yet support onended for any stream (remote/local)
  isFirefox && mediaElement.addEventListener('ended', function() {
      stream.onended();
  }, false);

  mediaElement.play();

  return <HTMLContainer child={ mediaElement }/>  
}

function setRoomEvents(forceUpdate, setLivekitParticipantsMuteUpdateCounter, setLivekitComponentUpdateCounter, setMyMediaStream, videoConstraints, audioConstraints, useVideo, useAudio, setActiveSpeakers, ) {
  function handleTrackSubscribed(
      track, //: RemoteTrack,
      publication, //: RemoteTrackPublication,
      participant, //: RemoteParticipant,
    ) {
      if (track.kind === Track.Kind.Video || track.kind === Track.Kind.Audio) {
        // attach it to a new HTMLVideoElement or HTMLAudioElement
        console.log("ASASAS LIVEKIT Subscribed ", track, participant);
        forceUpdate();
      }
    }

    function handleTrackMuted(
      participant, //: RemoteParticipant,
      publication, //: RemoteTrackPublication,
    ) {
      console.log("ASASAS LIVEKIT handleTrackMuted")
      forceUpdate();
      setLivekitParticipantsMuteUpdateCounter((result)=>{return result + 1})
    }

    function handleTrackUnmuted(
      participant, //: RemoteParticipant,
      publication, //: RemoteTrackPublication,
    ) {
      console.log("ASASAS LIVEKIT Unmuted")
      forceUpdate();
      setLivekitParticipantsMuteUpdateCounter((result)=>{return result + 1})
    }

    function handleMediaDevicesError(mediaDevicesError) {
      console.log("ASASAS LIVEKIT testMob handleMediaDevicesError", mediaDevicesError)
    }
    
    function handleTrackUnsubscribed(
      track, //: RemoteTrack,
      publication, //: RemoteTrackPublication,
      participant, //: RemoteParticipant,
    ) {
      // remove tracks from all attached elements
      if (track.kind === Track.Kind.Video || track.kind === Track.Kind.Audio) {
        console.log("ASASAS LIVEKIT Unsubscribed ", track, participant);
        // setLivekitComponentUpdateCounter((result)=>{return result + 1})
        //track.detach();
        forceUpdate();
      }
    }
    
    function handleLocalTrackUnpublished(track, //: LocalTrackPublication,
       participant) //: LocalParticipant) 
    {
      // when local tracks are ended, update UI to remove them from rendering
      console.log("ASASAS LIVEKIT Unpublished ", participant);
      //track.detach();
      forceUpdate();
    }
    
    function handleActiveSpeakerChange(speakers) { // : Participant[]
      // show UI indicators when participant is speaking
      console.log('ASASAS LIVEKIT handleActiveSpeakerChange');
      let activeSpeakers = []
      for (let speaker of speakers) {
        activeSpeakers.push(speaker.identity)
      }
      setActiveSpeakers(activeSpeakers)
    }
    
    function handleDisconnect() {
      console.log('ASASAS LIVEKIT disconnected from room');
    }

    function handleTrackPublished() {
      forceUpdate();
      console.log('ASASAS LIVEKIT handleTrackPublished');
    }

    function handleLocalTrackPublication(trackPublickation, localParticipant){
      console.log("ASASAS LIVEKIT test AudioProcessor PUBLISHED", trackPublickation)
       // const keys = Array.from(localParticipant.videoTracks.keys());
        for (let track of localParticipant.getTracks()) {
          if (track.kind == Track.Kind.Video) {
            if (!useVideo) {
              track.track.mute()
            }
            setMyMediaStream(track.track.mediaStream)
          }
          if (track.kind == Track.Kind.Audio) {
            console.log("ASASAS LIVEKIT test AudioProcessor PUBLISHED AUDIO")
            if (!useAudio) {
              track.track.mute()
            }
          }
        }
    }

    if (livekitRoomGlobal == null) {
        let videoExactDeviceId = ""
        if (videoConstraints && videoConstraints !== null && videoConstraints.constraints && videoConstraints.constraints.deviceId) {
          videoExactDeviceId = videoConstraints.constraints.deviceId.exact
        }
        let audioExactDeviceId = ""
        if (audioConstraints && audioConstraints !== null && audioConstraints.constraints && audioConstraints.constraints.deviceId) {
          audioExactDeviceId = audioConstraints.constraints.deviceId.exact
        }
        let createRoom = new Room({
          // automatically manage subscribed video quality
          adaptiveStream: true,
        
          // optimize publishing bandwidth and CPU for published tracks
          dynacast: true,
        
          // default capture settings
          videoCaptureDefaults: {
            deviceId: videoExactDeviceId,
            facingMode: 'user',
            // resolution: VideoPresets.h720.resolution,
          },
          audioCaptureDefaults: {
            autoGainControl: true,
            deviceId: audioExactDeviceId,
            echoCancellation: true,
            noiseSuppression: true,
          },
          // reconnectPolicy: new DefaultReconnectPolicy(),
        });
        // set up event listeners
        createRoom
        .on(RoomEvent.Reconnecting, ()=>{console.log("RECONNECTING");})
        .on(RoomEvent.Reconnected, ()=>{console.log("RECONNECTED");})
        .on(RoomEvent.MediaDevicesChanged, ()=>{console.log("MediaDevicesChanged");})
        .on(RoomEvent.ConnectionStateChanged, (state)=>{console.log("ConnectionStateChanged", state);})
        .on(RoomEvent.TrackSubscribed, handleTrackSubscribed)
        .on(RoomEvent.TrackUnsubscribed, handleTrackUnsubscribed)
        .on(RoomEvent.ActiveSpeakersChanged, handleActiveSpeakerChange)
        .on(RoomEvent.Disconnected, handleDisconnect)
        .on(RoomEvent.LocalTrackUnpublished, handleLocalTrackUnpublished)
        .on(RoomEvent.TrackMuted, handleTrackMuted)
        .on(RoomEvent.TrackUnmuted, handleTrackUnmuted)
        .on(RoomEvent.MediaDevicesError, handleMediaDevicesError)
        .on(RoomEvent.LocalTrackPublished , handleLocalTrackPublication)
        .on(RoomEvent.TrackPublished , handleTrackPublished);
        
        livekitRoomGlobal = createRoom;
    } else {
      livekitRoomGlobal
      .on(RoomEvent.Reconnecting, ()=>{console.log("RECONNECTING");})
      .on(RoomEvent.Reconnected, ()=>{console.log("RECONNECTED");})
      .on(RoomEvent.MediaDevicesChanged, ()=>{console.log("MediaDevicesChanged");})
      .on(RoomEvent.ConnectionStateChanged, (state)=>{console.log("ConnectionStateChanged", state);})
      .on(RoomEvent.TrackSubscribed, handleTrackSubscribed)
      .on(RoomEvent.TrackUnsubscribed, handleTrackUnsubscribed)
      .on(RoomEvent.ActiveSpeakersChanged, handleActiveSpeakerChange)
      .on(RoomEvent.Disconnected, handleDisconnect)
      .on(RoomEvent.LocalTrackUnpublished, handleLocalTrackUnpublished)
      .on(RoomEvent.TrackMuted, handleTrackMuted)
      .on(RoomEvent.LocalTrackPublished , handleLocalTrackPublication)
      .on(RoomEvent.TrackUnmuted, handleTrackUnmuted)
      .on(RoomEvent.TrackPublished , handleTrackPublished);
    }

  

  forceUpdate();
}

export const setRoomSettings = (preflight, useVideo, useAudio, name, useSoundAtom, soundLevelAtom)=>{
  localStorage.setItem("RoomSettings", JSON.stringify({preflight: preflight, useVideo: useVideo, useAudio: useAudio, name: name, useSound: useSoundAtom, soundLevel: soundLevelAtom}));
}
import {
    atom,
} from 'recoil';
import {FormattedMessage} from 'react-intl'


export const UserInfoAtom = atom({
    key: 'UserInfoAtom',
    default: {
        UserInfo: null,
    },
})

export const UserPersonalAtom = atom({
    key: 'UserPersonalAtom',
    default: {
        UserPersonal: null,
    },
})

export const UserAvatarSuffix = atom({
    key: 'UserAvatarSuffix',
    default: "fsdhfkss"
})

export const ShowState = atom({
    key: 'ShowState',
    default: {},
})

export const ShowRegisterGiftSubscription = atom({
    key: 'ShowRegisterGiftSubscription',
    default: {Open: false},
})

export const UserBorders = atom({
    key: 'UserBorders',
    default: {
        Borders: {
            Name: "none",
            Xpercents: 0,
            Ypercents: 0,
        },
    },
})

export const OpenedPersonalChats = atom({
    key: 'OpenedPersonalChats',
    default: [
        // {
        //     occasionID: 1,
        //     occasionType: "page",
        //     title: <FormattedMessage id="common_chat"/>,
        //     opened: !window.matchMedia("(max-width: 899px)").matches,
        //     icon: "",
        // }
    ],
})
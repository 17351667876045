import { Avatar, Tooltip, Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography, Snackbar, Alert } from '@mui/material';
import React, {useState} from 'react';
import { FormattedMessage } from 'react-intl';
import CommunitiesService from '../../../services/CommunitiesService';
import {SuccessMessage, MaxAvatarSize} from "../../../constants";
import {useRecoilState,} from 'recoil';
import { ShowFileTooBig } from '../../atoms/Common';
import {domainNameAPI, protocolAPI, apiPort} from "../../../environment"
import { makeString } from '../../../utils/common';
import {useNavigate} from "react-router-dom"
import {OpenCreateCommunity} from "../atoms/Common";
import {MaxCommunityNameLength, MaxCommunityDescriptionLength} from "../../../constants"
import { styled } from '@mui/material/styles';
import HelpIcon from '@mui/icons-material/Help';
import { tooltipClasses } from '@mui/material/Tooltip';

const CustomWidthTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
  ))({
	[`& .${tooltipClasses.tooltip}`]: {
	  maxWidth: 500,
	},
  });

const CreateCommunityForm = () => {
	const [name, setName] = useState('');
	const [communityType, setCommunityType] = useState('');
	const [description, setDescription] = useState('');
	const [icon, setIcon] = useState('');
	const [avaSrc, setAvaSrc] = useState('');
	const [acceptEveryone, setAcceptEveryone] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);	
	const [showTooBig, setShowTooBig] = useRecoilState(ShowFileTooBig);

	const [nameError, setNameError] = useState(null)
	const [descriptionError, setDescriptionError] = useState(null)
	const [cantCreateMore, setCantCreateMore] = useState({show: false})

	const [requestedClan, setRequestedClan] = useState({show: false})
	const [joinedClan, setJoinedClan] = useState({show: false})


	const [openCreateCommunity, setOpenCreateCommunity] = useRecoilState(OpenCreateCommunity);
	const navigate = useNavigate();

	const handleChangeType = (event) => {
		setCommunityType(event.target.value);
	};
	const changeName = (e)=>{
		if (e.target.value.length > MaxCommunityNameLength) {
			setNameError(<span><FormattedMessage id="cantbelonger"/> {MaxCommunityNameLength}</span>)
			return 
		}
		setName(e.target.value)
		setNameError(null)
	}
	const changeDescription = (e)=>{
		if (e.target.value.length > MaxCommunityDescriptionLength) {
			setDescriptionError(<span><FormattedMessage id="cantbelonger"/> {MaxCommunityDescriptionLength}</span>)
			return 
		}
		setDescription(e.target.value)
		setDescriptionError(null)
	}
	const switchAcceptEveryone = (e)=>{
		setAcceptEveryone(e.target.checked)
	}

	const createCommunity = ()=>{
		// const createCommunity =  async function(name, communityType, icon, description, telegram, acceptEveryone) 
		CommunitiesService.createCommunity(name, communityType, icon, description, "", acceptEveryone).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				setOpenCreateCommunity(false)
			}
		}).catch((err)=>{
			if (err.response.data.ErrorCode == 3) {
				setCantCreateMore({show: true})
			}
			if (err.response.data.ErrorCode == 4) {
				setRequestedClan({show: true})
			}
			if (err.response.data.ErrorCode == 5) {
				setJoinedClan({show: true})
			}			
		})
	}

    const handleFileSelect = (event) => {
        console.log(event.target.files[0])
        if (event.target.files[0].size > MaxAvatarSize){
            setShowTooBig({show: true})
        } else {
            setSelectedFile(event.target.files[0])
			const formData = new FormData();
			formData.append("avatarFile", event.target.files[0]);
			CommunitiesService.uploadAvatar(formData).then((response)=>{
				if (response.data.Success == SuccessMessage) {
					setIcon(response.data.Icon)
					let apiUrl = protocolAPI+domainNameAPI+apiPort
					setAvaSrc(apiUrl+"/mkstatic/shared_communities/icons/"+response.data.Icon+"?dop"+makeString(5))
				}
			})
         }
    }
	return (
		<Stack
			direction="column"
			spacing={3}
		>
			<Stack
				direction={{md: 'row'}}
				spacing={2}
			>
				<Typography variant='h4'>
					<FormattedMessage id="creating_community" />
				</Typography>

				<CustomWidthTooltip 
						title={
							<>
								<h1><FormattedMessage id="communities_views" /></h1>
								<h2><FormattedMessage id="communities_views_descriptions" /></h2>
							</>
						} 
					>
						<HelpIcon />
				</CustomWidthTooltip>				
			</Stack>

			<Stack
				direction={{xs: 'column', md: 'row'}}
				spacing={2}
			>
				<Box sx={{ minWidth: 160 }}>
					<FormControl 
						fullWidth
						size='small'
					>
						<InputLabel id="communityType-label">
							<FormattedMessage id="type" />
						</InputLabel>

						<Select
							labelId="communityType-label"
							id="communityType"
							value={communityType}
							label="Клуб"
							onChange={handleChangeType}
						>
							<MenuItem value="club"><FormattedMessage id="club"/></MenuItem>
							<MenuItem value="clan"><FormattedMessage id="clan"/></MenuItem>
						</Select>
					</FormControl>
				</Box>

				<TextField 
					fullWidth 
					id="name" 
					label={<FormattedMessage id="title" />} 
					variant="outlined" 
					value={name}
					onChange={changeName}
					size='small'
					helperText={nameError}
					error= {nameError != null}					
				/>
			</Stack>

		{false && <Stack
						direction='row'
						alignItems="center"
						spacing={2}
					>
						<Typography variant='body1'>
							<FormattedMessage id="accept_everyone" />					
						</Typography>
						
						<Switch 
							checked={acceptEveryone}
							onChange={switchAcceptEveryone}
						/>
					</Stack>}

			<TextField
				id="description"
				label={<FormattedMessage id="description" />}
				multiline
				rows={3}
				value={description}
				onChange={changeDescription}
				helperText={descriptionError}
				error= {descriptionError != null}				
			/>

			<Stack
				direction={{xs: 'column', md: 'row'}}
				alignItems="center"
				spacing={{xs: 2, md: 4}}
			>
				<FormattedMessage id="communities_after_create" />
			</Stack>			
			
			<Button variant="contained" size="large" onClick={createCommunity}>
				<FormattedMessage id="create" />
			</Button>

			<Snackbar open={cantCreateMore.show} 
				onClose={()=>{setCantCreateMore({show: false})}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
				  key={"cantCreateMore"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"cantCreateMore"}>
				  <FormattedMessage id="cant_create_more_communities" />
  				</Alert>
			</Snackbar>	
			<Snackbar open={requestedClan.show} 
				onClose={()=>{setRequestedClan({show: false})}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
				  key={"requestedClan"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"requestedClan"}>
				  <FormattedMessage id="requested_clan_cant_create" />
  				</Alert>
			</Snackbar>	
			<Snackbar open={joinedClan.show} 
				onClose={()=>{setJoinedClan({show: false})}}
				autoHideDuration={6000} 
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
				  key={"joinedClan"}>
  				<Alert  severity="error" sx={{ width: '100%' }} key={"joinedClan"}>
				  <FormattedMessage id="joined_clan_cant_create" />
  				</Alert>
			</Snackbar>
		</Stack>
	);
};

export default CreateCommunityForm;
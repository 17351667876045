import { Grid, Typography,} from '@mui/material';
import {useRecoilState} from "recoil";
import React, { useState, useEffect } from 'react';
import {GeneralizeGameState, GeneralizeTeamsState, VotesState} from "../atoms/GameState"
import {GeneralizationMillisecondsForVote} from "../../../../../constants"
import {SoundTheme, UseSoundAtom, SoundLevelAtom} from "../../../../atoms/Common"
import useSound from 'use-sound';

import {PositionToColor} from "../GeneralizeLayout"


let progressSteps = 150


function MapCell(props) {
    const [votesState, setVotesState]  = useRecoilState(VotesState);
    const [generalizeGameState, setGeneralizeGameState]  = useRecoilState(GeneralizeGameState);
    const [generalizeTeamsState, setGeneralizeTeamsState]  = useRecoilState(GeneralizeTeamsState);

	const [soundTheme, setSoundTheme]  = useRecoilState(SoundTheme);
	const [useSoundAtom, setUseSoundAtom]  = useRecoilState(UseSoundAtom);
    const [soundLevelAtom, setSoundLevelAtom] = useRecoilState(SoundLevelAtom);

	const [playBubble] = useSound(
		'/sounds/themes/'+soundTheme.theme+'/generalize/bubble_burst.mp3',
		{ volume: soundLevelAtom }
	  );


	const [votePoints, setVotePoints] = useState([]);
	const [teamColor, setTeamColor] = useState("#c94d4d");
    const [voteProgress, setVoteProgress] = useState(0);
    const [progressInterval, setProgressInterval] = useState(0);
    const [votesCount, setVotesCount] = useState(0);

    useEffect(()=> {
        if (votesState.Votes.length > votesCount && useSoundAtom) {
            playBubble()
        }
        let cellVotesCount = 0
        for (let vote of votesState.Votes) {
            if (vote.I == props.i && vote.J == props.j) {
                cellVotesCount++
            }
        }
        setVotesCount(cellVotesCount)
    }, [votesState])
    
    useEffect(()=> {
        if (generalizeGameState.TeamIDTurn == 1) {
            setTeamColor("#c94d4d")
        }
        if (generalizeGameState.TeamIDTurn == 2) {
            setTeamColor("#4b60c9")
        }
    }, [generalizeGameState])

    useEffect(()=> {
        let votePointsUpdated = []
        let cellVotesCount = 0
        for (let vote of votesState.Votes) {
            if (vote.I == props.i && vote.J == props.j) {
                cellVotesCount++
                let pointStyle = {
                    width: "9px",
                    height: "9px",
                    borderRadius: "10px",
                    display: "inline-block",
                    backgroundColor: PositionToColor(vote.Position),
                    marginRight: "7px"
                }
                votePointsUpdated.push(<div style={pointStyle} key={vote.Position}></div>)

            }
        }
        if (generalizeTeamsState.Teams && generalizeTeamsState.Teams[generalizeGameState.TeamIDTurn]) {
            if (progressInterval != 0) {
                clearInterval(progressInterval)
            }
            if (cellVotesCount > (generalizeTeamsState.Teams[generalizeGameState.TeamIDTurn].Players.length - 1) / 2) {
                let updateVoteProgress = setInterval(() => {
                    setProgressInterval(updateVoteProgress)
                    if (voteProgress < 100 - 100/progressSteps) {
                        setVoteProgress(voteProgress+100/progressSteps)
                    } else {
                        clearInterval(updateVoteProgress)            
                    }
                }, GeneralizationMillisecondsForVote / progressSteps)
            } else {
                if (progressInterval != 0) {
                    clearInterval(progressInterval)
                }
                setVoteProgress(0)
            }
        }

        if (votePointsUpdated.length == 0) {
            if (progressInterval != 0) {
                clearInterval(progressInterval)
            }
            setProgressInterval(0)
            clearInterval(progressInterval)           
        }
        setVotePoints(votePointsUpdated)
    }, [votesState, voteProgress, generalizeTeamsState, generalizeGameState]);
    let wordStyle = { position: "absolute", hyphens: "manual", top: "50%", left: "50%", transform: "translate(-50%, -50%)", fontWeight: "bold", color: "#5c5c5b"}
    if (window.matchMedia("(max-width: 899px)").matches) {
        wordStyle.fontSize = 'xx-small'
    }
    return (
        <Grid item xs={1} sm={1} md={1} key={props.k} style={{position: "relative", height: "80px", }} onClick={(e)=>props.onClick(e, props.i, props.j)}>
            <div style={{maxWidth: "180px", height: "70px", border: "1px solid black", position: "relative", borderRadius: "5px", margin: "8px", backgroundColor: props.background, cursor: props.cursor}}>
                <span style={wordStyle}>
                {props.cell.Word}
                </span>
                <div style={{ position: "absolute", bottom: "8px", left: "10px"}}>{votePoints}</div>
               {!props.cell.Opened && <div style={{ position: "absolute", height: "5px", bottom: "0px", left: "0px", width: "100%"}}><div style={{height: "100%", backgroundColor: teamColor, width: voteProgress+"%"}}></div></div>}
            </div>
        </Grid>
    )
} 

export default MapCell

import { Box,  IconButton, Menu, MenuItem,  Modal, Snackbar, Alert, } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {FormattedMessage} from "react-intl";
import React, { useState, useEffect } from 'react';
import ComplainModal from "./Modals/ComplainModal"
import ModalStyle from '../../../utils/ModalStyle/ModalStyle';
import ModalClose from "../../../utils/ModalStyle/ModalClose"
import BanModal from './Modals/BanModal';





function BoxMenu(props) {
    const [openComplainModal, setOpenComplainModal] = useState(false);
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [openBanModal, setOpenBanModal] = useState(false);
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);        
    };

    const handleComplainModalClose = ()=>{
        setOpenComplainModal(false)
        handleCloseNavMenu()
    }
    const complainClick = ()=>{
        setOpenComplainModal(true)
    }
    const banClick = ()=>{
      setOpenBanModal(true)
    }

    let style = {
        flexGrow: 1,
        display: { xs: 'flex' },
        position: "absolute",
        top: "5%",
        right: "3%",
        zIndex: 201,
    }
    return(
          <Box sx={style}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              style={{backgroundColor: "rgba(255, 255, 255, 0.5)", width: "40px", height: "40px"}}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', },
                color: 'inherit',
              }}
            >
                <MenuItem key={1} onClick={complainClick}>
                    <FormattedMessage id="complain"/>
                </MenuItem>
                {props.isMeAdmin && !props.isAdmin && 
                    <MenuItem key={1} onClick={banClick}>
                        <FormattedMessage id="ban"/>
                    </MenuItem>
                }
            </Menu>

            <Modal
                open={openComplainModal}
                onClose={handleComplainModalClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...ModalStyle, width: 350 }}>
                    <ModalClose onClose={handleComplainModalClose}/>
                    <ComplainModal userid={props.userid} identity={props.identity} closeModal={handleComplainModalClose} />
                </Box>
            </Modal>  

            <Modal
                open={openBanModal}
                onClose={()=>{setOpenBanModal(false)}}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...ModalStyle, width: 350 }}>
                    <ModalClose onClose={()=>{setOpenBanModal(false)}}/>
                    <BanModal userid={props.userid} identity={props.identity} closeModal={()=>{setOpenBanModal(false)}} />
                </Box>
            </Modal>                           
          </Box>
    )
}
// ComplainModal
export default BoxMenu;
import {
    atom, useRecoilState,
} from 'recoil';

export const WSOpened = atom({
    key: 'WSOpened',
    default: {
        opened: false,
    },
})

export const ShareRoomLinkModal = atom({
    key: 'ShareRoomLinkModal',
    default: false,
})

export const ShowVideoAudioSettings = atom({
    key: 'ShowVideoAudioSettings',
    default: {
        show: false,
    },
})

export const VideoConstraints = atom({
    key: 'VideoConstraints',
    default: {
        constraints: {},
    },
})

export const AudioConstraints = atom({
    key: 'AudioConstraints',
    default: {
        constraints: true,
    },
})

export const AppliedVideoConstraints = atom({
    key: 'AppliedVideoConstraints',
    default: {
        constraints: false, //{},
    }
})

export const AppliedAudioConstraints = atom({
    key: 'AppliedAudioConstraints',
    default: {
        constraints: true,
    },
})

export const Theme = atom({
    key: 'Theme',
    default: {
        name: "default",
    },
})

export const RoomToken = atom({
    key: 'RoomToken',
    default: {
        name: "",
        token: "",
    }
})

export const MyInfo = atom({
    key: 'MyInfo',
    default: {
        name: "",
        role: "",
    }
})

export const RoomInfo = atom({
    key: 'RoomInfo',
    default: {}
})

export const ConnectedToRoom = atom({
    key: 'ConnectedToRoom',
    default: false
})

export const MePublished = atom({
    key: 'MePublished',
    default: {
        isPublished: false,
    }
})

export const MyMediaStream = atom({
    key: 'MyMediaStream',
    default: null
})

export const StreamSourceChanged = atom({
    key: 'StreamSourceChanged',
    default: {
        changed: false,
    }
})

export const MyTrackStateStreamReady = atom({
    key: 'MyTrackStateStreamReady',
    default: {
        streamReady: false,
    },
})

export const AdminRooms = atom({
    key: 'AdminRooms',
    default: {
        Rooms: [],
    },
})

export const HasNoCameras = atom({
    key: 'HasNoCameras',
    default: {
        hasNoCameras: false,
    }
})

export const ComplainsStatuses = atom({
    key: 'ComplainsStatuses',
    default: {
        complainsStatuses: {},
    }
})

export const MatesInfoVersions = atom({
    key: 'MatesInfoVersions',
    default: {}
})

export const LivekitComponentUpdateCounter = atom({
    key: 'LivekitComponentUpdateCounter',
    default: 0
})

export const LivekitParticipantsMuteUpdateCounter = atom({
    key: 'LivekitParticipantsMuteUpdateCounter',
    default: 0
})

export const PositionsToMute = atom({
    key: 'LivekitPositionsToMute',
    default: new Set([])
})

export const PositionsToHideVideo = atom({
    key: 'LivekitPositionsToHideVideo',
    default: new Set([])
})

export const UseVideo = atom({
    key: 'UseVideo',
    default: true
})

export const UseAudio = atom({
    key: 'UseAudio',
    default: true
})

export const Preflight = atom({
    key: 'Preflight',
    default: true
})

export const MyGlobalStream = atom({
    key: 'MyGlobalStream',
    default: null
})

export const RequestedStream = atom({
    key: 'RequestedStream',
    default: false
})

export const MatesBoxesMap = atom({
    key: 'MatesBoxesMap',
    default: new Map()
})

export const RoomSeed = atom({
    key: 'RoomSeed',
    default: ""
})

export const MyWebcamUpdateCounter = atom({
    key: 'MyWebcamUpdateCounter',
    default: 0
})

export const ActiveSpeakers = atom({
    key: 'ActiveSpeakers',
    default: []
})

export const LivekitDevicesRequested = atom({
    key: 'LivekitDevicesRequested',
    default: false
})


export const OthersAudioBoxes = atom({
    key: 'LivekitOthersAudioBoxes',
    default: new Map()
})

export const CanBecameCommonParticipant = atom({
    key: 'CanBecameCommonParticipant',
    default: {
        can: false,
    },
})

export const ShowChangeRoomType = atom({
    key: 'ShowChangeRoomType',
    default: false,
})

export const SendAdmin = atom({
    key: 'LivekitSendAdmin',
    default: false,
})
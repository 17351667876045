import {
    atom,
} from 'recoil';

export const OpenCreateEvent = atom({
    key: 'OpenCreateEvent',
    default: false
})

export const OpenCreateCommunity = atom({
    key: 'OpenCreateCommunity',
    default: false
})

export const OpenEditCommunity = atom({
    key: 'OpenEditCommunity',
    default: false
})


import React, { useState, useEffect, useRef } from 'react';
import SoundLevelChanger from './SoundLevelChanger'
import UserInfo from './UserInfo.js'
import BanMate from './BanMate.js'
import {PositionsToMute, PositionsToHideVideo, LivekitParticipantsMuteUpdateCounter, RoomInfo, SendAdmin,} from "../../atoms";
import {useRecoilState,} from 'recoil';
import BoxMenu from "./BoxMenu.js"
import VideocamOff from '@mui/icons-material/VideocamOff';
import MicOffIcon from '@mui/icons-material/MicOff';
import {makeString} from "../../../utils/common"
import { useNavigate } from 'react-router-dom';
import { Chip,} from '@mui/material';
import {FormattedMessage} from "react-intl";
import {sendMsg} from "../../WSController"

function OthersMediaBox(props) {
	const [borders, setBorders] = useState({Name: "none"});

    const navigate = useNavigate();

    const [roomInfo, setRoomInfo] = useRecoilState(RoomInfo);  
    useEffect(() => {
		for (let nickname in roomInfo.roomMates) {
			if (roomInfo.roomMates[nickname].Position == props.position && roomInfo.roomMates[nickname].Borders) {
				let borders = roomInfo.roomMates[nickname].Borders
				setBorders(borders)
			}
		}
	}, [roomInfo])

    const [positionsToMute, setPositionsToMute]  = useRecoilState(PositionsToMute);
    const [positionsToHideVideo, setPositionsToHideVideo]  = useRecoilState(PositionsToHideVideo);
    const [livekitParticipantsMuteUpdateCounter, setLivekitParticipantsMuteUpdateCounter] = useRecoilState(LivekitParticipantsMuteUpdateCounter);
    const [sendAdmin, setSendAdmin]  = useRecoilState(SendAdmin);

    const [showNoCamera, setShowNoCamera] = useState(false);
    const [showNoMic, setShowNoMic] = useState(false);

    let videoClass = "OtherCam"+props.position
    let audioClass = "OtherMic"+props.position
    //let videoID = 'Video'+props.position;
    //let audioID = 'Audio'+props.position;
    useEffect(() => {
        //console.log("ASASAS "+props.identity+" videoRef.current props.videoTrack positionsToHideVideo.has(props.position)", props.identity, videoRef.current, props.videoTrack, positionsToHideVideo.has(props.position))
        if (props.videoTrack && !positionsToHideVideo.has(props.position)) {
            // props.videoTrack.detach()            
            const videoElementsT = document.getElementsByClassName(videoClass)
            for (let videoElement of videoElementsT) {
                props.videoTrack.attach(videoElement)
            }
        }

        if (props.videoTrack && props.videoTrack.isMuted) {
            setShowNoCamera(true)
        } else {
            setShowNoCamera(false)
        }

        // if (videoRef.current && positionsToHideVideo.has(props.position)) {
        //     videoRef.current.pause()
        // } 
        // else if (videoRef.current) {
        //     videoRef.current.play()
        // }


        // if (props.audioTrack && props.audioTrack.isMuted) {
        //     setShowNoMic(true)
        // } else {
        //     setShowNoMic(false)
        // }

        // if (props.audioTrack) {
        //     const audioElementsT = document.getElementsByClassName(audioClass)
        //     for (let audioElement of audioElementsT) {
        //        // if (attachedAudio) {                    
        //             props.audioTrack.attach(audioElement)
        //        // }
        //         if (audioElement && positionsToMute.has(props.position)) {
        //             audioElement.pause()
        //         } else if (audioElement) {
        //             try {
        //                 audioElement.play()
        //             } catch (e) {
                        
        //             }
        //         }            
        //     }
        //     setAttachedAudio(true)
        // }


    }, [props.videoTrack, props.audioTrack, positionsToMute, PositionsToHideVideo]);

    useEffect(() => {
        if (props.videoTrack && props.videoTrack.isMuted) {
            setShowNoCamera(true)
        } else {
            setShowNoCamera(false)
        }
        if (props.audioTrack && props.audioTrack.isMuted) {
            setShowNoMic(true)
        } else {
            setShowNoMic(false)
        }
    }, [props.videoTrack, props.audioTrack, livekitParticipantsMuteUpdateCounter])

    let identityPositionStyle = {
        position: "absolute",
        bottom: borders.Name != "none" ? "8%" : "5%",
        left: borders.Name != "none" ? "6%" : "3%",
        color: "white",
        padding: "0.2rem",
        zIndex: 201,
        cursor: "pointer",
    }
    let mobile = false
    if (window.matchMedia("(max-width: 899px)").matches && !props.modal) {
        identityPositionStyle.display = 'none'
        mobile = true
    }
    let positionSquareStyle = {
        padding: "5px 10px",
        backgroundColor: "black",
        opacity: "0.7",
        borderRadius: "6px"
     }
     let identitySquareStyle = {
        padding: "5px 10px",
        backgroundColor: "black",
        opacity: "0.7",
        borderRadius: "6px"
     }
     let colorNoPositionValue = {
        marginTop: "15px",
        width: "15px",
        height: "15px",
        fontSize: "5px",
        backgroundColor: props.color,
        borderRadius: "10px",
        display: "inline-block",
     }
     const changeAdmin = ()=>{
        sendMsg("ChangeAdmin", {Position: props.position})
        setSendAdmin(false)
    }     
     const goToCard = ()=>{
        navigate('/user/'+props.identity)
     }
    return (
      <div style={{height: "100%",}}>
          <div style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", height: "100%",}}><video class={videoClass}  style={{height: "100%", transform: "rotateY(180deg)", display: showNoCamera ? "none" : "inline-block",}} preload="true" autoPlay/></div>
          {!mobile && <SoundLevelChanger level={60} audioTrack={props.audioTrack} position={props.position}/>}
          <UserInfo userid={props.userid} modal={props.modal} position={props.position}/>
          {showNoMic && <MicOffIcon style={{position: "absolute", width: mobile ? "20%" : "8%", left: borders.Name != "none" ? "6.5%" : "3.5%", bottom: borders.Name != "none" ? "39%" : "36%", color: "red"}} />}
          {showNoCamera && <VideocamOff style={{position: "absolute", width: "100px", left: "50%", top: "50%", zIndex: 199, transform: "translate(-50%, -50%)",}}/>}
          {!mobile && <BanMate userid={props.userid} myself={false} modal={props.modal}/>}
          {!mobile && <BoxMenu isMeAdmin={props.isMeAdmin} isAdmin={props.isAdmin} userid={props.userid} identity={props.identity} myself={false} modal={props.modal}/>}
          <div style={identityPositionStyle} onClick={goToCard}>{!props.roulette ? props.show_position ? <span style={positionSquareStyle}>{props.position}</span> : props.color ? <span style={colorNoPositionValue}></span> : null : null} <span style={identitySquareStyle}>{props.identity}</span></div>
          {props.isAdmin && !mobile && <Chip sx={{color: "white"}} label={<FormattedMessage id="admin" />}  size="small" key={1} className="admin-label-style"/>}
          {sendAdmin && <div style={{width:"100%", height: "100%", backgroundColor:"rgba(111, 111, 111, 0.5)", zIndex:9999, position: "absolute", top:0, left:0, cursor: "pointer"}} onClick={changeAdmin}>
                <div
                style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)"}}><FormattedMessage id="send_admin_player" /></div>
            </div>} 
      </div>
    );
}

export default OthersMediaBox;

import React, { useState, useEffect } from 'react';
import {FormattedMessage} from "react-intl";
import {useRecoilState,} from 'recoil';
import {UseSoundAtom,} from "../../../../atoms/Common"




let finalSize = 170
const timeMillis = 800
const steps = 100
const startSize = 30

function PreRunningRoundPhase(props) {
    const [fontSize, setFontSize] = useState(startSize);
    const [soundStarted, setSoundStarted] = useState(false);

    const [useSoundAtom, setUseSoundAtom]  = useRecoilState(UseSoundAtom);

    if (props.state == "go") {
        if (window.matchMedia("(max-width: 899px)").matches) {
            finalSize = 100
        } else {
            finalSize = 120
        }
    }

    useEffect(() => {
        if (useSoundAtom) {
            props.sound()
        }
    }, [useSoundAtom])

    useEffect(() => {
        if (finalSize <= fontSize) {
            return
        }
        let timeoutMe = setTimeout(() => {
            let size = fontSize + (finalSize - startSize) / steps
            setFontSize(size)
        }, timeMillis / steps)
    }, [fontSize])

    return (
        <div style={{fontWeight: "bold", fontSize: fontSize+"px"}}>
            {props.state == "go" ? <FormattedMessage id="go_go_go" /> : props.state}
        </div>
    )
}

export default PreRunningRoundPhase;


import React, { useState, useEffect, useRef } from 'react';
import Api from "../../../services/Api";
import TokenService from "../../../services/TokenService";
import {RoulettesWSOpened, RouletteSettings, RouletteMateInfo, RouletteChatHistory, RoulettesWS, OnRoulettePage, RouletteWSRestartTimeout} from "../../atoms/RoulettesAtoms";
import {UserInfoAtom, UserPersonalAtom} from "../../atoms/UserAtoms";
import LivekitComponent from "../../../livekit_prod/LivekitComponent"
import {domainNameAPI, protocolAPI, apiPort} from "../../../environment"
import { LivekitComponentUpdateCounter } from '../../../livekit_prod/atoms';
import {
    useRecoilState,
} from 'recoil';
import {isIterable} from "../../../utils/common"

let ws = null

export const sendRouletteMsg = (ws, type, obj)=>{
    ws.send(JSON.stringify({'Type': type, 'Payload': obj}));
}

function connect(atomValues, atomSets, apiUrl, forceUpdate, props, reconnect) {
    let {rouletteSettings, roulettesWSOpened, userInfo, livekitComponentUpdateCounter, onRoulettePage, rouletteWSRestartTimeout} = atomValues
    let {setRoulettesWSOpened, setLivekitComponentUpdateCounter, setRouletteMateInfo, setRouletteChatHistory, setRoulettesWS, setRouletteWSRestartTimeout} = atomSets
    if (reconnect) {
        ws = new WebSocket(apiUrl)
        setRoulettesWS({ws: ws})
    }
    if (ws == null) {
        return
    }
    let userInfoNickname = "<donotknown>"
    if (userInfo.UserInfo != null) {
        userInfoNickname = userInfo.UserInfo.Nickname
    }
    let tetatetSettings = getTetatetSettings(rouletteSettings, userInfoNickname)
    if (!tetatetSettings) {
        return
    }
    //console.log("Remade Listeners");
    ws.onopen = () => {
        let newState = Object.assign({}, roulettesWSOpened);
        newState.opened = true;
        setRoulettesWSOpened(newState);
        sendRouletteMsg(ws, 'InitRequest', {'SessionID': TokenService.getLocalAccessToken(), 
            EighteenPlus: tetatetSettings.EighteenPlus,
            YourSex: tetatetSettings.YourSex,
            RoomMateSex: tetatetSettings.RoomMateSex,
            YourAge: tetatetSettings.YourAge,
            RoomMateAge: tetatetSettings.RoomMateAge,
            Nickname: tetatetSettings.Nickname,
        })
    };

    ws.onclose = () => {
        if (onRoulettePage) {
            if (rouletteWSRestartTimeout.timeout != 0) {
                clearInterval(rouletteWSRestartTimeout.timeout)
            }
            let newState = Object.assign({}, setRoulettesWSOpened);
            newState.opened = false;
            setRoulettesWSOpened(newState);
            let rTimeOut = setTimeout(function() {
                connect(atomValues, atomSets, apiUrl, forceUpdate, props, true);
            }, 2000);
            setRouletteWSRestartTimeout({timeout: rTimeOut})
        } else {
            if (rouletteWSRestartTimeout.timeout != 0) {
                clearInterval(rouletteWSRestartTimeout.timeout)
            }
        }
    };

    ws.onmessage = (message) => {
        let msg = JSON.parse(message.data);
        switch (msg.Type) {
            case "InFindingProcess":
                break;
            case "FoundReport":
                forceUpdate()
                setLivekitComponentUpdateCounter(livekitComponentUpdateCounter + 1)
                processFoundReport(msg, setRouletteMateInfo);
                break;
            case "MateEnded":
                console.log("MateEnded");
                forceUpdate()
                setLivekitComponentUpdateCounter(livekitComponentUpdateCounter + 1)
                sendRouletteMsg(ws, 'InitRequest', {'SessionID': TokenService.getLocalAccessToken(), 
                    EighteenPlus: tetatetSettings.EighteenPlus,
                    YourSex: tetatetSettings.YourSex,
                    RoomMateSex: tetatetSettings.RoomMateSex,
                    YourAge: tetatetSettings.YourAge,
                    RoomMateAge: tetatetSettings.RoomMateAge,
                    Nickname: tetatetSettings.Nickname,
                })
                break;
            case "ChatStory":
                processChatStory(msg, setRouletteChatHistory, props.myid);
                break;   
            case "ErrorCode":
                console.log("ErrorCode");
                Api.post("/auth/refreshToken", {
                    RefreshToken: TokenService.getLocalRefreshToken(),
                  }).then(response=> {
                    TokenService.updateLocalAccessTokens(response.data.Tokens.AuthToken, response.data.Tokens.RefreshToken);
                    sendRouletteMsg(ws, 'InitRequest', {'SessionID': TokenService.getLocalAccessToken(), 
                        EighteenPlus: tetatetSettings.EighteenPlus,
                        YourSex: tetatetSettings.YourSex,
                        RoomMateSex: tetatetSettings.RoomMateSex,
                        YourAge: tetatetSettings.YourAge,
                        RoomMateAge: tetatetSettings.RoomMateAge,
                        Nickname: tetatetSettings.Nickname,
                    })
                  });
                break;
        }
    };
}

function RouletteController(props) {
    const apiUrl = props.apiURL
    const [roulettesWSOpened, setRoulettesWSOpened] = useRecoilState(RoulettesWSOpened); 
    const [rouletteSettings, setRouletteSettings] = useRecoilState(RouletteSettings);
    const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);
    const [value, forceUpdate] = useForceUpdate();
    const [livekitComponentUpdateCounter, setLivekitComponentUpdateCounter] = useRecoilState(LivekitComponentUpdateCounter)
    const [rouletteMateInfo, setRouletteMateInfo] = useRecoilState(RouletteMateInfo)
    const [rouletteChatHistory, setRouletteChatHistory] = useRecoilState(RouletteChatHistory)
    const [roulettesWS, setRoulettesWS] = useRecoilState(RoulettesWS);
	const [onRoulettePage, setOnRoulettePage] = useRecoilState(OnRoulettePage);
    const [rouletteWSRestartTimeout, setRouletteWSRestartTimeout] = useRecoilState(RouletteWSRestartTimeout);


    useEffect(()=> {
        ws = new WebSocket(apiUrl)
        setRoulettesWS({ws: ws})
    }, []);

    useEffect(()=> {
        connect({rouletteSettings, roulettesWSOpened, userInfo, livekitComponentUpdateCounter, onRoulettePage, rouletteWSRestartTimeout}, 
        {setRoulettesWSOpened, setLivekitComponentUpdateCounter, setRouletteMateInfo, setRouletteChatHistory, setRoulettesWS, setRouletteWSRestartTimeout}, apiUrl, forceUpdate, props, false)
    }, [livekitComponentUpdateCounter, userInfo, props.myid, onRoulettePage, rouletteWSRestartTimeout, rouletteSettings]);

    let gameControllerStyle = {
        Position: "fixed",
        Width: 0,
        Height: 0,
    }

    return (
        <div>
            <span style={gameControllerStyle}></span>
        </div>
    );
}

export default RouletteController;

function getTetatetSettings(rouletteSettings, userInfoNickname) {
    if (rouletteSettings.Find) {
        return {
            EighteenPlus: rouletteSettings.EighteenPlus,
            YourSex: rouletteSettings.YourSex,
            RoomMateSex: rouletteSettings.MateSex,
            YourAge: rouletteSettings.YourAge,
            RoomMateAge: rouletteSettings.MateAge,
            Nickname: userInfoNickname,
        }
    }
    let fromLocalStorage = localStorage.getItem('TetatetSettings');
    if (fromLocalStorage != null) {
        let fromLocalStorageObj = JSON.parse(fromLocalStorage)
        return {
            EighteenPlus: fromLocalStorageObj.EighteenPlus,
            YourSex: fromLocalStorageObj.YourSex,
            RoomMateSex: fromLocalStorageObj.MateSex,
            YourAge: fromLocalStorageObj.YourAge,
            RoomMateAge: fromLocalStorageObj.MateAge,
            Nickname: fromLocalStorageObj.Nickname,
        }
    }
    return undefined
}

  //create your forceUpdate hook
function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return [value, () => setValue(value => value + 1)]; // update state to force render
    // An function that increment 👆🏻 the previous state like here 
    // is better than directly setting `value + 1`
}

const processFoundReport = (msg, setRouletteMateInfo) =>{
    setRouletteMateInfo({
        Sex: msg.Payload.Sex,
        Age: msg.Payload.Age,
        UserID: msg.Payload.UserID,
        Nickname: msg.Payload.Nickname,
    })
}

const processChatStory = (msg, setRouletteChatHistory, myid) => {
    if (!isIterable(msg.Payload.MessagesStory)) {
        return
    }
    let chatStory = []
    for (let message of msg.Payload.MessagesStory) {
        let self = false
        if (message.AuthorID == myid) {
            self = true
        }
        chatStory.push({
            self: self,
            Message: message.Message,
            Time: message.Time,
            AuthorID: message.AuthorID,
        })
    }
    setRouletteChatHistory(chatStory)
}
import { Box } from '@mui/system';
import {IamaliveGameState} from "./atoms/GameAtoms"
import {useRecoilState,} from 'recoil';
import {FormattedMessage} from "react-intl";
import { Button, } from '@mui/material';
import {RoomInfo} from "../../../../livekit_prod/atoms"






const GamePanel = (props) => {
    const [iamaliveGameState, setIamaliveGameState]  = useRecoilState(IamaliveGameState); 
    const [roomInfo, setRoomInfo]  = useRecoilState(RoomInfo);

    let nickOfForWhoome = ""
    let nickOfWho = ""
    for (let identity in roomInfo.roomMates) {
      if (roomInfo.roomMates[identity].Position == iamaliveGameState.positionToAddWord) {
        nickOfForWhoome = identity
      }
      if (roomInfo.roomMates[identity].Position == iamaliveGameState.positionToChooseWord) {
        nickOfWho = identity
      }
    }

    return (
        <Box
        sx={{
          minHeight: '100vh',
          bgcolor: 'background.default',
          color: 'text.primary',
        }}
      >
            {iamaliveGameState.phase == "picking" ?
            <div>
                <div><FormattedMessage id="RecruitingPlayers" /></div><br/>
                <div><FormattedMessage id="admin_can_start_game" /></div><br/>                
            </div>
            : null}
            {iamaliveGameState.phase == "choose_word" ?
            <div>
                <div><FormattedMessage id="choosing_word" /></div><br/>
                <div><FormattedMessage id="for_whom_we_choose" />: {nickOfForWhoome} (<FormattedMessage id="not_hear_anyone" />)</div><br/>
                <div><FormattedMessage id="who_choose" />: {nickOfWho}</div><br/>
            </div>
            : null}
            {iamaliveGameState.phase == "playing" ?
            <div>
                <div><FormattedMessage id="playing_now" /></div><br/>
                {props.myrole != "spectator" && <div><FormattedMessage id="admin_can_change_the_word" /></div>}<br/>
            </div>
            : null}
      </Box>
    )
}

export default GamePanel
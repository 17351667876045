









export const Ratings = {
    "ratings":`
### Все выше и выше и выше

Чем больше человек общается, чем больше он проводит времени в играх, побеждает, тем выше в рейтинге он будет.

За активность в Много Комнат человеку даются баллы опыта. 

- Если человек просто ходит по сайту и у негот открыта вкладка - начисляются баллы. 
- Если он находится в комнате - общает там - начисляются баллы.
- Если человек сыграл игру - начисляются баллы

Баллы опыта складываются в уровни

Таблица опыта:

| Уровень | Опыт  |
| ------- | ----- |
| 0       | 300   |
| 1       | 500   |
| 2       | 750   |
| 3       | 900   |
| 4       | 1100  |
| 5       | 1300  |
| 6       | 1600  |
| 7       | 2000  |
| 8       | 2400  |
| 9       | 2900  |
| 10      | 3500  |
| 11      | 4200  |
| 12      | 5100  |
| 13      | 6200  |
| 14      | 7500  |
| 15      | 9000  |
| 16      | 10800 |
| 17      | 14000 |
| 18      | 15600 |
| 19      | 18800 |
| 20      | 22600 |
| 21      | 27200 |
| 22      | 32700 |
| 23      | 35900 |
`
}
import { Button, Grid, Typography, TextField } from '@mui/material';
import React, {useState} from 'react';
import {FormattedMessage} from "react-intl";
import {MaxTelephoneLength, MaxEmailLength, SuccessMessage} from "../../constants"
import SubscriptionsService from "../../services/SubscriptionsService"

const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePhone = (phone) => {
    return String(phone)
      .toLowerCase()
      .match(
        /^\+[1-9]\d{1,14}$/
      );
  };


const NeedPhoneEmail = (props)=>{
    const [telephone, setTelephone] = useState("")
    const [email, setEmail] = useState("")

    const [telephoneError, setTelephoneError] = useState(null);
	const [emailError, SetEmailError] = useState(null);

    const changeTelephone = (event)=>{
		if (event.target.value.length > MaxTelephoneLength) {
			setTelephoneError(<span><FormattedMessage id="cantbelonger"/> {MaxTelephoneLength}</span>)
		} else {
			setTelephoneError(null)
		}
        if (!validatePhone(event.target.value) && event.target.value != "") {
            setTelephoneError(<span><FormattedMessage id="wrong_phone_format"/></span>)
        } else {
			setTelephoneError(null)
		}
        setTelephone(event.target.value);
    }
    const changeEmail = (event)=>{
        if (event.target.value.length > MaxEmailLength) {
			SetEmailError(<span><FormattedMessage id="cantbelonger"/> {MaxEmailLength}</span>)
		} else {
			SetEmailError(null)
		}
        if (!validateEmail(event.target.value) && event.target.value != "") {
            SetEmailError(<span><FormattedMessage id="wrong_email_format"/></span>)
        } else {
			SetEmailError(null)
		}
		setEmail(event.target.value);
    }
    const continuePayment = ()=>{
        if (telephoneError != null || emailError != null) {
            return
        }
        if (email == "" && telephone == "") {
            return
        }
        SubscriptionsService.setPhoneOrEmail(telephone, email).then((response)=>{
			if (response.data.Success == SuccessMessage) {
                props.continueToMakePayment()
            }
        })
    }
    return (
        <Grid
            container
            rowSpacing={2}
            spacing={{ xs: 2, sm: 4 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            direction="column"
            justifyContent="center"
            alignItems="center"
            padding={1}
        >
            <Grid item xs={4} sm={8} md={12}>
				<Typography variant='h4'>
					<FormattedMessage id="payment_needs_phone_or_email" />
				</Typography>
			</Grid>

            <Grid item xs={4} sm={8} md={12}>
				<TextField 
					value={telephone}
					onChange={changeTelephone}
					fullWidth 
					id="name" 
					label={<FormattedMessage id="phone" />}
					variant="outlined" 
                    helperText={telephoneError}
                    error= {telephoneError != null}
					size='small'
				/>
			</Grid>

            <Grid item xs={4} sm={8} md={12}>
				<Typography variant='h6'>
					<FormattedMessage id="or" />
				</Typography>
			</Grid>

            <Grid item xs={4} sm={8} md={12}>
				<TextField 
					value={email}
					onChange={changeEmail}
					fullWidth 
					id="name" 
					label={<FormattedMessage id="email" />}
					variant="outlined"
                    helperText={emailError}
                    error= {emailError != null}
					size='small'
				/>
			</Grid>

			<Grid item xs={4} sm={8} md={12}>
				<Button variant="contained" size="large" onClick={continuePayment} disabled={(telephoneError != null || emailError != null) || (email == "" && telephone == "")}>
					<FormattedMessage id="continue" />
				</Button>
			</Grid>            
        </Grid>
    )
}

export default NeedPhoneEmail;
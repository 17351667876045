import Api from "../Api"

// Name            string
// NumberOfPlayers int
// EverybodiesHear bool
// EighteenPlus    bool
// Closed          bool
// Passwd          string

const createHackword = async function(name, numOfPlayers, everybodyHear, eighteenPlus, closed, passwd) {
    return await Api.post("/hackword/create", {Name: name, NumberOfPlayers: numOfPlayers, EverybodiesHear: everybodyHear, 
      EighteenPlus: eighteenPlus, Closed: closed, Passwd: passwd})
}


const HackwordService = {
    createHackword,
  };
  
export default HackwordService;
import Api from "./Api"


const createPaymentForMonth = async function(domainNameAPI, prolongate) {
    return await Api.post(`/subscription/createPaymentForMonth`, {Domain: domainNameAPI, DoAutoProlongate: prolongate})
  }

const checkPaymentForMonth = async function(orderID) {
  return await Api.post(`/subscription/checkSuccessPaymentForMonth`, {OrderID: orderID})
}

const turnOffProlongation = async function() {
  return await Api.post(`/subscription/turnOffProlongation`, {})
}

const setPhoneOrEmail = async function(phone, email) {
  return await Api.post(`/subscription/setPhoneOrEmail`, {Phone: phone, Email: email})  
}

const SubscriptionsService = {
    createPaymentForMonth,
    checkPaymentForMonth,
    turnOffProlongation,
    setPhoneOrEmail,
}

export default SubscriptionsService;
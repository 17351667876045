







export const VIDEO_BOX_WIDTH = 320;
export const VIDEO_BOX_HEIGHT = 180;

let baseMargin = "1rem";
let smallMargin = "0.5rem";
let tetatetHorizontalMergin = "250px"
let tetatetVerticalMergin = "500px"


export const CircleType = "Circle";
export const TetATetType = "TetATet";

export let PositionStyles = {
    TetATet: {
        1: {
            position: "absolute",
           // top: tetatetVerticalMergin,
            left: tetatetHorizontalMergin,
        },
        2: {
            position: "absolute",
           // top: tetatetVerticalMergin,
            right: tetatetHorizontalMergin,
        },
    },
    Circle2: {
        1: {
            position: "fixed",
            left: baseMargin,
            top: "45%",
            transform: "translate(0%, -50%)",
        },
        2: {
            position: "fixed",
            right: baseMargin,
            top: "45%",
            transform: "translate(0%, -50%)",
        }
    },
    Circle3: {
        1: {
            position: "fixed",
            left: baseMargin,
            top: "45%",
            transform: "translate(0%, -50%)",
        },
        2: {
            position: "fixed",
            left: "50%",
            top: baseMargin,
            transform: "translate(-50%, 0%)",
        },
        3: {
            position: "fixed",
            right: baseMargin,
            top: "45%",
            transform: "translate(0%, -50%)",
        },
    },
    Circle4: {
        1: {
            position: "fixed",
            left: baseMargin,
            top: baseMargin,
        },
        2: {
            position: "fixed",
            right: baseMargin,
            top: baseMargin,
        },
        3: {
            position: "fixed",
            right: baseMargin,
            bottom: baseMargin,
        },
        4: {
            position: "fixed",
            left: baseMargin,
            bottom: baseMargin,
        }
    },
    Circle5: {
        1: {
            position: "fixed",
            left: baseMargin,
            top: baseMargin,
        },
        2: {
            position: "fixed",
            left: "50%",
            top: baseMargin,
            transform: "translate(-50%, 0%)",
        },
        3: {
            position: "fixed",
            right: baseMargin,
            top: baseMargin,
        },
        4: {
            position: "fixed",
            right: baseMargin,
            bottom: baseMargin,
        },
        5: {
            position: "fixed",
            left: baseMargin,
            bottom: baseMargin,
        }        
    },
    Circle6: {
        1: {
            position: "fixed",
            left: baseMargin,
            top: baseMargin,
        },
        2: {
            position: "fixed",
            left: "50%",
            top: baseMargin,
            transform: "translate(-50%, 0%)",
        },
        3: {
            position: "fixed",
            right: baseMargin,
            top: baseMargin,
        },
        4: {
            position: "fixed",
            right: baseMargin,
            bottom: baseMargin,
        },
        5: {
            position: "fixed",
            left: "50%",
            bottom: baseMargin,
            transform: "translate(-50%, 0%)",
        },
        6: {
            position: "fixed",
            left: baseMargin,
            bottom: baseMargin,
        }        
    },
    Circle7: {
        1: {
            position: "fixed",
            left: baseMargin,
            top: baseMargin,
        },
        2: {
            position: "fixed",
            left: "50%",
            top: baseMargin,
            transform: "translate(-50%, 0%)",
        },
        3: {
            position: "fixed",
            right: baseMargin,
            top: baseMargin,
        },
        4: {
            position: "fixed",
            right: baseMargin,
            bottom: baseMargin,
        },
        5: {
            position: "fixed",
            left: "50%",
            bottom: baseMargin,
            transform: "translate(-50%, 0%)",
        },
        6: {
            position: "fixed",
            left: baseMargin,
            bottom: baseMargin,
        },
        7: {
            position: "fixed",
            left: baseMargin,
            top: "50%",
            transform: "translate(0%, -50%)",
        }
    },
    Circle8: {
        1: {
            position: "fixed",
            left: baseMargin,
            top: baseMargin,
        },
        2: {
            position: "fixed",
            left: "50%",
            top: baseMargin,
            transform: "translate(-50%, 0%)",
        },
        3: {
            position: "fixed",
            right: baseMargin,
            top: baseMargin,
        },
        4: {
            position: "fixed",
            right: baseMargin,
            top: "50%",
            transform: "translate(0%, -50%)",
        },        
        5: {
            position: "fixed",
            right: baseMargin,
            bottom: baseMargin,
        },
        6: {
            position: "fixed",
            left: "50%",
            bottom: baseMargin,
            transform: "translate(-50%, 0%)",
        },
        7: {
            position: "fixed",
            left: baseMargin,
            bottom: baseMargin,
        },
        8: {
            position: "fixed",
            left: baseMargin,
            top: "50%",
            transform: "translate(0%, -50%)",
        }        
    },
    Circle9: {
        1: {
            position: "fixed",
            left: "15%",
            transform: "translate(-50%, 0%)",
            top: baseMargin,
        },
        2: {
            position: "fixed",
            left: "40%",
            transform: "translate(-50%, 0%)",
            top: baseMargin,
        },
        3: {
            position: "fixed",
            left: "60%",
            transform: "translate(-50%, 0%)",
            top: baseMargin,
        },
        4: {
            position: "fixed",
            left: "85%",
            transform: "translate(-50%, 0%)",
            top: baseMargin,
        },
        5: {
            position: "fixed",
            left: "85%",
            top: "50%",
            transform: "translate(-50%, -50%)",
        },        
        6: {
            position: "fixed",
            left: "85%",
            transform: "translate(-50%, 0%)",
            bottom: baseMargin,
        },
        7: {
            position: "fixed",
            left: "50%",
            bottom: baseMargin,
            transform: "translate(-50%, 0%)",
        },
        8: {
            position: "fixed",
            left: "15%",
            bottom: baseMargin,
            transform: "translate(-50%, -50%)",
        },
        9: {
            position: "fixed",
            left: "15%",
            top: "50%",
            transform: "translate(-50%, -50%)",
        }        
    }
}

export const GetPositionStyle = function(positionType, maxCountOfMates, position) {
    if (maxCountOfMates < 0) {
        return {};
    }
    if (positionType === TetATetType) {
        return PositionStyles[positionType][position]
    }
    if (positionType === "Circle") {
        if (maxCountOfMates < 9) {
            return PositionStyles[positionType+maxCountOfMates][position]
        } else {
            var w = window.innerWidth;
           // console.log("Width! = "+w);
            // 4 in horizontal line
            let margin = (parseInt(w) - 4 * VIDEO_BOX_WIDTH) / 5;
          //  console.log("Margin! = "+margin);
            if (position <= 4) {
                return {
                    position: "fixed",
                    left: parseInt(margin) * position + VIDEO_BOX_WIDTH * (position - 1) + "px",
                    top: baseMargin,
                }
            }
            if (position == 5) {
                return {
                    position: "fixed",
                    right: parseInt(margin),
                    top: "50%",
                    transform: "translate(0%, -50%)",
                }
            }
            if (position == 6) {
                return {
                    position: "fixed",
                    right: parseInt(margin),
                    bottom: baseMargin,
                }
            }
            if (maxCountOfMates == 9) {
                if (position == 7) {
                    return {
                        position: "fixed",
                        left: "50%",
                        bottom: baseMargin,
                        transform: "translate(-50%, 0%)",
                    }
                }
                if (position == 8) {
                    return {
                        position: "fixed",
                        left: parseInt(margin),
                        bottom: baseMargin,
                    }
                }
                if (position == 9) {
                    return {
                        position: "fixed",
                        left: parseInt(margin),
                        top: "50%",
                        transform: "translate(0%, -50%)",
                    }
                }
            } else if (maxCountOfMates == 10) {
                if (position >= 6 && position <= 9) {
                    return {
                        position: "fixed",
                        right: parseInt(margin) * (position - 5) + VIDEO_BOX_WIDTH * (position - 6) + "px",
                        bottom: baseMargin,
                    }
                }
                // 10th position
                return {
                    position: "fixed",
                    left: parseInt(margin) + "px",
                    top: "50%",
                    transform: "translate(0%, -50%)",
                }
            }
        }
        return {
            position: "fixed",
            right: "10px",
            bottom: baseMargin,
        }
    }
}
import { Button, Container, Modal, Stack, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import RatingPage from "./RatingPage/RatingPage"




function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`ratings-tabpanel-${index}`}
        aria-labelledby={`ratings-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

function a11yProps(index) {
    return {
      id: `ratings-tab-${index}`,
      'aria-controls': `ratings-tabpanel-${index}`,
    };
  }

const Ratings = () => {
    const [tabValue, setTabValue] = useState(0);

	const handleChange = (event, newValue) => {
		setTabValue(newValue);
	};

    return (
        <Box
            sx={{
                minHeight: '100vh',
                bgcolor: 'background.default',
                color: 'text.primary',
            }}
        >

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} style={{width: "100%"}}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                    <Tab label="Все" {...a11yProps(0)} />
                    <Tab label="Общение" {...a11yProps(1)} />
                    <Tab label="Hackword" {...a11yProps(2)} /> 
                    <Tab label="Я живое?" {...a11yProps(3)} />
                    <Tab label="Обобщение" {...a11yProps(4)} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                <RatingPage ratingType={"all"}></RatingPage>                
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <RatingPage ratingType={"talk"}></RatingPage>                            
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <RatingPage ratingType={"hackword"}></RatingPage>                                
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
                <RatingPage ratingType={"iamalive"}></RatingPage>
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
                <RatingPage ratingType={"generalize"}></RatingPage>
            </TabPanel>                                    
        </Box>
    )
}

export default Ratings;

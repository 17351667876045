import { Button, Container, Grid, Modal, Pagination, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import CommunityItem from './CommunityItem/CommunityItem';
import CommunitiesService from '../../services/CommunitiesService';
import AvatarImg from '../../assets/images/avatar.png';
import AvatarImg2 from '../../assets/images/avatar2.png';
import AvatarImg3 from '../../assets/images/2.jpg';
import { FormattedMessage } from 'react-intl';
import ModalStyle, { ModalStyleXL } from '../../utils/ModalStyle/ModalStyle';
import ModalClose from '../../utils/ModalStyle/ModalClose';
import { useNavigate } from 'react-router-dom';
import EventItem from './EventItem/EventItem';
import CreateCommunityForm from './CreateCommunityForm/CreateCommunityForm';
import {SuccessMessage} from "../../constants";
import {domainNameAPI, protocolAPI, apiPort} from "../../environment"
import { makeString } from '../../utils/common';
import {OpenCreateCommunity} from "./atoms/Common";
import {useRecoilState} from "recoil";
import {ruTypeTime} from "../../utils/DateUtils"
import {ThemeAtom} from "../atoms/Common";
import {Authorized} from "../atoms/AuthAtoms"
import {UserInfoAtom,} from "../atoms/UserAtoms"

const mockData = [
	{
		onClick: () => {},
		icon: AvatarImg,
		title: "Клуб Любителей пива",
		countUser: 10
	},
	{
		onClick: () => {},
		icon: AvatarImg2,
		title: `Клуб Кинокритиков "Пальмовая ветвь"`,
		countUser: 15
	},
	{
		onClick: () => {},
		icon: AvatarImg2,
		title: `Клуб Кинокритиков "Пальмовая ветвь"`,
		countUser: 15
	},
	{
		onClick: () => {},
		icon: AvatarImg3,
		title: "Клуб Любителей пива",
		countUser: 10
	},
	{
		onClick: () => {},
		icon: AvatarImg,
		title: "Клуб Любителей пива",
		countUser: 10
	},
	{
		onClick: () => {},
		icon: AvatarImg2,
		title: `Клуб Кинокритиков "Пальмовая ветвь"`,
		countUser: 15
	},
	
];

const NumberOfCommunitiesOnPage = 6

const Communities = () => {
	const [openMyCommunities, setOpenMyCommunities] = useState(false);
	const [openMyEvents, setOpenMyEvents] = useState(false);
	const [communities, setCommunities] = useState([]);
	const [myCommunities, setMyCommunities] = useState([]);
	const [ownCommunities, setOwnCommunities] = useState([]);
	const [myClan, setMyClan] = useState(null);
	const [myEvents, setMyEvents] = useState([]);
	const [pagesCount, setPagesCount] = useState(1);
	const [page, setPage] = useState(1);

	const [openCreateCommunity, setOpenCreateCommunity] = useRecoilState(OpenCreateCommunity);
	const [themeAtom, setThemeAtom] = useRecoilState(ThemeAtom);
	const [authorized, setAuthorized] = useRecoilState(Authorized);
	const [userInfo, setUserInfo] = useRecoilState(UserInfoAtom);

	const navigate = useNavigate();
  
	const handleOpen = () => {
    	setOpenMyCommunities(true);
		CommunitiesService.myCommunities().then((response)=>{
			if (response.data.Success == SuccessMessage) {				
				if (response.data.Communities.Clan && response.data.Communities.Clan.ID > 0) {
					setMyClan({
						onClick: () => {navigate("/community/"+response.data.Communities.Clan.ID)},
						icon: apiUrl+"/mkstatic/shared_communities/icons/"+response.data.Communities.Clan.Icon+"?dop"+makeString(5),
						communityType: response.data.Communities.Clan.CommunityType,
						title: response.data.Communities.Clan.Name,
						countUser: response.data.Communities.Clan.CountOfUsers,
					})
				}
				let ownComms = []
				for (let c of response.data.Communities.Own) {
					ownComms.push({
						onClick: () => {navigate("/community/"+c.ID)},
						icon: apiUrl+"/mkstatic/shared_communities/icons/"+c.Icon+"?dop"+makeString(5),
						communityType: c.CommunityType,
						title: c.Name,
						countUser: c.CountOfUsers,
					})
				}
				setOwnCommunities(ownComms)				
				let comms = []
				for (let c of response.data.Communities.Member) {
					comms.push({
						onClick: () => {navigate("/community/"+c.ID)},
						icon: apiUrl+"/mkstatic/shared_communities/icons/"+c.Icon+"?dop"+makeString(5),
						communityType: c.CommunityType,
						title: c.Name,
						countUser: c.CountOfUsers,
					})
				}
				setMyCommunities(comms)
			}
		});
  	};

  	const handleCloseMyCommunities = () => {
    	setOpenMyCommunities(false);
  	};

	const handleOpenEvents = () => {
    	setOpenMyEvents(true);
		CommunitiesService.myEvents().then((response)=>{
			if (response.data.Success == SuccessMessage) {
				setMyEvents(response.data.Events.Events)
			}
		})
  	};

  	const handleCloseMyEvents = () => {
   	 setOpenMyEvents(false);
  	};

	const handleOpenCreateCommunity = () => {
		if (userInfo.UserInfo && userInfo.UserInfo.AccountType == "standart") {
			navigate("/subscription")
		} else {
			setOpenCreateCommunity(true)
		}
	};

	const handleCloseCriateCommunity = () => {
		setOpenCreateCommunity(false)
	};

	const pagesChange = (e, v)=>{
		setPage(v)
	}

	let apiUrl = protocolAPI+domainNameAPI+apiPort
    useEffect(() => {
        CommunitiesService.getCommunitiesList(page, NumberOfCommunitiesOnPage).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				let comms = []
				for (let c of response.data.Communities.Communities) {
					comms.push({
						onClick: () => {navigate("/community/"+c.ID)},
						icon: apiUrl+"/mkstatic/shared_communities/icons/"+c.Icon+"?dop"+makeString(5),
						communityType: c.CommunityType,
						title: c.Name,
						countUser: c.CountOfUsers,
					})
				}
				setCommunities(comms)
				let pagesCount = (response.data.Communities.Count - response.data.Communities.Count % NumberOfCommunitiesOnPage) / NumberOfCommunitiesOnPage
				if (response.data.Communities.Count % NumberOfCommunitiesOnPage > 0) {
					pagesCount = pagesCount + 1
				}
				setPagesCount(pagesCount)
			}			
        })
    }, [page, openCreateCommunity])

	return (
		<Box
			sx={{
				minHeight: '100vh',
				bgcolor: 'background.default',
				color: 'text.primary',
			}}
		>
			<Container maxWidth="lg">
				<Stack spacing={4} paddingY={3}>
					<Grid
						container
						spacing={4}
						columns={{ xs: 4, sm: 12, md: 12}}
						justifyContent="space-between"
						alignItems="center"
					>
						<Grid item xs={4} sm={8}>
							<Stack spacing={2}>
								<Typography variant='h4'>
									<FormattedMessage id="communities_title" />
								</Typography>

								<Typography variant='body1'>
									<FormattedMessage id="communities_desc" />
								</Typography>
							</Stack>							
						</Grid>

						{authorized.Authorized ? 
							<Grid item xs="auto">
								<Button variant="contained" size="large" onClick={handleOpenCreateCommunity}>
									<FormattedMessage id="create_community" />
								</Button>
							</Grid> : null}
					</Grid>

					{authorized.Authorized ? 
						<Stack direction={{xs: 'column', sm: 'row'}} spacing={2} justifyContent="flex-end">
							<Button variant="outlined" size="medium" onClick={handleOpen}>
								<FormattedMessage id="my_communities" />
							</Button>

							<Button variant="outlined" size="medium" onClick={handleOpenEvents}>
								<FormattedMessage id="my_events" />
							</Button>
						</Stack>
					: null}


					<Typography variant="h5">
						<FormattedMessage id="communities" /> МногоКомнат
					</Typography>

					<Box>
						<Grid
							container
							spacing={4}
							columns={{ xs: 4, sm: 12, md: 12}}
						>
							{communities.map((item, index) => (
								<Grid item xs={4} sm={12} md={6} key={index}>
									<CommunityItem
										onClick={item.onClick}
										icon={item.icon}
										type={item.communityType}
										title={item.title}
										countUser={item.countUser}
									/>
								</Grid>
							))}
						</Grid>
					</Box>

					
					<Box style={{display: 'flex', justifyContent: 'center'}}>
						<Pagination count={pagesCount} shape="rounded" page={page} onChange={pagesChange}/>
					</Box>					
				</Stack>
			</Container>	

			<Modal
        open={openMyCommunities}
        onClose={handleCloseMyCommunities}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...ModalStyleXL, height: 400 }}>
					<ModalClose onClose={handleCloseMyCommunities}/>
					<Grid
						container
						spacing={4}
						columns={{ xs: 4, sm: 12, md: 12, lg: 12}}
					>
						{myClan != null && <><Grid item xs={4} sm={12} md={2} lg={2}>
							<Typography variant='h6'>
								<FormattedMessage id="clan" />
							</Typography>
						</Grid> 
						<Grid item xs={4} sm={12} md={10} lg={10}>
							<CommunityItem
								onClick={myClan.onClick}
								icon={myClan.icon}
								type={myClan.communityType}
								title={myClan.title}
								countUser={myClan.countUser}
							/>
						</Grid></>}

						{ownCommunities.length > 0 && <><Grid item xs={4} sm={12} md={12} lg={12}>
							<Typography variant='h6'>
								<FormattedMessage id="you_own" />
							</Typography>
						</Grid> 
						{ownCommunities.map((item, index) => (
							<Grid item xs={4} sm={12} md={12} lg={6} key={index}>
									<CommunityItem
										onClick={item.onClick}
										icon={item.icon}
										type={item.communityType}
										title={item.title}
										countUser={item.countUser}
									/>
							</Grid>
						))}</>}

						{myCommunities.length > 0 && <><Grid item xs={4} sm={12} md={12} lg={12}>
							<Typography variant='h6'>
								<FormattedMessage id="you_are_member" />
							</Typography>
						</Grid> 
						{myCommunities.map((item, index) => (
							<Grid item xs={4} sm={12} md={12} lg={6} key={index}>
									<CommunityItem
										onClick={item.onClick}
										icon={item.icon}
										type={item.communityType}
										title={item.title}
										countUser={item.countUser}
									/>
							</Grid>
						))}</>}
						{myClan == null && ownCommunities.length == 0 && myCommunities.length == 0 && 
							<Grid item xs={4} sm={12} md={12} lg={12}>
								<Typography variant='h6'>
									<FormattedMessage id="you_are_not_member" />
								</Typography>
							</Grid> 
						}
					</Grid>
        </Box>
      </Modal>

			<Modal
        open={openMyEvents}
        onClose={handleCloseMyEvents}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
				<Box sx={{ ...ModalStyleXL, height: 400 }}>
					<ModalClose onClose={handleCloseMyEvents}/>
					<Stack
						direction="column"
						spacing={3}
					>
						<Typography variant='h4'>
							<FormattedMessage id="my_events" />
						</Typography>

						{myEvents.map((item, index) => (
									<EventItem
										key={index}
										onClick={() => navigate('/event/'+item.CommunityID+"/"+item.ID)}
										date={ruTypeTime(item.Datetime)}
										icon={"/themes/"+themeAtom.theme+"/imgs/features/"+item.EventType+".png"}
										name={item.Name}
										roomSize={item.MaxUsers}
										users={item.JoinedUsers}
									/>
								))}	
					</Stack>
        </Box>
			</Modal>

			<Modal
        open={openCreateCommunity}
        onClose={handleCloseCriateCommunity}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
				<Box sx={{ ...ModalStyle, }}>
					<ModalClose onClose={handleCloseCriateCommunity}/>
					<CreateCommunityForm />
       			</Box>
			</Modal>
		</Box>
	)
};

export default Communities;

import { Box,  IconButton, Menu, MenuItem,  Modal, Snackbar, Alert, } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {FormattedMessage} from "react-intl";
import React, { useState, useEffect } from 'react';
import SpectateWarningModal from './Modals/SpectateWarningModal';
import ModalStyle from '../../../utils/ModalStyle/ModalStyle';
import ModalClose from "../../../utils/ModalStyle/ModalClose"




function MyBoxMenu(props) {
    const [openSpectateWarningModal, setOpenSpectateWarningModal] = useState(false)
    const [anchorElNav, setAnchorElNav] = useState(null);
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);        
    };


    const spectateClick = ()=>{
      setOpenSpectateWarningModal(true)
    }

    let style = {
        flexGrow: 1,
        display: { xs: 'flex' },
        position: "absolute",
        top: "5%",
        right: "3%",
        zIndex: 201,
    }
    return(
          <Box sx={style}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              style={{backgroundColor: "rgba(255, 255, 255, 0.5)", width: "40px", height: "40px"}}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', },
                color: 'inherit',
              }}
            >
                {!props.isAdmin && <MenuItem key={1} onClick={spectateClick}>
                    <FormattedMessage id="spectate"/>
                </MenuItem>}
            </Menu>

            <Modal
                open={openSpectateWarningModal}
                onClose={()=>{setOpenSpectateWarningModal(false)}}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...ModalStyle, width: 350 }}>
                    <ModalClose onClose={()=>{setOpenSpectateWarningModal(false)}}/>
                    <SpectateWarningModal closeModal={()=>{setOpenSpectateWarningModal(false)}} />
                </Box>
            </Modal>                         
          </Box>
    )
}

export default MyBoxMenu;
import React, {useState, useEffect, useRef} from 'react';
import { Divider, IconButton, Stack, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import './flat-chat.scss';
import FlatChatMsg from './FlatChatMsg/FlatChatMsg';
import ChatController from './ChatController';
import {sendMsgChat} from "./ChatController"
import {useRecoilState,} from 'recoil';
import {ChatWS} from "./atoms/Connection"
import {ChatMessagesMap, NewMessagesCount, NotWatchedPersonalChats} from "./atoms/Chat"
import {MaxChatMessageLength, DefaultLang} from "../../../constants"
import {FormattedMessage} from "react-intl";
import TokenService from "../../../services/TokenService"
import {Authorized, OpenAuth} from "../../atoms/AuthAtoms"
import {ruOnlyTime} from "../../../utils/DateUtils";
import { Box } from '@mui/system';
import {OpenedPersonalChats} from "../../atoms/UserAtoms"
import {numOfWidthSlots, } from "../../../utils/common"
import {MiniChatWidth, MiniChatMobileWidth} from "../../../constants"
import {addToNewChats} from "../SmallChat/scutils"
import {useLocation} from "react-router-dom"
import {checkForMate} from "../../../utils/TextFilters"

const FlatChat = (props) => {
	let messagesEndRef = useRef(null)
	let chatContainerRef = useRef(null)

	const [chatWS, setChatWS]  = useRecoilState(ChatWS);
	const [chatMessagesMap, setChatMessagesMap]  = useRecoilState(ChatMessagesMap);
    const [authorized, setAuthorized] = useRecoilState(Authorized);
	const [openAuth, setOpenAuth] = useRecoilState(OpenAuth);
    const [newMessagesCount, setNewMessagesCount] = useRecoilState(NewMessagesCount);

	const [notWatched, setNotWatched] = useRecoilState(NotWatchedPersonalChats);
    const [openedPersonalChats, setOpenedPersonalChats] = useRecoilState(OpenedPersonalChats);

	const location = useLocation();

	const [messageError, setMessageError] = useState(null)
	const [message, setMessage] = useState("")
	const [showScroll, setShowScroll] = useState(false)
	useEffect(()=> {
		if (location.pathname.startsWith("/room/")) {
			return
		}
		// OPEN AS MUCH AS WE CAN
		let mobile = false
		if (window.matchMedia("(max-width: 899px)").matches) {
			mobile = true
		}
		let slots = numOfWidthSlots(mobile ? MiniChatMobileWidth : MiniChatWidth);
		let newChats = [...openedPersonalChats]
		for (let notWatchedPerson of notWatched) {
			let alreadyOpened = false
			let i = 0;
			while(i < openedPersonalChats.length) {
				if (openedPersonalChats[i].occasionType == "personal" && openedPersonalChats[i].occasionID == notWatchedPerson.UserID) {
					alreadyOpened = true;
				}
				i++
			}
			if (!alreadyOpened) {
				newChats = addToNewChats(notWatchedPerson.UserID, notWatchedPerson.Nickname, notWatchedPerson.Icon, mobile ? false : true, newChats, slots)
			}
		}
		if (openedPersonalChats.length < newChats.length) {
			setOpenedPersonalChats(newChats)
		}

		let i = 0;
		let newNotWatched = [...notWatched]
		while(i < openedPersonalChats.length) {
			if (i < slots && openedPersonalChats[i].opened) {
				let j = 0;
				while(j < newNotWatched.length) {
					if (openedPersonalChats[i].occasionType == "personal" && newNotWatched[j].UserID == openedPersonalChats[i].occasionID) {
						sendMsgChat(chatWS.ws, "Watched", {OccasionType: openedPersonalChats[i].occasionType, OccasionID: parseInt(openedPersonalChats[i].occasionID)})
						newNotWatched.splice(j, 1)
					}
					j++
				}
			}
			i++
		}
		if (notWatched.length != newNotWatched.length) {
			setNotWatched(newNotWatched)
		}		
	}, [notWatched, openedPersonalChats, location])

	const sendMessage = ()=>{
		if (!authorized.Authorized) {
			setOpenAuth({Open: true});
			return
		}
		if (message.length == 0) {
			setMessageError(<span><FormattedMessage id="cantbeempty"/></span>)
			return
		}
		if (messageError != null) {
			return
		}	
		if (message.length <= MaxChatMessageLength) {
			sendMsgChat(chatWS.ws, "SendMessage", {Message: message, OccasionID: props.occasionID, OccasionType: props.occasionType})
			setMessage("")
			setMessageError(null)
			if (props.occasionType == "banned") {
				sendMsgChat(chatWS.ws, "GetBannedMessages", {})
			}
		} else {
			setMessageError(<span><FormattedMessage id="cantbelonger"/> {MaxChatMessageLength}</span>)
		}
	}
	useEffect(()=> {
		let currChatMessagesMap = new Map(chatMessagesMap)
		currChatMessagesMap.set(props.occasionType+props.occasionID, [])
        setChatMessagesMap(currChatMessagesMap)
		if (props.occasionType == "banned" && chatWS.ws != null) {
			let updateCheckCurrent = setInterval(() => {
				if (chatWS.ws != null) {
					sendMsgChat(chatWS.ws, "GetBannedMessages", {})
				}
			}, 120*1000)
		}
    }, [chatWS]);
	useEffect(()=> {
		let chatMessages = chatMessagesMap.get(props.occasionType+props.occasionID)
		if (!chatMessages) {
			chatMessages = []
		}
		if (chatMessages.length > 0 && !showScroll) {
			//messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
			chatContainerRef.current?.scrollTo({left: 0, top: chatContainerRef.current?.scrollHeight, behavior: "smooth"});
		}
	},[chatMessagesMap])
	const handleScrollMessages = (e)=>{
		let scrollBottom = e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.clientHeight;
		if (scrollBottom > 40) {
			if (!showScroll) {
				setNewMessagesCount(0)
			}
			setShowScroll(true)
		} else {
			setShowScroll(false)
		}
	}
	const scrollDownClick= ()=>{
		chatContainerRef.current?.scrollTo({left: 0, top: chatContainerRef.current?.scrollHeight, behavior: "smooth"});
	}
	const handleKeyDown = (e)=>{
		if(e.key === 'Enter' && !e.shiftKey){
			sendMessage()
			e.preventDefault()
		}
	}
	let chatMessages = chatMessagesMap.get(props.occasionType+props.occasionID)
	if (!chatMessages) {
		chatMessages = []
	}
	return (
		<Stack
			className='flat-chat'
			direction="column" useFlexGap 
			style={{height: props.fullHeight ? "100%" : undefined, zIndex: 299, display: props.hide ? "none" : undefined}}
		>
			<ChatController key={props.occasionType+props.occasionID} occasionID={props.occasionID} occasionType={props.occasionType}></ChatController>
			<Stack padding={1} spacing={1} className="chat__body" style={{height: props.fullHeight ? "100%" : "260px"}} direction="column" alignItems="flex-start" onScroll={handleScrollMessages} ref={chatContainerRef}>
				{chatMessages.map((item, index) => (
					<FlatChatMsg
						isSystem={item.isSystem}
						name={item.Nickname}
						msg={item.Message}
						time={ruOnlyTime(item.Datetime)}
						key={index}
					/>
				))}
				{showScroll ? <div style={{cursor: "pointer", position: "sticky", left: "200px", padding: "5px", bottom: "0px", color: "white", backgroundColor: "rgba(99, 99, 99, 0.4)", borderRadius: "10px"}} onClick={scrollDownClick}><FormattedMessage id="sroll_down"/>{newMessagesCount > 0 ? <span> ({newMessagesCount} <FormattedMessage id="new_msgs"/>)</span> : null}</div> : null}
				<div ref={messagesEndRef}>
       			</div>
			</Stack>			
			
			<Divider />

			<Stack direction="row" spacing={1} paddingX={1} paddingY={1} alignItems="flex-start">				
				<TextField
					multiline
					maxRows={4}
					fullWidth
					value={message}
					onKeyDown={(e) => handleKeyDown(e)}
					onChange={(e)=>{
						setMessage(e.target.value)
						if (e.target.value.length > MaxChatMessageLength) {
							setMessageError(<span><FormattedMessage id="cantbelonger"/> {MaxChatMessageLength}</span>)
							return
						}
						if (checkForMate(DefaultLang, e.target.value)) {
							setMessageError(<span><FormattedMessage id="cant_use_mate"/></span>)
							return		
						} else {
							setMessageError(null)
						}						
						setMessageError(null)
					}}
					helperText={messageError}
					error= {messageError != null}
        		/>

				<IconButton aria-label="delete" onClick={sendMessage}>
					<SendIcon />
				</IconButton>
			</Stack>
		</Stack>
	);
};

export default FlatChat;
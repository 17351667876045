import React, { useState, useRef, useEffect } from 'react';
import {
    useRecoilState,
  } from 'recoil';
  import {Theme, RoomInfo} from '../../atoms'


function SoundLevelChanger(props) {
	const [borders, setBorders] = useState({Name: "none"});

    const [roomInfo, setRoomInfo] = useRecoilState(RoomInfo);  
    useEffect(() => {
		for (let nickname in roomInfo.roomMates) {
			if (roomInfo.roomMates[nickname].Position == props.position && roomInfo.roomMates[nickname].Borders) {
				let borders = roomInfo.roomMates[nickname].Borders
				setBorders(borders)
			}
		}
	}, [roomInfo])

    const [theme, _] = useRecoilState(Theme);
    let [level, setLevel] = useState(props.level);
    let [showPanel, setShowPanel] = useState(false);
    const panelRef = useRef();
    let [flagControl, setFlagControl] = useState(false);

    if (props.audioTrack) {
        props.audioTrack.setVolume(level / 100);
    } else {
        console.log("Has no volume track")
    }

    let soundStyle = {
        position: "absolute",
        bottom: borders.Name != "none" ? "22%" : "19%",
        left: borders.Name != "none" ? "6.5%" : "3.5%",
        zIndex: 201,
    }
    const soundImgSrc = "/themes/"+theme.name+"/imgs/box/sound_on_dop.png";
    let panelWidth = 9.3;
    let levelLineWidth = 8;
    let levelLineRight = 0.5;
    let changerPanelStyle = {
        height: "1.7rem",
        width: panelWidth+"rem",
        position: "absolute",
        left: "1.4rem",
        top: "0rem",
        //backgroundColor: "#505050",
        backgroundColor: "black",
        opacity: "0.7",
        borderRadius: "0rem 0.5rem 0.5rem 0rem",
        zIndex: 201,
    }
    if (!showPanel) {
        changerPanelStyle.display = "none";
    }
    let levelLineStyle = {
        height: "0.25rem",
        width: levelLineWidth+"rem",
        position: "absolute",
        right: levelLineRight+"rem",
        top: "0.725rem",
        backgroundColor: "white",
        borderRadius: "0.5rem",
        zIndex: 201,
    }
    let levelFlagStyle = {
        height: "1rem",
        width: "0.3rem",
        backgroundColor: "#468af0", 
        position: "absolute",
        top: "-0.375rem",
        left: level+"%",
        borderRadius: "0.5rem",
        zIndex: 201,   
    }
    let levelStyle = {
        height: "0.25rem",
        width: level+"%",
        position: "absolute",
        left: "0rem",
        top: "0rem",
        backgroundColor: "#468af0",
        borderRadius: "0.5rem 0rem 0rem 0.5rem",
        zIndex: 201,
    }
    let setLevelClick = (e)=>{
        e.preventDefault();
        var rect = panelRef.current.getBoundingClientRect();
        var x = e.clientX - rect.left; //x position within the element.
        var y = e.clientY - rect.top;  //y position within the element.
        let panelWidthPX = parseFloat(panelWidth)*parseFloat(getComputedStyle(document.documentElement).fontSize);
        let leftMargin = (parseFloat(panelWidth)-parseFloat(levelLineWidth)-parseFloat(levelLineRight))*parseFloat(getComputedStyle(document.documentElement).fontSize);
        let levelLineWidthPX = parseFloat(levelLineWidth)*parseFloat(getComputedStyle(document.documentElement).fontSize);
        let level = ((x-leftMargin) / levelLineWidthPX) * 100;
        if (level < 0) {
            level = 0;
        }
        if (level > 100) {
            level = 100;
        }
        setLevel(level);
    }
    let mouseOverHandler = (e)=>{
        setShowPanel(true);
    }
    let mouseLeaveHandler = (e)=>{
        setShowPanel(false);
        setFlagControl(false)
    }

    const mouseMove = (e)=>{
        if (flagControl) {
            e.preventDefault();
            var rect = panelRef.current.getBoundingClientRect();
            var x = e.clientX - rect.left; //x position within the element.
            var y = e.clientY - rect.top;  //y position within the element.
            let panelWidthPX = parseFloat(panelWidth)*parseFloat(getComputedStyle(document.documentElement).fontSize);
            let leftMargin = (parseFloat(panelWidth)-parseFloat(levelLineWidth)-parseFloat(levelLineRight))*parseFloat(getComputedStyle(document.documentElement).fontSize);
            let levelLineWidthPX = parseFloat(levelLineWidth)*parseFloat(getComputedStyle(document.documentElement).fontSize);
            let level = ((x-leftMargin) / levelLineWidthPX) * 100;
            if (level < 0) {
                level = 0;
            }
            if (level > 100) {
                level = 100;
            }
            setLevel(level);
        }
    }
    const flagMouseDown = ()=>{
        setFlagControl(true)
    }
    const flagMouseUp = ()=>{
        setFlagControl(false)
    }
    return (
        <div style={soundStyle}><img style={{width: "1.7rem", height: "1.7rem",}} src={soundImgSrc} onMouseEnter={mouseOverHandler} onMouseLeave={mouseLeaveHandler}/>
            <div ref={panelRef} style={changerPanelStyle} onMouseMove={mouseMove} onClick={setLevelClick} onMouseLeave={mouseLeaveHandler} onMouseEnter={mouseOverHandler}>
                <div style={levelLineStyle}><div style={levelStyle}></div><div style={levelFlagStyle} onMouseDown={ flagMouseDown } onMouseUp={ flagMouseUp }></div></div>
            </div>
        </div>);
}

export default SoundLevelChanger;

import Api from "./Api"







const getRatingList = async function(ratingType, page, numberOfEntriesOnPage) {
    return await Api.post(`/ratings/getRating`, {RatingType: ratingType, Page: page, NumberOfEntriesOnPage: numberOfEntriesOnPage})
}

const getRatingUser = async function(ratingType, page, numberOfEntriesOnPage) {
    return await Api.post(`/rating/getRatingUser`, {RatingType: ratingType, Page: page, NumberOfEntriesOnPage: numberOfEntriesOnPage})
}

const RatingsService = {
    getRatingList,
    getRatingUser,
};
  
export default RatingsService;
import {  Typography, Box } from '@mui/material';
import {useRecoilState,} from 'recoil';
import {OpenedPersonalChats} from "../../atoms/UserAtoms"
import React, { useState, useEffect } from 'react';
import './small-chat.scss';
import { numOfWidthSlots } from '../../../utils/common';
import { MiniChatWidth } from '../../../constants';
import {NotWatchedPersonalChats} from "../FlatChat/atoms/Chat"



const Others = ()=>{
    const [openedPersonalChats, setOpenedPersonalChats] = useRecoilState(OpenedPersonalChats);
	const [notWatched, setNotWatched] = useRecoilState(NotWatchedPersonalChats);

    const [hovered, setHovered] = useState(false)
    const [notShowed, setNotShowed] = useState([])
    const [newMessages, setNewMessages] = useState(false)

    let chatsSlots = numOfWidthSlots(MiniChatWidth)

    useEffect(()=>{
        let i = 0;
        let toShow = []
        let styleTitle = {
            width: "150px",
            textOverflow: "ellipsis",
        }
        let newMessagesAll = false
        while (i < openedPersonalChats.length) {
            if (i > chatsSlots - 1) {
                let chooseChat = function(index) {
                        return ()=>{
                        let newChats = [...openedPersonalChats]
                        let lastIndexToSet = chatsSlots - 1;
                        let tempLastChat = newChats[lastIndexToSet]
                        console.log("ASASAS chooseChat", index, lastIndexToSet, chatsSlots, newChats)
                        newChats[lastIndexToSet] = newChats[index]
                        newChats[index] = tempLastChat
                        setOpenedPersonalChats(newChats)
                    }
                } (i)
                let newMessages = false
                for (let notWatchedChat of notWatched) {
                    if (openedPersonalChats[i].occasionType == "personal" && openedPersonalChats[i].occasionID == notWatchedChat.UserID) {
                        newMessages = true 
                        newMessagesAll = true                       
                        break
                    }
                }
                toShow.push(<Box
                    sx={{
                        bgcolor: 'background.default',
                        color: 'text.primary',
                        borderColor: 'text.primary',
                        border: "1px",
                      }}
                      class="other-chat"
                      onClick={chooseChat}
                >{newMessages && <span style={{color: "#f73636"}}> + </span>}{openedPersonalChats[i].title}</Box>)
            }
            i++
        }
        setNotShowed(toShow)
        setNewMessages(newMessagesAll)
    }, [openedPersonalChats])

    const mouseEnterHandler = ()=>{
        setHovered(true)
    }
    const mouseLeaveHandler = ()=>{
        setHovered(false)
    }
    return (
        <Box
        sx={{
          bgcolor: 'background.default',
          color: 'text.primary',
        }}
        style={{position: "fixed", bottom: 0, left: "20px", zIndex: 290, padding: "7px", borderRadius: "15px 15px 0px 0px", border: "1px solid black", width: "30px"}}
        onMouseEnter={mouseEnterHandler} onMouseLeave={mouseLeaveHandler}
      >
        {hovered && 
        <Box
        sx={{
            bgcolor: 'background.default',
            color: 'text.primary',
            width: "150px",
          }}   
        >
            {notShowed}
        </Box>}
        <Typography variant="h5">{newMessages ? <div style={{backgroundColor: "#f73636", borderRadius: "30px", width: "20px", height: "20px", marginLeft: "5px", textAlign: "center", verticalAlign: "middle", lineHeight: "20px"}}>+</div> : <>+</>}</Typography>
      </Box>
    )
}

export default Others
import { Avatar, Divider, IconButton, Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import SendIcon from '@mui/icons-material/Send';
import React, {useState, useRef, useEffect} from 'react';
import ChatMessage from './ChatMessage/ChatMessage';
import './chat.scss';
import { MaxChatMessageLength } from '../../constants';
import { timeOf } from '../common';

const Chat = ({
	messages, avatar, sendChatMsg
}) => {
	let messagesEndRef = useRef(null)
	useEffect(()=> {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);
	let [textToSend, setTextToSend] = useState("")
	const changeMessage = (event) => {
		setTextToSend(event.target.value);
	}
	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	}

	return (
		<Box className="chat">
			<Stack direction="row" spacing={2} paddingX={3} paddingY={1} alignItems="center">
				<Avatar alt="Remy Sharp" src={avatar} />
				
				<Stack spacing={-1}>
					<Typography variant='subtitle1'>
					</Typography>

					<Typography variant='caption' color="grey">
					</Typography>
				</Stack>
			</Stack>

			<Divider />

			<Stack padding={3} spacing={2} className="chat__body">
				{messages.map((msg, index) => 
					<ChatMessage
						self={msg.self}
						avatar={msg.UserID}
						message={msg.Message}
						time={timeOf(msg.Time)}
						key={index}
					/>
				)}
				<div ref={messagesEndRef}>
       			</div>
			</Stack>			
			
			<Divider />

			<Stack direction="row" spacing={2} paddingX={3} paddingY={1} alignItems="flex-start">
				<Avatar alt="Remy Sharp" src={avatar} />
				
				<TextField
         			id="outlined-multiline-flexible"
          			multiline
          			maxRows={4}
					fullWidth
					value={textToSend}
					onChange={changeMessage}
        		/>

				<IconButton aria-label="delete" onClick={(ev)=>{
					if (textToSend.length < MaxChatMessageLength) {
						setTextToSend("")
						scrollToBottom()
					}
					sendChatMsg(ev, textToSend)}}>
					<SendIcon />
				</IconButton>
			</Stack>
		</Box>
	)
};

export default Chat;
import {
    atom,
} from 'recoil';



export const ThemeAtom = atom({
    key: 'ThemeAtom',
    default: {
        theme: "default",
    },
})

export const SoundTheme = atom({
    key: 'SoundThemeAtom',
    default: {
        theme: "default",
    },
})

export const UseSoundAtom = atom({
    key: 'UseSoundAtom',
    default: true,
})

export const SoundLevelAtom = atom({
    key: 'SoundLevelAtom',
    default: 0.25,
})

export const ShowFileTooBig = atom({
    key: 'ShowFileTooBig',
    default: {
        show: false
    },
})

export const ShowChangePassword = atom({
    key: 'ShowChangePassword',
    default: {
        show: false
    },
})

export const ShowPasswordChangedAlert = atom({
    key: 'ShowPasswordChangedAlert',
    default: {
        show: false
    },
})

export const BirthDayModal = atom({
    key: 'BirthDayModal',
    default: {
        Open: false
    },
})

export const NickNameModal = atom({
    key: 'NickNameModal',
    default: {
        Open: false
    },
})

export const ShowTooYoung = atom({
    key: 'ShowTooYoung',
    default: {
        show: false
    },
})

export const SetGamePasswordModal = atom({
    key: 'SetGamePasswordModal',
    default: {
        Open: false,
        EntityID: 0,
        GameName: "",
    },
})

export const RequestGamesChatOnlineInterval = atom({
    key: 'RequestGamesChatOnlineInterval',
    default: {
        interval: 0,
    },
})
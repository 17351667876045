import React from 'react';
import { Avatar, IconButton, Stack, Typography, Chip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import './user-item.scss';
import CommunitiesService from '../../../../services/CommunitiesService';
import {SuccessMessage} from "../../../../constants";
import { UpdateUsersList} from "../../atoms/Community";
import {useRecoilState} from "recoil";
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
	let initials = name.split(' ');

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: initials.length > 1 
			? `${initials[0][0].toUpperCase()}${initials[1][0].toUpperCase()}` 
			: name[0].toUpperCase(),
  };
}

const UserItem = ({
	communityID,
	userID,
	name,
	avatar,
	role,
	isRequest,
	myRole,
	me,
	closeTransferOwnership,
}) => {
	const navigate = useNavigate();

	const [updateUsersList, setUpdateUsersList] = useRecoilState(UpdateUsersList)
	const goToCard = ()=>{
        window.open('/user/'+name,'_blank')
     }
	const handleTransfer = ()=>{
		CommunitiesService.transferOwnership(communityID, userID).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				closeTransferOwnership()
			}
		})		
	} 	
	return (
		<Stack 
			className={me ? "me-user-item": "user-item"}
			direction="row" 
			alignItems="center"
			justifyContent="space-between"			
			padding={1}
			spacing={2}
		>
			<Stack 
				spacing={2} 
				direction="row" 
				alignItems="center"
				maxWidth="65%"
				onClick={goToCard}
			>
				{avatar ? (
					<Avatar alt={name} src={avatar} />
				) 
				: 
				(
					<Avatar {...stringAvatar(name)} />
				)}				

				<Typography variant="body2" noWrap>
					{name}
				</Typography>
			</Stack>

			<Stack 
				spacing={0} 
				direction="row" 
				alignItems="center"
			>
				<Chip label={<FormattedMessage id="transfer" />} color="error" variant="outlined" onClick={handleTransfer}></Chip> 				
			</Stack>
		</Stack>
	);
};

export default UserItem;
import {
	useRecoilState,
  } from 'recoil';
import React, { useEffect, useState } from 'react';
import './radial-chart.scss';
import { RoundSecondsPassed } from '../atoms/GameConfiguration';

const RadialChart = ({
	maxValue
}) => {
	const [roundSecondsPassed, setRoundSecondsPassed]  = useRecoilState(RoundSecondsPassed);

	const [value, setValue] = useState(maxValue - roundSecondsPassed.RoundSecondsPassed);
	const [isActive, setIsActive] = useState(true);
	let colors = ["#19cf6e", "#bdb82d", "#f24646"]
	const [color, setColor] = useState(colors[0]);
	const [colorText, setColorText] = useState(colors[0]);
	const strokeWidth = 3;

	let currentValue = value / (maxValue / 100);
	const radius = 15;
	let circumference = 2 * radius * Math.PI;		
	let percentValue = (currentValue / 100) * circumference;
	let strokeDasharray = percentValue + ' ' + circumference;

	useEffect(() => {
		let interval = null;
		if (roundSecondsPassed.Updated) {
			setValue(maxValue - roundSecondsPassed.RoundSecondsPassed)
			setRoundSecondsPassed({Updated: false, RoundSecondsPassed: 0})
		}

		if (value < (maxValue / 2)) {
			if (value < (maxValue / 4)) {
				setColor(colors[2])
				setColorText(colors[2])
			} else {
				setColor(colors[1])
				setColorText(colors[1])
			}
		}

		if(isActive && value > 0) {
			interval = setInterval(() => {
				setValue(value => value - 1);
			}, 1000);
		} else if(value <= 0) {
			setIsActive(false);
			clearInterval(interval);
		}		

    	return () => clearInterval(interval);
  	}, [isActive, value, roundSecondsPassed]);

	return (
		<div className="radial-chart">
			<svg viewBox="0 0 38 38" className="radial-chart__base">
				<circle 
					className="pie-chart__ring" 
					cx="19" 
					cy="19" 
					r={radius}
					fill="none" 
					stroke="#d2d3d4" 
					strokeWidth="3" 
				/>

				<circle
					className="radial-chart__value"
					cx="19"
					cy="19"
					r={radius}
					fill="none"
					stroke={color}
					strokeWidth={strokeWidth}
					strokeDasharray={strokeDasharray}
				/>

			
			</svg>
		</div>
	);
};

{/* <text
x="50%"
y="50%"
dominantBaseline="central"
textAnchor="middle"
className="radial-chart__text"
style={{
	fill: 'indianred',
	color: colorText,
}}
stroke={colorText}
>
{value}
</text>	 */}

export default RadialChart;
import UserService from "../../../../services/UserService"
import {SuccessMessage} from "../../../../constants";
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Avatar, IconButton, Stack, Typography, Chip } from '@mui/material';
import {domainNameAPI, protocolAPI, apiPort} from "../../../../environment"
import { FormattedMessage } from 'react-intl';
import {stringAvatar} from '../../../../utils/common';






const BlockedUsers = (props)=>{
    const [listLoaded, setListLoaded] = useState(false)
    const [blockedChatsList, setBlockedChatsList] = useState([])

	const navigate = useNavigate();

    useEffect(()=>{
        UserService.getUserBlockedChats().then((response)=>{
            if (response.data.Success == SuccessMessage) {
                setListLoaded(true)
                setBlockedChatsList(response.data.BlockedChats.BlockedChats)
            }
        })
    }, [])

    const goToCard = (name)=>{
		navigate('/user/'+name)
     }
    const handleUnblock = (index, userID)=>{
        UserService.unblockChat(userID).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                var newList = [...blockedChatsList]
                newList.splice(index, 1)
                setBlockedChatsList(newList)
            }
        })
    }

    let apiUrl = protocolAPI+domainNameAPI+apiPort
    return (
        <Box>
            {!listLoaded && <div style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)"}}><CircularProgress /></div>}

            <Typography variant='h6' style={{marginBottom: "12px"}}>
                <FormattedMessage id="blocked" />
            </Typography>

            {blockedChatsList.map((item, index) => (
                <Stack 
                    className={"user-item"}
                    direction="row" 
                    alignItems="center"
                    justifyContent="space-between"			
                    padding={1}
                    spacing={2}
                >
                    <Stack 
                        spacing={2} 
                        direction="row" 
                        alignItems="center"
                        maxWidth="65%"
                        onClick={()=>goToCard(item.Nickname)}
                    >
                        {item.Icon ? (
                            <Avatar alt={item.Nickname} src={apiUrl+"/mkstatic/shared/avatars/"+item.Icon+"?dopasdasda"} />
                        ) 
                        : 
                        (
                            <Avatar {...stringAvatar(item.Nickname)} />
                        )}				

                        <Typography variant="body2" noWrap>
                            {item.Nickname}
                        </Typography>
                    </Stack>

                    <Stack 
                        spacing={0} 
                        direction="row" 
                        alignItems="center"
                    >
                        <Chip label={<FormattedMessage id="unblock" />} color="error" variant="outlined" onClick={()=>handleUnblock(index, item.UserID)}></Chip> 				
                    </Stack>
                </Stack>
				))}
        </Box>
    )
}

export default BlockedUsers
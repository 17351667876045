import {
    atom,
} from 'recoil';

export const ChatMessagesMap = atom({
    key: 'ChatMessagesMap',
    default: new Map(),
})

export const ChatOnline = atom({
    key: 'ChatOnline',
    default: {
        online: null,
    },
})

export const NewMessagesCount = atom({
    key: 'FlatChatNewMessagesCount',
    default: 0,
})

export const NotWatchedPersonalChats = atom({
    key: 'NotWatchedPersonalChats',
    default: [],
})


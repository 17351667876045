import {Box, Button, Grid, TextField, Typography} from '@mui/material';
import {FormattedMessage} from "react-intl";
import React, {useState} from "react";
import { DatePicker } from '@mui/x-date-pickers';
import { ruRU } from '@mui/x-date-pickers/locales';
import UserService from "../../services/UserService";
import {BirthDayModal} from "../atoms/Common";
import {UserPersonalAtom} from "../atoms/UserAtoms";
import {useRecoilState} from "recoil";
import {SuccessMessage} from "../../constants";
import {removeTimezoneOffsetMomentJS} from "../../utils/DateUtils"
import moment from "moment"

const SetBirthDay = (props) => {
    const [birthDay, setBirthDay] = useState(moment("01.01.2000", 'DD.MM.YYYY'));

    const [birthDayModal, setBirthDayModal] = useRecoilState(BirthDayModal)
	const [userPersonal, setUserPersonal] = useRecoilState(UserPersonalAtom);

    const saveBirthDay = ()=>{
        UserService.setBirthDay(birthDay).then((response)=>{
            if (response.data.Success == SuccessMessage) {
                setUserPersonal({UserPersonal: {...userPersonal.UserPersonal, Birthday: {Valid: true, Time: response.data.SetBirthDay.BirthDay}}})
            }
            props.onClose()
            if (props.onActionAfter !== undefined) {
                props.onActionAfter()
            }
        }).catch((err)=>{
            props.onClose()
            if (props.onActionAfter !== undefined) {
                props.onActionAfter()
            }
        });
    }

    return(
        <Grid
            container
            rowSpacing={2}
            spacing={{ xs: 1, sm: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            direction="column"
            padding={1}
        >
            <Grid item xs={4} sm={8} md={12}>
                <Typography variant='h4'>
                    <FormattedMessage id="set_birth_day" />
                </Typography>
                <Typography variant='h6'>
                    <FormattedMessage id="only_one_time_to_do_it" />
                </Typography>                
            </Grid>

            <Grid item xs={4} sm={8} md={12}>
                <DatePicker
                        name="date"
                        format="DD.MM.YYYY"
                        dayOfWeekFormatter={(day) => `${day}`}
                value={birthDay} onChange={(newValue) => setBirthDay(removeTimezoneOffsetMomentJS(newValue))}
                localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}  />
            </Grid>

            <Grid item xs={4} sm={8} md={12}>
                <Button variant="contained" size="large" onClick={saveBirthDay}>
                    <FormattedMessage id="save" />
                </Button>
            </Grid>
        </Grid>
    )
}

export default SetBirthDay;
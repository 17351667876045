import React, { useState, useRef, useEffect } from 'react';
import {
    useRecoilState,
  } from 'recoil';
  import {Theme, RoomInfo} from '../../atoms'
  import UserService from '../../../services/UserService';
  import {SuccessMessage,} from "../../../constants";
  import {FormattedMessage} from 'react-intl'


function UserInfo(props) {
	const [borders, setBorders] = useState({Name: "none"});

    const [roomInfo, setRoomInfo] = useRecoilState(RoomInfo);  
    useEffect(() => {
		for (let nickname in roomInfo.roomMates) {
			if (roomInfo.roomMates[nickname].Position == props.position && roomInfo.roomMates[nickname].Borders) {
				let borders = roomInfo.roomMates[nickname].Borders
				setBorders(borders)
			}
		}
	}, [roomInfo])

    const [theme, _] = useRecoilState(Theme);
    let [userInfo, setUserInfo] = useState({Surname: "", Name: "", About: "", Phone: "", Birthday: "", Country: "", Region: "", City: ""});
    let [gotInfo, setGotInfo] = useState(false);
    let [showInfo, setShowInfo] = useState(false);

    let iStyle = {
        position: "absolute",
        top: borders.Name != "none" ? "8%" : "5%",
        left: borders.Name != "none" ? "6%" : "3%",
        zIndex: 301,
    }
    const infoImgSrc = "/themes/"+theme.name+"/imgs/box/info.png";
    let mouseOverHandler = (e)=>{
        console.log("Mouse Over");
        if (!gotInfo) {
            UserService.showUserInfo(props.userid).then((response)=>{
                if (response.data.Success == SuccessMessage) {
                    setGotInfo(true)
                    setUserInfo(response.data.UserPersonal)
                }
            })
        }
        setShowInfo(true);
    }
    let mouseLeaveHandler = (e)=>{
        console.log("Mouse Leave");
        setShowInfo(false);
    }

    const loadingImgSrc = "/themes/"+theme.name+"/imgs/icons/loading.jpg";

    let loadingStyle = {
        marginLeft: "auto", 
        marginRight: "auto",
        width: "60px",
        position: "absolute", 
        left: 0,
        right: 0,
        zIndex: 300,
    }
    let infoStyle= {
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        zIndex: 300,
        color: "black",
        backgroundColor: "white",
        opacity: 0.9,
        fontSize: "0.9rem",
    }
    let infotextStyle = {
        position: "absolute",
        top: "20%",
        padding: "5px",
        left: "50%", 
        width: "90%",
        transform: "translate(-50%, 0%)",
    }
    if (!showInfo) {
        infoStyle.display = "none";
    }
    let fromString = ""
    if (userInfo.Country !== "" || userInfo.Region !== "" || userInfo.City !== "") {
        fromString = <span><FormattedMessage id="from"/> {userInfo.City} {userInfo.Region} {userInfo.Country}</span>
    }
    let telString = ""
    if (userInfo.Phone != "") {
        telString = <span><FormattedMessage id="tel"/> {userInfo.Phone}</span>
    }
    let dohaveAnything = false;
    if (userInfo.Name !== "" || userInfo.Surname !== "" || userInfo.About !== "" || userInfo.Country !== "" || userInfo.Region !== "" || userInfo.City !== "" || userInfo.Phone != "") {
        dohaveAnything = true
    }
    if (window.matchMedia("(max-width: 899px)").matches && !props.modal) {
        iStyle.display = 'none'
    }
    return (<div style={{width: "100%", height: "100%"}}>
        <div style={iStyle}><img style={{width: "1.7rem", height: "1.7rem",}} src={infoImgSrc} onMouseEnter={mouseOverHandler} onMouseLeave={mouseLeaveHandler}/></div>
        <div style={infoStyle}>
            {
              gotInfo ? <div style={infotextStyle}>
                            {userInfo.Surname} {userInfo.Name}<br/>
                            {userInfo.About}<br/>
                            {fromString}<br/>
                            {telString}<br/>
                            {dohaveAnything ? <span></span> : <FormattedMessage id="havenoanything"/>}
                        </div>
              : <img style={loadingStyle} src={loadingImgSrc}/> 
            }
        </div>
    </div>)
}

export default UserInfo;
import {
	useRecoilState,
  } from 'recoil';
import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import {FormattedMessage} from "react-intl";
import './hackword-room-teams.scss';
import { TeamsState, HackwordGameSettings, WordsHistory, TeamToPlay } from '../atoms/GameConfiguration';
import {HackwordWS} from "../atoms/Connection"
import { useEffect } from 'react';
import { RoomInfo } from '../../../../../livekit_prod/atoms';
import { sendMsgHackword } from '../HackwordGameController';
import Headphones from '@mui/icons-material/Headphones';
import Mic from '@mui/icons-material/Mic';
import {ThemeAtom,} from "../../../../atoms/Common"

const HackwordRoomTeams = ({
	isAdmin, myPosition, imgsNames
}) => {
	const [teamsState, setTeamsState]  = useRecoilState(TeamsState);
    const [roomInfo, setRoomInfo] = useRecoilState(RoomInfo);
	const [gameSettings, setGameSettings]  = useRecoilState(HackwordGameSettings); 
	const [hackwordWS, setHackwordWS]  = useRecoilState(HackwordWS);
	const [wordsHistory, setWordsHistory]  = useRecoilState(WordsHistory);
	const [teamToPlay, setTeamToPlay]  = useRecoilState(TeamToPlay);


	const bgColors = ['#b42342', '#1f98db', '#ce93d8', '#ffcc80'] // 1 - f48fb1 2 - '#80cbc4'
	const [myTeamID, setMyTeamID] = useState(0)
	const [teams, setTeams] = useState([]);

	useEffect(()=>{
		let teamsToSet = []
		let pointsToAdd = wordsHistory.Words.reduce((a, b) => (b.Points + a), 0)
		for (let teamNum in teamsState.teams) {
			let team = teamsState.teams[teamNum]
			let users = []
			let i = 1
			for (let player of team.Players) {
				let nickName = player
				let foundPlayer = false
				for (let identity in roomInfo.roomMates) {
					if (roomInfo.roomMates[identity].Position == player) {
						nickName = identity
						foundPlayer = true
					}
				}
				if (!foundPlayer) {
					sendMsgHackword(hackwordWS.ws, "RefreshTeams", {})
				}
				let icon = ""
				for (let guessing of teamToPlay.Guess) {
					if (guessing == player) {
						icon = "Headphones"
					}
				}
				if (teamToPlay.Explains == player) {
					icon = "Mic"
				}
				users.push({
					id: player,
					name: nickName,
					icon: icon,
				})
				if (player == myPosition) {
					setMyTeamID(teamNum)
				}
				i++;
			}
			let toAdd = 0;
			if (teamToPlay.PointsTeam == teamNum) {
				toAdd = pointsToAdd
			}
			teamsToSet.push({
				id: teamNum,
				title: <FormattedMessage id="score" />,
				count: team.Points,
				users: users,
				toAdd: toAdd,
				background: bgColors[teamNum - 1],
			})
		}
		setTeams(teamsToSet)
	}, [teamsState, roomInfo, wordsHistory, teamToPlay])


	const [currentTeam, setCurrentTeam] = useState(null);
	const [currentUser, setCurrentUser] = useState(null);

	function dragStartHandler(e, team, user) {
		setCurrentTeam(team);
		setCurrentUser(user);
	}

	function dragEndHandler(e) {
		e.target.style.backgroundColor = '';
	}

	function dragOverHandler(e) {
		e.preventDefault();
		e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.15)';
	}

	function dropHandler(e, team, user) {
		e.preventDefault();
		e.stopPropagation();
		const currentIndex = currentTeam.users.indexOf(currentUser);
		currentTeam.users.splice(currentIndex, 1);
		const dropIndex = team.users.indexOf(user);
		team.users.splice(dropIndex + 1, 0, currentUser);

		setTeams(teams.map(item => {
			if(item.id === team.id) {
				return team;
			}

			if(item.id === currentTeam.id) {
				return currentTeam;
			}

			return item;
		}));

		e.target.style.backgroundColor = '';
	}

	function dragOverCardHandler(e) {
		e.preventDefault();
	}
	function dropCardHandler(e, team) {
		e.preventDefault();
		if (gameSettings.phase == "waitready" || gameSettings.phase == "playing") {
			return
		}
		team.users.push(currentUser);
		const currentIndex = currentTeam.users.indexOf(currentUser);
		currentTeam.users.splice(currentIndex, 1);
		sendMsgHackword(hackwordWS.ws, "MovePlayer", {PlayerPosition: currentUser.id, NewTeamID: parseInt(team.id)})

		setTeams(teams.map(item => {
			if(item.id === team.id) {
				return team;
			}

			if(item.id === currentTeam.id) {
				return currentTeam;
			}

			return item;
		}));

		e.target.style.backgroundColor = team.background;
	}
	const onCardClick = (e, teamID)=>{
		if (gameSettings.phase == "waitready" || gameSettings.phase == "playing") {
			return
		}
		if (myTeamID != teamID && myPosition != 0) {
			sendMsgHackword(hackwordWS.ws, "MovePlayer", {PlayerPosition: myPosition, NewTeamID: parseInt(teamID)})
		}
	}

	let teamProps = {};

	const [theme, _] = useRecoilState(ThemeAtom);


	return (
		<div className="hackword-room-teams" style={{marginTop: "10px"}}>
			<Grid container spacing={1}>
				{teams.map((team, index) => 
					<Grid 
						item 
						xs={6} 
						sm={3} 
						md={6} 
						lg={3} 
						key={team.id}
						className="hackword-room-teams__row"
					>
						<div 
							{...(
								isAdmin &&
								{
									onDragOver: (e) => dragOverCardHandler(e),
									onDrop: (e) => dropCardHandler(e, team)
								}
							)}
							className="hackword-room-teams__team"
							style={{ height: '220px', backgroundColor: team.background, position: "relative" }}
							onClick={(e)=> onCardClick(e, team.id)}
						>
							<Typography variant='h6' align="center">
								{team.title}: {team.count} {team.toAdd > 0 ? <>+{team.toAdd}</> : null}
							</Typography>

							{team.users.map((user, index) => 
								<div 
									{...(
										isAdmin &&
										{
											onDragStart: (e) => dragStartHandler(e, team, user),
											onDragLeave: (e) => dragEndHandler(e),
											onDragEnd: (e) => dragEndHandler(e),
											onDragOver: (e) => dragOverHandler(e),
											onDrop: (e) => dropHandler(e, team, user),
											draggable: true,
										}
									)}									
									style={{cursor: 'pointer', marginBottom: '4px', paddingLeft: '15px', position: "relative", zIndex: 100}}
									key={index}
								>
									<Grid container direction="row" alignItems="center">
										{(gameSettings.phase == "waitready" || gameSettings.phase == "playing") && <Grid item>
											{user.icon == "Headphones" ? <Headphones/>: null}
											{user.icon == "Mic" ? <Mic/>: null}
										</Grid>}
										<Grid item>
											<Typography variant='body2'>
												{user.name}
											</Typography>
										</Grid>
									</Grid>
								</div>	
							)}							
							<img style={{width: "100%", position: "absolute", bottom: "0px", left: "0px", zIndex: 1}} src={"/themes/"+theme.theme+"/imgs/games/hackword/"+imgsNames[index]}/>
						</div>
					</Grid>
				)}
			</Grid>
		</div>
	);
};

export default HackwordRoomTeams;
import {useRecoilState,} from 'recoil';
import {ShowRegisterGiftSubscription} from "../../atoms/UserAtoms";
import UserService from "../../../services/UserService";
import {SuccessMessage,} from "../../../constants";
import {FormattedMessage} from "react-intl";
import {Button, Typography, Grid} from '@mui/material';



const RegisterGiftSubscription = (props) => {
    const [showRegisterGiftSubscription, setShowRegisterGiftSubscription] = useRecoilState(ShowRegisterGiftSubscription)    

    let thankYou = ()=>{
        UserService.setShowState({...props.ShowState, ShowRegisterGiftSubscription: 1}).then((response)=>{
			if (response.data.Success == SuccessMessage) {
                setShowRegisterGiftSubscription({Open: false})
            }
        })
    }
    let imgStyle = {
        width: "400px",
    }
    if (window.matchMedia("(max-width: 600px)").matches) {
        imgStyle.width = "300px"
    }
    return (
        <Grid
            container
            rowSpacing={2}
            spacing={{ xs: 1, sm: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            direction="column"
            padding={1}
        >
            <Grid item xs={4} sm={8} md={12} style={{textAlign: "center"}}>
                <Typography variant='h4'>
                    <FormattedMessage id="register_present_title" />
                </Typography>
            </Grid>

            <Grid item xs={4} sm={8} md={12} style={{textAlign: "center"}}>
                <img src="/show_state/gift_subscription.jpg" style={imgStyle}/>
            </Grid> 

            <Grid item xs={4} sm={8} md={12}>
                <Typography variant='h6'>
                    <FormattedMessage id="register_present_text" />
                </Typography>
            </Grid> 

            <Grid item xs={4} sm={8} md={12} style={{textAlign: "center"}}>
                <Button variant="contained" size="large" onClick={thankYou}>
                    <FormattedMessage id="thankyou" />
                </Button>
            </Grid>      
        </Grid>
    )
}

export default RegisterGiftSubscription;
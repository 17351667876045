import {
    atom,
} from 'recoil';

export const CurrentRoom = atom({
    key: 'CurrentRoom',
    default: {
        HaveCurrent: false,
        RoomKey: "",
    },
})

export const CurrentlyInRoom = atom({
    key: 'CurrentlyInRoom',
    default: false,
})

export const ShowCurrentlyInRoom = atom({
    key: 'ShowCurrentlyInRoom',
    default: {
        theme: false,
    },
})

export const CheckCurrentInterval = atom({
    key: 'CheckCurrentInterval',
    default: {
        interval: 0,
    },
})

export const ListRoomsInterval = atom({
    key: 'ListRoomsInterval',
    default: {
        interval: 0,
    },
})

export const TalkRoomsList = atom({
    key: 'TalkRoomsList',
    default: {
        rooms: [],
    },
})
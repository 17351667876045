import React from 'react';
import { Avatar, IconButton, Stack, Typography, Chip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import './user-item.scss';
import CommunitiesService from '../../../services/CommunitiesService';
import {SuccessMessage} from "../../../constants";
import { UpdateUsersList} from "../atoms/Community";
import {useRecoilState} from "recoil";
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
	let initials = name.split(' ');

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: initials.length > 1 
			? `${initials[0][0].toUpperCase()}${initials[1][0].toUpperCase()}` 
			: name[0].toUpperCase(),
  };
}

const UserItem = ({
	communityID,
	userID,
	name,
	avatar,
	role,
	isRequest,
	myRole,
	me,
	openTransferOwnership,
}) => {
	const navigate = useNavigate();

	const [updateUsersList, setUpdateUsersList] = useRecoilState(UpdateUsersList)
	const banUser = ()=>{
		CommunitiesService.banUser(userID, communityID).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				setUpdateUsersList(updateUsersList+1)
			}
		})
	}
	const declineUserRequest = ()=>{
		CommunitiesService.declineRequest(userID, communityID).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				setUpdateUsersList(updateUsersList+1)
			}
		})		
	}
	const acceptUserRequest = ()=>{
		CommunitiesService.acceptRequest(userID, communityID).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				setUpdateUsersList(updateUsersList+1)
			}
		})		
	}
	const goToCard = ()=>{
		navigate('/user/'+name)
     }
	const handleLeave = ()=>{
		CommunitiesService.leaveCommunity(communityID).then((response)=>{
			if (response.data.Success == SuccessMessage) {
				navigate("/communities")
			}
		}).catch((err)=>{
			if (err.response.data.ErrorCode == 2) {
				openTransferOwnership()
			}			
		})		
	} 	
	return (
		<Stack 
			className={me ? "me-user-item": "user-item"}
			direction="row" 
			alignItems="center"
			justifyContent="space-between"			
			padding={1}
			spacing={2}
		>
			<Stack 
				spacing={2} 
				direction="row" 
				alignItems="center"
				maxWidth="65%"
				onClick={goToCard}
			>
				{avatar ? (
					<Avatar alt={name} src={avatar} />
				) 
				: 
				(
					<Avatar {...stringAvatar(name)} />
				)}				

				<Typography variant="body2" noWrap>
					{name}
				</Typography>
			</Stack>

			<Stack 
				spacing={0} 
				direction="row" 
				alignItems="center"
			>
				{role != "admin" && role != "owner" && (myRole == "admin" || myRole == "owner") && isRequest && (						
					<IconButton aria-label="check" color="success" onClick={acceptUserRequest}>
						<CheckIcon />
					</IconButton>
				)}
				{role != "admin" && role != "owner" && (myRole == "admin" || myRole == "owner") && 				
				<IconButton aria-label="delete" color="error" onClick={isRequest ? declineUserRequest : banUser}>
					<DeleteIcon />
				</IconButton>
				}
				{role != "admin" && !isRequest && me &&
					<Chip label={<FormattedMessage id="leave_community" />} color="error" variant="outlined" onClick={handleLeave}></Chip> 				
				}
			</Stack>
		</Stack>
	);
};

export default UserItem;
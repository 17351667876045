
import {numOfWidthSlots, } from "../../../utils/common"
import {MiniChatWidth, MiniChatMobileWidth} from "../../../constants"






export const addToNewChats = (UserID, nickname, icon, opened, newChats, slots)=>{
    let i = 0;
    while (i < newChats.length) {
        if (newChats[i].occasionID == UserID && newChats[i].occasionType == "personal") {
            break
        }
        i++;
    }
    let lastIndexToSet = slots - 1;
    if (i == newChats.length) {
        console.log("ASASAS CHATS HAS NO", slots, newChats.length, newChats.length)
        let newChat = {
            occasionID: UserID,
            occasionType: "personal",
            title: nickname,
            icon: icon,
            opened: opened,
        }
        if (newChats.length < slots) {
            newChats.push(newChat)
        } else {
            let tempLastChat = newChats[lastIndexToSet]
            newChats[lastIndexToSet] = newChat
            newChats.push(tempLastChat)
        }
    } else {
        console.log("ASASAS CHATS HAS IT",)
        newChats[i] = {...newChats[i], opened: opened}
        if (i > lastIndexToSet) {
            let tempLastChat = newChats[lastIndexToSet]
            newChats[lastIndexToSet] = newChats[i]
            newChats[i] = tempLastChat
        } else {
            // ALREADY OPENED. KEEP THE SAME?
            newChats[i].opened = opened
        }
    }
    return newChats
}

export const showChat = (UserID, nickname, icon, opened, openedPersonalChats, setOpenedPersonalChats)=>{
    let mobile = false
    if (window.matchMedia("(max-width: 899px)").matches) {
        mobile = true
    }
    let slots = numOfWidthSlots(mobile ? MiniChatMobileWidth : MiniChatWidth);
    console.log("ASASAS CHATS SLOTS", slots, openedPersonalChats.length)
    let newChats = [...openedPersonalChats]
    newChats = addToNewChats(UserID, nickname, icon, opened, newChats, slots)
    console.log("ASASAS CHATS SET", slots, newChats)
    setOpenedPersonalChats(newChats)
}
import {domainName} from "../environment";
import AuthService from "../services/AuthService";
import {SuccessMessage, EndSaltLength} from "../constants";
import {makeString} from "../utils/common"
import React, { useEffect } from 'react';

import {
    useRecoilState,
  } from 'recoil';
  import {Authorized, AuthTokens} from './atoms/AuthAtoms'


function AuthCheck() {
    const [authorized, setAuthorized] = useRecoilState(Authorized);
    const [authTokens, setAuthTokens] = useRecoilState(AuthTokens);

    useEffect(() => {
        let authToken = localStorage.getItem('AuthToken');
        let refreshToken = localStorage.getItem('RefreshToken');
        if (!authorized.Authorized && authToken !== null && refreshToken !== null && authToken !== "" && refreshToken !== "") {
            authToken = authToken.slice(0, -EndSaltLength);
            refreshToken = refreshToken.slice(0, -EndSaltLength);
            setAuthTokens({AuthToken: authToken, RefreshToken: refreshToken})
            setAuthorized({Authorized: true})
        } else if (!authorized.Authorized) {
        }
    }, []);

    return (
        <div></div>
    )
}

export default AuthCheck;

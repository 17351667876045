import {
    atom,
} from 'recoil';

export const IamaliveWSOpened = atom({
    key: 'IamaliveWSOpened',
    default: {
        open: false,
    },
})

export const IamaliveWS = atom({
    key: 'IamaliveWS',
    default: {
        ws: null,
    },
})

export const IamaliveWSRestartTimeout = atom({
    key: 'IamaliveWSRestartTimeout',
    default: {
        timeout: 0,
    },
})
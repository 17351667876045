import { Typography, } from '@mui/material';
import {FormattedMessage} from "react-intl";
import {AllExperience, AddPointsNumber} from "../../atoms/Ratings";
import {useRecoilState,} from 'recoil';
import React, { useState, useEffect } from 'react';
import {sendMsgSite} from "../../SiteController";
import {SiteWS,} from "../../atoms/SiteAtoms"



function ExperienceIndicator(props) {
    const [allExperience, setAllExperience] = useRecoilState(AllExperience)
    const [addPointsNumber, setAddPointsNumber] = useRecoilState(AddPointsNumber)
    const [siteWS, setSiteWS] = useRecoilState(SiteWS)

    
    const panelExpStyle = {
        width: "300px",
        height: "80px",
        backgroundColor: "#6d6d6d",
        position: "absolute",
        right: "0px",
        top: "70px",
        zIndex: 9999,
    }
    const fullLineStyle = {
        width: "250px",
        position: "absolute",
        left: "50%",
        top: "50%",
        height: "40px",
        backgroundColor: "#ffffff",
        transform: "translate(-50%, -50%)",
        borderRadius: "10px",
    }
    let lineWidth = allExperience.Experience / allExperience.EdgeExperience * 250
    const experienceLevelLineStyle = {
        height: "100%",
        width: lineWidth+"px",
        backgroundColor: "#7dcff5",
        borderRadius: "10px 0px 0px 10px",
    }
    const experienceLevelNumbersStyle = {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        color: "#65307a",
    }
    const [showPanel, setShowPanel] = useState(false);


    const addStartSize = 50
    const addBiggestSize = 90
    const steps = 70
    const millisFor1 = 200
    const millisFor2 = 100
    const millisFor3 = 200    
    const millisFor4 = 1800
    const smallestSize = 10

    const [fontSize, setFontSize] = useState(addStartSize);
    const [state, setState] = useState(-1);
    const [expAnimation, setExpAnimation] = useState(0);
    const [plusExpAnimation, setPlusExpAnimation] = useState(0);
    useEffect(() => {
        let timeoutMe = 0
        switch (state) {
            case -1:
                if (addPointsNumber.Points > 0) {
                    setExpAnimation(allExperience.Experience)
                    setPlusExpAnimation(addPointsNumber.Points)
                    setState(0)
                    setShowPanel(true)
                    sendMsgSite(siteWS.ws, "RequestAllExperienceState", {})
                } 
                break;        
            case 0:
                timeoutMe = setTimeout(() => {
                    let size = fontSize + (addBiggestSize - addStartSize) / steps
                    setFontSize(size)
                }, millisFor1 / steps)
                if (addBiggestSize <= fontSize) {
                    setState(1)
                }
                break;
            case 1:
                timeoutMe = setTimeout(() => {
                    let size = fontSize - (addBiggestSize - addStartSize) / steps
                    setFontSize(size)
                }, millisFor2 / steps)
                if (addStartSize >= fontSize) {
                    setState(2)
                }
                break;
            case 2:
                timeoutMe = setTimeout(() => {
                    let size = fontSize + (addBiggestSize - addStartSize) / steps
                    setFontSize(size)
                }, millisFor3 / steps)
                if (addBiggestSize <= fontSize) {
                    setState(3)
                }
                break;
            case 3:
                timeoutMe = setTimeout(() => {
                        let size = fontSize - (addBiggestSize - smallestSize) / steps
                        setFontSize(size)
                        setExpAnimation(expAnimation + addPointsNumber.Points / steps)
                        setPlusExpAnimation(plusExpAnimation - addPointsNumber.Points / steps)
                }, millisFor4 / steps)                
                if (smallestSize >= fontSize) {
                    setState(4)
                }
                break;
            case 4:
                if (addPointsNumber.Points > 0) {
                    setAddPointsNumber({Points: 0})
                }
                if (showPanel) {
                    setShowPanel(false)
                }
                setState(-1)                
                break                   
        }
    }, [addPointsNumber, state, fontSize, allExperience, expAnimation, plusExpAnimation])

    let pointsToAddStyle = {
        fontWeight: "bold", 
        fontSize: fontSize+"px",
        position: "absolute",
        top: "90px",
        left: "50%",
        color: "#40e33d",
        transform: "translate(-50%, 0%)",
        zIndex: 9999,
    }
    const mouseOverHandler = ()=>{
        setShowPanel(true)
    }
    const mouseLeaveHandler = ()=>{
        setShowPanel(false)
    }
    return (
        <div onMouseEnter={mouseOverHandler} onMouseLeave={mouseLeaveHandler}>
            <Typography style={{cursor: "pointer"}}>{allExperience.Level >= 0 && allExperience.Level} <FormattedMessage id="lev" /></Typography>
            {showPanel && <div style={panelExpStyle}>
                <div style={fullLineStyle}>
                    <div style={experienceLevelLineStyle}></div>
                    <Typography style={experienceLevelNumbersStyle}>{state == -1 || state == 5 ? allExperience.Experience : Math.round(expAnimation)}/{allExperience.EdgeExperience}</Typography>
                </div>
                {state != -1 && state != 5 && <div style={pointsToAddStyle}>+{Math.round(plusExpAnimation)}</div>}
            </div> }
        </div>
    )
}

export default ExperienceIndicator;
import { Box } from '@mui/system';
import {FormattedMessage} from "react-intl";
import React, {useState} from 'react';
import { TextField } from '@mui/material';





const MaxNotesLength = 5000



const GameNotes = (props) => {
  let notesStory = localStorage.getItem('IamaliveNotes')
  if (!notesStory) {
    notesStory = ""
  }

  const [notes, setNotes] = useState(notesStory);
	const [notesError, setNotesError] = useState(null);

  const changeNotes = (event)=>{
		if (event.target.value.length > MaxNotesLength) {
			setNotesError(<span><FormattedMessage id="cantbelonger"/> {MaxNotesLength}</span>)
      return
		} else {
			setNotesError(null)
		}
		setNotes(event.target.value);
    localStorage.setItem('IamaliveNotes', event.target.value);
  }
    return (
        <Box
        sx={{
          minHeight: '100vh',
          bgcolor: 'background.default',
          color: 'text.primary',
        }}
      >
							<TextField
                sx={{
                    width: "100%",
                    height: "100%",
                }}
								value={notes}
								onChange={changeNotes}
								label={<FormattedMessage id="game_notes" />}
								multiline
								rows={15}
                helperText={notesError}
								error= {notesError != null}
							/>
      </Box>
    )
}

export default GameNotes
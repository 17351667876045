import React from 'react';
import { Alert, Button, Card, CardContent, Chip, Divider, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';

import './rate.scss';
import { FormattedMessage } from 'react-intl';

const Rate = ({
	rateTirle,
	features,
	current,
	daysLeft,
	actionText,
	onClick,
	onClickTurnOffProlongation,
	callToActionText,
	haveProlongation,
}) => {
	return (
		<Card className='rate'>
			<CardContent>
				<Stack 
					direction="column"
					spacing={2}
				>
					<Stack 
						direction="column"
						alignItems="center"
						className='rate__header'
						paddingY={2}
					>
						<Stack 
							direction="row"
							alignItems="center"
							justifyContent="center"
							spacing={1}
						>
							<Typography variant="h5">
								{rateTirle}
							</Typography>
							
							{current && (
								<Chip 							
									label={<FormattedMessage id="your_tariff" />} 
									size="small" 
									color="success"
								/>
							)}
						</Stack>					

						<Typography variant="subtitle2">
							<FormattedMessage id="account" />
						</Typography>
					</Stack>

					<Divider />

					{current && daysLeft && (
						<Alert severity="info">
							<FormattedMessage id="still_valid" />:
							&nbsp;
							{daysLeft}
						</Alert>
					)}

					<List>
						{features.map((item, index) => (
							<ListItem key={index}>
								<ListItemText
									primary={item}
								/>
							</ListItem>
						))}						
					</List>					

					{onClick && (
						<>
							<Divider />
							
							{haveProlongation ? 
								<Stack 
									alignItems="center"
									className='rate__footer'
									paddingY={2}
									spacing={2}
								>
									<Typography variant="h6" align="center">
										<FormattedMessage id="you_have_subusciption_prolongation" />
									</Typography>
									<Button variant="contained" size="large" onClick={onClickTurnOffProlongation}>
										<FormattedMessage id="turn_off_prolongation" />								
									</Button>
								</Stack>							
								: <Stack 
									alignItems="center"
									className='rate__footer'
									paddingY={2}
									spacing={2}
								>			
									<Typography variant="h6" align="center">
										{callToActionText}
									</Typography>

									<Button variant="contained" size="large" onClick={onClick}>
										{actionText}
									</Button>
								</Stack>
							}			
						</>
					)}					
				</Stack>
			</CardContent>
		</Card>
	);
};

export default Rate;

import { Button, Grid, Slider, Stack, Switch, TextField, Typography, Box, IconButton, Tooltip, Divider } from '@mui/material';
import {GeneralizeGameState, GeneralizeTeamsState, ProcessTime, } from "../atoms/GameState"
import React, { useState, useEffect } from 'react';
import {useRecoilState} from "recoil";
import {GetMyTeamID, GetIsMaster} from "../GeneralizeLayout"
import HelpIcon from '@mui/icons-material/Help';
import AddBox from '@mui/icons-material/AddBox';
import {FormattedMessage} from "react-intl";
import {MaxGeneralizeWordLength} from "../../../../../constants"
import {sendMsgGeneralize} from "../GeneralizeGameController"
import {GeneralizeWS} from "../atoms/Connection"
import AlarmIcon from '@mui/icons-material/Alarm';
import {SoundTheme, UseSoundAtom, SoundLevelAtom} from "../../../../atoms/Common"
import useSound from 'use-sound';

const twoDigits = function(d) {
	if (d < 10) {
		return "0"+d;
	} else {
		return d;
	}
}

function secondsToMinSecs(seconds) {
    if (seconds == 0) {
        return ""
    }
    let secs = seconds % 60
    let mins = Math.floor(seconds/60)
    return twoDigits(mins)+":"+twoDigits(secs)
}

function TeamState(props) {
    const [generalizeTeamsState, setGeneralizeTeamsState]  = useRecoilState(GeneralizeTeamsState);
    const [generalizeGameState, setGeneralizeGameState]  = useRecoilState(GeneralizeGameState);
	const [generalizeWS, setGeneralizeWS] = useRecoilState(GeneralizeWS);
    const [processTime, setProcessTime] = useRecoilState(ProcessTime);

	const [soundTheme, setSoundTheme]  = useRecoilState(SoundTheme);
	const [useSoundAtom, setUseSoundAtom]  = useRecoilState(UseSoundAtom);
    const [soundLevelAtom, setSoundLevelAtom] = useRecoilState(SoundLevelAtom);

    const [myTeamID, setMyTeamID] = useState(0)
    const [isMaster, setIsMaster] = useState(false)
    const [generalizeWord, setGeneralizeWord] = useState("");
	const [generalizeWordError, setGeneralizeWordError] = useState(null);
    const [wordsList, setWordsList] = useState("");
    const [secondsLeft, setSecondsLeft] = useState(0);

	const [playLastSeconds] = useSound(
		'/sounds/themes/'+soundTheme.theme+'/generalize/tik.mp3',
		{ volume: soundLevelAtom }
	);


    useEffect(()=> {
        let interval = null;
		if (processTime.Updated) {
			setSecondsLeft(processTime.SecondsLeft)
			setProcessTime({Updated: false, SecondsLeft: 0})
		}
		if(generalizeGameState.Phase != "picking" && generalizeGameState.Phase != "ended" && secondsLeft > 0) {
			interval = setInterval(() => {
				setSecondsLeft(secondsLeft => secondsLeft - 1);
			}, 1000);
            if (useSoundAtom && secondsLeft <= 7 && secondsLeft > 0) {
                playLastSeconds()
            }
		} else if(secondsLeft <= 0) {
			clearInterval(interval);
		}		

    	return () => clearInterval(interval);
    }, [generalizeGameState, processTime, secondsLeft, soundLevelAtom, useSoundAtom])

    useEffect(()=> {
        setMyTeamID(GetMyTeamID(props.myposition, generalizeTeamsState))
        setIsMaster(GetIsMaster(props.myposition, generalizeTeamsState))
        if (generalizeTeamsState.Teams && generalizeTeamsState.Teams[props.teamID] && generalizeTeamsState.Teams[props.teamID].Words != null) {
            let words = []
            let i = 0;
            for (let word of generalizeTeamsState.Teams[props.teamID].Words) {
                i++
                words.unshift(<div key={i} style={{width: "100%", overflowWrap: "break-word"}}>{word}</div>)
            }
            setWordsList(words)
        }
    }, [generalizeTeamsState]);
	const changeGeneralizeWord = (event) => {
        if (event.target.value.length > MaxGeneralizeWordLength) {
			setGeneralizeWordError(<span><FormattedMessage id="cantbelonger"/> {MaxGeneralizeWordLength}</span>)
			return
		} else {
			setGeneralizeWordError(null)
		}
		setGeneralizeWord(event.target.value);
    }
    const addWord = ()=>{
        if (generalizeWord.length > MaxGeneralizeWordLength) {
            return
        }
        sendMsgGeneralize(generalizeWS.ws, "GeneralizationWord", {Word: generalizeWord})
        setGeneralizeWord("")
        setGeneralizeWordError(null)
    }


    let background = "#eb746c"//"#c94d4d"
    if (props.teamID == 2) {
        background = "#6d82ed"//"#4b60c9"
    }
    return (
        <Stack
        direction="column"
         style={{position: "relative", height: "400px", backgroundColor: background, marginTop: "40px", color: "black"}}>
            <div style={{padding: "10px"}}>
                {generalizeGameState.TeamIDTurn == props.teamID ?  
                       <span> {myTeamID == props.teamID ? 
                            generalizeGameState.Phase == "learning" ? 
                                isMaster ?
                                    <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap',}}><FormattedMessage id="your_turn"/>  <Tooltip style={{paddingLeft: "8px"}} title={<Typography variant='body1'><FormattedMessage id="learn_words_and_make_a_turn"/></Typography>} ><HelpIcon/></Tooltip></div>
                                :
                                    <FormattedMessage id="master_turn"/>
                            : 
                            generalizeGameState.Phase == "making_word_number" ?
                                isMaster ?
                                    <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap',}}><FormattedMessage id="your_turn"/>  <Tooltip style={{paddingLeft: "8px"}} title={<Typography variant='body1'><FormattedMessage id="make_a_turn_as_master"/></Typography>} ><HelpIcon/></Tooltip></div>
                                :     
                                    <FormattedMessage id="master_turn"/>
                            :
                            generalizeGameState.Phase == "guessing" ?
                                isMaster ?
                                    <FormattedMessage id="players_turn"/>
                                :
                                    <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap',}}><FormattedMessage id="your_turn"/>  <Tooltip style={{paddingLeft: "8px"}} title={<Typography variant='body1'><FormattedMessage id="guess_which_words_master_generalized"/></Typography>} ><HelpIcon/></Tooltip></div>
                            : null
                        : 
                            generalizeGameState.Phase == "learning" ? 
                                <FormattedMessage id="master_turn"/>
                            :
                            generalizeGameState.Phase == "making_word_number" ?
                                <FormattedMessage id="master_turn"/>
                            :
                            generalizeGameState.Phase == "guessing" ?
                                <FormattedMessage id="players_turn"/>
                            : null}
                        <div><Stack direction="row" alignItems="center" justifyContent="center" sx={{padding: "1px"}}>{secondsToMinSecs(secondsLeft)}<AlarmIcon/></Stack></div></span>
                : null}
            </div>                    
            <Divider />
            {generalizeGameState.TeamIDTurn == props.teamID && myTeamID == props.teamID && isMaster && (generalizeGameState.Phase == "learning" || generalizeGameState.Phase == "making_word_number" || (generalizeGameState.Phase == "guessing" && !generalizeGameState.MadeWordAndNumber)) ?
                <div><div style={{backgroundColor: "white", }}><TextField
                    sx={{ input: { color: 'black' } }}
                    fullWidth 
                    value={generalizeWord}
                    onChange={changeGeneralizeWord}
                    id="generalize_word" 
                    label={<FormattedMessage id="generalize_word" />}
                    variant="filled" 
                    size='small'
                    helperText={generalizeWordError}
                    error= {generalizeWordError != null}                    
                
                ></TextField></div><Button variant="contained" onClick={addWord} endIcon={<AddBox style={{width: "30px"}}/>}><FormattedMessage id="add"/></Button></div>    
            : null}
            <div style={{overflow: "auto"}}>
                {wordsList}
            </div>
            <div style={{position: "absolute", bottom: "10px", left: "50%", transform: "translate(-50%, 0%)", }}><Typography variant='h1'>{generalizeTeamsState.Teams && generalizeTeamsState.Teams[props.teamID] && generalizeTeamsState.Teams[props.teamID].WordsToGuess}</Typography></div>
        </Stack>
    )
}

export default TeamState

import {
    atom,
} from 'recoil';

export const CommunityStatus = atom({
    key: 'CommunityStatus',
    default: "not_authorized"
})

export const UpdateUsersList = atom({
    key: 'UpdateUsersList',
    default: 0
})